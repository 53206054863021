import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'give-feedback-component',
  templateUrl: 'give-feedback.component.html',
  styleUrls: ['../../home-v2.component.scss'],
})
export class GiveFeedbackComponent {

  @Output()
  onResult: EventEmitter<any>;

  constructor(
    public dialogRef: MatDialogRef<GiveFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.onResult = new EventEmitter();
  }

  onFeedbackResult($event): void {
    this.onResult.emit($event);
    if ('isCanceled' in $event && $event.isCanceled) {
      this.onNoClick();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
