import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  allComps: Array<any> = [];
  allTypes: Array<any> = [];
  allStatuses: Array<any> = [];
  selectedComps: Array<any> = [];
  selectedTypes: Array<any> = [];
  selectedStatuses: Array<any> = [];
  selectedComp: Array<string> = [];
  selectedType: Number;
  selectedStatus: Array<Number> = [];
  onlyActive: Boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.allComps = this.data.allComps;
    this.allTypes = this.data.allTypes;
    this.allStatuses = this.data.allStatuses;
    this.selectedComps = this.data.selectedComps;
    this.selectedComp = this.selectedComps.map(c => c._id);
    this.selectedTypes = this.data.selectedTypes;
    if (this.selectedTypes && this.selectedTypes.length) {
      this.selectedType = this.selectedTypes[0].value;
    }
    this.selectedStatuses = this.data.selectedStatuses;
    this.selectedStatus = this.selectedStatuses.map(c => c.value);
    this.onlyActive = this.data.onlyActive;
  }

  clear(): void {
    this.selectedComps = [];
    this.selectedTypes = [];
    this.selectedStatuses = [];
    this.onlyActive = false;
    this.applyFilter();
  }

  applyFilter(): void {
    console.log(this.onlyActive);
    const data = {
      selectedComps: this.selectedComps,
      selectedTypes: this.selectedTypes,
      selectedStatuses: this.selectedStatuses,
      onlyActive: this.onlyActive
    };
    this.dialogRef.close({ success: true, isCanceled: false, data });
  }

  removeComp(comp): void {
    this.selectedComps = this.selectedComps.filter(c => c._id !== comp._id);
    this.selectedComp = this.selectedComps.map(c => c._id);
  }

  removeType(type): void {
    this.selectedTypes = this.selectedTypes.filter(c => c.value !== type.value);
    this.selectedType = null;
  }

  removeStatus(status): void {
    this.selectedStatuses = this.selectedStatuses.filter(c => c.value !== status.value);
    this.selectedStatus = this.selectedStatuses.map(c => c.status);
  }

  selectComp(event): void {
    const value = event.value;
    this.selectedComps = this.allComps.filter(c => {
      const found = value.find(item => item === c._id);
      return !!found;
    });
  }

  selectType(event): void {
    const value = event.value;
    this.selectedTypes = [];
    this.selectedTypes = this.allTypes.filter(c => c.value === value);
  }

  selectStatus(event): void {
    const value = event.value;
    this.selectedStatuses = this.allStatuses.filter(c => {
      const found = value.find(item => item === c.value);
      return !!found;
    });
  }

  selectOnlyActive(event): void {
    // this.onlyActive = event.checked;
  }

}
