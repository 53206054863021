import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'delete-task-confirmation-component',
  templateUrl: 'delete-task-confirmation.component.html',
  styleUrls: ['delete-task-confirmation.component.scss'],
  animations: fuseAnimations
})
export class DeleteTaskConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteTaskConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  delete(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
