import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'competency-filter-component',
  templateUrl: 'competency-filter-component.html',
  styleUrls: ['../../my-journeys.component.scss'],
  providers: []
})
export class CompetencyFilterComponent {

  competencies: Array<any> = [];
  selectedCompetencies: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<CompetencyFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.competencies = data.competencies;
    this.selectedCompetencies = data.selectedCompetencies;
  }

  clearFilter(): void {
    this.selectedCompetencies = [];
  }

  apply(): void {
    this.dialogRef.close(this.selectedCompetencies);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
