import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatRadioModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSidebarModule } from '@fuse/components/sidebar/sidebar.module';
import { FuseMaterialColorPickerModule } from '@fuse/components/material-color-picker/material-color-picker.module';

import { HelpComponent } from 'app/core/help/help.component';
import { HelpService } from 'app/core/help/help.service';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,

    FuseDirectivesModule,
    FuseMaterialColorPickerModule,
    FuseSidebarModule,
    SharedDirectivesModule,
    LevelIndicatorModule
  ],
  declarations: [
    HelpComponent
  ],
  exports: [
    HelpComponent
  ],
  providers: [
    HelpService
  ]
})
export class HelpModule { }
