import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class AssessmentListService {
    constructor(
        private http: HttpClient,
        private requestHelpers: RequestHelpers,
        private localStorageService: LocalStorageService
    ) { }

    fetchAssessments(body): Observable<any> {
        return this.http.post(
            environment.AUKAT_BASE_URL + environment.AUKAT_GET_USER_ASSESSMENTS,
            body,
            this.requestHelpers.getBFFHeader()
        );
    }

}
