
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatGridListModule,
  MatCardModule,
  MatTabsModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,

  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatSelectModule,
  MatSortModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatToolbarModule

} from '@angular/material';
import { DialogMenuComponent } from '../search/dialog-menu/dialog-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [{
  path: 'search',
  component: SearchComponent
}]

@NgModule({

  entryComponents: [DialogMenuComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forChild(routes),
    DragScrollModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseWidgetModule,
    ChatPanelModule,
    FuseSharedModule,
    CorePipesModule,
    FuseSidebarModule,
    ChatPanelModule,

    LevelIndicatorModule,
    SharedDirectivesModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    FuseWidgetModule,
    MatProgressBarModule,
    DragScrollModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    // SearchContentLibraryModule,

    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,

    MatSelectModule,

    MatSortModule,

    MatBadgeModule,
    MatListModule,
    MatChipsModule,

    MatToolbarModule,
    CommonComponentsModule,
    FormsModule,
    SvgIconModule,

    DragScrollModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    SearchComponent,
    DialogMenuComponent
  ],


  exports: [
    SearchComponent
  ],

  providers: [
    SearchComponent,
  ]
})
export class SearchModule { }
