import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appDate'
})

export class AppDatePipe implements PipeTransform {

  transform(value: any, showTime: boolean = false): any {

    if (value) {
      if (showTime) {
        return moment(value).format('MMM DD, YYYY h:mm a');
      } else {
        return moment(value).format('MMM DD, YYYY');
      }
    }

    return value;
  }
}
