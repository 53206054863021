import { Component, OnInit, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { JourneyReportService } from './journey-report.service';
import { JourneyStatus } from 'app/enums/journey-status';
import { JourneyModel } from 'app/models/journey-model'
import { JourneyAccessRoles } from 'app/enums/journey-access-roles'
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { JourneyReportDialogComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-report/journey-report.component';
import { RetakeJourneyConfirmation } from './RetakeJourneyConfirmation/RetakeJourneyConfirmation.component';

@Component({
  selector: 'journey-report',
  templateUrl: './journey-report.component.html',
  styleUrls: ['./journey-report.component.scss'],
  animations: fuseAnimations
})
export class JourneyReportComponent implements OnInit {

  @Input() journeyId: String;
  @Input() isManager: Boolean;
  @Input() journey: JourneyModel;
  @Input() showControls: Boolean;

  user: User;
  reportData: any;
  reportExists: boolean;
  isRetakingJourney: boolean;
  customizableChecked: boolean;
  colorScheme = {
    domain: ['#2ec5e8']
  };
  customColors = [
    {
      name: 'End Self Score',
      value: '#0000ff'
    },
    {
      name: 'End Supporter Score',
      value: '#0000ff'
    }
  ];
  displayedColumns: string[] = ['indicator', 'result'];
  journeyStatus = JourneyStatus;
  JourneyAccessRoles = JourneyAccessRoles;
  constructor(
    private localStorageService: LocalStorageService,
    private journeyReportService: JourneyReportService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.getJourneyReportData(this.journeyId);
  }

  getJourneyReportData(journeyId): void {
    this.journeyReportService.getReportData(this.user.id, false, this.user.orgId, journeyId)
      .subscribe((data: any) => {

        this.reportExists = false;
        if (data.resource.length > 0) {
          this.reportExists = true;

          this.reportData = data.resource[0];
          if (!this.reportData.daysTakenToCompleteJourney) {
            this.reportData['daysTakenToCompleteJourney'] = 0;
          }
          if (!this.reportData.fbResponsesReceived) {
            this.reportData['fbResponsesReceived'] = 0;
          }
          if (!this.reportData.leaderboardScore) {
            this.reportData['leaderboardScore'] = 0;
          }

          if (!this.reportData.KCResult || !this.reportData.KCResult.averageKCAttempts) {
            this.reportData['KCResult']['averageKCAttempts'] = 0;
          }
          if (!this.reportData.KCResult || !this.reportData.KCResult.averageKCScore) {
            this.reportData['KCResult']['averageKCScore'] = 0;
          }

          this.reportData.KCResult.averageKCScore = this.reportData.KCResult.averageKCScore === 'NaN' ? 0 : this.reportData.KCResult.averageKCScore;
          this.reportData.KCResult.averageKCAttempts = this.reportData.KCResult.averageKCAttempts === 'NaN' ? 0 : this.reportData.KCResult.averageKCAttempts;
        }
      },
        (error: any) => {
          console.log(error);
        }
      );
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  openJourneyReportDialog(): void {
    const dialogRef = this.dialog.open(JourneyReportDialogComponent, {
      width: '90vw',
      height: '50vw',
      data: {
        journeyId: this.journey._id,
        isManager: this.isManager,
        journey: this.journey
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  retakeJourneyConfirmation() {
    const dialogRef = this.dialog.open(RetakeJourneyConfirmation, {
      panelClass: 'app-dialog-box',
      width: '236px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.retakeJourney();
      }
    });
  }
  retakeJourney(): void {
    this.isRetakingJourney = true;
    const postObj = {
      journeyId: this.journeyId,
      keepCustomization: this.customizableChecked
    };

    this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_RESTART_JOURNEY, postObj, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.openSnackBar('Request sent', 'Ok');
          this.router.navigate(['/journeys-take/' + data['resource'][0]['_id']]);
          this.isRetakingJourney = false;
        },
        error => {
          this.requestHelpers.catchExceptions(error);
          this.isRetakingJourney = false;
          this.openSnackBar('Something went wrong!', 'Ok');
        }
      );
  }
}

