import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { JourneyListService } from './journey-list.service';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyFilterModel } from 'app/models/filter-model';
import { JourneyListTypes } from 'app/enums/journey-list-type';
import { LocalStorageService } from 'ngx-store';
import { User } from 'app/models/user';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'journey-list',
  templateUrl: './journey-list.component.html',
  styleUrls: ['./journey-list.component.scss']
})

export class JourneyListComponent implements OnInit, OnChanges, OnDestroy {
  dataLength = 0;
  pageSize = 10;
  displayedColumns: string[] = [];
  dataSource: Array<any> = [];
  user: User;
  filterData: any;
  selectedStatuses: Array<any> = [];
  selectedCompetencies: Array<String> = [];
  selectedOwners: Array<Number> = [];
  isTableFetching: Boolean = true;
  routeSubscription: any;
  pageIndex = 0;
  fetchJourneySubscription;

  constructor(
    private service: JourneyListService,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
  ) { }

  @Input() type: JourneyListTypes;
  @Input() status: Array<Number>;
  @Input() filters: JourneyFilterModel;
  @Input() ownerId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');

    switch (this.type) {
      case JourneyListTypes.myJourneys:
      case JourneyListTypes.othersJourneys:
      case JourneyListTypes.reporteeJourneys:
        this.displayedColumns = [
          'title',
          'competency.displaytext',
          'dueDate',
          'status',
          'attempt',
          'completedTasks'
        ];
        break;
      case JourneyListTypes.mySupportedJourneys:
      case JourneyListTypes.othersSupportedJourneys:
      case JourneyListTypes.reporteeSupportedJourneys:
        this.displayedColumns = [
          'title',
          'competency.displaytext',
          'owner',
          'dueDate',
          'status',
          'attempt',
          'completedTasks'
        ];
        break;
    }

    this.selectedStatuses = this.status ? this.status : [];
    this.fetchJourneys();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filters: SimpleChange = changes.filters;
    if (!filters.firstChange) {
      const current = <JourneyFilterModel>filters.currentValue;
      if (current && current.comps) {
        this.selectedCompetencies = current.comps.map(comp => comp._id);
        this.selectedOwners = current.owners.map(owner => owner.id);
        this.paginator.pageIndex = 0;
        this.pageIndex = 0;
        this.fetchJourneys();
      }
    }
  }

  onPaginateChange(event): void {
    this.pageIndex = event.pageIndex;
    this.fetchJourneys();
  }

  fetchJourneys = () => {

    if (this.fetchJourneySubscription
    ) {
      this.fetchJourneySubscription
        .unsubscribe();
    }

    this.isTableFetching = true;
    const order = 'createdOn asc';

    const filterList = [];

    const latestAttemptFilter = '(newJourneyCreated IS NULL)';
    filterList.push(latestAttemptFilter);

    if (this.selectedCompetencies.length) {
      const compFilter = `(competencyId IN (${this.selectedCompetencies.join(',')}))`;
      filterList.push(compFilter);
    }

    if (this.selectedOwners.length) {
      const ownerFilter = `(userId IN (${this.selectedOwners.join(',')}))`;
      filterList.push(ownerFilter);
    }

    if (this.type === JourneyListTypes.mySupportedJourneys) {
      const supportedJourneyFilter = `(supporters.userId=${this.user.id})`;
      filterList.push(supportedJourneyFilter);
    }
    if (this.type === JourneyListTypes.reporteeSupportedJourneys) {
      const supportedJourneyFilter = `(supporters.userId=${this.ownerId})`;
      filterList.push(supportedJourneyFilter);
    }
    if (this.type === JourneyListTypes.othersJourneys) {
      const supportedJourneyFilter = `(supporters.userId=${this.user.id})`;
      filterList.push(supportedJourneyFilter);
    }
    if (this.type === JourneyListTypes.othersSupportedJourneys) {
      const supportedJourneyFilter = `(supporters.userId=${this.user.id}) AND (supporters.userId=${this.ownerId})`;
      filterList.push(supportedJourneyFilter);
    }

    if (this.selectedStatuses.length) {
      const statusFilter = `(status IN (${this.selectedStatuses.join(',')}))`;
      filterList.push(statusFilter);
    }

    let filter;
    if (filterList.length > 1) {
      filter = `&filter=(${filterList.join(' AND ')})`;
    } else if (filterList.length === 1) {
      filter = `&filter=${filterList[0]}`;
    }

    let userId;
    if (this.type === JourneyListTypes.myJourneys) {
      userId = this.user.id;
    }
    if (this.type === JourneyListTypes.reporteeJourneys || this.type === JourneyListTypes.othersJourneys) {
      userId = this.ownerId;
    }

    this.fetchJourneySubscription
      = this.service.fetchJourneys({ filter, pageIndex: this.pageIndex, pageSize: this.pageSize, order, userId })
        .subscribe(
          data => {
            this.dataSource = data['resource'];
            this.dataLength = data['meta']['count'];
            this.isTableFetching = false;
          },
          error => {
            this.isTableFetching = false;
            this.requestHelpers.catchExceptions(error);
          }
        );
  }


  ngOnDestroy(): void {
    if (this.fetchJourneySubscription) {
      this.fetchJourneySubscription.unsubscribe();
    }
  }


}
