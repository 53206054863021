import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'curated-content-preview-component',
  templateUrl: 'curated-content-preview.component.html',
})
export class CuratedContentPreviewComponent {
  constructor(
    public dialogRef: MatDialogRef<CuratedContentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
