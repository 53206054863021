export class Competency {
    public _id: String;
    public name: String;
    public definition: String;
    public displaytext: String;
    public tag: Array<any>;
    public disabled: Boolean;
    public status: Number;
    public type: Number;
    public createdOn: String;
    public updatedOn: String;
};