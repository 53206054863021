import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { User } from 'app/models/user';

@Component({
  selector: 'journey-completed.component',
  templateUrl: 'journey-completed.component.html',
})
export class JourneyCompletedComponent {
  journeyId: any;
  user: any;
  constructor(
    public dialogRef: MatDialogRef<JourneyCompletedComponent>,
    public snackBar: MatSnackBar,
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = <User>this.localStorageService.get('user');
    this.journeyId = data['journeyId'];
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  clickedOkay(): void {
    this.dialogRef.close();
    this.router.navigate(['./journeys']);
  }

}
