import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AssessmentComponent } from './assessment.component';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { MatProgressButtons } from 'mat-progress-buttons';
import { CountdownModule } from 'app/shared/countdown/countdown.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatRadioModule,
  MatExpansionModule
} from '@angular/material';

import { RequestHelpers } from 'app/services/request-helpers.service';


@NgModule({
  declarations: [
    AssessmentComponent
  ],
  imports: [
    TranslateModule,
    FuseSharedModule,
    BrowserAnimationsModule,

    // theme modules
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatSortModule,
    MatGridListModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MatCardModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatRadioModule,
    CountdownModule,
    MatExpansionModule,
    // External libraries
    MatProgressButtons,
    MatProgressBarModule,
    // Shared modules
    SharedDirectivesModule
  ],
  exports: [
    AssessmentComponent
  ],
  providers: [RequestHelpers]
})

export class AssessmentModule { }
