import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<any>();
  public onAssmntCompDestroyed = new Subject<any>();

  sendMessage(message: any): void {
    this.subject.next(message);
  }

  // sendChatActivated(message: any): void {
  //   this.subject.next(message);
  // }

  clearMessage(): void {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // getChatActivated(): Observable<any> {
  //   return this.subject.asObservable();
  // }

  sendTeamFilter(message: any): void {
    this.subject.next(message);
  }

  getTeamFilter(): Observable<any> {
    return this.subject.asObservable();
  }

  sendChatMessage(message: any): void {
    this.subject.next(message);
  }

  clearChatMessage(): void {
    this.subject.next();
  }

  getChatMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
