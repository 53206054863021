import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { MatSelectModule, MatPaginatorModule, MatListModule } from '@angular/material';

// InjectionToken
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatTable, MatTableModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { HelpModule } from './core/help/help.module';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

// App components
import { MyCompetenciesModule } from 'app/core/my-competencies/my-competencies.module';
import { MyJourneysModule } from 'app/core/my-journeys/my-journeys.module';
import { MyAssessmentsModule } from 'app/core/my-assessments/my-assessments.module';
import { MyTeamsModule } from 'app/core/my-teams/my-teams.module';
import { MyteamApprovalsModule } from 'app/core/my-teams/myteam-approvals/myteam-approvals.module';
import { FeedbackRequestModule } from 'app/core/feedback-request/feedback-request.module';
import { TakeJourneyModule } from 'app/core/my-journeys/take-journey/take-journey.module';

import { PageNotFoundModule } from 'app/core/pagenotfound/pagenotfound.module';
import { OnboardingModule } from 'app/core/onboarding/onboarding.module';
import { ChangePasswordModule } from 'app/core/change-password/change-password.module';
import { LogoutModule } from 'app/core/logout/logout.module';
import { HomeV2Module } from 'app/core/home-v2/home-v2.module';
// import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from 'environments/environment';


// Shared components
import { LevelIndicatorComponent } from 'app/shared/level-indicator/level-indicator.component';
import { WebStorageModule } from 'ngx-store';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { ImageService } from 'app/services/images.service';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { SupportedJourneysModule } from './core/supported-journeys/supported-journeys.module';
import { EditTasksModule } from './core/my-journeys/edit-tasks/edit-tasks.module';
import { CreateJourneyModule } from './core/my-journeys/create-journey/create-journey.module';
import { httpInterceptorProviders } from './interceptors';
import { AuthService } from 'app/services/auth.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { HelpService } from 'app/core/help/help.service';
import { DashboardModule } from 'app/core/dashboard/dashboard.module';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { AssessmentComponent } from './core/assessment/assessment.component';
import { MdePopoverModule } from '@material-extended/mde';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { MatCheckboxModule, MatCardModule } from '@angular/material';
import { GiveFeedbackModule } from './shared/give-feedback/give-feedback.module';
import { UserProfileModule } from './core/user-profile/user-profile.module';
import { CallbackComponent } from './callback.component';
import { ConsentModule } from './shared/consent/consent.module';
import { ConsentComponent } from './shared/consent/consent.component';

export const customElements = [LevelIndicatorComponent];

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'home',
    redirectTo: 'home'
  },
  {
    path: 'competencies',
    redirectTo: 'competencies'
  },
  {
    path: 'journeys',
    redirectTo: 'journeys'
  },
  {
    path: 'my-assessments',
    redirectTo: 'my-assessments'
  },
  {
    path: 'supported-journeys',
    redirectTo: 'supported-journeys'
  },
  {
    path: 'login',
    redirectTo: 'login'
  },
  {
    path: 'feedback-request',
    redirectTo: 'feedback-request'
  },
  {
    path: 'my-teams',
    redirectTo: 'my-teams'
  },
  {
    path: 'profile',
    redirectTo: 'profile'
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard'
  },
  {
    path: 'onboarding',
    redirectTo: 'onboarding'
  },
  {
    path: 'change-password',
    redirectTo: 'change-password'
  },
  {
    path: 'logout',
    redirectTo: 'logout'
  },
  {
    path: 'assessment',
    component: AssessmentComponent
  },
  {
    path: 'pagenotfound',
    redirectTo: 'pagenotfound'
  },
  {
    path: '**',
    redirectTo: 'pagenotfound'
  },
  {
    path: 'user-profile/:id',
    redirectTo: 'user-profile/:id'
  },
];

@NgModule({
  declarations: [
    AppComponent,
    AssessmentComponent,
    CallbackComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule.forRoot(),

    // Local storage
    WebStorageModule,

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    MyCompetenciesModule,
    MyJourneysModule,
    MyTeamsModule,
    MyteamApprovalsModule,
    SupportedJourneysModule,
    PageNotFoundModule,
    TakeJourneyModule,
    OnboardingModule,
    ChangePasswordModule,
    LogoutModule,
    FeedbackRequestModule,
    EditTasksModule,
    CreateJourneyModule,
    HelpModule,
    DashboardModule,
    HomeV2Module,
    SvgIconModule,
    MyAssessmentsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    FuseWidgetModule,
    MatPaginatorModule,
    MatListModule,
    MdePopoverModule,
    CorePipesModule,
    MatCheckboxModule,
    MatCardModule,
    GiveFeedbackModule,
    UserProfileModule,
    ConsentModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    ImageService,
    RequestHelpers,
    httpInterceptorProviders,
    MixpanelManager,
    HelpService,
    AuthService
  ],
  entryComponents: [
    ConsentComponent
  ]
  // , AngularFireAuth ,InjectionToken]
})
export class AppModule {
}
