import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel } from 'app/models/journey-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ImageService } from 'app/services/images.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { JourneyStatus } from 'app/enums/journey-status';

@Component({
  selector: 'journey-details-dialog-component',
  templateUrl: 'journey-details-dialog-component.html',
  styleUrls: ['journey-details-dialog-component.scss'],
  providers: [ImageService, RequestHelpers]
})
export class JourneyDetailsDialogComponent {

  journey: JourneyModel;
  onJourneyEdited = new EventEmitter<Object>();

  supportersDetails: Array<any>;
  journeySupporters: Array<Object> = [];
  allUsersData: Array<any>;
  roles: Array<any>;

  userId: number;
  orgId: number;

  role: string;
  defaultImage: string;
  isLoadingSupporters: boolean;
  isDateInEdit: Boolean;


  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<JourneyDetailsDialogComponent>,
    private imageService: ImageService,
    private mix: MixpanelManager,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.journey = <JourneyModel>data.details;
    this.journeySupporters = data.journeySupporters.filter(supp => supp.active);
    console.log(this.journey);

    this.orgId = data.orgId;
    this.userId = data.userId;
  }
  
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
