import {Component} from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
    selector: 'journey-task-menu',
    templateUrl: './journey-task-menu.component.html',
})
export class JourneyTaskMenuComponent {
    constructor(
        private _fuseSidebarService: FuseSidebarService) {
    }

    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
