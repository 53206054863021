import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'filter-dialog-component',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['../../my-teams.component.scss', './filter-dialog.component.scss'],
  providers: []
})
export class FilterDialogComponent {

  filterType: Number = 1;  // 1 - status, 2 - team, 3 - competency

  filterData: Array<any> = [];
  selectedFilterData: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.filterType = data.filterType;
    this.filterData = data.filterData;
    this.selectedFilterData = data.selectedFilterData;
  }

  clearFilter(): void {
    this.filterData = [];
    this.selectedFilterData = [];
    this.cancel();
  }

  applyFilter(): void {
    this.dialogRef.close(this.selectedFilterData);
  }

  cancel(): void {
    this.dialogRef.close([]);
  }
}
