export enum MixpanelEventType {
    LOG_IN = 'LOG_IN',
    LOG_OUT = 'LOG_OUT',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    JOURNEY_EDITED = 'JOURNEY_EDITED',
    JOURNEY_CREATED = 'JOURNEY_CREATED',
    JOURNEY_CUSTOMIZED = 'JOURNEY_CUSTOMIZED',
    JOURNEY_STARTED = 'JOURNEY_STARTED',
    TASK_COMPLETED = 'TASK_COMPLETED',
    CONTENT_VIEWED = 'CONTENT_VIEWED',
    FEEDBACK_REQUEST_CREATED = 'FEEDBACK_REQUEST_CREATED',
    FEEDBACK_GIVEN = 'FEEDBACK_GIVEN',
    MOBILE_NAVIGATION = 'MOBILE_NAVIGATION',
    WEB_NAVIGATION = 'WEB_NAVIGATION',
    RECOMMENDATION_CONTENT_SWIPED = 'RECOMMENDATION_CONTENT_SWIPED',
    RECOMMENDATION_CONTENT_VIEWED = 'RECOMMENDATION_CONTENT_VIEWED',
    RECOMMENDATION_CONTENT_FEEDBACK = 'RECOMMENDATION_CONTENT_FEEDBACK',
    RECOMMENDATION_JOURNEY_SWIPED = 'RECOMMENDATION_JOURNEY_SWIPED',
    RECOMMENDATION_JOURNEY_VIEWED = 'RECOMMENDATION_JOURNEY_VIEWED',
}
