import { Pipe, PipeTransform } from '@angular/core';
import { CuratedContentType } from 'app/enums/curated-content-type';


@Pipe({ name: 'CCTypeLabelPipe' })

export class CCTypeLabelPipe implements PipeTransform {

  transform(status: CuratedContentType): any {

    switch (status) {
      case 1:
        return 'DOCUMENT';
      case 2:
        return 'IMAGE';
      case 3:
        return 'VIDEO';
      case 4:
        return 'AUDIO';
      case 5:
        return 'LINK';
      default:
        return '';
    }
  }
}
