import { Component, Input, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

@Component({
  selector: 'level-indicator',
  templateUrl: './level-indicator.component.html',
  styleUrls: ['./level-indicator.component.scss']
})
export class LevelIndicatorComponent implements OnInit {
  widgets: any;
  @Input() data: Array<any>;
  @Input() showLabelBg: boolean;
  @Input() showProgress: boolean;
  // @Input() competencyName: string;
  levelTotal: number;
  @Input() peerLevel: Array<number>;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  /**
* On init
*/
  ngOnInit(): void {
   
    this.levelTotal = this.data.length;
  }

}
