import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TaskModel } from 'app/models/journey-model';
import { TaskType } from 'app/enums/task-type';
import { FormGroup } from '@angular/forms';
import { ButtonOpts } from 'mat-progress-buttons';
import { fuseAnimations } from '@fuse/animations';
import { JourneyTasksService } from 'app/shared/journey-tasks/journey-tasks.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'task-details-component',
  templateUrl: 'task-details.component.html',
  styleUrls: ['task-details.component.scss'],
  providers: [],
  animations: fuseAnimations
})
export class TaskDetailsComponent implements OnDestroy {
  private _unsubscribeAll: Subject<any>;
  task: TaskModel;
  taskType = TaskType;
  supporters: Array<any>;
  formType: string;
  taskForm: FormGroup;

  user: any;
  displayedColumns = ['checkbox', 'publicId', 'name', 'role'];
  selectedContacts: any[];
  checkboxes: {};
  isUpdatingUDT: boolean;
  isAddingUDT: boolean;
  editDisabled: boolean;

  addNewTaskBtnOptions: ButtonOpts = {
    active: false,
    text: 'SAVE',
    buttonColor: 'accent',
    barColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    value: 0,
    disabled: false
  };

  constructor(
    public dialogRef: MatDialogRef<TaskDetailsComponent>,
    public snackBar: MatSnackBar,
    private _journeyTasksService: JourneyTasksService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this._unsubscribeAll = new Subject();
    console.log(data);
    this.task = Object.assign({}, data.task);
    this.supporters = data.supporters;
    this.formType = data.type;
    this.editDisabled = data.isEditDisabled;
    if (this.formType === 'add') {
      this.task.fbRequestContent = `Please review the task I have completed to let me know how I am doing and give me suggestions for improvement.`;
    }
    this.setSupporters();
    this.task.taskType = TaskType.user_defined;

    this._journeyTasksService.onNewTaskAddedResponse
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result) => {
      this.isAddingUDT = false;
      if (result) {
        this.formType = 'edit';
      }
    });

    this._journeyTasksService.onTaskUpdated
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result) => {
      this.isUpdatingUDT = false;
      this.dialogRef.close();
    });
  }

  addNewTask(): void {
    this.task.taskType = TaskType.user_defined;

    if (!this.task.title || !this.task.title.length) {
      this.openSnackBar('Title is required.', 'Ok');
      return;
    }

    if (!this.task.description || !this.task.description.length) {
      this.openSnackBar('Description is required.', 'Ok');
      return;
    }

    if (this.task.isFbRequested && (!this.task.fbRequestContent || !this.task.fbRequestContent.length)) {
      this.openSnackBar('Feedback request message is required.', 'Ok');
      return;
    }

    if (this.task.isFbRequested && (!this.task.supporters || !this.task.supporters.length)) {
      this.openSnackBar('Please select supporters for the task.', 'Ok');
      return;
    }

    this.isAddingUDT = true;
    this._journeyTasksService.addTaskInList(this.task, false);
  }

  updateUDT(): void {

    if (!this.task.title || !this.task.title.length) {
      this.openSnackBar('Title is required.', 'Ok');
      return;
    }

    if (!this.task.description || !this.task.description.length) {
      this.openSnackBar('Description is required.', 'Ok');
      return;
    }

    if (this.task.isFbRequested && (!this.task.fbRequestContent || !this.task.fbRequestContent.length)) {
      this.openSnackBar('Feedback request message is required.', 'Ok');
      return;
    }

    if (this.task.isFbRequested && (!this.task.supporters || !this.task.supporters.length)) {
      this.openSnackBar('Please select supporters for the task.', 'Ok');
      return;
    }

    this.isUpdatingUDT = true;
    this._journeyTasksService.updateTask(this.task);
  }

  setSupporters(): void {
    this.checkboxes = {};

    this.supporters.map(contact => {
      if (this.formType === 'edit' && this.task.supporters && this.task.supporters.find(supp => supp.userId === contact.userId)) {
        this.checkboxes[contact.userId] = true;
      } else {
        this.checkboxes[contact.userId] = false;
      }
    });
  }

  onSelectedChange(supporter): void {
    if (this.task.supporters) {
      const suppResult = this.task.supporters.findIndex(item => supporter.userId === item.userId);
      if (suppResult === -1) {
        this.task.supporters.push(supporter);
      } else {
        this.task.supporters.splice(suppResult, 1);
      }
    } else {
      this.task.supporters = [];
      this.task.supporters.push(supporter);
    }
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
