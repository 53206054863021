import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GiveFeedbackComponent } from './give-feedback.component';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NgxGaugeModule } from 'ngx-gauge';

import {
  MatIconModule,
  MatButtonModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatRadioModule,
  MatExpansionModule,
  MatRippleModule,
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatSortModule,
    MatGridListModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MatCardModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatRadioModule,
    MatExpansionModule,
    MatRippleModule,
    RouterModule,
    CorePipesModule,
    NgxGaugeModule
  ],
  declarations: [GiveFeedbackComponent],
  exports: [GiveFeedbackComponent]
})
export class GiveFeedbackModule { }
