import { NgModule } from '@angular/core';

import { OcticonDirective } from 'app/shared/directives/octicon/octicon.directive';

@NgModule({
    declarations: [
        OcticonDirective
    ],
    imports     : [],
    exports     : [
        OcticonDirective
    ]
})
export class SharedDirectivesModule
{
}
