import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FeedbackFilterModel } from 'app/models/filter-model';
import { Competency } from 'app/models/competency.model';
import { User } from 'app/models/user';

@Component({
  selector: 'app-feedback-list-filter-dialog',
  templateUrl: './feedback-list-filter-dialog.component.html',
  styleUrls: ['./feedback-list-filter-dialog.component.scss']
})
export class FeedbackListFilterDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FeedbackListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { allFilterData: FeedbackFilterModel, selectedFilterData: FeedbackFilterModel },
  ) { }

  selectedFilter: FeedbackFilterModel = new FeedbackFilterModel();
  selectedOwner: Array<Number> = [];

  ngOnInit(): void {
    this.selectedFilter = this.data.selectedFilterData;
    this.selectedOwner = this.selectedFilter.requestedBy.map(owner => owner.id);
  }

  selectOwner(ev): void {
    const { value } = ev;
    const selectedOwners = this.data.allFilterData.requestedBy.filter(owner => value.includes(owner.id));
    this.selectedFilter.requestedBy = selectedOwners;
  }

  removeOwner(ownerToRemove: User): void {
    this.selectedFilter.requestedBy = this.selectedFilter.requestedBy.filter(owner => owner.id !== ownerToRemove.id);
    this.selectedOwner = this.selectedFilter.requestedBy.map(owner => owner.id);
  }

  clear(): void {
    this.selectedFilter = new FeedbackFilterModel();
    this.dialogRef.close(this.selectedFilter);
  }

  applyFilter(): void {
    this.dialogRef.close(this.selectedFilter);
  }

}
