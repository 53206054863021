import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  MatIconModule,
  MatButtonModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatRadioModule,
  MatExpansionModule,
  MatRippleModule,
} from '@angular/material';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { MdePopoverModule } from '@material-extended/mde';


import { UserListComponent } from './user-list.component';
import { UserListFilterComponent } from './user-list-filter/user-list-filter.component';
import { UserListFilterDialogComponent } from './user-list-filter/user-list-filter-dialog/user-list-filter-dialog.component';
import { UserListService } from './user-list.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatSortModule,
    MatGridListModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MatCardModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatRadioModule,
    MatExpansionModule,
    MatRippleModule,
    RouterModule,
    CorePipesModule,
    SvgIconModule,
    MdePopoverModule
  ],
  entryComponents: [UserListFilterDialogComponent],
  declarations: [UserListComponent, UserListFilterComponent, UserListFilterDialogComponent],
  exports: [UserListComponent, UserListFilterComponent],
  providers: [UserListService]
})
export class UserListModule { }
