import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-store';
import { SearchService } from '../search/search.service';
import { MatDialog, MatSnackBar, MatPaginator } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CuratedContentType } from '../../enums/curated-content-type';
import { RequestHelpers } from '../../services/request-helpers.service';
import { DialogMenuComponent } from './dialog-menu/dialog-menu.component';
import { MixpanelEventType } from '../../enums/mixpanel-events';
import { MixpanelManager } from '../../services/mixpanel.service';
import { Course } from 'app/models/course.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  contentLoaderStatus = true;
  public user: any;
  public compContents = [];
  dataLengthImage = 0;
  dataLengthLink = 0;
  dataLengthDoc = 0;
  dataLengthVideo = 0;
  dataLengthComp = 0;
  dataLengthJourney = 0;
  dataLengthCourse = 0;
  pageSize = 12;
  pageEvent: Object;
  pageIndex = 0;
  pageIndexImage = 0;
  pageIndexLink = 0;
  pageIndexDoc = 0;
  pageIndexVideo = 0;
  pageIndexComp = 0;
  pageIndexJourney = 0;
  pageIndexCourse = 0;
  routeSubscription: any;
  priCompIds = [];
  isFetchingContent: Boolean = true;
  isSearchingLink: Boolean = true;
  isSearchingImage: Boolean = true;
  isSearchingVideo: Boolean = true;
  isJourneyData: Boolean = true;
  isCompetencyData: Boolean = true;
  isSearchingDoc: Boolean = true;
  isTableFetching: Boolean;
  imageTab: Boolean = false;
  docTab: Boolean = false;
  videoTab: Boolean = false;
  linkTab: Boolean = false;
  isFetchingComp: Boolean = false;
  isFetchingJourney: Boolean = false;
  isFetchingCourse: Boolean = false;
  isPageLoader: Boolean = true;
  competencyId;
  searchTxt = '';
  reset: Boolean = false;
  compJourney: Boolean = false;
  compCourse: Boolean = false;
  searchTextValue;
  noCompContents: Boolean = false;
  public compLevel;
  onDrillSearchText;
  compSearchText: Boolean;
  searchTextResult: Boolean;
  searchResult;
  competencyResult: Boolean = false;
  allJourney: Boolean = true;
  compjourney: Boolean = false;
  CuratedContentType = CuratedContentType;
  public dataSourceImage = [];
  public dataSourceLink = [];
  public dataSourceDoc = [];
  public dataSourceVideo = [];
  public dataSourceComp = [];
  public dataSourceJourney = [];
  public dataSourceCourse: Course[] = [];
  matTableContent: any = [] = [];
  public searchTextCount = [];
  public curatedContent = [];
  public compTab: Boolean = false;
  public journeyTab: Boolean = false;
  public courseTab: Boolean = false;
  searchTerm = '';
  checkSearchText: Boolean;
  noContentsFound: Boolean;
  errorOccured: Boolean;
  competencyName: any = '';
  invalidSearch: Boolean = false;

  stockImage = environment.IMAGES.DEFAULT_CONTENT;

  @ViewChild('tabGroup') tabGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public mix: MixpanelManager,
    private localStorageService: LocalStorageService,
    public searchcontents: SearchService,
    private activatedroute: ActivatedRoute,
    private requestHelpers: RequestHelpers,
  ) { }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    const imageContentType = 2;
    const linkContentType = 5;
    const docContentType = 1;
    const videoContentType = 3;
    this.curatedContent[0] = docContentType;
    this.curatedContent[1] = imageContentType;
    this.curatedContent[2] = videoContentType;
    this.curatedContent[3] = linkContentType;


    this.routeSubscription = this.activatedroute.queryParams.subscribe(params => {

      if (params.q !== '') {
        this.searchTxt = params.q;
        this.competencyName = '';
        this.checkSearchText = true;
        this.searchTerm = params.q;
        this.callSearchContent(params.q);
        this.getAllCompetency(params.q);
        this.getAllJourney(params.q);
        this.getCourses(params.q);
        this.onSearchButton();
        this.noCompContents = false;
        this.noContentsFound = false;
        this.invalidSearch = false;
      }
      else if (params.q === '') {
        this.isPageLoader = false;
        this.invalidSearch = true;
        this.searchTextResult = false;
      }
    });
  }
  selectNewJourney(templateId: string): void {
    this.dialog.open(DialogMenuComponent, {
      panelClass: 'app-dialog-menu',
      width: '250px',
    });
    this.mix.track(MixpanelEventType.RECOMMENDATION_JOURNEY_VIEWED, {
      templateId
    });
    return;
  }
  
  onPaginateChange(event, contentType, searchText, priCompId): void {
    const primComId = priCompId !== '' ? priCompId : this.competencyId;
    this.pageIndexImage = event.pageIndex;
    this.pageIndexDoc = event.pageIndex;
    this.pageIndexLink = event.pageIndex;
    this.pageIndexVideo = event.pageIndex;
    this.searchContentPagination(contentType, this.searchTxt, primComId);
  }

  onPaginate(event, searchText?, priCompId?): void {
    const primComId = priCompId !== '' ? priCompId : this.competencyId;
    this.pageIndexJourney = event.pageIndex;
    this.getJourneyPagination(this.searchTxt, primComId);
  }

  onCompPaginate(event, searchText): void {
    this.pageIndexComp = event.pageIndex;
    const localSearchText = searchText;
    this.getCompetencyPagination(localSearchText);
  }

  onCoursePaginate(event, searchText): void {
    this.pageIndexCourse = event.pageIndex;
    const localSearchText = searchText;
    this.getCoursesPagination(localSearchText);
  }

  parseJSON(json: any): string {
    return JSON.stringify(json, null, 2);
  }

  getCompetencyContents(compId, name): void {
    this.dataSourceComp = [];
    this.dataLengthComp = 0;
    this.dataSourceCourse = [];
    this.dataLengthCourse = 0;
    this.compTab = false;
    this.courseTab = false;
    this.searchTextValue = '';
    this.compJourney = true;
    this.compCourse = true;
    this.competencyResult = true;
    this.competencyName = name;
    this.competencyId = compId;
    this.searchTextResult = false;
    this.getAllJourney(this.searchTxt, compId);
    this.curatedContent.forEach(contentType => { this.searchContent(contentType, this.searchTxt, compId); });
  }

  searchContent(contentType, competency?, compId?): void {
    this.isPageLoader = true;
    this.noCompContents = false;
    this.dataSourceDoc = [];
    this.dataSourceImage = [];
    this.dataSourceLink = [];
    this.dataSourceVideo = [];
    this.docTab = false;
    this.imageTab = false;
    this.linkTab = false;
    this.videoTab = false;
    this.isSearchingLink = true;
    this.isSearchingDoc = true;
    this.isSearchingImage = true;
    this.isSearchingVideo = true;
    this.isJourneyData = true;
    this.isCompetencyData = true;
    this.isFetchingContent = true;
    const order = 'createdOn asc';
    let contentArray = [];
    const searchText = competency;
    let filter = '';
    if (contentType === CuratedContentType.image) {
      this.pageIndex = 0;
      filter = contentType;
    }
    else if (contentType === CuratedContentType.document) {
      this.pageIndex = 0;
      filter = contentType;
    }
    else if (contentType === CuratedContentType.link) {
      this.pageIndex = 0;
      filter = contentType;
    }
    else if (contentType === CuratedContentType.video) {
      this.pageIndex = 0;
      filter = contentType;
    }
    if (compId === '') {
      compId = undefined;
    }
    this.searchcontents.getSearchContents(this.user.orgId, this.pageIndex, this.pageSize, filter, searchText, compId)
      .subscribe((res: any) => {
        console.log('content data',res.resource);
        this.matTableContent = res.resource;
        contentArray = this.matTableContent;
        if (contentType === CuratedContentType.document) {
          this.dataSourceDoc = contentArray;
          this.dataLengthDoc = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingDoc = false;
          if (this.dataLengthDoc > 0) {
            this.docTab = true;
            this.mix.track(MixpanelEventType.CONTENT_VIEWED,
              { _id: res.resource[0]._id, contentType: res.resource[0].contentType, title: res.resource[0].title });
          }
        }
        else if (contentType === CuratedContentType.image) {
          this.dataSourceImage = contentArray;
          this.dataLengthImage = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingImage = false;
          if (this.dataLengthImage > 0) {
            this.imageTab = true;
            this.mix.track(MixpanelEventType.CONTENT_VIEWED,
              { _id: res.resource[0]._id, contentType: res.resource[0].contentType, title: res.resource[0].title });
          }
        }
        else if (contentType === CuratedContentType.link) {
          this.dataSourceLink = contentArray;
          this.dataLengthLink = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingLink = false;
          if (this.dataLengthLink > 0) {
            this.linkTab = true;
            this.mix.track(MixpanelEventType.CONTENT_VIEWED,
              { _id: res.resource[0]._id, contentType: res.resource[0].contentType, title: res.resource[0].title });
          }
        }
        else if (contentType === CuratedContentType.video) {
          this.dataSourceVideo = contentArray;
          this.dataLengthVideo = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingVideo = false;
          if (this.dataLengthVideo > 0) {
            this.videoTab = true;
            this.mix.track(MixpanelEventType.CONTENT_VIEWED,
              { _id: res.resource[0]._id, contentType: res.resource[0].contentType, title: res.resource[0].title });

          }
        }
        this.pageLoader();
        this.noCompetencyContents();
        this.errorOccured = false;
        // this.getAllJourney(searchText);
      },
        error => {
          this.errorOccured = true;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }


  searchContentPagination(contentType, competency?, compId?): void {
    this.isTableFetching = true;
    const searchText = competency;
    let contentArray = [];
    let filter = '';
    if (contentType === CuratedContentType.image) {
      this.pageIndex = this.pageIndexImage * this.pageSize;
      filter = contentType;
    }
    else if (contentType === CuratedContentType.document) {
      this.pageIndex = this.pageIndexImage * this.pageSize;
      filter = contentType;
    }
    else if (contentType === CuratedContentType.link) {
      this.pageIndex = this.pageIndexImage * this.pageSize;
      filter = contentType;
    }
    else if (contentType === CuratedContentType.video) {
      this.pageIndex = this.pageIndexImage * this.pageSize;
      filter = contentType;
    }
    this.searchcontents.getSearchContents(this.user.orgId, this.pageIndex, this.pageSize, filter, searchText, compId)
      .subscribe((res: any) => {
        this.matTableContent = res.resource;
        contentArray = this.matTableContent;

        if (contentType === CuratedContentType.document) {
          this.dataSourceDoc = contentArray;
          this.dataLengthDoc = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingDoc = false;
          this.isTableFetching = false;
        }
        else if (contentType === CuratedContentType.image) {
          this.dataSourceImage = contentArray;
          this.dataLengthImage = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingImage = false;
          this.isTableFetching = false;
        }
        else if (contentType === CuratedContentType.link) {
          this.dataSourceLink = contentArray;
          this.dataLengthLink = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingLink = false;
          this.isTableFetching = false;
        }
        else if (contentType === CuratedContentType.video) {
          this.dataSourceVideo = contentArray;
          this.dataLengthVideo = res.meta.total;
          this.isFetchingContent = false;
          this.isSearchingVideo = false;
          this.isTableFetching = false;
        }
        this.errorOccured = false;
      },
        error => {
          this.errorOccured = true;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  callSearchContent(searchTitle): void {
    this.isPageLoader = true;
    this.noCompContents = false;
    this.searchTextValue = searchTitle;
    this.onDrillSearchText = searchTitle;
    this.searchResult = searchTitle;
    this.searchTextResult = true;
    this.compSearchText = false;
    this.compjourney = false;
    this.allJourney = true;
    this.competencyName = '';
    this.reset = true;
    this.searchTextCount = [];
    this.noContentsFound = false;
    const searchText = searchTitle;
    this.curatedContent.forEach(contentType => { this.searchContent(contentType, searchText); });
  }

  getAllCompetency(searchText): any {
    this.noCompContents = false;
    this.reset = true;
    this.compTab = false;
    this.dataSourceComp = [];
    const localSearchText = searchText;
    this.pageIndex = 0;
    this.searchcontents.getSearchCompetency(this.user.orgId, this.pageSize, this.pageIndex, localSearchText)
      .subscribe((res: any) => {
        this.dataSourceComp = res.resource;
        this.dataLengthComp = res.meta.total;
        this.compLevel = res.compLevel;
        if (this.dataLengthComp > 0) {
          this.mix.track(MixpanelEventType.CONTENT_VIEWED,
            { competencyId: res.resource[0]._id, displayText: res.resource[0].displayText, });
          this.compTab = true;
        }
        for (let i = 0; i < this.dataSourceComp.length; i++) {
          const compLevel = [];
          for (let j = 0; j < this.compLevel.length; j++) {
            if (this.dataSourceComp[i]._id === this.compLevel[j].CompId) {
              compLevel.push(this.compLevel[j]);
            }
            this.dataSourceComp[i]['compLevelInfo'] = compLevel;
          }
        }
        this.noCompetencyContents();
        this.isCompetencyData = false;
        this.pageLoader();
      }
      );
  }

  getCompetencyPagination(searchText): void {
    this.isFetchingComp = true;
    const localSearchText = searchText;
    this.pageIndex = this.pageIndexComp * this.pageSize;
    this.searchcontents.getSearchCompetency(this.user.orgId, this.pageSize, this.pageIndex, localSearchText)
      .subscribe((res: any) => {
        this.dataSourceComp = res.resource;
        this.isFetchingComp = false;
        this.compLevel = res.compLevel;
        for (let i = 0; i < this.dataSourceComp.length; i++) {
          const compLevel = [];
          for (let j = 0; j < this.compLevel.length; j++) {
            if (this.dataSourceComp[i]._id === this.compLevel[j].CompId) {
              compLevel.push(this.compLevel[j]);
            }
            this.dataSourceComp[i]['compLevelInfo'] = compLevel;
          }
        }
      }
      );
  }

  onSearchButton(): void {
    this.compTab = false;
    this.searchTextResult = true;
    this.compSearchText = true;
  }

  getAllJourney(searchText?, priCompId?): any {
    this.noCompContents = false;
    this.compJourney = false;
    const primComId = priCompId !== '' ? priCompId : this.competencyId;
    this.reset = true;
    this.journeyTab = false;
    this.dataSourceJourney = [];
    const localSearchText = searchText;
    this.pageIndex = 0;
    this.searchcontents.getSearchJourney(this.user.orgId, this.pageSize, this.pageIndex, localSearchText, primComId)
      .subscribe((res: any) => {
        this.dataSourceJourney = res.resource;
        this.dataLengthJourney = res.meta.total;
        if (this.dataSourceJourney.length > 0) {
          this.journeyTab = true;
        }
        this.noCompetencyContents();
        this.isJourneyData = false;
        this.pageLoader();
      }
      );

  }

  getJourneyPagination(searchText?, priCompId?): any {
    this.isTableFetching = true;
    this.isFetchingJourney = true;
    const localSearchText = searchText;
    this.pageIndex = this.pageIndexJourney * this.pageSize;
    this.searchcontents.getSearchJourney(this.user.orgId, this.pageSize, this.pageIndex, localSearchText, priCompId)
      .subscribe((res: any) => {
        this.isTableFetching = false;
        this.dataSourceJourney = res.resource;
        this.dataLengthJourney = res.meta.total;
        this.isFetchingJourney = false;
      }
      );
  }

  getCourses(searchText) {
    this.compCourse = false;
    this.courseTab = false;
    this.noCompContents = false;
    this.reset = true;
    this.dataSourceCourse = [];
    const localSearchText = searchText;
    this.pageIndex = 0;
    this.dataSourceCourse = [];
    this.searchcontents.getSearchCourses(this.pageSize, this.pageIndex, localSearchText)
      .subscribe((res: any) => {
        this.dataSourceCourse = res.resource;
        this.dataLengthCourse = res.meta.total;
        if (this.dataLengthCourse > 0) {
          this.courseTab = true;
        }
        this.noCompetencyContents();
        this.pageLoader();
      });
  }

  getCoursesPagination(searchText) {
    this.isTableFetching = true;
    const localSearchText = searchText;
    this.pageIndex = this.pageIndexCourse * this.pageSize;
    this.searchcontents.getSearchCourses(this.pageSize, this.pageIndex, localSearchText)
      .subscribe((res: any) => {
        this.dataSourceCourse = res.resource;
        this.dataLengthCourse = res.meta.total;
        this.isTableFetching = false;
      });
  }

  compDrill(): void {
    this.competencyId = undefined;
    this.callSearchContent(this.onDrillSearchText);
    this.getAllJourney(this.onDrillSearchText);
    this.getAllCompetency(this.onDrillSearchText);
    this.getCourses(this.onDrillSearchText);
    this.compTab = true;
  }
  pageLoader(): void {
    if ((this.dataLengthDoc > 0) || (this.dataLengthImage > 0) || (this.dataLengthLink > 0) || (this.dataLengthVideo > 0) || (this.dataLengthJourney > 0) ||
      (this.dataLengthComp > 0) || (this.dataLengthCourse > 0) || (this.noContentsFound === true) || (this.noCompContents === true)) {
      this.isPageLoader = false;
    }
  }

  noCompetencyContents(): void {
    if ((this.dataLengthDoc === 0) && (this.dataLengthImage === 0) && (this.dataLengthLink === 0)
      && (this.dataLengthVideo === 0) && (this.dataLengthJourney === 0) && (this.dataLengthComp === 0) && (this.dataLengthCourse === 0)) {
      this.noContentsFound = true;
    }
    else {
      this.noContentsFound = false;
    }
  }

  goToPluralSight(id) : void {
    const PluralSightLink = 'https://app.pluralsight.com/library/courses/';
    window.open(PluralSightLink + id ,'_blank');
  }
}

