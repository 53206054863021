import firebase from '@firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import { environment } from '../../environments/environment';

const firebaseConfig = environment.firebase;

// TODO: Figure out how to make this a proper Injectable angular service

const instance = null;
export class FirebaseService {
    app: any;
    constructor() {
        if (!instance) {
            this.app = firebase.initializeApp(firebaseConfig);
            return this;
        }
    }
}

export const firebaseService = new FirebaseService().app;
