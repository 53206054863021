import { Component, ViewChild } from '@angular/core';
import { IImage } from 'ng-simple-slideshow/src/app/modules/slideshow/IImage';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog } from '@angular/material';
import { HelpRequiredComponent } from './dialogs/help-required/help-required.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {


  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private dialog: MatDialog,
    private _fuseSidebarService: FuseSidebarService,
  ) {
    this.user = this.localStorageService.get('user');
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  @ViewChild('slideshow') slideshow: any;
  imageUrlArray: Array<any> = [
    {
      url: 'https://res.cloudinary.com/decffyhib/image/upload/v1548394132/web_onboarding_1.png',
      title: 'Quick start your journey',
      // tslint:disable-next-line:max-line-length
      description: 'You can start your journeys from any of the assigned competencies or picking your own journey. Develoop also provides recommendations based on the organisation and your skill set. You can choose from a pool of options to start your journey. It\'s quick and easy to get started'
    },
    {
      url: 'https://res.cloudinary.com/decffyhib/image/upload/v1548394095/web_onboarding_2.png',
      title: 'Explore your Journey and Tasks',
      // tslint:disable-next-line:max-line-length
      description: 'This is where you achieve the most. Checking and completing all your assigned tasks, managing and adding supporters. Fulfulling knowledge checks and various tasks and sending feedback requests to all the supporters of your journey. Your journey completion will move you to next level'
    },
    {
      url: 'https://res.cloudinary.com/decffyhib/image/upload/v1548394124/web_onboarding_3.png',
      title: 'Get Feedback or Give Feedback',
      // tslint:disable-next-line:max-line-length
      description: 'Feedback is the most essential part of your entire lifecycle. Your move to next task or next journey to purely based on the feedback received by you. If you are supporting other person than you have the priviledge to give feedback on their performance or journey'
    },
    {
      url: 'https://res.cloudinary.com/decffyhib/image/upload/v1548394112/web_onboarding_4.png',
      title: 'Manage Team and Support Journeys',
      // tslint:disable-next-line:max-line-length
      description: 'You can now manage the journeys of your team, check their competencies and also support their journeys. Create teams, monitor the progress of whole team and individual with detailed insights'
    }
  ];

  height: String = '350px';
  minHeight: String;
  arrowSize: String = '30px';
  showArrows: Boolean = false;
  disableSwiping: Boolean = false;
  autoPlay: Boolean = false;
  autoPlayInterval: Number = 3333;
  stopAutoPlayOnSlide: Boolean = true;
  debug: Boolean = false;
  backgroundSize: String = 'contain';
  backgroundPosition: String = 'center center';
  backgroundRepeat: String = 'no-repeat';
  showDots: Boolean = true;
  dotColor: String = '#03a9f4';
  showCaptions: Boolean = true;
  captionColor: String = '#FFF';
  captionBackground: String = 'rgba(0, 0, 0, .35)';
  lazyLoad: Boolean = false;
  hideOnNoSlides: Boolean = false;
  width: String = '50%';
  user: any;

  goPrevious(): void {
    this.slideshow.onSlide(-1);
  }

  goNext(): void {
    this.slideshow.onSlide(1);
    if (this.slideshow.slideIndex === (this.imageUrlArray.length - 1)) {
      this.updateOnboardingStatus(2);
    }
  }

  skipOnboarding(): void {
    this.updateOnboardingStatus(1);
    this.openHelpRequiredDialog();
    this.router.navigate(['/home']);
  }

  doneOnboarding(): void {
    this.openHelpRequiredDialog();
    this.router.navigate(['/home']);
  }

  updateOnboardingStatus(status): void {
    const putData = {
      resource: [{
        onboardingStatus: status
      }],
      ids: [this.user.id]
    };

    this.http
      .patch(
        environment.DF_BASE_URL +
        environment.USER_TABLE, putData, this.requestHelpers.getDFHeader())
      .subscribe(
        (data: any) => {
          this.user.onboardingStatus = status;
          this.localStorageService.set('user', this.user);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  openHelpRequiredDialog(): void {
    const data = {
      title: 'Welcome to DeveLoop',
      description: 'Here is an overview of what is happening with your journeys.',
      userId: this.user.id
    };
    const dialogRef = this.dialog.open(HelpRequiredComponent, {
      width: '24vw',
      position: {
        top: '70px',
        right: '7px'
      },
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.toggleSidebarOpen('themeOptionsPanel');
      }
    });
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
