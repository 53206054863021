import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { JourneyStatus } from 'app/enums/journey-status';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from 'app/services/images.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { environment } from 'environments/environment';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { TaskType } from 'app/enums/task-type';
import { User } from '../../../../../../../models/user';
import { FeedbackModel } from 'app/models/feedback-model';
import { FeedbackRequestComponent } from 'app/core/my-journeys/take-journey/dialogs/feedback-request/feedback-request.component';
import { JourneyCompletedComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-completed/journey-completed.component';

@Component({
  selector: 'information-task',
  templateUrl: './information-task.component.html',
  styleUrls: ['./information-task.component.scss']
})
export class InformationTaskComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() journey: JourneyModel;
  @Input() task: TaskModel;
  @Input() showFeedback: Boolean;
  @Output() tabChange = new EventEmitter();

  animationDirection: 'left' | 'right' | 'none';
  animationDirectionFeedback: 'left' | 'right' | 'none';
  isMarkingIamDone: Boolean = false;
  isMarkingStartTask: Boolean = false;
  isKnowledgeCheck: Boolean = false;
  isLoadingCC: Boolean = false;
  viewOnly: Boolean = false;
  taskType = TaskType;
  journeyStatus = JourneyStatus;
  imagePDFViewer: any;
  feedbacks: Array<FeedbackModel> = [];
  user: User;
  isNetworkCallActive = false;
  loadingFeedback = false;
  isOngoing: Boolean;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private activatedroute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private imageService: ImageService,
    private mix: MixpanelManager,
    private newTakeJourneyService: TakeJourneyService
  ) {
    this.animationDirection = 'none';
    this.animationDirectionFeedback = 'none';
  }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');

    // if (this.task.pendingFeedback) {
    //   this.showFeedback = true;
    // }
    if (!this.task.supporters || !this.task.supporters.length) {
      this.showFeedback = false;
    }

    this.isOngoing = this.journey.ongoingTask === this.task.taskNo;

  }

  ngAfterViewInit(): void { }

  getImage(publicId: string): string {
    return this.imageService.getUserProfileImage(publicId);
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }
  completeTask(task): void {
    console.log('complete task');
  }
  startOfflineTask(): void {
    if (this.isNetworkCallActive) {
      return;
    }

    this.isNetworkCallActive = true;
    this.isMarkingStartTask = true;
    this.newTakeJourneyService
      .startTask(this.journey._id, this.task.taskId)
      .subscribe(
        (data: any) => {
          this.isNetworkCallActive = false;
          this.isMarkingStartTask = false;
          const taskIndex = this.journey.tasks.findIndex(
            task => task.taskId === this.task.taskId
          );
          if (taskIndex !== -1) {
            this.journey.tasks[taskIndex].status = this.journeyStatus.ongoing;
            this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
          }
        },
        (error: any) => {
          console.log(error);
          this.isMarkingStartTask = false;
        }
      );
  }

  openRequestDialog(): void {
    const dialogRef = this.dialog.open(FeedbackRequestComponent, {
      width: '50vw',
      data: {
        journey: this.journey,
        task: this.task,
        user: this.user,
        type: 1
      }
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe(
      journey => {
        this.journey = journey;
        // call update journey subject
        const taskIndex = this.journey.tasks.findIndex(
          task => task.taskId === this.task.taskId
        );
        if (taskIndex !== -1) {
          console.log(this.journey.tasks[taskIndex].feedback);
          this.task['feedback'] = [];
          this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  openJourneyCompletedDialog(): void {
    const dialogRef = this.dialog.open(JourneyCompletedComponent, {
      width: '30vw',
      data: {}
    });
  }

  iAmDone(): void {
    if (this.isNetworkCallActive) {
      return;
    }

    this.isNetworkCallActive = true;
    this.isMarkingIamDone = true;
    console.log('completing this task');
    this.newTakeJourneyService
      .completeTask(this.task.taskId, this.journey._id)
      .subscribe(
        (result: any) => {
          console.log('result.....', result);
          const taskIndex = this.journey.tasks.findIndex(
            task => task.taskId === this.task.taskId
          );
          if (taskIndex !== -1) {
            this.journey.tasks[
              taskIndex
            ].status = this.journeyStatus.completed;
            this.journey.ongoingTask =
              this.journey.tasks[taskIndex].taskNo + 1;
            this.newTakeJourneyService.onJourneyChanged.next(this.journey);
          }
          if (result.journeyCompleted) {
            this.openJourneyCompletedDialog();
          }
        },
        (error: any) => {
        }
      );
  }

  ngOnDestroy(): void { }
}
