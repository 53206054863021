import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from 'app/services/images.service';
import { MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material';

import { JourneyFilterModel, JourneyFilterModelMini } from 'app/models/filter-model';
import { JourneyListTypes } from 'app/enums/journey-list-type';
import { Competency } from 'app/models/competency.model';

@Component({
  selector: 'my-journeys',
  templateUrl: './my-journeys.component.html',
  styleUrls: ['./my-journeys.component.scss'],
  providers: [ImageService, RequestHelpers, MixpanelManager]
})
export class MyJourneysComponent implements OnInit, AfterViewInit {

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    public mixPanelManager: MixpanelManager,
    private activatedroute: ActivatedRoute,
    private router: Router
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  user: any;
  selectedStatuses: Array<any> = [];
  selectedCompetencies: Array<any> = [];
  isFilterCompFetching: Boolean = true;
  routeSubscription: any;
  filters: JourneyFilterModel = new JourneyFilterModel();
  JourneyListTypes = JourneyListTypes;
  allFilterData: JourneyFilterModel = new JourneyFilterModel();
  alreadyFiltered: JourneyFilterModelMini = new JourneyFilterModelMini();
  loadedFilterDataCount = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tabGroup') tabGroup;

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.routeSubscription = this.activatedroute.queryParams.subscribe(params => {
      if (params.type) {
        const type = params.type;
        switch (type) {
          // case 'all':
          //   this.tabGroup.selectedIndex = 0;
          //   break;
          case 'ongoing':
            this.tabGroup.selectedIndex = 0;
            break;
          case 'not-started':
            this.tabGroup.selectedIndex = 1;
            break;
          case 'completed':
            this.tabGroup.selectedIndex = 2;
            break;
          case 'inconclusive':
            this.tabGroup.selectedIndex = 3;
            break;
          case 'continuance':
            this.tabGroup.selectedIndex = 4;
            break;
          default:
            this.router.navigate(['/journeys'], { queryParams: { type: 'ongoing' }, queryParamsHandling: 'merge' });
            this.tabGroup.selectedIndex = 0;
        }
      }
      if (params.competency) {
        this.alreadyFiltered = { ... this.alreadyFiltered, comps: params.competency };
      }
    });
    this.user = this.localStorageService.get('user');
    this.getFilteredCompetencies('');
  }

  getFilteredCompetencies(filter: string): void {
    this.isFilterCompFetching = true;
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GET_JOURNEY_LIST_FILTER + '?userId=' + this.user['id'] + filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.loadedFilterDataCount++;
          this.allFilterData = { ...this.allFilterData, comps: <Competency[]>data['resource'] };
          if (this.alreadyFiltered.comps.length) {
            const filterComps = this.allFilterData.comps.filter(comp => this.alreadyFiltered.comps.includes(comp._id));
            this.filters = { ...this.filters, comps: filterComps };
          }
          this.isFilterCompFetching = false;
        },
        error => {
          this.loadedFilterDataCount++;
          this.requestHelpers.catchExceptions(error);
          this.isFilterCompFetching = false;
        }
      );
  }

  filterSelect(selectedFilter: JourneyFilterModel): void {
    const queryParams = {};
    if (selectedFilter.comps.length) {
      queryParams['competency'] = selectedFilter.comps.map(comp => comp._id);
      this.router.navigate(['/journeys'], { queryParams, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/journeys']);
    }
    this.filters = { ...selectedFilter };
  }

  tabChanged($event: MatTabChangeEvent): void {
    switch ($event.index) {
      // case 0:
      //   this.router.navigate(['/journeys'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
      //   break;
      case 0:
        this.router.navigate(['/journeys'], { queryParams: { type: 'ongoing' }, queryParamsHandling: 'merge' });
        break;
      case 1:
        this.router.navigate(['/journeys'], { queryParams: { type: 'not-started' }, queryParamsHandling: 'merge' });
        break;
      case 2:
        this.router.navigate(['/journeys'], { queryParams: { type: 'completed' }, queryParamsHandling: 'merge' });
        break;
      case 3:
        this.router.navigate(['/journeys'], { queryParams: { type: 'inconclusive' }, queryParamsHandling: 'merge' });
        break;
      case 4:
        this.router.navigate(['/journeys'], { queryParams: { type: 'continuance' }, queryParamsHandling: 'merge' });
        break;
      default:
        this.router.navigate(['/journeys'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
        break;
    }
  }
}
