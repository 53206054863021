import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'team-filter-component',
  templateUrl: 'team-filter-component.html',
  styleUrls: ['../../my-teams.component.scss'],
  providers: []
})
export class TeamFilterComponent {

  teams: Array<any> = [];
  selectedTeams: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<TeamFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.teams = data.teams;
    this.selectedTeams = data.selectedTeams;
  }

  clearFilter(): void {
    this.selectedTeams = [];
  }

  apply(): void {
    this.dialogRef.close(this.selectedTeams);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
