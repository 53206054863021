import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({ name: 'publicIdToImageUrl' })
export class PublicIdToImageUrlPipe implements PipeTransform {
  /**
   * Transform
   * example - https://res.cloudinary.com/demo/image/upload/w_120,h_120/e_colorize,co_rgb:01579b/l_text:Roboto_50_letter_spacing_5:IR,co_white/flowers.jpg
   * @param {string} value
   * @param {string} name
   * @returns {string}
   */
  transform(value: string, name: string): string {
    if (value) {
      return environment.CLOUDINARY_PUBLIC_URL +
        'b_rgb:fff,w_120,h_120,c_thumb,fl_progressive/' + value;
    } else {
      // return environment.CLOUDINARY_PUBLIC_URL +
      //     'b_rgb:fff,w_120,h_120,c_thumb,fl_progressive/' + environment.DEFAULT_IMAGE;
      let initials = '';
      if (name && name.length > 2) {
        const names = name.trim().replace(/\s+/g, ' ').split(' ');
        initials += names[0].charAt(0).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].charAt(0).toUpperCase();
        } else {
          initials += names[0].charAt(2).toUpperCase();
        }
      } else {
        initials += 'DL';
      }
      return `${environment.CLOUDINARY_PUBLIC_URL}w_120,h_120,c_thumb,fl_progressive/` +
        `e_colorize,co_rgb:01579b/l_text:Roboto_65_letter_spacing_2:${initials},co_white/profileimages/dl_default_pic.jpg`;
    }
  }
}
