import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RequestHelpers } from '../../services/request-helpers.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from '../../services/images.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material';
import { FeedbackFilterModel } from 'app/models/filter-model';
import { FeedbackUserFilterModel } from 'app/models/filter-model';
import { User } from 'app/models/user';

@Component({
  selector: 'feedback-request',
  templateUrl: './feedback-request.component.html',
  styleUrls: ['./feedback-request.component.scss'],
})

export class FeedbackRequestComponent implements OnInit, AfterViewInit {

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    private imageService: ImageService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private mix: MixpanelManager,
    private activatedroute: ActivatedRoute,
    private router: Router
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  data: any = {
    statuses: [
      { name: 'Responded', display: 'responded', count: 0, value: true, class: 'active' },
      { name: 'Not Responded', display: 'notResponded', count: 0, value: true, class: 'active' }
    ],
    competencies: [],
    title: 'Feedback Requests'
  };

  // current page of items
  pageOfItems: Array<any> = [];
  user: any;
  filterData: any;
  selectedStatuses: Array<any> = [];
  isTableFetching: Boolean = true;
  isSupporterFetching: Boolean = true;
  isUsersFetching: Boolean = true;
  orderByFilter: string;
  supporters: any;
  totalSupportersLength: number;
  popUpData: any;
  defaultImage: string;
  pageEvent: Object;
  notRespondedCount: any;
  routeSubscription: any;
  allFilterData: FeedbackFilterModel = new FeedbackFilterModel();
  filters: FeedbackFilterModel = new FeedbackFilterModel();
  alreadyFiltered: FeedbackUserFilterModel = new FeedbackUserFilterModel();
  loadedFilterDataCount = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tabGroup') tabGroup;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.activatedroute.queryParams.subscribe(params => {
      if (params.type) {
        const type = params.type;
        switch (type) {
          // case 'all':
          //   this.tabGroup.selectedIndex = 0;
          //   break;
          case 'notResponded':
            this.tabGroup.selectedIndex = 0;
            break;
          case 'responded':
            this.tabGroup.selectedIndex = 1;
            break;
          default:
            this.router.navigate(['/feedback-request'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
            this.tabGroup.selectedIndex = 0;
        }
      }
      if (params.requestedBy) {
        let requestedByArr;
        if (typeof params.requestedBy === 'string') {
          requestedByArr = [Number(params.requestedBy)];
        }
        else { requestedByArr = params.requestedBy.map(Number); }
        this.alreadyFiltered = { ... this.alreadyFiltered, requestedBy: requestedByArr };
      }
    });
    this.user = <User>this.localStorageService.get('user');
    this.filterByPerson();
  }

  filterByPerson(): void {
    this.isUsersFetching = true;
    const filter = '?filter=(from IN (' + this.user.id + ')) and (type = 1))';
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_FEEDBACK_USERS +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.loadedFilterDataCount++;
          this.allFilterData.requestedBy = <User[]>data['resource'];
          this.allFilterData = { ...this.allFilterData, requestedBy: <User[]>data['resource'] };
          if (this.alreadyFiltered.requestedBy.length) {
            const filterComps = this.allFilterData.requestedBy.filter(comp => this.alreadyFiltered.requestedBy.includes(comp.id));
            this.filters = { ...this.filters, requestedBy: filterComps };
          }
          this.isUsersFetching = false;
        },
        error => {
          this.loadedFilterDataCount++;
          this.isUsersFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  tabChanged($event: MatTabChangeEvent): void {
    switch ($event.index) {
      // case 0:
      //   this.router.navigate(['/feedback-request'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
      //   break;
      case 0:
        this.router.navigate(['/feedback-request'], { queryParams: { type: 'notResponded' }, queryParamsHandling: 'merge' });
        break;
      case 1:
        this.router.navigate(['/feedback-request'], { queryParams: { type: 'responded' }, queryParamsHandling: 'merge' });
        break;
      default:
        this.router.navigate(['/feedback-request'], { queryParams: { type: 'notResponded' }, queryParamsHandling: 'merge' });
        break;
    }
  }
  filterSelect(selectedFilter): void {
    const queryParams = {};
    if (selectedFilter.requestedBy.length) {
      queryParams['requestedBy'] = selectedFilter.requestedBy.map(user => user.id);
      this.router.navigate(['/feedback-request'], { queryParams, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/feedback-request']);
    }
    this.filters = { ...selectedFilter };
  }
}
