import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';

import { navigation } from 'app/navigation/navigation';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from 'app/services/images.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { DocumentChangeAction } from 'angularfire2/firestore';
import { AssessmentFirebase } from 'app/models/assessment-firebase';
import { Observable } from 'rxjs/Observable';
import { environment } from 'environments/environment';
import { User } from 'app/models/user';
import { SearchContentLibraryComponent } from '../../../../app/core/search-content-library/search-content-library.component';
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [ImageService]
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  user: string;
  loggedinUser: User;
  userImage: string;
  unread = 0;
  notificationCount: any;
  sidebarFolded: boolean;
  openSideBar: boolean;

  // Private
  overAllChatCount: number;
  cavisChatCount: number;
  private _unsubscribeAll: Subject<any>;

  fstoreContent: Observable<DocumentChangeAction<AssessmentFirebase>[]>;
  cavisImage = environment.IMAGES.CAVIS;

  constructor(
    private _chatPanelService: ChatPanelService,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private localStorageService: LocalStorageService,
    private _translateService: TranslateService,
    private changeDetection: ChangeDetectorRef,
    private imageService: ImageService,
    private db: AngularFireDatabase,

  ) {


    // Set the defaults
    this.sidebarFolded = true;
    this.openSideBar = false;
    this.overAllChatCount = 0;
    this.cavisChatCount = 0;
    this.chatNotificationCount();
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();


  }

  public changePassword(): void {
    // this.authenticationService.changePassword();
  }

  public logout(): void {
    // this.authenticationService.logoutUser();
  }

  public chatNotificationCount(): void {
    const user = <User>this.localStorageService.get('user');
    const overallNotificationCount = this.db.object('notifications/' + user['id'] + '/channels/').valueChanges();
    overallNotificationCount.subscribe(channels => {
      // console.log(newCount);
      this.overAllChatCount = 0;
      this.cavisChatCount = 0;
      for (const key in channels) {
        if (
          channels.hasOwnProperty(key) &&
          channels[key].count !== undefined) {
          if ((channels[key].type === 'DM' ||
              channels[key].type === 'GROUP')){
            this.overAllChatCount += channels[key].count;
          }else if (channels[key].type === 'DM_BOT'){
            this.cavisChatCount += channels[key].count;
          }
        }
      }
    });
  }
  public getProfileImage(): void {
    const url = <User>this.localStorageService.get('user');
    this.userImage = this.imageService.getUserProfileImage(url['fileDetails_by_profileImage'] === null ? null : url['fileDetails_by_profileImage']['publicId']);

    const items = this.db.object('notifications/' + url.id + '/appNotifications').valueChanges();

    items.subscribe(fbaseitem => {
      this.notificationCount = fbaseitem;
    });
  }

  deleteCount(): void {
    const url = <User>this.localStorageService.get('user');
    const items = this.db.object('notifications/' + url.id + '/appNotifications').remove();
  }
  /**
   * Unfold the sidebar temporarily
   */
  unfoldSidebarTemporarily(key): void {
    this._fuseSidebarService.getSidebar(key).unfoldTemporarily();
    this._fuseSidebarService.getSidebar(key).foldedChanged.emit(this._fuseSidebarService.getSidebar(key).folded);
  }

  ngOnInit(): void {

    const user = <User>this.localStorageService.get('user');
    this.loggedinUser = user;

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
    const userobj = this.localStorageService.get('user');
    if (!userobj || userobj === undefined) {
      this.user = '';
      setTimeout(() => {
        if (this.localStorageService.get('user')) {
          this.user = this.localStorageService.get('user').firstName;
          this.getProfileImage();
          this.changeDetection.detectChanges();
        }
      }, 5000);
    }
    else {
      this.user = userobj.firstName;
      this.getProfileImage();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  search(value): void {
    // Do your search here...
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  goToUserGuide(): void {
    window.open(environment.USER_GUIDE, '_blank');
  }
}
