import { Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TaskModel, JourneyModel } from 'app/models/journey-model';
import { TaskType } from 'app/enums/task-type';
import { Subject } from 'rxjs';
import { JourneyTasksService } from 'app/shared/journey-tasks/journey-tasks.service';
import { MatDialog } from '@angular/material';
import { DeleteTaskConfirmationComponent } from '../dialogs/delete-task-confirmation/delete-task-confirmation.component';

@Component({
  selector: 'task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskListItemComponent implements OnInit, OnDestroy {
  @Input() journey: JourneyModel;
  @Input() task: TaskModel;

  @HostBinding('class.selected')
  selected: boolean;

  @HostBinding('class.completed')
  completed: boolean;

  @HostBinding('class.move-disabled')
  moveDisabled: boolean;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _journeyTasksService: JourneyTasksService,
    public dialog: MatDialog,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    if (this.task.taskType !== TaskType.user_defined) {
      this.moveDisabled = true;
    }
   

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onSelectedChange(): void {
  }

  removeTask(): void {
    if (this.task.taskType === TaskType.user_defined) {
      this._journeyTasksService.deleteTask(this.task);
    } else {
      console.log('You can only delete tasks that you have created.');
    }
  }

  openConfirmDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteTaskConfirmationComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`The dialog was closed : ${result}`);
      if (result) {
        this.removeTask();
      }
    });
  }

  openEditTaskDialog(): void {
    this._journeyTasksService.openEditTaskDialog(this.task);
  }

  toggleStar(event): void {
    event.stopPropagation();
  }

  toggleImportant(event): void {
    event.stopPropagation();
  }

  toggleCompleted(event): void {
    event.stopPropagation();
  }
}
