import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent {

  @Output() result = new EventEmitter<Object>();
  loading = false;
  policyId: string;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.policyId = data.policyId;
  }

  accept(value: Boolean): void {
    this.loading = true;
    const body = {
      policyId: this.policyId,
      consent: value
    };
    this.http.post(
      environment.GENESIS_SERVICE_URL + environment.genesisEndpoints.USER_CONSENT,
      body,
      this.requestHelpers.getBFFHeader()
    ).subscribe(res => {
      this.loading = false;
      this.result.emit({ accept: value });
    },
      err => {
        this.loading = false;
        console.error('Error while sending consent: ', err);
      });
  }

}
