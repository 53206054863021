import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TakeJourneyService} from 'app/core/my-journeys/take-journey/take-journey.service';
import {JourneyModel, TaskModel} from '../../../models/journey-model';
import {User} from 'app/models/user';
import {LocalStorageService} from 'ngx-store';
import { saveAs } from 'file-saver';
import { MatDialog, MatSnackBar, MatTabChangeEvent } from '@angular/material';
import {AttachmentViewComponent} from '../attachment-view/attachment-view.component';
import {ContentViewComponent} from '../../content-view/content-view.component';
import {environment} from '../../../../environments/environment';
import {CuratedContentType} from '../../../enums/curated-content-type';

@Component({
    selector: 'task-attachment',
    templateUrl: './task-attachment.component.html',
    styleUrls: ['./task-attachment.component.scss']
})
export class TaskAttachmentComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() journey: JourneyModel;
    @Input() task: TaskModel;
    taskAttachementType: string;
    user: User;
    isViewingAttachement = false;
    constructor(private takeJourneyService: TakeJourneyService,
                private localStorageService: LocalStorageService,
                public dialog: MatDialog,
                public snackBar: MatSnackBar) {
    }

    viewAttachment(): void {
        if (this.task.attachments) {
            this.isViewingAttachement = true;
            if (this.task.attachments['contentType'] === CuratedContentType.document) {
                this.taskAttachementType = 'Document';
            } else if (this.task.attachments['contentType'] === CuratedContentType.image) {
                this.taskAttachementType = 'Image';
            }else if (this.task.attachments['contentType'] === CuratedContentType.video) {
                this.taskAttachementType = 'Video';
            }else if (this.task.attachments['contentType'] === CuratedContentType.link) {
                this.taskAttachementType = 'Link';
            }
            let fileHash;
            let fileName = '';
            fileHash = this.task.attachments['fileHash'];
            fileName = this.task.attachments['fileName'];
            if (this.task.attachments['contentType'] === CuratedContentType.document) {
                this.takeJourneyService.getUploadedContent(this.user.orgId, this.journey._id, fileName)
                    .subscribe(response => {
                        this.isViewingAttachement = false;
                        const dialogRef = this.dialog.open(AttachmentViewComponent, {
                            panelClass: 'content-viewer',
                            width: '50vw',
                            data: {
                                blobContent: response,
                                contentType: this.taskAttachementType
                            }
                        });
                    });
            }else{
                this.takeJourneyService.getUploadedContentUrl(this.user.orgId, this.journey._id, fileName)
                    .subscribe(response => {
                        this.isViewingAttachement = false;
                        if (response.status){
                            if (this.task.attachments['contentType'] === CuratedContentType.link){
                                window.open(response.url, '_blank');
                            }else{
                                const dialogRef = this.dialog.open(AttachmentViewComponent, {
                                    panelClass: 'content-viewer',
                                    width: '50vw',
                                    data: {
                                        contentUrl: response.url,
                                        contentType: this.taskAttachementType
                                    }
                                });
                            }
                        }else {
                            console.log('Error fetching content');
                        }
                    });
            }
        }
    }

    ngAfterViewInit(): void {
        this.user = this.localStorageService.get('user');
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }
}
