import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { User } from 'app/models/user';
import { MessageService } from 'app/services/subjects.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'stacked-area',
  templateUrl: './stacked-area.component.html',
  styleUrls: ['./stacked-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class StackedAreaComponent implements OnInit, OnDestroy {
  @Input() teamFilter: any;
  user: User;
  single: any[];
  multi: { 'name': string, 'series': Array<{ 'name': Date, 'value': number }> }[] = [];
  feedbackExchangedData: boolean;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';
  subscription: Subscription;
  selectedTeam: any;
  colorScheme = {
    domain: ['#4ca5f5', '#4ca5f5', '#4ca5f5', '#4ca5f5']
  };

  // line, area
  autoScale = true;

  dataCount = 0;

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.feedbackExchangedData = true;
    this.user = this.localStorageService.get('user');
    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      console.log(message.selectedTeam);
      this.selectedTeam = message.selectedTeam;

      this.feedbackExchanged();
    });
    if (this.teamFilter && this.teamFilter.length > 0){
      this.selectedTeam = this.teamFilter;
      this.feedbackExchanged();
    }
    
  }
  feedbackExchanged(): void {
    this.feedbackExchangedData = true;
    const teamIds = [];
    let teamFilter = '';
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';
    }
    const filter = '?managerId=' + this.user['id'] + '&activityType=8' + teamFilter;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.feedbackExchangedData = false;
          this.multi = [
            {
              'name': 'Feedbacks',
              'series': []
            }
          ];
          this.dataCount = 0;
          for (const i in data['resource'][0]['feedback_exchanged']['data']) {
            if (data['resource'][0]['feedback_exchanged']['data'][i]) {
              const obj = {
                'name': new Date(data['resource'][0]['feedback_exchanged']['data'][i]['endDate']),
                'value': data['resource'][0]['feedback_exchanged']['data'][i]['count']
              };
              this.multi[0].series.push(obj);
              this.dataCount += data['resource'][0]['feedback_exchanged']['data'][i]['count'];
            }
          }
        },
        error => {
          console.log(error);
          this.feedbackExchangedData = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  ngOnDestroy(): void {
  }
}
