import { Pipe, PipeTransform } from '@angular/core';
import { JourneyStatus } from 'app/enums/journey-status';
import * as moment from 'moment';

@Pipe({
  name: 'journeyStatusColor'
})
export class JourneyStatusColorPipe implements PipeTransform {

  transform(status: JourneyStatus, dateTime: Date): any {
    switch (status) {
      case 1: {
        // return "EDIT TO BEGIN";
        // return 'NOT STARTED';
        return 'mat-light-blue-900-bg';
      }
      case 2:
        const diff = moment(dateTime).diff(moment(), 'days');
        return diff < 0 ? 'mat-amber-600-bg' : 'mat-light-green-500-bg'; 
        
      case 3:
        // return 'COMPLETED';
        return 'mat-light-green-800-bg';
      case 4:
        // return 'OVERDUE';
        return 'mat-amber-600-bg';
      case 5:
        // return 'DELETED';
        return 'mat-light-blue-900-bg';
      case 6:
        // return 'CONTINOUS';
        return 'mat-orange-500-bg';
      case 7:
        // return 'Inconclusive';
        return 'mat-grey-500-bg';
      default:
        // return 'NEW';
        return 'mat-light-blue-900-bg';
    }

  }

}
