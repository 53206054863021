import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatProgressSpinnerModule
} from '@angular/material';

import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdePopoverModule } from '@material-extended/mde';
import { MyteamApprovalsComponent } from 'app/core/my-teams/myteam-approvals/myteam-approvals.component';

const routes = [
  {
    path: 'myteam-approvals',
    component: MyteamApprovalsComponent
  }
];

@NgModule({
  declarations: [
    MyteamApprovalsComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    ChartsModule,
    NgxChartsModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatGridListModule,
    CdkTableModule,
    NgxContentLoadingModule,
    MatSortModule,
    FuseWidgetModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MdePopoverModule,
    MatCardModule,
    MatToolbarModule,
    CorePipesModule,
    MatProgressSpinnerModule,
    SharedDirectivesModule
  ],
  exports: [
    MyteamApprovalsComponent
  ]
})

export class MyteamApprovalsModule {
}
