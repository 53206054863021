import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JourneyReportComponent } from './journey-report.component';
import { RouterModule } from '@angular/router';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';
import {
  MatButtonModule,
  MatDividerModule,
  MatIconModule,
  MatTabsModule,
  MatCardModule,
  MatExpansionModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatProgressBarModule,
} from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { JourneyReportService } from './journey-report.service';
import { RetakeJourneyConfirmation } from './RetakeJourneyConfirmation/RetakeJourneyConfirmation.component';

const routes = [
  {
    path: 'profile',
    component: JourneyReportComponent
  }
];
@NgModule({
  entryComponents: [RetakeJourneyConfirmation],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    FuseSidebarModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatExpansionModule,
    LevelIndicatorModule,
    MatTableModule,
    MatSortModule,
    CorePipesModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    FuseSharedModule,
    MatChipsModule,
    FuseWidgetModule,
    SvgIconModule,
    NgxChartsModule,
    MatProgressBarModule,
  ],
  declarations: [
    JourneyReportComponent,
    RetakeJourneyConfirmation
  ],
  exports: [
    JourneyReportComponent,
  ],
  providers: [JourneyReportService]
})
export class JourneyReportModule { }
