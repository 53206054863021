import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { FeedbackFilterModel } from 'app/models/filter-model';
import { FeedbackUserFilterModel } from 'app/models/filter-model';
import { MatDialog } from '@angular/material';
import { FeedbackListFilterDialogComponent } from './feedback-list-filter-dialog/feedback-list-filter-dialog.component';

@Component({
  selector: 'feedback-list-filter',
  templateUrl: './feedback-list-filter.component.html',
  styleUrls: ['./feedback-list-filter.component.scss']
})
export class FeedbackListFilterComponent implements OnInit, OnChanges {

  @Input() filterData: FeedbackFilterModel;
  @Input() alreadyFiltered: FeedbackUserFilterModel;
  @Input() disabled: Boolean;
  @Output() filterSelect = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog
  ) {
  }

  allFilterData: FeedbackFilterModel;
  selectedFilterData: FeedbackFilterModel;

  ngOnInit(): void {
    this.allFilterData = this.filterData;
    this.selectedFilterData = new FeedbackFilterModel();
    const selectedComps = this.allFilterData.requestedBy.filter(comp => this.alreadyFiltered.requestedBy.includes(comp.id));
    this.selectedFilterData = { ...this.selectedFilterData, requestedBy: selectedComps };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filterData: SimpleChange = changes.filterData;
    if (filterData && !filterData.firstChange) {
      const current = <FeedbackFilterModel>filterData.currentValue;
      if (current && current.requestedBy) {
        this.allFilterData = current;
        const selectedComps = current.requestedBy.filter(comp => this.alreadyFiltered.requestedBy.includes(comp.id));
        this.selectedFilterData = { ...this.selectedFilterData, requestedBy: selectedComps };
      }
    }
  }
  openFilterDialog(): void {
    const filterDialog = this.dialog.open(FeedbackListFilterDialogComponent, {
      width: '30vw',
      height: 'auto',
      data: { allFilterData: { ...this.allFilterData }, selectedFilterData: { ...this.selectedFilterData } }
    });

    filterDialog.afterClosed().subscribe(result => {
      if (result) {
        this.selectedFilterData = result;
        this.filterSelect.emit(result);
      }
    });
  }
}
