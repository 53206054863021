import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'journey-selector-dialog',
  templateUrl: 'journey-selector.component.html',
})
export class JourneySelectorComponent {

  constructor(
    public dialogRef: MatDialogRef<JourneySelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
