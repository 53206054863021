import { Component, Inject } from '@angular/core';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'select-competency.component',
  templateUrl: 'select-competency.component.html',
  styleUrls: ['../../create-journey.component.scss'],
  providers: [RequestHelpers]
})
export class SelectCompDialogComponent {
  competency: any;
  type: string;

  constructor(
    public dialogRef: MatDialogRef<SelectCompDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.competency = data.competency;
    this.type = data.type;
  }

  selectCompetency(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    if (this.type === 'active') {
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.competency);
    }
  }
}
