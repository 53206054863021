import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatRadioModule,
  MatSidenavModule,
  MatToolbarModule, MatDialogModule, MatProgressSpinnerModule,
  MatProgressBarModule, MatOptionModule, MatAutocompleteModule, MatSelectModule,
  MatExpansionModule, MatRippleModule, MatTabsModule, MatTooltipModule, MatCheckboxModule, MatChipsModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { ChatService } from 'app/shared/chat/chat.service';
import { ImageService } from 'app/services/images.service';
import { ChatComponent } from 'app/shared/chat/chat.component';
import { ChatStartComponent } from 'app/shared/chat/chat-start/chat-start.component';
import { ChatViewComponent } from 'app/shared/chat/chat-view/chat-view.component';
import { ChatChatsSidenavComponent } from 'app/shared/chat/sidenavs/left/chats/chats.component';
import { ChatUserSidenavComponent } from 'app/shared/chat/sidenavs/left/user/user.component';
import { ChatLeftSidenavComponent } from 'app/shared/chat/sidenavs/left/left.component';
import { ChatRightSidenavComponent } from 'app/shared/chat/sidenavs/right/right.component';
import { ChatContactSidenavComponent } from 'app/shared/chat/sidenavs/right/contact/contact.component';
import { DialogAddRemovePeopleComponent } from '../../shared/chat/chat-view/chat-view.component';
// const routes: Routes = [
//     {
//         path     : '**',
//         component: ChatComponent,
//         children : [],
//         resolve  : {
//             chat: ChatService
//         }
//     }
// ];
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { CorePipesModule } from 'app/pipes/pipes.module';


@NgModule({
  declarations: [
    ChatComponent,
    ChatViewComponent,
    ChatStartComponent,
    ChatChatsSidenavComponent,
    ChatUserSidenavComponent,
    ChatLeftSidenavComponent,
    ChatRightSidenavComponent,
    ChatContactSidenavComponent,
    DialogAddRemovePeopleComponent
  ],
  imports: [
    // RouterModule.forChild(routes),

    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSidenavModule,
    MatToolbarModule,

    FuseSharedModule,
    MatSelectModule,
    MatExpansionModule,

    MatAutocompleteModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatRippleModule,

    FuseSharedModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
    MatChipsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    CorePipesModule
  ],
  providers: [
    ChatService, ImageService, AngularFireAuthModule, AngularFireAuth
  ],
  exports: [
    ChatComponent
  ],
  entryComponents: [
    DialogAddRemovePeopleComponent
  ]
})
export class ChatModule {
}
