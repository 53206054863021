import {Component, Inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {RequestHelpers} from '../../../services/request-helpers.service';
import {MixpanelManager} from '../../../services/mixpanel.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TakeJourneyService} from 'app/core/my-journeys/take-journey/take-journey.service';

@Component({
    selector: 'attachment-content',
    templateUrl: './attachment-view.component.html',
    styleUrls: ['./attachment-view.component.scss']
})
export class AttachmentViewComponent {
    public contentUrl: string;
    public contentType: string;
    public blobContent: string;
    public pdfBinaryContent: any;

    constructor(
        private takeJourneyService: TakeJourneyService,
    public dialogRef: MatDialogRef<AttachmentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private snackBar: MatSnackBar,
    private mix: MixpanelManager){
        this.contentUrl = this.data.contentUrl ? this.data.contentUrl : null;
        this.contentType = this.data.contentType ? this.data.contentType : 'Image';
        this.blobContent = this.data.blobContent ? this.data.blobContent : null;
        if (this.contentType === 'Document'){
            const binary_string =  window.atob(this.blobContent);
            const len = binary_string.length;
            const bytes = new Uint8Array( len );
            for (let i = 0; i < len; i++)        {
                bytes[i] = binary_string.charCodeAt(i);
            }
            this.pdfBinaryContent = bytes;
        }
    }
    onClick(): void {
        this.dialogRef.close();
    }
}
