import { Component, OnInit,Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CoursesCardsComponent implements OnInit {
  color = 'primary';
  mode = 'determinate';
  value = 0;
  bufferValue = 0;

  @Input() courseCardData: any;
  stockImage = environment.IMAGES.DEFAULT_CONTENT;
  constructor() { }

  ngOnInit() {
   this.taskCompletion();
    }

  taskCompletion(){
    const duration = this.courseCardData.courseDetails.duration;
    const usage = this.courseCardData.usage;
    let taskCompletion;
    taskCompletion = (usage/duration)*100;
    this.courseCardData['taskCompletion'] = taskCompletion;
  }
  goToPluralSight(id) : void {
    window.open('https://app.pluralsight.com/library/courses/' + id ,'_blank');
  }

 
}
