import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { LevelIndicatorComponent } from './level-indicator.component';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { MatTooltipModule } from '@angular/material';


@NgModule({
    declarations: [
        LevelIndicatorComponent
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        SharedDirectivesModule,
        MatTooltipModule
    ],
    exports: [
        LevelIndicatorComponent
    ]
})

export class LevelIndicatorModule { }
