import { Pipe, PipeTransform } from '@angular/core';
import { JourneyStatus } from 'app/enums/journey-status';
import * as moment from 'moment';

@Pipe({
  name: 'assessmentStatusColor'
})
export class AssessmentStatusColorPipe implements PipeTransform {

  transform(status: JourneyStatus, dateTime: Date): any {
    switch (status) {
      case 1: {
        // return "EDIT TO BEGIN";
        // return 'NOT STARTED';
        const diff = moment(dateTime).diff(moment(), 'days');
        return diff < 0 ? 'amber-600-fg' : 'light-blue-900-fg';
      }
      case 2:
        const daydiff = moment(dateTime).diff(moment(), 'days');
        return daydiff < 0 ? 'amber-600-fg' : 'light-green-500-fg';

      case 3:
        // return 'COMPLETED';
        return 'light-green-800-fg';
      case 4:
        // return 'OVERDUE';
        return 'amber-600-fg';
      case 5:
        // return 'DELETED';
        return 'light-blue-900-fg';
      case 6:
        // return 'CONTINOUS';
        return 'orange-500-fg';
      case 7:
        // return 'Inconclusive';
        return 'grey-500-fg';
      default:
        // return 'NEW';
        return 'light-blue-900-fg';
    }

  }

}
