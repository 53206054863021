import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {FileUploader, FileLikeObject, FileItem} from 'ng2-file-upload';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Headers, RequestOptions } from '@angular/http';
import { LocalStorageService } from 'ngx-store';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { DataSource } from '@angular/cdk/table';
import { environment } from 'environments/environment';
import { JourneyModel, TaskModel } from 'app/models/journey-model';

@Component({
  selector: 'app-offline-upload-content',
  templateUrl: './offline-upload-content.component.html',
  styleUrls: ['./offline-upload-content.component.scss']
})
export class OfflineUploadContentComponent implements OnInit {
  journey: JourneyModel;
  task: TaskModel;
  type: 1 | 2;
  data1 ;
  user;
  dataSource = [] ;
  fileData: File;
  fileToUpload: File;
  public url: any;
  public uploader: FileUploader;
  ELEMENT_DATA: { type: string; fileName: any; action: string; status: string; }[];
  displayedColumns: string[] = ['type', 'fileName', 'action', 'status'];
  constructor(
    public dialogRef: MatDialogRef<OfflineUploadContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: LocalStorageService,
    private requestHelpers: RequestHelpers,
    private http: HttpClient
    ) {
      this.journey = <JourneyModel>data.journey;
    this.task = <TaskModel>data.task;
    this.type = data.type;
    this.user = data.user;
     }

  ngOnInit() {
    this.url = `${environment.GENESIS_SERVICE_URL}${environment.UPLOAD_TASK_CONTENT}?orgId=${this.user.orgId}&journeyId=${this.journey._id}&taskId=${this.task.taskId}`;
    this.initializeUploader(this.url);
  }

    initializeUploader(uri) {
      this.uploader = new FileUploader({
        url: uri,
        disableMultipart: false,
        autoUpload: false,
        method: 'post',
        itemAlias: 'file',
        maxFileSize: 10 * 1024 * 1024,
          queueLimit: 1,
          authToken: 'Bearer ' + <string>this.localStorageService.get('accessToken'),
          headers: this.requestHelpers.getFileUploaderOptions().headers
      });

      this.uploader.onProgressItem = (item: FileItem, progress: any) => {
        console.log(item);
        this.ELEMENT_DATA = [
          { type: 'Document', fileName: this.data1, action: 'Remove', status: 'Upload in progress' },
        ];
        this.dataSource = this.ELEMENT_DATA;
      };

      this.uploader._onErrorItem = (item: any, response: any, status: any, headers: any) => {
        this.ELEMENT_DATA = [
          { type: 'Document', fileName: this.data1, action: 'Remove', status: 'Failed to upload' },
        ];
        this.dataSource = this.ELEMENT_DATA;
      };

      this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
        response = JSON.parse(response);
        if (response.status) {
          this.ELEMENT_DATA = [
            { type: 'Document', fileName: this.data1, action: 'Remove', status: 'Uploaded successfully' },
          ];
          this.dataSource = this.ELEMENT_DATA;
          this.uploader.queue[0].remove();
        }
        else if (!response.status) {
          this.ELEMENT_DATA = [
            { type: 'Document', fileName: this.data1, action: 'Remove', status: 'Failed to upload' },
          ];
          this.dataSource = this.ELEMENT_DATA;
        }

      };

    }

  public onFileSelected(event: EventEmitter<File[]>): void{
    console.log(event[0].type);
    const file: File = event[0];
    const type = event[0].type;
    this.data1 = file.name ;
    let status = 'Ready to Upload';
    // if (type !== 'image/jpeg' && type !== 'image/png' && type !== 'application/pdf' && type !== 'video/mp4') {
    //   status = 'File type not supported';
    // }
    this.fileToUpload = file;
      this.ELEMENT_DATA = [
        { type: 'Document', fileName: this.data1, action: 'Remove', status: status},
      ];
      this.dataSource = this.ELEMENT_DATA;
  }


  removeFile(): void{
    this.dataSource = [];
    this.uploader.queue[0].remove();
  }

  cancel(): void {
    this.onCancel({ status: true});
  }
  onCancel(result): void {
    this.dialogRef.close(result);
  }


  onUpload(): void{
    this.uploader.uploadAll();
  }
}
