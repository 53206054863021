import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import {
  APP_CONSTANTS
} from './app-constants';

const MESH_API = 'https://staging-istio.sproutlogix.com';
const CLOUDINARY_URL = 'https://res.cloudinary.com/owlyadmin/image/upload/';
const APPLICATION_URL = 'https://staging-app.sproutlogix.com';

export const environment = {
  production: false,
  hmr: false,
  APPLICATION_URL,
  DREAMFACTORY_API_KEY: '36fda24fe5588fa4285ac6c6c2fdfbdb6b6bc9834699774c9bf777f706d05a88',
  BOOST_SERVICE_URL: 'http://staging-app.sproutlogix.com/mobileapi',
  DF_BASE_URL: 'https://staging-api-engine.sproutlogix.com/api/v2',
  FIREBASE_API_URL: 'https://us-central1-develoop-staging.cloudfunctions.net',
  GENESIS_SERVICE_URL: `${MESH_API}/genesisapi`,
  BRAINIAC_BASE_URL: `${MESH_API}/`,
  CALYPSO_BASE_URL: `${MESH_API}/calypso/api/2.0`,
  BRAINIAC_CONSTRUCTIVENESS_URL: 'http://staging-brainiac.sproutlogix.com:8080/email_program/newcvscore/',
  USER_TABLE: '/booststaging/_table/uom_user',
  DF_BASE_URL_HL: 'https://staging-api-engine.sproutlogix.com/api/v2/booststaging/_table/hyperloop_',
  DF_MONGO_BASE_URL_HL: 'https://staging-api-engine.sproutlogix.com/api/v2/cavanticsmongodb/_table/hyperloop.orgid_',
  GENOS_BASE_URL: `${MESH_API}/genos/api/2.0`,
  AUKAT_BASE_URL: `${MESH_API}/aukat/api/2.0`,
  CLOUDINARY_PUBLIC_URL: `${CLOUDINARY_URL}`,
  ANALYTICS_URL: 'analyticsapi/getDeveloopAnalytics',
  SKYNET_BASE_URL: `${MESH_API}/skynet/1.0`,
  firebase: {
    apiKey: 'AIzaSyAEMu6dhCvcmN2_RIzum15GY6bvij9byGA',
    authDomain: 'develoop-staging.firebaseapp.com',
    databaseURL: 'https://develoop-staging.firebaseio.com',
    projectId: 'develoop-staging',
    storageBucket: 'develoop-staging.appspot.com',
    messagingSenderId: '963064230790',
    appId: "1:963064230790:web:e7d7c6963ad72f1d647207",
    measurementId: "G-1D8D05KQDD"
  },
  MIXPANEL_TOKEN: '541b954b773befe242db554f151a9d27',
  ONESIGNAL_APPID: '13f80a32-c4f1-4d5b-8569-374823347803',
  IMAGES: {
    DEFAULT_CONTENT: `${CLOUDINARY_URL}default-content.png`,
    CAVIS: `${CLOUDINARY_URL}cavis_circle.png`
  },
  auth: {
    clientID: 'xpnp172gVaA6Ti4mTX4qhTSiknoHNrcZ',
    domain: 'weetingstage.auth0.com',
    audience: 'https://staging-app.sproutlogix.com/',
    redirect: `${APPLICATION_URL}/callback`,
    scope: 'openid profile email'
  },
  ...APP_CONSTANTS
};
