import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule, MatBadgeModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { NotificationsModule } from 'app/shared/notifications/notifications.module';

import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';
import { SearchContentLibraryModule } from '../../../core/search-content-library/search-content-library.module';
@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatToolbarModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    SharedDirectivesModule,
    NotificationsModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    ChatPanelModule,
    MatBadgeModule,
    SearchContentLibraryModule
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule {
}
