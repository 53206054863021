import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { ContentViewComponent } from 'app/shared/content-view/content-view.component';

@Component({
  selector: 'content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardsComponent {
  @Input() contentdata;
  @Input() competencyName;
  @Input() fromJourney;
  stockImage = environment.IMAGES.DEFAULT_CONTENT;

  popsupDialogBox(content): void {
    const dialogRef = this.dialog.open(ContentViewComponent, {
      panelClass: 'content-viewer',
      width: '50vw',
      data: {
        contentId: content._id,
        contentURL: content.link,
        contentType: content.contentType,
        title: content.title,
        fromJourney: this.fromJourney ? this.fromJourney : false
      }
    });
  }

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar) {}
}
