import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatCardModule, MatToolbarModule } from '@angular/material';
import { OnboardingComponent } from './onboarding.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CommonModule } from '@angular/common';
import { HelpRequiredComponent } from './dialogs/help-required/help-required.component';

const routes = [
  {
    path: 'onboarding',
    component: OnboardingComponent
  }
];

@NgModule({
  declarations: [
    OnboardingComponent,
    HelpRequiredComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    FormsModule,
    CommonModule,
    SlideshowModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule
  ],
  exports: [
    OnboardingComponent
  ],
  entryComponents: [
    HelpRequiredComponent
  ]
})

export class OnboardingModule { }
