import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentComponent } from './consent.component';
import { MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  declarations: [ConsentComponent],
  exports: [
    ConsentComponent
  ]
})
export class ConsentModule { }
