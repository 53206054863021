import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { LocalStorageService } from 'ngx-store';
import * as moment from 'moment';

import { User } from 'app/models/user';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: fuseAnimations
})
export class NotificationsComponent {

  items: Observable<any[]>;
  noNotify: boolean;
  notifications: any[];

  constructor(

    private afsdb: AngularFirestore,
    private localStorageService: LocalStorageService) {

    this.notifications = [];

    const slDateFormat = {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: '[last] dddd [at] LT',
      nextWeek: 'dddd [at] LT',
      sameElse: 'DD MMM YYYY'
    };

    setTimeout(() => {
      const user = <User>this.localStorageService.get('user');

      this.items = this.afsdb
        .collection('appNotifications', ref => ref
          .where('userId', '==', Number(user['id']))
          .where('app', '==', 'HYPERLOOP')
          .orderBy('createdOn', 'desc').limit(25)).snapshotChanges();

      this.noNotify = false;
      this.items.forEach(fbaseitem => {
        const channels = [];
        fbaseitem.forEach(item => {

          const key = item.payload.doc.id;
          const data = item.payload.doc.data();
          const chanObj = {
            channelId: key,
            ...data
          };
          channels.push(chanObj);
        });
        console.log(channels);

        channels.forEach(notify => {
          let tempData;
          if (notify['data'] && notify['data'] !== {} && typeof notify['data'] === 'string') {
            tempData = JSON.parse(notify['data']);
          }
          const journeyId = tempData && tempData['journeyId'] ? tempData['journeyId'] : null;
          switch (notify.type) {
            case 1:
            case 5:
              notify['redirectionURL'] = '/feedback-request';
              notify['imageURL'] = 'comment';
              break;
            case 6:
            case 2:
            case 3:
            case 10:
            case 7:
            case 11:
              notify['redirectionURL'] = '/journeys-take/' + journeyId;
              notify['imageURL'] = 'flag';
              break;
            case 8:
            case 4:
            case 9:
              notify['redirectionURL'] = '/supported-journeys';
              notify['imageURL'] = 'supervisor_account';
              break;
            case 12:
              notify['redirectionURL'] = '/journeys';
              notify['imageURL'] = 'flag';
              break;
            case 13:
              notify['redirectionURL'] = '/competencies';
              notify['imageURL'] = 'tune';
              break;
            case 14:
              notify['redirectionURL'] = '/journeys';
              notify['imageURL'] = 'supervisor_account';
              break;
            case 15:
              notify['imageURL'] = 'supervisor_account';
              break;
            case 16:
              notify['redirectionURL'] = '/my-assessments';
              notify['imageURL'] = 'assessment';
              break;
            case 17:
              notify['redirectionURL'] = '/my-assessments';
              notify['imageURL'] = 'assessment';
              break;
            case 18:
              notify['redirectionURL'] = '/my-assessments';
              notify['imageURL'] = 'assessment';
              break;
            case 19:
              notify['redirectionURL'] = '/competencies';
              notify['imageURL'] = 'tune';
              break;
            case 21:
              notify['redirectionURL'] = '/my-assessments';
              notify['imageURL'] = 'assessment';
              break;
            default:
              notify['imageURL'] = 'flag';
              break;
          }
          const filteredTime = moment
            .utc(notify.createdOn.toDate())
            .local()
            .calendar(null, slDateFormat);

          notify['filteredTime'] = filteredTime;

          this.notifications.push(notify);
        }, 10000);
      });

    });

  }

  isReadFlag(item): void {
    console.log(item);
    const ref = this.afsdb.collection('appNotifications').doc(item.channelId);
    ref.update({ isRead: true });
  }
  isMilestoneIconNotification(notifType: string): Boolean {
    return notifType === '3' || notifType === '4' || notifType === '7' || notifType === '8' || notifType === '9';
  }
}
