import { NgModule } from '@angular/core';
import {
  MatButtonModule, MatMenuModule, MatDialogModule, MatProgressSpinnerModule,
  MatProgressBarModule, MatOptionModule, MatAutocompleteModule, MatSelectModule,
  MatExpansionModule, MatListModule,
  MatFormFieldModule, MatIconModule, MatInputModule, MatRippleModule, MatTabsModule, MatTooltipModule, MatCheckboxModule, MatChipsModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { ChatPanelComponent } from 'app/layout/components/chat-panel/chat-panel.component';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { LinkyModule } from 'angular-linky';
import { MatProgressButtons } from 'mat-progress-buttons';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    ChatPanelComponent,
  ],
  providers: [
    ChatPanelService, AngularFireAuthModule, AngularFireAuth
  ],
  imports: [
    MatSelectModule,
    MatExpansionModule,
    MatProgressButtons,
    MatAutocompleteModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    LinkyModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatRippleModule,
    CorePipesModule,
    FuseSharedModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
    MatChipsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule
  ],
  exports: [
    ChatPanelComponent
  ],
  entryComponents: [],
})
export class ChatPanelModule {
}
