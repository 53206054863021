import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'give-feedback-component',
  templateUrl: 'give-feedback.component.html',
  styleUrls: ['../../feedback-request.component.scss'],
})
export class GiveFeedbackComponent {
  onResult = new EventEmitter<Object>();

  constructor(
    public dialogRef: MatDialogRef<GiveFeedbackComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  onFeedbackResult($event): void {
    this.onResult.emit($event);
    if ('isCanceled' in $event && $event.isCanceled) {
      this.onNoClick();
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
