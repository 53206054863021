import { Component, Input } from '@angular/core';

@Component({
  selector: 'list-header-section',
  templateUrl: './list-header-section.component.html',
  styleUrls: ['./list-header-section.component.scss']
})
export class ListHeaderSectionComponent {

  @Input() title: String;
  @Input() subtitle: String;
  @Input() icon: String;

  constructor() { }

}
