import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TakeAssessmentComponent } from 'app/shared/assessment-list/assessment-list/take-assessment/take-assessment.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<TakeAssessmentComponent> {
  canDeactivate(component: TakeAssessmentComponent): boolean {

    if (component.isOngoingAssessment()) {
      if (confirm('Are you sure you want to quit the assessment?')) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
