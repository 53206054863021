import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { TaskModel, JourneyModel } from 'app/models/journey-model';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { JourneyStatus } from 'app/enums/journey-status';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'ngx-store';
import { MatDialog } from '@angular/material';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { ConfirmStartAssessmentComponent } from 'app/core/my-journeys/take-journey/dialogs/confirm-start-assessment/confirm-start-assessment.component';
import { InstructionsComponent } from 'app/core/my-journeys/take-journey/dialogs/instructions/instructions.component';
import { Router } from '@angular/router';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import * as moment from 'moment';

@Component({
  selector: 'assessment-task-component',
  templateUrl: './assessment-task.component.html',
  styleUrls: ['./assessment-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AssessmentTaskComponent implements OnInit, OnDestroy {

  @Input() task: TaskModel;
  @Input() journey: JourneyModel;

  user: any;
  startingAssessment: Boolean;
  assessmentId: Number;
  batchId: string;
  behavioralAssessmentStatus: Array<any> = [];
  isFetchingAssessStatus: Boolean;
  startedAssessment: Boolean;
  JourneyStatus;
  selfAssessment;
  JourneyAccessRoles = JourneyAccessRoles;
  isPastDue: Boolean = true;

  constructor(
    private newTakeJourneyService: TakeJourneyService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    public mixPanelManager: MixpanelManager,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.selfAssessment = {};
    this.JourneyStatus = JourneyStatus;
    this.user = this.localStorageService.get('user');
    if (this.journey.competency['tag'][0].id === 1 && this.journey['endBatchId'] !== undefined) {
      console.log(this.journey);
      this.fetchAssessmentStatus(this.journey['endBatchId']);
      this.fetchBatchStatus(this.journey['endBatchId']);
    } else {
      this.isPastDue = false;
    }
  }

  fetchBatchStatus(batchId): void {
    this.http
      .get(environment.DF_MONGO_BASE_URL_HL + this.user.orgId + environment.DF_BASELINE_BATCH + `?filter=(_id=${batchId})`, this.requestHelpers.getDFHeader())
      .subscribe(res => {
        const data = res['resource'][0];
        const dueDate = moment(data.dueDate.$date);
        const diff = moment(dueDate).diff(moment(), 'days');
        this.isPastDue = ((diff) < 0);
      }, err => {
      });
  }

  assessmentCompleted(): void {
    const journey = { ...this.journey };
    const taskId = this.task.taskId;
    journey.tasks.forEach(task => {
      if (task.taskId === taskId) {
        task.status = JourneyStatus.completed;
      }
    });
    journey.ongoingTask++;
    this.newTakeJourneyService.updateOnJourneyChanged(journey);
  }

  startAssessment(): void {
    this.openConfirmStartDialog();
  }

  openConfirmStartDialog(): void {
    const dialogRef = this.dialog.open(ConfirmStartAssessmentComponent, {
      width: '30vw',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.status) {
        this.startedAssessment = true;
        this.startingAssessment = true;
        console.log('Start the assessment!');
        const postData = {
          'userId': this.user.id,
          'supporters': this.journey.supporters,
          'assessmentType': 1,
          'competencyId': this.journey.competencyId,
          'orgId': this.user.orgId,
          'journeyId': this.journey._id
        };

        this.http
          .post(
            environment.AUKAT_BASE_URL +
            environment.AUKAT_CREATE_JOURNEY_BATCH,
            postData,
            this.requestHelpers.getBFFHeader())
          .subscribe(
            (data: any) => {
              console.log(data);
              if (data.resource.length) {
                this.assessmentId = data.resource[0].assessmentId;
                this.batchId = data.resource[0].batchId;
              }
              this.fetchAssessmentStatus(this.batchId);
              this.startingAssessment = false;
              this.journey['endBatchId'] = this.batchId;
            },
            (error: any) => {
              console.log(error);
              this.startingAssessment = false;
            }
          );
        // this.router.navigate([`../../my-assessments/take-assessment/${this.assessmentId}`]);
      }
    });
  }

  goToAssessment(): void {
    const dialogRef = this.dialog.open(InstructionsComponent, {
      width: '40vw',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.router.navigate([`../../my-assessments/take-assessment/${this.selfAssessment.assessmentId}`]);
      }
    });
  }

  fetchAssessmentStatus(batchId): void {
    this.behavioralAssessmentStatus = [];
    this.isFetchingAssessStatus = true;
    this.http
      .get(
        environment.AUKAT_BASE_URL +
        environment.AUKAT_GET_ASSESSEMENT_STATUS +
        `?userId=${this.user.id}&orgId=${this.user.orgId}&batchId=${batchId}`,
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (result: any) => {
          console.log(result);
          if (result.resource.length) {
            result.resource.forEach(element => {
              element['selected'] = element.status === JourneyStatus.completed ? true : false;
              if (element.userId === this.user.id) {
                this.selfAssessment = element;
                console.log(this.selfAssessment);
              }
            });
            this.behavioralAssessmentStatus = result.resource;
          }
          this.isFetchingAssessStatus = false;
        },
        (error: any) => {
          console.log(error);
          this.isFetchingAssessStatus = false;
        });
  }


  ngOnDestroy(): void {
  }
}
