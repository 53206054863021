import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { JourneyStatus } from 'app/enums/journey-status';
import { LocalStorageService } from 'ngx-store';
import { User } from '../../../../../../../models/user';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { FeedbackRequestComponent } from 'app/core/my-journeys/take-journey/dialogs/feedback-request/feedback-request.component';
import { JourneyCompletedComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-completed/journey-completed.component';
import { OfflineUploadContentComponent } from 'app/core/my-journeys/take-journey/dialogs/offline-upload-content/offline-upload-content.component';
import { CuratedContentType } from 'app/enums/curated-content-type';
import { saveAs} from 'file-saver';
@Component({
  selector: 'udt-task-component',
  templateUrl: './udt-task.component.html',
  styleUrls: ['./udt-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UDTTaskComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() task: TaskModel;
  @Input() journey: JourneyModel;
  @Input() showFeedback: Boolean;

  journeyStatus = JourneyStatus;
  user: User;
  filetype;
  isNetworkCallActive = false;
  isMarkingStartTask: Boolean = false;
  isMarkingIamDone: Boolean = false;
  isOngoing: Boolean;
  isAttachment: Boolean = false;
  attachmentVersion = 1;

  animationDirection: 'left' | 'right' | 'none';
  animationDirectionFeedback: 'left' | 'right' | 'none';

  constructor(
    private localStorageService: LocalStorageService,
    private newTakeJourneyService: TakeJourneyService,
    private dialog: MatDialog
  ) {
    this.animationDirection = 'none';
    this.animationDirectionFeedback = 'none';
  }

  startUDTask(): void {
    if (this.isNetworkCallActive) {
      return;
    }

    this.isNetworkCallActive = true;
    this.isMarkingStartTask = true;
    this.newTakeJourneyService
      .startTask(this.journey._id, this.task.taskId)
      .subscribe(
        (data: any) => {
          this.isNetworkCallActive = false;
          this.isMarkingStartTask = false;
          const taskIndex = this.journey.tasks.findIndex(
            task => task.taskId === this.task.taskId
          );
          if (taskIndex !== -1) {
            this.journey.tasks[taskIndex].status = this.journeyStatus.ongoing;
            this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
          }
        },
        (error: any) => {
          console.log(error);
          this.isMarkingStartTask = false;
        }
      );
  }


  uploadTaskConfirmation(){
    const dialogRef = this.dialog.open(OfflineUploadContentComponent, {
      panelClass: 'app-dialog-box',
      width: '50vw',
      height: 'auto',
      data: {
        journey: this.journey,
        task: this.task,
        user: this.user,
        type: 1
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.iAmDone()
      }
    });
}

  iAmDone(): void {
    if (this.isNetworkCallActive) {
      return;
    }

    this.isNetworkCallActive = true;
    this.isMarkingIamDone = true;
    this.newTakeJourneyService
      .updateTaskStatus(this.journey._id, this.task.taskId, false, true)
      .subscribe(
        (data: any) => {
          if (this.task.isFbRequested) {
            this.showFeedback = true;
            this.isNetworkCallActive = false;
            this.isMarkingIamDone = false;
            this.task.pendingFeedback = true;
          }
          else {
            this.completeTask();
          }


        },
        (error: any) => {
          console.log(error);
          this.isMarkingIamDone = false;
        }
      );
  }

  openJourneyCompletedDialog(): void {
    const dialogRef = this.dialog.open(JourneyCompletedComponent, {
      width: '30vw',
      data: {}
    });
  }

  completeTask(): void {
    console.log('completing this task');
    this.newTakeJourneyService
      .completeTask(this.task.taskId, this.journey._id)
      .subscribe(
        (result: any) => {
          console.log('result.....', result);
          const taskIndex = this.journey.tasks.findIndex(
            task => task.taskId === this.task.taskId
          );
          if (taskIndex !== -1) {
            this.journey.tasks[
              taskIndex
            ].status = this.journeyStatus.completed;
            this.journey.ongoingTask =
              this.journey.tasks[taskIndex].taskNo + 1;
            this.newTakeJourneyService.onJourneyChanged.next(this.journey);
          }
          if (result.journeyCompleted) {
            this.openJourneyCompletedDialog();
          }
        },
        (error: any) => {
        }
      );
  }

  openRequestDialog(): void {
    const dialogRef = this.dialog.open(FeedbackRequestComponent, {
      width: '50vw',
      data: {
        journey: this.journey,
        task: this.task,
        user: this.user,
        type: 1
      }
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe(
      journey => {
        this.journey = journey;
        // call update journey subject
        const taskIndex = this.journey.tasks.findIndex(
          task => task.taskId === this.task.taskId
        );
        if (taskIndex !== -1) {
          console.log(this.journey.tasks[taskIndex].feedback);
          this.task['feedback'] = [];
          this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  ngOnInit(): void {
    if (this.task.attachments) {
      this.isAttachment = true;
      if (this.task.attachments['version']){
        this.attachmentVersion = this.task.attachments['version'];
      }
    }
    this.user = this.localStorageService.get('user');
    if (!this.task.supporters || !this.task.supporters.length) {
      this.showFeedback = false;
    }
    this.isOngoing = this.journey.ongoingTask === this.task.taskNo;
  }

  downloadContent(): void {
    if (this.task.attachments) {
      const fileHash = this.task.attachments['fileHash'];
      const fileName = this.task.attachments['fileName'];
      this.isAttachment = true;
      this.newTakeJourneyService.downloadUploadedContent(this.user.orgId, fileHash)
      .subscribe(resource => {
        saveAs(new Blob([resource]), fileName);
      });
    }
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void { }

}
