import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RequestHelpers } from '../../services/request-helpers.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-store';
import { MatPaginator } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material';
import { CompetencyStatuses, CompetencyListTypes } from 'app/enums/competency-list-types';

@Component({
  selector: 'my-competencies',
  templateUrl: './my-competencies.component.html',
  styleUrls: ['./my-competencies.component.scss'],
})
export class MyCompetenciesComponent implements OnInit, AfterViewInit {
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    private activatedroute: ActivatedRoute,
    private router: Router

  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }
  levelData: Array<any>;
  useGreyBg: string;
  peerLevel: number;
  // competencyName: string;
  data: any = {
    statuses: [
      { name: 'Active', display: 'active', count: 0, value: true, class: 'active' },
      { name: 'Expected', display: 'expected', count: 0, value: true, class: 'active' },
      { name: 'Additional', display: 'additional', count: 0, value: false, class: 'active' }
    ],
    competencies: [],
    title: 'My Competencies'
  };

  dataLength = 0;
  pageSize = 10;
  displayedColumns: string[] = [
    'displaytext',
    'tag.name',
    // 'assignedBy.firstName',
    'view'
  ];

  // current page of items
  users: Array<any> = [];
  pageOfItems: Array<any> = [];
  dataSource: Array<any> = [];
  popUpContent: any = {};
  user: any;
  filterData: any;
  selectedStatuses: Array<any> = [];
  currentLevel: string;
  targetLevel: string;
  flag = false;
  isTableFetching: Boolean = true;
  compFilter = '';
  pageEvent: Object;
  routeSubscription: any;
  CompetencyStatuses = CompetencyStatuses;
  CompetencyListTypes = CompetencyListTypes;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tabGroup') tabGroup;

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    // this.onPaginateChange({ pageIndex: 0 });
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.activatedroute.queryParams.subscribe(params => {
      if ('type' in params) {

        const selectedStatus = this.data.statuses.find(status => status.display === params.type);
        if (selectedStatus) {
          this.selectedStatuses = [selectedStatus];
          // if (params.type === 'all') {
          //   this.tabGroup.selectedIndex = 0;
          // }
          if (params.type === 'active') {
            this.tabGroup.selectedIndex = 0;
          }
          if (params.type === 'expected') {
            this.tabGroup.selectedIndex = 1;
          }
          if (params.type === 'additional') {
            this.tabGroup.selectedIndex = 2;
          }

          this.pageEvent = { pageIndex: 0 };
          this.onPaginateChange({ pageIndex: 0 });

        } else {
          this.router.navigate(['/competencies'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
          this.selectedStatuses = [];
          this.tabGroup.selectedIndex = 0;
          this.pageEvent = { pageIndex: 0 };
          this.onPaginateChange({ pageIndex: 0 });
        }
      } else {
        this.router.navigate(['/competencies'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
        this.selectedStatuses = [];
        this.tabGroup.selectedIndex = 0;
        this.pageEvent = { pageIndex: 0 };
        this.onPaginateChange({ pageIndex: 0 });
      }
    });

    // this.user = this.localStorageService.get('user');
  }

  onPaginateChange($event: any): void {

    let filter = '';
    let order = '';

    if (this.selectedStatuses.length === 0) {
      filter = '&additional=1&expected=1&active=1';
    } else {
      if (this.selectedStatuses[0]['display'] == 'active') {
        filter = '&additional=0&expected=0&active=1';
      }
      if (this.selectedStatuses[0]['display'] == 'expected') {
        filter = '&additional=0&expected=1&active=0';
      }
      if (this.selectedStatuses[0]['display'] == 'additional') {
        filter = '&additional=1&expected=0&active=0';
      }
    }

    if ($event.sort) {
      if ($event.by === '') {
        order = null;
      } else {
        order = $event.sort + ' ' + $event.by;
      }
    } else {
      order = null;
    }

    this.dataSource = [];
    this.isTableFetching = true;
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GET_COMPETENCY_LIST +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&order=' + order +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.dataSource = data['resource'];
          const orig = this.dataSource;
          const newArray = orig.map(comp => {

            const newComp = comp;
            newComp['level'] = comp['levels'].map(level => {
              const obj = {
                levelName: ''
              };
              let levelStatus = '';

              if (level.id < comp['endLevel'] && level.id > comp['startLevel']) {
                levelStatus = 'gap';
              }
              if (level.id <= comp['startLevel']) {
                levelStatus = 'active';
              }
              if (level.id === comp['endLevel']) {
                levelStatus = 'current';
              }
              obj['levelStatus'] = levelStatus;
              level['levelStatus'] = levelStatus;
              level['levelName'] = level.levelName;
              obj['levelName'] = level.levelName;
              return obj;
            });
            return newComp;
          });

          // adding filter counts
          this.data['statuses'][0]['count'] = data['active'];
          this.data['statuses'][1]['count'] = data['expected'];
          this.data['statuses'][2]['count'] = data['added'];

          this.dataLength = data['meta']['count'];
          this.isTableFetching = false;
        },
        error => {
          this.isTableFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }
  applyFilter(): void {
    this.paginator.pageIndex = 0;
    this.onPaginateChange({ pageIndex: 0, filter: this.compFilter });
  }

  onStatusFilterChanged($event): void {
    this.compFilter = '';
    if ($event.length) {
      for (let i = 0; i < $event.length; i++) {
        this.compFilter = this.compFilter + '&' + $event[i]['display'] + '=1';
      }
    } else {
      this.compFilter = '&additional=1&expected=1&active=1';
    }
    // this.paginator.pageIndex = 0;
    // this.onPaginateChange({ pageIndex: 0, filter: this.compFilter });
  }

  sortData(sort: any): void {

    this.onPaginateChange({ pageIndex: 0, filter: this.compFilter, sort: sort.active, by: sort.direction });
  }
  openPopup(element: any): void {

    this.flag = true;
    this.popUpContent = element;
    for (let i = 0; i < this.popUpContent['levels'].length; i++) {
      if (this.popUpContent['levels'][i]['id'] === this.popUpContent['startLevel']) {
        this.currentLevel = this.popUpContent['levels'][i]['levelName'];
      }
      if (this.popUpContent['levels'][i]['id'] === this.popUpContent['endLevel']) {
        this.targetLevel = this.popUpContent['levels'][i]['levelName'];
      }
    }

  }

  statusFilterClear(): void {
    this.selectedStatuses = [];
    this.paginator.pageIndex = 0;
    this.onPaginateChange({ pageIndex: 0 });
  }


  tabChanged($event: MatTabChangeEvent): void {
    switch ($event.index) {
      // case 0:
      //   this.router.navigate(['/competencies'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
      //   break;
      case 0:
        this.router.navigate(['/competencies'], { queryParams: { type: 'active' }, queryParamsHandling: 'merge' });
        break;
      case 1:
        this.router.navigate(['/competencies'], { queryParams: { type: 'expected' }, queryParamsHandling: 'merge' });
        break;
      case 2:
        this.router.navigate(['/competencies'], { queryParams: { type: 'additional' }, queryParamsHandling: 'merge' });
        break;
      default:
        this.router.navigate(['/competencies'], { queryParams: { type: 'all' }, queryParamsHandling: 'merge' });
        break;
    }
  }
}
