import { Inject, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ButtonOpts } from 'mat-progress-buttons';
import { Subject, observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-store';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { User } from 'app/models/user';
import { ImageService } from '../../../services/images.service';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import { AuthPro } from 'angularfire2';

import * as firebase from 'firebase/app';
import { type } from 'os';

export interface State {
    flag: string;
    name: string;
    population: string;
}
@Component({
    selector: 'develoop-chat-panel',
    templateUrl: './develoop-chat.component.html',
    styleUrls: ['./develoop-chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ImageService, RequestHelpers]
})
export class DeveloopChatComponent implements OnInit, AfterViewInit, OnDestroy {
    loadLastCount: number;
    chat: any;
    selectedContact: any;
    sidebarFolded: boolean;
    groupInfo: any;
    channelDetails: any;
    contacts: Array<any> = [];
    groupDetails: Array<any> = [];
    openDMSelection: boolean;
    openGroupSelection: boolean;
    localUser: any;
    allUsersData: any;
    searchFilter: any;
    checked: any;
    groupName: string;
    newSelectedOption: any;
    allUserDataLoaded: boolean;
    groupLoaderFlag: boolean;
    filteredStates: Observable<State[]>;
    members: any;
    stateCtrl = new FormControl();
    readyToReply: boolean;
    searchName: any;
    showInputBox: boolean;
    editName: any;
    removedOn: any;
    openSideBar: boolean;
    overAllChatCount: number;
    showInputTextBox: boolean;
    @ViewChild('replyForm')
    set replyForm(content: NgForm) {
        this._replyForm = content;
    }

    @ViewChild('replyInput')
    set replyInput(content: ElementRef) {
        this._replyInput = content;
    }

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _replyForm: NgForm;
    private _replyInput: ElementRef;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {HttpClient} _httpClient
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _chatPanelService: ChatPanelService,
        private _httpClient: HttpClient,
        // private http: HttpClient,
        private _fuseSidebarService: FuseSidebarService,
        private angularDB: AngularFireDatabase,
        public dialog: MatDialog,
        private db: AngularFirestore,
        private localStorageService: LocalStorageService,
        private requestHelpers: RequestHelpers,
        private imageService: ImageService,
    ) {
        // Set the defaults
        this.openGroupSelection = false;
        this.openDMSelection = false;
        this.selectedContact = null;
        this.sidebarFolded = true;
        this.groupInfo = [];
        this.allUserDataLoaded = true;
        // Set the private defaults

        this._unsubscribeAll = new Subject();
        this.contacts = [];
        this.members = [];
        this.groupLoaderFlag = false;
        this.loadLastCount = 1;
        this.readyToReply = false;
        this.showInputBox = false;
        this.removedOn = false;
        this.showInputTextBox = true;
        this.openSideBar = false;
        this.overAllChatCount = 0;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    openDialog(groupInfo): void {
        const dialogRef = this.dialog.open(DialogOverviewComponent, {
            width: '50vw',
            height: 'auto',
            data: {
                groupInfo: groupInfo,
                userData: this.localUser
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.selectedContact = null;
            }
            console.log('The dialog was closed');
        });
    }
    openAddRemoveUserDialog(groupInfo): void {
        const dialogRef = this.dialog.open(DialogAddRemovePeopleComponent, {
            width: '50vw',
            height: 'auto',
            data: {
                groupInfo: groupInfo,
                userData: this.localUser
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            this.toggleChat(this.selectedContact);

        });
    }

    openGroupDialog(): void {
        const dialogRef = this.dialog.open(DialogOverviewGroupComponent, {
            width: '50vw',
            height: 'auto',
            data: {
                userData: this.localUser
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result) {
                this.selectedContact = result;
                this.toggleChat(this.selectedContact);
            }
        });
    }
    ngOnInit(): void {

        setTimeout(() => {

            this.localUser = <User>this.localStorageService.get('user');


            // Load the contacts
            this.chat = {
                'dialog': []
            };

            // Subscribe to the foldedChanged observable
            this._fuseSidebarService.getSidebar('develoopChatPanel').foldedChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((folded) => {
                    this.sidebarFolded = folded;
                });

            this.getChannels();
            this.getAllUsers();
        }, 5000);


    }


    createDM(user): void {

        const postData = {
            'from': this.localUser['id'],
            'to': user['id'],
        };
        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.CREATE_DM, postData)
            .subscribe(
                (data: any) => {
                    const newlyCreatedData = {
                        'avatar': this.imageService.getUserProfileImage(user.fileDetails_by_profileImage ? user.fileDetails_by_profileImage['publicId'] : null),
                        'firstName': user['firstName'],
                        'lastName': user['lastName'],
                        'channelType': 'DM',
                        'channelName': null,
                        'channelId': data['data'],
                        'groupIdentifier': null,
                        'userId': user['id']
                    };
                    // this.selectedContact = newlyCreatedData;
                    // this.getChannels();
                    this.searchName = null;
                    this.toggleChat(newlyCreatedData);
                },
                error => {
                    console.log(error);
                }
            );

    }
    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    public getChannels(): void {

        const overallNotificationCount = this.angularDB.object('notifications/' + this.localUser['id'] + '/channels/').valueChanges();
        overallNotificationCount.subscribe(channels => {
            // console.log(newCount);
            this.overAllChatCount = 0;
            for (const key in channels) {
                if (
                    channels.hasOwnProperty(key) &&
                    channels[key].count !== undefined &&
                    (channels[key].type === 'DM' ||
                        channels[key].type === 'DM_BOT' ||
                        channels[key].type === 'GROUP')
                ) {
                    this.overAllChatCount += channels[key].count;
                }
            }

        });

        const channelRef = this.db.collection('channels', ref => ref.where('members', 'array-contains', this.localUser['id']).orderBy('lastMessageOn', 'desc')).snapshotChanges();
        // console.log('channelRef------', channelRef);


        channelRef.subscribe(snap => {

            const channels = [];
            snap.forEach(item => {

                const key = item.payload.doc.id;
                const data = item.payload.doc.data();
                const chanObj = {
                    channelId: key,
                    ...data
                };
                channels.push(chanObj);
            });

            this.checkCavisExist(channels)
                .then((result: any) => {
                    // console.log(result);
                })
                .catch((err: any) => {
                    // console.log(err);
                    const postData = {
                        'from': this.localUser['id'],
                        'to': 10000,
                        'type': 'DM_BOT'
                    };
                    this._httpClient
                        .post(
                            environment.FIREBASE_API_URL +
                            environment.CREATE_DM, postData)
                        .subscribe(
                            (data: any) => {
                                // console.log('data', data);
                            },
                            (error: any) => {
                                console.log(error);
                            }
                        );
                });

            this.contacts = [];
            this.groupDetails = [];
            const active = [];
            const inActive = [];
            channels.forEach(notify => {
                if (notify['type'] === 'DM') {

                    const notificationCount = this.angularDB.object('notifications/' + this.localUser['id'] + '/channels/' + notify['channelId'] + '/count').valueChanges();

                    this.channelDetails = notify;
                    notify['membersMeta'].forEach(userDetails => {
                        notificationCount.subscribe(newCount => {
                            userDetails['chatCount'] = newCount;
                        });
                        userDetails['membersMeta'] = notify['membersMeta'];
                        userDetails['channelType'] = notify['type'];
                        userDetails['channelName'] = null;
                        userDetails['channelId'] = notify['channelId'];
                        userDetails['groupIdentifier'] = null;
                        if (userDetails && (userDetails['userId'] !== this.localUser['id'])) {

                            if (userDetails['publicProfileId']) {
                                userDetails['avatar'] = this.imageService.getUserProfileImage(userDetails['publicProfileId']);

                            } else {
                                userDetails['avatar'] = this.imageService.getUserProfileImage(null);
                            }
                            this.contacts.push(userDetails);
                        }
                    });

                }

                if (notify['type'] === 'GROUP') {
                    const notificationCount = this.angularDB.object('notifications/' + this.localUser['id'] + '/channels/' + notify['channelId'] + '/count').valueChanges();
                    notificationCount.subscribe(newCount => {
                        notify['chatCount'] = newCount;

                    });

                    notify['channelType'] = notify['type'];
                    notify['channelName'] = notify['name'];
                    notify['groupIdentifier'] = 'groups';
                    notify['channelId'] = notify['channelId'];
                    if (notify['publicProfileId']) {
                        notify['avatar'] = this.imageService.getUserProfileImage(notify['publicProfileId']);
                    } else {
                        notify['avatar'] = this.imageService.getUserProfileImage(null);
                    }

                    if ((notify['membersMeta']
                        .filter(x => x.active)
                        .map(x => x.userId)
                        .includes(this.localUser['id']))) {

                        active.push(notify);
                    } else {

                        inActive.push(notify);
                    }
                }

            });
            this.groupDetails = active.concat(inActive);
        });

    }

    public checkCavisExist(channels: Array<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            let cavisFound = false;
            // setTimeout(() => {
            channels.forEach(item => {
                if (item['type'] === 'DM_BOT') {
                    cavisFound = true;
                }
            });
            if (cavisFound) {
                resolve(true);
            } else {
                reject(false);
            }

            // }, 800);

        });
    }
    /**
     * Prepare the chat for the replies
     */
    private _prepareChatForReplies(): void {
        setTimeout(() => {

            // Reset the reply form
            this._replyForm.reset();

            // Focus to the reply input
            // this._replyInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            if (this._chatViewScrollbar) {
                this._chatViewScrollbar.update();

                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this.openSideBar = false;
        this._fuseSidebarService.getSidebar('develoopChatPanel').foldTemporarily();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this.openSideBar = true;
        this._fuseSidebarService.getSidebar('develoopChatPanel').unfoldTemporarily();
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('develoopChatPanel`').toggleOpen();
    }

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {

        return (
            message.who === this.selectedContact.userId &&
            ((this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== this.selectedContact.userId) || !this.chat.dialog[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {

        return (i === 0 || this.chat.dialog[i - 1] && this.chat.dialog[i - 1].who !== message.who);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.chat.dialog.length - 1 || this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== message.who);
    }


    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    toggleChat(contact): void {
        // console.log('contact', contact);
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        // if (this.selectedContact && contact.userId !== this.selectedContact.userId) {
        //   // Reset
        //   console.log('this.selected contact');
        //   return;
        // }
        // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        // else {
        // Unfold the sidebar temporarily
        if (this.selectedContact) {
            this.resetChat();
        }
        this.unfoldSidebarTemporarily();
        // Set the selected contact
        this.removedOn = false;
        this.showInputTextBox = true;
        const limitUpTo = 20 * this.loadLastCount;
        this.selectedContact = contact;
        // console.log('selectedCOntact', this.selectedContact);

        let removedOnTime;

        if (this.selectedContact['membersMeta']) {
            this.selectedContact['membersMeta'].forEach(item => {
                if (this.localUser['id'] === item['userId']) {
                    if (item['removedOn']) {
                        this.removedOn = true;
                        removedOnTime = item['removedOn'].toDate();
                    }
                }
            });
        }
        let firebaseMessages = null;
        if (this.removedOn) {
            firebaseMessages = this.db.collection
            ('messages', ref => ref
                .where('channelId', '==', this.selectedContact.channelId)
                .where('createdOn', '<=', removedOnTime)
                .orderBy('createdOn', 'desc').limit(limitUpTo)).snapshotChanges();
        } else {
            firebaseMessages = this.db.collection
            ('messages', ref => ref
                .where('channelId', '==', this.selectedContact.channelId)
                .orderBy('createdOn', 'desc').limit(limitUpTo)).snapshotChanges();
        }

        // Load the chat

        firebaseMessages.subscribe(firebaseitems => {

            // console.log('selectedContact', this.selectedContact);
            const membersMeta = [...this.selectedContact['membersMeta']];
            // console.log(membersMeta);
            const channelMessages = [];
            firebaseitems.forEach(item => {
                const key = item.payload.doc.id;
                const data = item.payload.doc.data();
                const chanObj = {
                    messageId: key,
                    ...data
                };

                if (data['channelId'] === this.selectedContact.channelId) {
                    channelMessages.unshift(chanObj);
                }

            });

            const chatObj = {
                'id': this.selectedContact.userId,
                'dialog': []
            };
            if (channelMessages.length > 0) {

                const notificationCount = this.angularDB.object('notifications/' + this.localUser['id'] + '/channels/' + this.selectedContact.channelId + '/count').remove();
                channelMessages.forEach(items => {

                    let profileImageURL = '';
                    if (items['from'] && items['from']['publicProfileId']) {
                        profileImageURL = items['from']['publicProfileId'];
                    } else {
                        profileImageURL = null;
                    }

                    let name = '';
                    let avatar = null;
                    if (items['from'] && items['from']['userId']) {
                        const userId = items['from']['userId'];
                        const member = membersMeta.find(m => Number(m['userId']) === Number(userId));
                        name = member.firstName + ' ' + member.lastName;
                        avatar = this.imageService.getUserProfileImage(member['publicProfileId']);
                    }

                    const dialog = {
                        'who': items['from'] && items['from']['userId'] ? items['from']['userId'] : null,
                        'message': items['content']['text'],
                        'time': items['createdOn'].toDate(),
                        'name': name,
                        // 'name': items['from'] ? items['from']['firstName'] + ' ' + items['from']['lastName'] : null,
                        'avatar': avatar,
                        // 'avatar': this.imageService.getUserProfileImage(profileImageURL),
                        'type': items['type'],
                        'options': (items['type'] === 'OPTIONS' || items['type'] === 'REVIEW') && items['content'] ? items['content'] : null,
                        'intentName': (items['type'] === 'REVIEW') && items['content'] && items['content']['intentName'] ? items['content']['intentName'] : null,
                        'selectedValue': (items['type'] === 'REVIEW' && items['selectedValue']) ? items['selectedValue'] : null,
                        'messageId': items['messageId'] ? items['messageId'] : null,
                        'selected': items['selected'] ? items['selected'] : null,
                        'imageUrl': items['content'] ? items['content']['url'] : null
                    };

                    chatObj.dialog.push(dialog);

                });
            }
            this.chat = chatObj;

            if (this.chat.dialog[this.chat.dialog.length - 1]
                && this.chat.dialog[this.chat.dialog.length - 1].type
                && this.chat.dialog[this.chat.dialog.length - 1].type === 'OPTIONS') {
                this.showInputTextBox = false;
            } else {
                this.showInputTextBox = true;
            }

            if (!this.readyToReply && !this.removedOn) {
                this._prepareChatForReplies();
            }
        });

        this.groupInfo = this.selectedContact;
        this.groupInfo['image'] = this.imageService.getUserProfileImage(this.groupInfo['image']);
        this.editName = this.groupInfo['channelName'];
        if (this.groupInfo['membersMeta'] && this.groupInfo['owners']) {
            this.groupInfo['owners'].forEach(ownerDetais => {
                this.groupInfo['membersMeta'].forEach(item => {
                    const profileImageURL = item['publicProfileId'] ? item['publicProfileId'] : null;

                    item['imageURL'] = this.imageService.getUserProfileImage(profileImageURL);

                    if (this.localUser['id'] === ownerDetais) {

                        this.groupInfo['isOwner'] = true;
                    }
                    if (ownerDetais === item['userId']) {
                        item['owner'] = 'Owner';
                    } else {
                        item['owner'] = null;
                    }
                });
            });
            // }
        }
    }

    loadOlderConversation(): void {
        this.readyToReply = true;
        this.loadLastCount = this.loadLastCount + 1;

        this.toggleChat(this.selectedContact);
    }
    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void {
        // Set the selected contact as null
        this.selectedContact = null;

        // Set the chat as null
        this.chat = [];
    }

    toString(obj): any {
        return JSON.stringify(obj);
    }

    editGroupName(): void {
        this.showInputBox = true;
    }
    selectMessageType(messageType): void {

        if (messageType === 'GROUP') {
            this.openGroupSelection = true;
        }
        if (messageType === 'CANCEL') {
            this.openGroupSelection = false;
        }
    }
    updateGroupName(): void {
        if (this.editName.length <= 0) {
            return;
        }
        this.showInputBox = false;
        const editChannel = {
            'channelId': this.groupInfo['channelId'],
            'userId': this.localUser['id'],
            'name': this.editName
        };
        // EDIT_CHANNEL
        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.EDIT_CHANNEL, editChannel)
            .subscribe(
                (data: any) => {
                    this.groupInfo['channelName'] = this.editName;
                },
                error => {

                }
            );
    }
    searchFilterEvent(event): void {

        if (!this.searchName) {
            this.searchFilter = null;
            this.getAllUsers();
        }

        if (event['target']['value'].length > 0) {
            this.searchFilter = event['target']['value'];

            this.getAllUsers();
        }
    }

    getAllUsers(): void {
        this.allUserDataLoaded = true;
        let filter;

        if (this.searchFilter) {
            const searchString = this.searchFilter;
            filter = '((firstName LIKE ' + searchString + '%25) OR ( lastName LIKE ' + searchString
                + '%25) OR ( email LIKE ' + searchString + '%25)) AND (orgId= ' + this.localUser['orgId'] +
                ')';
        } else {

            filter = '(orgId= ' + this.localUser['orgId'] + ')';

        }
        const releated = 'fileDetails_by_profileImage';
        this._httpClient
            .get(
                environment.DF_BASE_URL +
                environment.USER_TABLE + '?filter=' + filter + '&limit=10&related=' + releated, this.requestHelpers.getDFHeader())
            .subscribe(
                data => {

                    this.allUsersData = data['resource'];
                    for (let i = 0; i < this.allUsersData.length; i++) {
                        if (this.allUsersData[i].fileDetails_by_profileImage) {
                            this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(this.allUsersData[i].fileDetails_by_profileImage['publicId']);
                        } else {
                            this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(null);
                        }
                    }

                    this.allUserDataLoaded = false;
                },
                error => {
                    console.log(error);
                    this.allUserDataLoaded = false;
                }
            );
    }

    selectedOption(options, messageId): void {
        this.newSelectedOption = options;
        const message = {
            who: this.localUser['id'],
            message: this.newSelectedOption,
            time: new Date().toISOString()
        };

        // Prepare the chat for the replies
        // this.chat.dialog.push(message);
        // this._prepareChatForReplies();



        const updateMessage = this.db.collection('messages').doc(messageId).set({ selected: options }, { merge: true }).then(data => console.log('update successful: ', data))
            .catch(err => console.error(err));


        const messageToSend = {
            channelId: this.selectedContact['channelId'],
            content: {
                mentions: [10000],
                mentionsMeta: [],
                text: this.newSelectedOption
            },
            from: {
                userId: Number(this.localUser['id']),
                firstName: this.localUser['firstName'],
                lastName: this.localUser['lastName'],
                profileImageId: this.localUser['fileDetails_by_profileImage'] ? this.localUser['fileDetails_by_profileImage']['publicId'] : null,
            },
            createdOn: new Date(),
            type: 'TEXT'
        };
        this.db.collection('messages').add(messageToSend);
        // const postData = {
        //   'from': this.localUser['id'],
        //   'channelId': this.selectedContact['channelId'],
        //   'message': this.newSelectedOption
        // };
        // this._httpClient
        //   .post(
        //     environment.FIREBASE_API_URL +
        //     environment.SEND_MESSAGE, postData)
        //   .subscribe(
        //     (data: any) => {

        //     },
        //     error => {
        //       console.log(error);
        //     }
        //   );
        this.newSelectedOption = null;
    }

    addReview(intentName, value, messageId): void {
        // Firestore tweaks

        const updateMessage = this.db.collection('messages').doc(messageId)
            .set({ selectedValue: value }, { merge: true })
            .then(data => console.log('update successful: ', data))
            .catch(err => console.error(err));

        const postData = {
            userId: this.localUser.id,
            intentName,
            value
        };
        this._chatPanelService.addReview(postData).then(res => {
            console.log('res', res);
        }).catch(err => {
            console.error('error', err);
        });
    }
    // /**
    //  * Reply
    //  */

    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        const messageToSend = {
            channelId: this.selectedContact['channelId'],
            content: {
                mentions: [],
                mentionsMeta: [],
                text: this._replyForm.form.value.message
            },
            from: {
                userId: Number(this.localUser['id']),
                firstName: this.localUser['firstName'],
                lastName: this.localUser['lastName'],
                profileImageId: this.localUser['fileDetails_by_profileImage'] ? this.localUser['fileDetails_by_profileImage']['publicId'] : null,
            },
            createdOn: new Date(),
            type: 'TEXT'
        };

        if (this.selectedContact['channelType'] === 'DM_BOT') {
            messageToSend.content.mentions = [10000];
        }

        this.db.collection('messages').add(messageToSend);

        this._replyForm.form.value.message = null;

        this._prepareChatForReplies();
    }
}


@Component({
    selector: 'dialog-overview-group-component',
    templateUrl: 'dialog-overview-group-component.html',
})
export class DialogOverviewGroupComponent {
    localUser: any;
    groupName: any;
    members: any;
    groupLoaderFlag: any;
    newGroup: any;
    searchFilter: any;
    allUsersData: any;
    loadingData: boolean;
    searchName: any;
    selectedUsers: Array<any>;
    createGroupButton: ButtonOpts = {
        active: false,
        text: 'Create',
        buttonColor: 'accent',
        barColor: 'primary',
        raised: true,
        mode: 'indeterminate',
        value: 0,
        disabled: false
    };
    bool: boolean;
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewGroupComponent>,
        private _httpClient: HttpClient,
        private requestHelpers: RequestHelpers,
        private imageService: ImageService,
        public snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.localUser = data['userData'];
        this.members = [];
        this.groupLoaderFlag = false;
        this.loadingData = false;
        this.bool = true;
        this.getAllUsers();
    }
    searchFilterEvent(event): void {
        // console.log(this.searchName);
        if (!this.searchName) {
            this.searchFilter = null;
            this.getAllUsers();
        }
        // console.log(event['target']['value']);
        if (event['target']['value'].length > 0) {
            this.searchFilter = event['target']['value'];
            // console.log('search filter', this.searchFilter);
            this.getAllUsers();
        }
    }

    getAllUsers(): void {
        this.loadingData = true;
        let filter;

        if (this.searchFilter) {
            const searchString = this.searchFilter;
            filter = '((firstName LIKE ' + searchString + '%25) OR ( lastName LIKE ' + searchString
                + '%25) OR ( email LIKE ' + searchString + '%25)) AND (orgId= ' + this.localUser['orgId'] +
                ')';
        } else {

            filter = '(orgId= ' + this.localUser['orgId'] + ')';

        }
        const releated = 'fileDetails_by_profileImage';
        this._httpClient
            .get(
                environment.DF_BASE_URL +
                environment.USER_TABLE + '?filter=' + filter + '&limit=10&related=' + releated, this.requestHelpers.getDFHeader())
            .subscribe(
                data => {

                    this.allUsersData = data['resource'];
                    for (let i = 0; i < this.allUsersData.length; i++) {
                        if (this.allUsersData[i].fileDetails_by_profileImage) {
                            this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(this.allUsersData[i].fileDetails_by_profileImage['publicId']);
                        } else {
                            this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(null);
                        }
                    }

                    this.loadingData = false;
                },
                error => {
                    console.log(error);
                    this.loadingData = false;
                }
            );
    }
    participantSelected(event): void {
        this.members = [];
        this.selectedUsers.forEach(item => {
            this.members.push(item['id']);
        });
    }

    creatGroup(): Boolean {
        this.bool = false;
        this.createGroupButton.active = true;
        this.createGroupButton.text = 'Creating...';
        const postData = {
            'userId': this.localUser['id'],
            'name': this.groupName
        };

        if (!this.groupName) {
            console.log(this.groupName);
            this.createGroupButton.active = false;
            this.createGroupButton.text = 'Create';
            this.openSnackBar('Please enter group name', 'Ok');
            return false;
        }
        if (this.members.length === 0) {
            console.log(this.members);
            this.createGroupButton.active = false;
            this.createGroupButton.text = 'Create';
            this.openSnackBar('Please select group members', 'Ok');
            return false;
        }



        this.groupLoaderFlag = true;
        postData['members'] = this.members;

        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.CREATE_GROUP, postData)
            .subscribe(
                (data: any) => {
                    const newlyCreatedData = {
                        'avatar': this.imageService.getUserProfileImage(null),
                        // 'firstName': user['firstName'],
                        // 'lastName': user['lastName'],
                        'channelType': 'GROUP',
                        'channelName': this.groupName,
                        'channelId': data['data'],
                        'groupIdentifier': null,
                        // 'userId': user['id']
                    };
                    this.newGroup = newlyCreatedData;
                    this.createGroupButton.active = false;
                    this.createGroupButton.text = 'Create';
                    this.openSnackBar('Request has been sent', 'Ok');
                    this.dialogRef.close();
                    this.groupName = '';
                    this.members = [];
                    this.groupLoaderFlag = false;
                },
                error => {
                    this.openSnackBar('Something went wrong! Try again.', 'Ok');
                    this.createGroupButton.active = false;
                    this.createGroupButton.text = 'Try again';
                    this.bool = true;
                    this.groupLoaderFlag = false;
                    console.log(error);
                }
            );
        return true;

    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    }
    onNoClick(): void {
        this.dialogRef.close(this.newGroup);
    }
}
@Component({
    selector: 'dialog-overview-component',
    templateUrl: 'dialog-overview-component.html',
})

export class DialogOverviewComponent {
    groupInfo: any;
    userData: any;
    groupOwner: boolean;
    searchFilter: any;
    allUsersData: any;
    owners: any;
    leavrGroupButton: ButtonOpts = {
        active: false,
        text: 'Yes',
        buttonColor: 'accent',
        barColor: 'primary',
        raised: true,
        mode: 'indeterminate',
        value: 0,
        disabled: false
    };

    ownerGroupButton: ButtonOpts = {
        active: false,
        text: 'Leave',
        buttonColor: 'accent',
        barColor: 'primary',
        raised: true,
        mode: 'indeterminate',
        value: 0,
        disabled: false
    };
    loadingData: any;

    constructor(
        public dialogRef: MatDialogRef<DialogOverviewComponent>,
        private _httpClient: HttpClient,
        private requestHelpers: RequestHelpers,
        private imageService: ImageService,
        public snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.groupInfo = data['groupInfo'];
        this.userData = data['userData'];
        this.groupOwner = false;
        this.owners = [];
        this.checkForOwner();
        // this.getAllUsers();
        // console.log('groutpInfo', this.groupInfo);
    }

    // searchForFilter(event): void {

    //   if (event['target']['value'].length > 0) {
    //     const searchString = event['target']['value'];
    //     this.groupInfo['membersMeta'] = this.groupInfo['membersMeta'].find(item => {
    //       if (item.firstName === searchString) {
    //         return;
    //       }
    //       if (item.lastName === searchString) {
    //         return;
    //       }
    //     });
    //   }
    // }
    checkForOwner(): void {

        this.groupOwner = false;
        if (this.groupInfo['owners'].length === 1) {
            // Transfer ownership
            this.groupInfo['owners'].forEach(element => {
                if (element === this.userData['id']) {
                    this.groupOwner = true;
                }
            });
        }
        this.groupInfo['membersMeta'] = this.groupInfo['membersMeta'].filter(item => item.userId !== this.userData['id']);
    }


    changeOwner(): void {
        const newMembers = [];
        this.groupInfo['membersMeta'].forEach(item => {
            if (item.selected === true) {
                this.owners.push(item['userId']);
            }
            newMembers.push(item['userId']);
        });
        if (this.owners.length === 0) {
            return;
        }
        this.ownerGroupButton.active = true;
        this.ownerGroupButton.text = 'Leaving...';

        const editChannel = {
            'channelId': this.groupInfo['channelId'],
            'userId': this.userData['id'],
            'owners': this.owners,
            'newMembers': newMembers
        };
        // EDIT_CHANNEL
        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.EDIT_CHANNEL, editChannel)
            .subscribe(
                (data: any) => {
                    this.ownerGroupButton.active = false;
                    this.ownerGroupButton.text = 'Leave';
                    this.openSnackBar('Request has been sent', 'Ok');
                    this.onNoClick({ success: true });
                },
                error => {
                    this.ownerGroupButton.active = false;
                    this.ownerGroupButton.text = 'Try again';

                    this.openSnackBar('Something went wrong! Try again.', 'Ok');
                    this.onNoClick({ success: true });
                }
            );
    }
    leaveGroup(): void {
        this.leavrGroupButton.active = true;
        this.leavrGroupButton.text = 'Deleting...';
        const newMembers = [];

        this.groupInfo['membersMeta'].forEach(item => {
            if (item['userId'] !== this.userData['id']) {
                newMembers.push(item['userId']);
            }
        });
        const editChannel = {
            'channelId': this.groupInfo['channelId'],
            'userId': this.userData['id'],
            'newMembers': newMembers
        };
        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.EDIT_CHANNEL, editChannel)
            .subscribe(
                (data: any) => {
                    this.leavrGroupButton.active = false;
                    this.leavrGroupButton.text = 'Yes';
                    this.openSnackBar('Request has been sent', 'Ok');
                    this.onNoClick({ success: true });
                },
                error => {
                    this.leavrGroupButton.text = 'Try again';
                    this.openSnackBar('Something went wrong! Try again.', 'Ok');
                    this.onNoClick({ success: false });
                }
            );
    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    }
    onNoClick(result): void {
        this.dialogRef.close(result);
    }
}


@Component({
    selector: 'add-remove-people-component',
    templateUrl: 'add-remove-people-component.html',
    providers: [ImageService, RequestHelpers]
})
export class DialogAddRemovePeopleComponent {
    supportersDetails: any;

    allUsersData: any;
    flag: boolean;
    defaultImage: string;
    allUserId: Array<number>;
    userId: number;
    isAddSupporterBtnDisabled: Boolean = false;

    groupDetails: any;
    userData: any;
    constructor(private http: HttpClient,
                private requestHelpers: RequestHelpers,
                public snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<DialogAddRemovePeopleComponent>,
                private imageService: ImageService,
                private _httpClient: HttpClient,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.groupDetails = data['groupInfo'];
        this.userData = data['userData'];
        this.flag = false;
        this.allUserId = [];
        // console.log(this.groupDetails);
        // console.log(this.userData);
        // this.groupDetails['membersMeta'] = this.groupDetails['membersMeta'].filter(item => item.userId !== this.userData['id']);
        for (let i = 0; i < this.groupDetails['membersMeta'].length; i++) {
            this.allUserId.push(this.groupDetails['membersMeta'][i]['userId']);
        }
        this.allUserId.push(this.userData['id']);
        this.getAllUsers(null);

    }

    removeSupporters(supporterDetails, index): void {

        this.groupDetails['membersMeta'] = this.groupDetails['membersMeta'].filter(item => item.userId !== supporterDetails['userId']);

        const tempAllId = [];
        // console.log(this.groupDetails);
        this.groupDetails['membersMeta'].forEach(item => {
            tempAllId.push(item['userId']);
        });
        tempAllId.push(this.userData['id']);
        const editChannel = {
            'channelId': this.groupDetails['channelId'],
            'userId': this.userData['id'],
            'newMembers': tempAllId
        };
        // console.log(tempAllId);
        this.allUserId.splice(index, 1);
        this.getAllUsers(null);
        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.EDIT_CHANNEL, editChannel)
            .subscribe(
                (data: any) => {
                    this.openSnackBar('Request has been sent', 'Ok');
                },
                error => {
                    this.openSnackBar('Something went wrong! Try again.', 'Ok');
                }
            );
    }
    addSupporters(user): void {

        const isSupporterAdded = this.groupDetails['membersMeta'].filter((item) => {
            return item.userId === user['id'];
        });
        const newUserId = [];
        this.groupDetails['membersMeta'].forEach(item => {
            newUserId.push(item['userId']);
        });
        newUserId.push(user['id']);
        newUserId.push(this.userData['id']);
        // console.log(newUserId);
        if (isSupporterAdded.length) {
            this.openSnackBar('Already a group member.', 'Ok');
            return;
        }
        this.flag = false;

        const editChannel = {
            'channelId': this.groupDetails['channelId'],
            'userId': this.userData['id'],
            'newMembers': newUserId
        };
        const newUser = {
            'firstName': user['firstName'],
            'lastName': user['lastName'],
            'userId': user['id'],
            'imageURL': user['fileDetails_by_profileImage'] ? this.imageService.getUserProfileImage(['fileDetails_by_profileImage']['publicId']) : null,
        };
        this.groupDetails['membersMeta'].push(newUser);
        this._httpClient
            .post(
                environment.FIREBASE_API_URL +
                environment.EDIT_CHANNEL, editChannel)
            .subscribe(
                (data: any) => {
                    this.allUserId.push(user['id']);
                    this.getAllUsers(null);
                    this.openSnackBar('Request has been sent', 'Ok');
                },
                error => {

                    this.openSnackBar('Something went wrong! Try again.', 'Ok');

                }
            );
    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    }
    getAllUsers(event): void {
        this.isAddSupporterBtnDisabled = true;
        let filter;

        if (event && event['target']['value'].length > 0) {
            const searchString = event['target']['value'];
            filter = '((firstName LIKE ' + searchString + '%25) OR ( lastName LIKE ' + searchString
                + '%25) OR ( email LIKE ' + searchString + '%25)) AND (id NOT IN (' + this.allUserId + ')) AND (orgId= ' + this.userData['orgId'] +
                ')';
        } else {
            if (this.allUserId.length > 0) {
                filter = '(id NOT IN (' + this.allUserId + ')) AND (orgId= ' + this.userData['orgId'] + ')';
            } else {
                filter = '(orgId= ' + this.userData['orgId'] + ')';
            }
        }
        const releated = 'fileDetails_by_profileImage';
        this.http
            .get(
                environment.DF_BASE_URL +
                environment.USER_TABLE + '?filter=' + filter + '&limit=10&related=' + releated, this.requestHelpers.getDFHeader())
            .subscribe(
                data => {

                    this.allUsersData = data['resource'];
                    for (let i = 0; i < this.allUsersData.length; i++) {
                        if (this.allUsersData[i].fileDetails_by_profileImage) {
                            this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(this.allUsersData[i].fileDetails_by_profileImage['publicId']);
                        } else {
                            this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(null);
                        }
                    }
                    // console.log(data);
                    this.isAddSupporterBtnDisabled = false;
                },
                error => {
                    console.log(error);
                    this.isAddSupporterBtnDisabled = false;
                }
            );
    }
    onNoClick(result): void {
        this.dialogRef.close();
    }

}

