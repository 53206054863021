import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel } from 'app/models/journey-model';
import { JourneyStatus } from 'app/enums/journey-status';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { JourneyTasksService } from 'app/shared/journey-tasks/journey-tasks.service';
import { TaskType } from 'app/enums/task-type';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'edit-tasks',
  templateUrl: './edit-tasks.component.html',
  styleUrls: ['./edit-tasks.component.scss'],
  animations: fuseAnimations
})
export class EditTasksComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  routeSubscription: any;
  journeyId: string;
  user: any;
  loadingTasks: boolean;
  journey: JourneyModel;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _localStorageService: LocalStorageService,
    private requestHelpers: RequestHelpers,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private _journeyTasksService: JourneyTasksService,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.user = this._localStorageService.get('user');

    this.routeSubscription = this._activatedRoute.params
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.journeyId = data['id'];
        this.getJourneyDetails(this.journeyId, this.user.orgId);
      });

    this._journeyTasksService.onNewTaskAdded
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.saveNewTask(data.task, data.ignoreTask);
      });

    this._journeyTasksService.onDeleteTask
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((task) => {
        this.deleteTask(task);
      });

    this._journeyTasksService.onUpdateTaskOrder
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((tasks) => {
        this.updateTaskOrder(tasks);
      });

    this._journeyTasksService.onUpdateTask
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((task) => {
        this.updateTask(task);
      });
  }

  getJourneyDetails(journeyId, orgId): void {
    this.loadingTasks = true;
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY + '?filter=_id=' +
        journeyId + '&orgId=' + orgId + '&appendUserDetails=1',
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          if (data.resource.length > 0) {

            this.journey = <JourneyModel>data.resource[0];

            if (this.journey.status !== JourneyStatus.not_started && this.journey.status !== JourneyStatus.ongoing && this.journey.status !== JourneyStatus.past_due) {
              this.openSnackBar('Cannot add tasks after the journey started.', 'Ok');
              this.router.navigate(['/journeys-take/' + journeyId]);
            }

            this.journey.tasks = this.journey.tasks.sort((n1, n2) => {
              if (n1.taskNo > n2.taskNo) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          this.loadingTasks = false;
        },
        (error: any) => {
          console.log(error);
          this.loadingTasks = false;
        }
      );
  }

  saveNewTask(task, ignoreTask): void {

    const journey = Object.assign({}, this.journey);

    journey.tasks.sort((a, b) => a.taskNo - b.taskNo);

    if (!ignoreTask) {
      let taskNo = null;
      for (let i = 0; i < journey.tasks.length; i++) {
        const taskItem = journey.tasks[i];
        if (taskItem.status === JourneyStatus.not_started) {
          taskNo = i + 1;
          break;
        }
      }
      if (taskNo === null) {
        this.openSnackBar('Cannot add tasks now', 'Ok');
        return;
      }
      journey.tasks = journey.tasks.map(x => {
        if (x.taskNo >= taskNo) {
          x.taskNo++;
        }
        return x;
      });
      task.taskNo = taskNo;
      journey.tasks.push(task);
      journey.tasks.sort((a, b) => a.taskNo - b.taskNo);
    }

    const resource = {
      journey: journey,
      userId: this.user.id,
      orgId: this.user.orgId,
      id: this.journey._id
    };

    this.http
      .put(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY + '/' + this.journey._id,
        resource,
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          this.getJourneyDetails(this.journeyId, this.user.orgId);
          this.openSnackBar('Task Added Successfully.', 'Ok');
          this._journeyTasksService.onNewTaskResponse(true);
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Please try again.', 'Ok');
          this._journeyTasksService.onNewTaskResponse(false);
        }
      );
  }

  updateTaskOrder(tasks): void {

    this.journey.tasks = Object.assign([], tasks);
    const journey = Object.assign({}, this.journey);

    journey.tasks.forEach((item, index) => {
      item.taskNo = (index + 1);
    });

    const resource = {
      journey: journey,
      userId: this.user.id,
      orgId: this.user.orgId,
      id: this.journey._id
    };

    this.http
      .put(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY + '/' + this.journey._id,
        resource,
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          this.openSnackBar('Changes Saved Successfully.', 'Ok');
          this._journeyTasksService.setResultOnTaskOrderUpdated({ success: true });
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Please try again.', 'Ok');
          this._journeyTasksService.setResultOnTaskOrderUpdated({ success: false });
        }
      );
  }

  updateTask(task): void {
    const taskIndex = this.journey.tasks.findIndex(item => item.taskId === task.taskId);
    if (taskIndex !== -1) {

      this.journey.tasks[taskIndex] = task;
      const journey = Object.assign({}, this.journey);

      journey.tasks.forEach((item, index) => {
        item.taskNo = (index + 1);
      });

      const resource = {
        journey: journey,
        userId: this.user.id,
        orgId: this.user.orgId,
        id: this.journey._id
      };

      this.http
        .put(
          environment.GENESIS_SERVICE_URL +
          environment.GENESIS_JOURNEY + '/' + this.journey._id,
          resource,
          this.requestHelpers.getBFFHeader())
        .subscribe(
          (data: any) => {
            this.getJourneyDetails(this.journeyId, this.user.orgId);
            this.openSnackBar('Task Updated Successfully.', 'Ok');
            this._journeyTasksService.onTaskUpdated.next({ success: true });
          },
          (error: any) => {
            console.log(error);
            this.openSnackBar('Something went wrong! Please try again.', 'Ok');
            this._journeyTasksService.onTaskUpdated.next({ success: false });
          }
        );
    } else {
      this.openSnackBar('Unable to find the task in task list. Please refresh the page and try again.', 'Ok');
    }
  }

  deleteTask(task): void {
    if (this.journey.tasks[(this.journey.tasks.length - 1)].taskType !== TaskType.assessment) {
      this.openSnackBar('You cannot add User defined tasks at the end of Journey.', 'Ok');
      return;
    }

    const journey = Object.assign({}, this.journey);

    const taskIndex = journey.tasks.findIndex(item => item.taskId === task.taskId);

    if (taskIndex !== -1) {
      journey.tasks.splice(taskIndex, 1);

      journey.tasks.forEach((item, index) => {
        item.taskNo = (index + 1);
      });

      const resource = {
        journey: journey,
        userId: this.user.id,
        orgId: this.user.orgId,
        id: this.journey._id
      };

      this.http
        .put(
          environment.GENESIS_SERVICE_URL +
          environment.GENESIS_JOURNEY + '/' + this.journey._id,
          resource,
          this.requestHelpers.getBFFHeader())
        .subscribe(
          (data: any) => {
            this.openSnackBar('Task Deleted Successfully.', 'Ok');
            this.journey.tasks = journey.tasks;
            this._journeyTasksService.onTaskResponse(this.journey);
          },
          (error: any) => {
            console.log(error);
            this.openSnackBar('Something went wrong! Please try again.', 'Ok');
          }
        );
    } else {
      this.openSnackBar('Coulnt found the task you selected. Please refresh the page and  try again', 'Ok');
    }
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
