import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { JourneyModel } from 'app/models/journey-model';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';

@Component({
  selector: 'journey-report-dialog',
  templateUrl: 'journey-report.component.html',
  styleUrls: ['journey-report.component.scss']
})
export class JourneyReportDialogComponent {
  journeyId: string;
  isManager: Boolean;
  journey: JourneyModel;
  loggedinUser: User;
  JourneyAccessRoles = JourneyAccessRoles;
  @ViewChild('#parentdiv') elementView: ElementRef;
  viewHeight: number;

  constructor(
    public dialogRef: MatDialogRef<JourneyReportDialogComponent>,
    public snackBar: MatSnackBar,
    public localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.journeyId = data.journeyId;
    this.isManager = data.isManager;
    this.journey = data.journey;
    this.loggedinUser = <User>this.localStorageService.get('user');
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }

  htmltoPDF(): void {
    // parentdiv is the html element which has to be converted to PDF
    html2canvas(document.querySelector('#parentdiv')).then(canvas => {
      const wid = canvas.width;
      const hgt = canvas.height;
      const img = canvas.toDataURL('image/png', 1.0);
      const hratio = hgt / wid;
      const doc = new jsPDF('p', 'pt', 'a4');
      const width = doc.internal.pageSize.width;
      const height = width * hratio;
      doc.addImage(img, 'JPEG', 10, 10, width - 20, height - 10);
      const user = <User>this.journey.userDetails;
      const fileName = `${user.firstName} ${user.lastName} - ${this.journey.title}`;
      doc.save(`${fileName}.pdf`);
    });

  }
}
