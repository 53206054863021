import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { ProfileAccessRoles } from 'app/enums/profile-access-roles';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/models/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RequestHelpers } from '../../services/request-helpers.service';
import { JourneyFilterModel } from 'app/models/filter-model';
import { JourneyListTypes } from 'app/enums/journey-list-type';
import { UserProfileService } from 'app/core/user-profile/user-profile.service';
import { fuseAnimations } from '@fuse/animations';
import { CompetencyStatuses, CompetencyListTypes } from 'app/enums/competency-list-types';
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: fuseAnimations
})
export class UserProfileComponent implements OnInit {
  userId: number;
  user: User;
  role: ProfileAccessRoles;
  routeSubscription: any;
  routeUserData: any;
  isManager: Boolean;
  showLeaderboardScore: Boolean;
  isLoadingUserInfo: Boolean;
  filters: JourneyFilterModel = new JourneyFilterModel();
  JourneyListTypes = JourneyListTypes;
  myjourneyListType: JourneyListTypes;
  supportedjourneyListType: JourneyListTypes;
  profileAccessRoles = ProfileAccessRoles;
  compCount: Number = 0;
  journeyCount: Number = 0;
  feedbackCount: Number = 0;
  score: Number = 0;
  loggedUser: User;
  CompetencyStatuses = CompetencyStatuses;
  CompetencyListTypes = CompetencyListTypes;
  constructor(
    private localStorageService: LocalStorageService,
    private activatedroute: ActivatedRoute,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private profileService: UserProfileService
  ) { }

  ngOnInit() {
    this.loggedUser = this.localStorageService.get('user');
    this.routeSubscription = this.activatedroute.params.subscribe(data => {
      console.log(data);
      this.routeUserData = data['id'];
      this.checkAccessInfo(this.routeUserData);
      this.fetchUserInfo();
      this.fetchStats();
      this.fetchLeaderboardScore();
    });

  }
  fetchStats(): void {
    const userId = this.userId;

    this.profileService.getDfJourney(this.loggedUser.orgId, this.userId)
      .subscribe((data: any) => {
        this.journeyCount = data;
      }, (err: any) => {
        console.log('error', err);
      });


    this.profileService.getDfFeedback(this.loggedUser.orgId, this.userId)
      .subscribe((data: any) => {
        this.feedbackCount = data;
      }, (err: any) => {
        console.log('error', err);
      });


    this.profileService.getUserCompetencies(this.userId, this.loggedUser.orgId)
      .subscribe(
        (data: any) => {
          if (data.userCompetencies && data.orgCompetencies) {

            const expectedComps = data.userCompetencies.filter(item => item.tag === 'expected');
            const additionalComps = data.userCompetencies.filter(item => item.tag === 'additional');
            const activeComps = data.userCompetencies.filter(item => item.tag === 'active');

            const totalComps = additionalComps.length + activeComps.length;
            this.compCount = totalComps;
          }

        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  checkAccessInfo(data) {
    if ((data === 'self') || (parseInt(data) === this.loggedUser.id)) {
      this.userId = this.loggedUser.id;
      this.role = ProfileAccessRoles.self;
      this.defineAccess(this.role);
    } else {
      this.userId = data;
      this.checkIfUserIsManager(data);
    }
    console.log(this.role);
  }
  fetchUserInfo(): void {
    this.isLoadingUserInfo = true;
    this.profileService.getUserInfo(this.userId, this.loggedUser.orgId)
      .subscribe(
        (data: any) => {
          this.user = <User>data;
          this.user.designation = (this.user.designation === null)? '-':this.user.designation;
          console.log(this.user);
          this.isLoadingUserInfo = false;
        }, (err: any) => {
          this.isLoadingUserInfo = false;
        });
  }

  fetchLeaderboardScore(): void {
    const postData = {
      userIds: [this.userId],
      orgId: this.loggedUser.orgId
    };

    this.profileService.fetchLeaderboardScore(postData)
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data.resource.length) {
            this.score = data.resource[0].score;
          }
        },
        (err: any) => {
          console.log(err);
        });
  }

  defineAccess(role: ProfileAccessRoles): void {
    if (role === this.profileAccessRoles.manager) {
      this.showLeaderboardScore = true;
      this.myjourneyListType = JourneyListTypes.reporteeJourneys;
      this.supportedjourneyListType = JourneyListTypes.reporteeSupportedJourneys;
    }
    else if (role === this.profileAccessRoles.self) {
      this.myjourneyListType = JourneyListTypes.myJourneys;
      this.supportedjourneyListType = JourneyListTypes.mySupportedJourneys;
    }
    else {
      this.myjourneyListType = JourneyListTypes.othersJourneys;
      this.supportedjourneyListType = JourneyListTypes.othersSupportedJourneys;
    }
  }
  renderManager(user): any {
    if (user && user.reportingManager) {
      return user.reportingManager.firstName + ' ' + user.reportingManager.lastName;
    } else { return '-'; }
  }

  checkIfUserIsManager(userId) {
    const postData = {
      userId,
      orgId: this.loggedUser.orgId,
      managerId: this.loggedUser.id
    };
    this.http
      .post(
        environment.CALYPSO_BASE_URL +
        environment.CHECK_IF_MANAGER,
        postData, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          console.log(data)
          this.isManager = data['status'];
          if (this.isManager) {
            this.role = ProfileAccessRoles.manager;
          } else {
            this.role = ProfileAccessRoles.other;
          }
          this.defineAccess(this.role);
        },
        error => {
          console.log(error);
          this.requestHelpers.catchExceptions(error);
        }
      );
    return this.isManager;
  }

}
