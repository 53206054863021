import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { JourneyModel } from 'app/models/journey-model';
import { FeedbackModel } from 'app/models/feedback-model';
import { User } from 'app/models/user';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { ImageService } from 'app/services/images.service';
import { LocalStorageService } from 'ngx-store';
import { JourneyStatus } from 'app/enums/journey-status';
import { JourneyCompletedComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-completed/journey-completed.component';

@Component({
  selector: 'feedback-detail',
  templateUrl: './feedback-detail.component.html',
  styleUrls: ['./feedback-detail.component.scss']
})
export class FeedbackDetailComponent implements OnInit, AfterViewInit {
  @Input() journey: JourneyModel;
  @Input() taskId: string;
  feedbacks: Array<FeedbackModel> = [];
  loadingFeedback: Boolean = true;
  isSendingFeedbackReminder: Boolean;
  user: User;
  task: any;
  completingTask = false;
  resendFeeedbackRequestUsers: Array<User>;
  journeyStatus = JourneyStatus;
  isJourneyManager: boolean;

  constructor(
    private newTakeJourneyService: TakeJourneyService,
    private imageService: ImageService,
    private localStorageService: LocalStorageService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    const journeyManager = this.journey.supporters.filter(supporter => {
      if (supporter.userId === this.user.id && supporter.role === 'Manager') {
        return supporter;
      }
    });
    this.isJourneyManager = journeyManager.length > 0 ? true : false;
    this.task = this.journey.tasks.filter(task => {
      if (task.taskId === this.taskId) {
        return task;
      }
    });
    this.task = this.task[0];
  }

  private getFeedbacks(): void {
    this.loadingFeedback = true;
    const filter = `(feedbackMeta.journeyId='${
      this.journey._id
      }') and (feedbackMeta.taskId = '${this.taskId}')`;
    this.newTakeJourneyService.getUserFeedbacks(filter, 10, 0).subscribe(
      (data: Array<FeedbackModel>) => {
        this.feedbacks = data;
        this.loadingFeedback = false;
        if (this.task.status !== 3 && this.task.taskType !== 8) {
          this.checkForNewSupporters();
        }
      },
      (error: any) => {
        console.log(error);
        this.loadingFeedback = false;
      }
    );
  }

  checkForNewSupporters(): void {
    this.resendFeeedbackRequestUsers = [];
    this.resendFeeedbackRequestUsers = this.journey.supporterDetails.filter(
      supporter => {
        let found = false;
        this.feedbacks.forEach(user => {
          if (user.from === supporter.userId) {
            found = true;
          }
        });
        return !found;
      }
    );
    this.resendFeeedbackRequestUsers = this.resendFeeedbackRequestUsers.filter(
      user => {
        if (user['active']) {
          return user;
        }
      }
    );
  }

  openJourneyCompletedDialog(): void {
    const dialogRef = this.dialog.open(JourneyCompletedComponent, {
      width: '30vw',
      data: {}
    });
  }

  completeTask(): void {
    this.completingTask = true;
    this.newTakeJourneyService
      .completeTask(this.task.taskId, this.journey._id)
      .subscribe(
        (result: any) => {
          this.completingTask = false;
          console.log('result.....', result);
          const taskIndex = this.journey.tasks.findIndex(
            task => task.taskId === this.task.taskId
          );
          if (taskIndex !== -1) {
            this.journey.tasks[taskIndex].status = this.journeyStatus.completed;
            this.journey.ongoingTask = this.journey.tasks[taskIndex].taskNo + 1;
            this.newTakeJourneyService.onJourneyChanged.next(this.journey);
          }
          if (result.journeyCompleted) {
            this.openJourneyCompletedDialog();
          }
        },
        (error: any) => {
          this.completingTask = false;
        }
      );
  }

  resendRequest(userId): void {
    const idx = this.resendFeeedbackRequestUsers.findIndex(
      user => user['userId'] === userId
    );
    this.resendFeeedbackRequestUsers[idx]['resendingFeedbackRequest'] = true;
    this.newTakeJourneyService
      .resendRequest(
        this.journey._id,
        this.task.taskId,
        [userId],
        this.feedbacks[0].requestMsg
      )
      .subscribe(
        (result: any) => {
          this.openSnackBar('Request has been sent', 'Ok');
          this.resendFeeedbackRequestUsers[idx][
            'resendingFeedbackRequest'
          ] = false;
          this.getFeedbacks();
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
          this.resendFeeedbackRequestUsers[idx][
            'resendingFeedbackRequest'
          ] = false;
        }
      );
  }

  sendReminder(feedback, userId): void {
    const idx = this.feedbacks.findIndex(fb => fb._id === feedback._id);
    this.feedbacks[idx]['isSendingFeedbackReminder'] = true;
    this.newTakeJourneyService
      .resendReminder(
        feedback.feedbackMeta.journeyId,
        feedback.feedbackMeta.taskId,
        [userId]
      )
      .subscribe(
        (result: any) => {
          this.openSnackBar('Request Reminder has been sent', 'Ok');
          this.feedbacks[idx]['isSendingFeedbackReminder'] = false;
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
          this.feedbacks[idx]['isSendingFeedbackReminder'] = false;
        }
      );
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  getImage(publicId: string): string {
    return this.imageService.getUserProfileImage(publicId);
  }

  ngAfterViewInit(): void {
    this.getFeedbacks();
  }

}
