import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { JourneyStatus } from 'app/enums/journey-status';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from 'app/services/images.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { environment } from 'environments/environment';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { TaskType } from 'app/enums/task-type';
import { User } from '../../../../../../../models/user';
import { FeedbackModel } from 'app/models/feedback-model';
import { FeedbackRequestComponent } from 'app/core/my-journeys/take-journey/dialogs/feedback-request/feedback-request.component';
import { OfflineUploadContentComponent } from 'app/core/my-journeys/take-journey/dialogs/offline-upload-content/offline-upload-content.component'
import { Resource } from 'ngx-store/src/service/resource';
import { CuratedContentType } from 'app/enums/curated-content-type';
import { saveAs } from 'file-saver';
import { TaskAttachmentComponent } from 'app/shared/common-components/task-attachment/task-attachment.component'

@Component({
  selector: 'offline-task-component',
  templateUrl: './offline-task.component.html',
  styleUrls: ['./offline-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class OfflineTaskComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() journey: JourneyModel;
  @Input() task: TaskModel;
  @Input() showFeedback: Boolean;
  @Output() tabChange = new EventEmitter();

  animationDirection: 'left' | 'right' | 'none';
  animationDirectionFeedback: 'left' | 'right' | 'none';
  isMarkingIamDone: Boolean = false;
  isMarkingStartTask: Boolean = false;
  isKnowledgeCheck: Boolean = false;
  isLoadingCC: Boolean = false;
  viewOnly: Boolean = false;
  taskType = TaskType;
  journeyStatus = JourneyStatus;
  imagePDFViewer: any;
  feedbacks: Array<FeedbackModel> = [];
  user: User;
  isNetworkCallActive = false;
  loadingFeedback = false;
  isOngoing: Boolean;
  fileData: any;

  isAttachment: Boolean = false;
  attachmentVersion: number = 1;
  dataSource: Array<any> = [];
  filetype;
  displayedColumns: string[] = ['title', 'fileType'];
  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private activatedroute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private imageService: ImageService,
    private mix: MixpanelManager,
    private newTakeJourneyService: TakeJourneyService
  ) {
    this.animationDirection = 'none';
    this.animationDirectionFeedback = 'none';
  }

  ngOnInit(): void {
    if (this.task.attachments) {
      this.isAttachment = true;
      if (this.task.attachments['version']){
        this.attachmentVersion = this.task.attachments['version'];
      }
    }
    this.user = this.localStorageService.get('user');
    if (!this.task.supporters || !this.task.supporters.length) {
      this.showFeedback = false;
    }

    this.isOngoing = this.journey.ongoingTask === this.task.taskNo;
  }

  ngAfterViewInit(): void { }

  downloadContent(): void {
    if (this.task.attachments) {
      let fileHash;
      let fileName = '';
      this.isAttachment = true;
      fileHash = this.task.attachments['fileHash'];
      fileName = this.task.attachments['fileName'];
      this.newTakeJourneyService.downloadUploadedContent(this.user.orgId, fileHash)
        .subscribe(resource => {
          saveAs(new Blob([resource]), fileName);
        });

    }
  }

  getImage(publicId: string): string {
    return this.imageService.getUserProfileImage(publicId);
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }
  startOfflineTask(): void {
    if (this.isNetworkCallActive) {
      return;
    }

    this.isNetworkCallActive = true;
    this.isMarkingStartTask = true;
    this.newTakeJourneyService
      .startTask(this.journey._id, this.task.taskId)
      .subscribe(
        (data: any) => {
          this.isNetworkCallActive = false;
          this.isMarkingStartTask = false;
          const taskIndex = this.journey.tasks.findIndex(
            task => task.taskId === this.task.taskId
          );
          if (taskIndex !== -1) {
            this.journey.tasks[taskIndex].status = this.journeyStatus.ongoing;
            this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
          }
        },
        (error: any) => {
          console.log(error);
          this.isMarkingStartTask = false;
        }
      );
  }

  openRequestDialog(): void {
    const dialogRef = this.dialog.open(FeedbackRequestComponent, {
      width: '50vw',
      data: {
        journey: this.journey,
        task: this.task,
        user: this.user,
        type: 1
      }
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe(
      journey => {
        this.journey = journey;
        // call update journey subject
        const taskIndex = this.journey.tasks.findIndex(
          task => task.taskId === this.task.taskId
        );
        if (taskIndex !== -1) {
          console.log(this.journey.tasks[taskIndex].feedback);
          this.task['feedback'] = [];
          this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  uploadTaskConfirmation(): void {
    const dialogRef = this.dialog.open(OfflineUploadContentComponent, {
      panelClass: 'app-dialog-box',
      width: '50vw',
      height: 'auto',
      data: {
        journey: this.journey,
        task: this.task,
        user: this.user,
        type: 1
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        this.iAmDone();
      }
    });
  }
  iAmDone(): void {
    if (this.isNetworkCallActive) {
      return;
    }
    this.isNetworkCallActive = true;
    this.isMarkingIamDone = true;
    this.newTakeJourneyService
      .updateTaskStatus(this.journey._id, this.task.taskId, false, true)
      .subscribe(
        (data: any) => {
          this.showFeedback = true;
          this.isNetworkCallActive = false;
          this.isMarkingIamDone = false;
          this.task.pendingFeedback = true;
          this.tabChange.emit();
        },
        (error: any) => {
          console.log(error);
          this.isMarkingIamDone = false;
        }
      );
  }
  ngOnDestroy(): void { }
}
