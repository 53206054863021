import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { ChatService } from 'app/shared/chat/chat.service';
import { ImageService } from '../../services/images.service';
import { RequestHelpers } from '../../services/request-helpers.service';

@Component({
  selector: 'journey-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [ImageService, RequestHelpers]
})
export class ChatComponent implements OnInit, OnDestroy {
  selectedChat: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  @Input() journey: any;

  /**
   * Constructor
   *
   * @param {ChatService} _chatService
   */
  constructor(
    private _chatService: ChatService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._chatService.setData(this.journey)
      .then(res => {
        console.log(res);
        this._chatService.onChatSelected
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(chatData => {
          this.selectedChat = chatData;
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
