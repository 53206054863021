import { NgModule } from '@angular/core';
import {
    MatButtonModule, MatMenuModule, MatDialogModule, MatProgressSpinnerModule,
    MatProgressBarModule, MatOptionModule, MatAutocompleteModule, MatSelectModule,
    MatExpansionModule, MatListModule,
    MatFormFieldModule, MatIconModule, MatInputModule, MatRippleModule, MatTabsModule, MatTooltipModule, MatCheckboxModule, MatChipsModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import {DeveloopChatComponent} from './develoop-chat.component';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { DialogOverviewComponent, DialogOverviewGroupComponent, DialogAddRemovePeopleComponent } from './develoop-chat.component';
import { LinkyModule } from 'angular-linky';
import { MatProgressButtons } from 'mat-progress-buttons';
// import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';

@NgModule({
    declarations: [
        DeveloopChatComponent,
        DialogOverviewComponent,
        DialogOverviewGroupComponent,
        DialogAddRemovePeopleComponent
    ],
    providers: [
        ChatPanelService, AngularFireAuthModule, AngularFireAuth
    ],
    imports: [
        MatSelectModule,
        MatExpansionModule,
        MatProgressButtons,
        MatAutocompleteModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        LinkyModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatInputModule,
        MatTabsModule,
        MatTooltipModule,
        MatRippleModule,
        CorePipesModule,
        FuseSharedModule,
        MatMenuModule,
        MatCheckboxModule,
        MatDialogModule,
        MatChipsModule,
        AngularFireAuthModule,
        // AngularFireAuth,
        AngularFireDatabaseModule
    ],
    exports: [
        DeveloopChatComponent
    ],
    entryComponents: [
        DialogOverviewComponent,
        DialogOverviewGroupComponent,
        DialogAddRemovePeopleComponent
    ],
})
export class DeveloopChatModule {
}
