import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import { HttpClient } from '@angular/common/http';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { navigation } from 'app/navigation/navigation';
import { LocalStorageService } from 'ngx-store';
import { RequestHelpers } from './services/request-helpers.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { AngularFireAuth } from 'angularfire2/auth';

import { HelpService } from 'app/core/help/help.service';
import { environment } from 'environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Location } from '@angular/common';
import { User } from './models/user';
import { MatDialog } from '@angular/material';
import { ConsentComponent } from './shared/consent/consent.component';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [RequestHelpers]
})
export class AppComponent implements OnInit, OnDestroy {
  navigation: any[];
  fuseConfig: any;
  fetchedUserData = false;
  isAuthenticated = false;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private mix: MixpanelManager,
    private helpService: HelpService,
    public afAuth: AngularFireAuth,
    private auth: AuthService,
    private location: Location,
    private dialog: MatDialog
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.mix.track(MixpanelEventType.WEB_NAVIGATION, { url: event['url'] });
      }
    });

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this._fuseSplashScreenService.show();
  }

  private navigateRegister(userId: number, orgId: number): void {
    const urlparams = '?filter=((userId=' + userId + ') and (isManager=true))&count_only=true';
    this.http
      .get(
        environment.DF_BASE_URL_HL + orgId +
        environment.DF_TEAM_USER_MAP + urlparams, this.requestHelpers.getDFHeader())
      .subscribe(
        (data: any) => {
          // Get default navigation
          this.navigation = navigation;
          if (data > 0) {
            const insights = {
              id: 'insights',
              title: 'Insights',
              type: 'item',
              icon: 'dashboard',
              url: '/dashboard'
            };
            this.navigation.splice(1, 0, insights);
            const myteam = {
              id: 'my-teams',
              title: 'My Teams',
              type: 'item',
              icon: 'scatter_plot',
              url: '/my-teams'
            };
            this.navigation.splice(7, 0, myteam);
            // 1, 6
          }
          // Register the navigation to the service
          this._fuseNavigationService.register('main', this.navigation);

          // Set the main navigation as our current navigation
          this._fuseNavigationService.setCurrentNavigation('main');
        },
        (error: any) => {
          console.error('Error with is manager info: ', error);
          this._fuseNavigationService.register('main', this.navigation);
          this._fuseNavigationService.setCurrentNavigation('main');
        }
      );
  }

  private initMixpanel(): void {
    const localUser = <User>this.localStorageService.get('user');
    const { id, firstName, lastName, orgId, email } = localUser;
    this.mix.identify(id);
    this.mix.register({ $first_name: firstName, $last_name: lastName, $email: email, orgId, source: 'WEB' });
    this.mix.track(MixpanelEventType.LOG_IN);
  }

  private initOneSignal(): void {
    const OneSignal = window['OneSignal'] || [];
    const localUser = <User>this.localStorageService.get('user');
    OneSignal.push(() => {
      OneSignal.init({
        appId: environment.ONESIGNAL_APPID,
      });
      OneSignal.getUserId().then(playerId => {
        this.localStorageService.set('oneSignalPlayerId', playerId);
        const postData = {
          playerId,
          orgId: localUser.orgId,
          app: 'HYPERLOOP'
        };
        this.http
          .post(
            environment.GENESIS_SERVICE_URL +
            environment.GENESIS_USER_NOTIFICATION_SETTING, postData, this.requestHelpers.getBFFHeader())
          .subscribe(
            (data: any) => { },
            error => {
              console.error('Failed to store playerId in DB: ', error);
            }
          );
      });
    });
  }

  checkPermison(): void {
    this.http.post(
      environment.GENESIS_SERVICE_URL + environment.genesisEndpoints.GET_USER_CONSENT,
      {},
      this.requestHelpers.getBFFHeader()
    ).subscribe(data => {
      const policyId = data['policy']['id'];
      if (
        !data['userPolicyConsent'] ||
        !data['userPolicyConsent'].length ||
        !data['userPolicyConsent'][0].consent
      ) {
        this.openConsentDialog(policyId);
      }
    });
  }

  openConsentDialog(policyId): void {
    const consentRef = this.dialog.open(ConsentComponent, {
      width: '90vw',
      height: '80vh',
      data: {
        policyId
      },
      disableClose: true
    });

    consentRef.componentInstance.result.subscribe(res => {
      if (!res.accept) {
        // Permison leni chahiye na
        consentRef.close();
        this.auth.logout();
      } else {
        consentRef.close();
      }
    });
  }

  async ngOnInit(): Promise<void> {

    this.auth.initAuthService();
    const path = this.location.path();


    this.auth.isAuthenticated.subscribe(value => {
      this.isAuthenticated = value;
    });

    this.auth.fetchedUserData.subscribe(async value => {
      this.fetchedUserData = value;
      if (value && this.isAuthenticated) {
        this._fuseSplashScreenService.hide();
        const user = <User>this.localStorageService.get('user');
        this.navigateRegister(user.id, user.orgId);
        this.initMixpanel();
        this.initOneSignal();
        this.checkPermison();

        if (!user.isPasswordSet) {
          this.router.navigate(['/change-password']);
          return;
        }

        if (user.onboardingStatus !== 2 && path === '/home') {
          this.router.navigateByUrl('/onboarding');
        }
      }
    });


    if (!this.isAuthenticated) {
      if (path === '/callback') {
        this.auth.handleLoginCallback();
      } else {
        const urlPath = this.location.path();
        this.localStorageService.set('redirectPath', urlPath);
        this.auth.login();
      }
    }

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    // Help component Routes
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.helpService.onUrlDataChanged(event.url);
      }
    });

  }

  login(): void {
    this.auth.login();
  }

  logout(): void {
    this.auth.logout();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
