import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class JourneyReportService implements Resolve<any> {

  onMyTeamClicked: Subject<any>;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers
  ) {
    this.onMyTeamClicked = new Subject();
  }

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  public getReportData(userId, isManager, orgId, journeyId): Observable<any> {
    return this.http.get(
      environment.AUKAT_BASE_URL +
      environment.AUKAT_GET_JOURNEY_REPORT_DATA +
      `?userId=${userId}&isManager=${isManager}&journeyId=${journeyId}&orgId=${orgId}`,
      this.requestHelpers.getBFFHeader()
    );
  }

  // public getReportData(orgId, journeyId): Observable<any> {
  //   return of({
  //     resource: [{
  //       '_id': '5c9db2542043be13a91104e3',
  //       'journeyId': '5c9cc9b0b518d1028f3a9982',
  //       'KCResult': {
  //         'averageKCScore': '33.00',
  //         'averageKCAttempts': '1'
  //       },
  //       'assessmentResults': {
  //         'scores': {
  //           'baselineScore': {

  //           },
  //           'endAssessmentScore': {
  //             'batchScore': 3.5,
  //             'assessmentScore': [
  //               {
  //                 'score': 2.5,
  //                 'userRole': 2,
  //                 'weightage': 1
  //               }
  //             ]
  //           }
  //         },
  //         'GraphData': [
  //           {
  //             'name': 'End Self Score',
  //             'value': 4.5
  //           },
  //           {
  //             'name': 'End Supporter Score',
  //             'value': 2.5
  //           }
  //         ],
  //         'avgPerceptionGap': 1,
  //         'baselinePerceptionGap': 0,
  //         'endPerceptionGap': 2,
  //         'baselineSelfScore': 3,
  //         'baselineSupporterScore': 3,
  //         'endSelfScore': 4.5,
  //         'endSupporterScore': 2.5
  //       },
  //       'behaviourIndicator': {
  //         'averageBehaviourScore': 2.25,
  //         'behaviourIndicators': [
  //           {
  //             'questionId': '5c9ce935ad89b10026fac7a8',
  //             'optionId': '2',
  //             'optionValue': 2,
  //             'text': 'Has there been any development in their ability to break down complex problems into cause and effect? ',
  //             'secondaryCompetency': '5b487dcb1ad1fe699d0fcce2'
  //           },
  //           {
  //             'questionId': '5c9ce935ad89b10026fac7a9',
  //             'optionId': '3',
  //             'optionValue': 3,
  //             'text': 'Has there been any development in their ability to weigh the pros and cons of a solution to a problem? ',
  //             'secondaryCompetency': '5b487dcb1ad1fe699d0fcce2'
  //           },
  //           {
  //             'questionId': '5c9ce935ad89b10026fac7aa',
  //             'optionId': '2',
  //             'optionValue': 2,
  //             'text': 'Has there been any development in their ability to guide others on problem-solving techniques? ',
  //             'secondaryCompetency': '5b487dcb1ad1fe699d0fcce2'
  //           },
  //           {
  //             'questionId': '5c9ce935ad89b10026fac7ab',
  //             'optionId': '3',
  //             'optionValue': 3,
  //             'text': 'Has there been any development in their ability to propose creative solutions to routine problems? ',
  //             'secondaryCompetency': '5b83919f1ad1fe202013e0e1'
  //           },
  //           {
  //             'questionId': '5c9ce935ad89b10026fac7ac',
  //             'optionId': '2',
  //             'optionValue': 2,
  //             'text': 'Has there been any development in their ability to set ambitious targets for their team members in order to enhance creativity?',
  //             'secondaryCompetency': '5b83919f1ad1fe202013e0e1'
  //           },
  //           {
  //             'questionId': '5c9ce936ad89b10026fac7ad',
  //             'optionId': '2',
  //             'optionValue': 2,
  //             'text': 'Has there been any development in their ability to relate their own role to the organizations vision? '
  //           },
  //           {
  //             'questionId': '5c9ce936ad89b10026fac7ae',
  //             'optionId': '3',
  //             'optionValue': 3,
  //             'text': 'Has there been any development in their ability to convert abstract thoughts into executable business plans? '
  //           },
  //           {
  //             'questionId': '5c9ce936ad89b10026fac7af',
  //             'optionId': '1',
  //             'optionValue': 1,
  //             'text': 'Has there been any development in their understanding of the relationship between short term goals and the long term vision of the organization?'
  //           }
  //         ]
  //       },
  //       'competencyName': 'Conceptual',
  //       'disclaimer': [
  //         'Due to the absence of Develoop Baselining, your journey results may not be an accurate reflection of your progress.'
  //       ],
  //       'journeyResult': {
  //         'journeyStatus': 7,
  //         'journeyStatusText': 'Despite your Manager‘s feedback being positive, your assessment results are inconsistent, you need your Manager‘s/Admin approval to proceed.',
  //         // tslint:disable-next-line:max-line-length
  //         'assessmentOneLiner': 'While your assessment scores have shown improvement, your supporter‘s scores have not shown improvement however there is a low perception gap between the two scores.',
  //         'journeyResultText': 'Your journey results are inconclusive, please consult your manager for next steps.'
  //       },
  //       'leaderboardScore': 46,
  //       'daysTakenToCompleteJourney': 23,
  //       'fbResponsesReceived': 45,
  //       'isLastLevel': false,
  //       'relatedCompetency': [
  //         {
  //           'competencyId': '5b487dcb1ad1fe699d0fcce2',
  //           'totalScore': 7,
  //           'avgScore': 2.3333333333333335,
  //           'compName': 'Problem Solving',
  //           'competencyTag': [{ 'id': 1 }]
  //         },
  //         {
  //           'competencyId': '5b83919f1ad1fe202013e0e1',
  //           'totalScore': 5,
  //           'avgScore': 2,
  //           'compName': 'Innovation',
  //           'competencyTag': [{ 'id': 2 }]
  //         }
  //       ]
  //     }]
  //   });
  // }
}
