export enum TaskType {
  start = 0,
  curated_content = 1,
  offline_task = 2,
  feedback_request = 3,
  assessment = 4,
  user_defined = 8,
  finish = 10,
  information = 5,
}
