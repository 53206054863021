import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class HomeService {

    constructor(
        public http: HttpClient,
        private requestHelpers: RequestHelpers
    ) {
    }

    public getRecommendedJourneys(userId, orgId): any {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('orgId', orgId);
        return this.http.get(
            environment.CALYPSO_BASE_URL + environment.GET_RECOMMENDED_JOURNEYS,
            { params, headers: this.requestHelpers.getBFFHeader().headers }
        );
    }

    public getRecommendedContent(userId, orgId): any {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('orgId', orgId);
        return this.http.get(
            environment.CALYPSO_BASE_URL + environment.GET_RECOMMENDED_CONTENT,
            { params, headers: this.requestHelpers.getBFFHeader().headers }
        );
    }

}

