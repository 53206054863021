import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { JourneyStatus } from '../../../enums/journey-status';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DetailsDialogComponent } from './details-dialog/details-dialog.component';
import { AssessmentListService } from './assessment-list.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.scss']
})
export class AssessmentListComponent implements OnInit, OnChanges {

  constructor(
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private service: AssessmentListService,
    private router: Router,
    public snackBar: MatSnackBar,
  ) { }

  displayedColumns: String[];
  dataLength = 0;
  pageSize = 10;
  dataSource: Array<any> = [];
  user: any;
  popOverData: Array<any> = [];
  pageEvent: Object;
  JourneyStatus;
  pageNumber = 0;
  loading = false;
  onlyActive: Boolean = false;

  selectedComps = [];
  selectedTypes = [];
  selectedStatuses = [];

  @Input() type: String;
  @Input() filters: Object;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    console.log(this.filters);
    this.JourneyStatus = JourneyStatus;
    this.user = this.localStorageService.get('user');
    switch (this.type) {
      case 'SELF':
        this.displayedColumns = [
          'competency',
          'type',
          'dueDate',
          'status',
          'actions'
        ];
        break;
      case 'OTHER':
        this.displayedColumns = [
          'name',
          'competency',
          'supportingAs',
          'type',
          'dueDate',
          'status',
          'actions'
        ];
        break;
      case 'SELF-MINI':
        this.displayedColumns = [
          'competency',
          'type',
          'dueDate',
          'actions'
        ];
        // this.onlyActive = true;
        break;
      case 'OTHER-MINI':
        this.displayedColumns = [
          'name',
          'competency',
          'dueDate',
          'actions'
        ];
        // this.onlyActive = true;
        break;
      default:
    }
    this.fetchAssessments();
  }

  fetchAssessments = () => {

    this.loading = true;
    const body = {
      userId: this.user.id,
      orgId: this.user.orgId,
      isSelf: this.type === 'SELF' || this.type === 'SELF-MINI',
      limit: this.type === 'SELF-MINI' || this.type === 'OTHER-MINI' ? 5 : 10,
      offset: this.pageNumber,
    };

    if (this.selectedComps.length) {
      body['competencyId'] = this.selectedComps;
    }

    if (this.selectedTypes.length) {
      body['isBaseline'] = this.selectedTypes[0] === 1;
    }

    if (this.selectedStatuses.length) {
      body['status'] = this.selectedStatuses;
    }

    if (this.onlyActive) {
      body['onlyActive'] = this.onlyActive;
    }

    this.service.fetchAssessments(body)
      .subscribe(res => {
        if (res.status) {
          const dataLength = res.meta.totalCount;
          this.dataLength = dataLength;
          let dataSource = res.resource;
          dataSource = dataSource.map(item => {
            const dueDate = moment(item.dueDate);
            const diff = moment(dueDate).diff(moment(), 'days');
            item['isOverdue'] = ((diff) < 0);
            item['viewLimit'] = 4;
            item['selfData'] = item.supportersData.filter(supporter => {
              if (supporter.userId === this.user.id) {
                return supporter;
              }
            })[0];
            return item;
          });
          this.dataSource = dataSource;
        } else {
          this.dataSource = [];
          this.dataLength = 0;
          let message = 'Something went wrong';
          if (res.message) {
            message = res.message;
          }
          this.snackBar.open(message, 'OK', {
            duration: 5000,
          });
        }
        this.loading = false;
      }, err => {
        this.loading = false;
      });

  }

  viewDetails = (item) => {
    const detailDialog = this.dialog.open(DetailsDialogComponent, {
      width: '50vw',
      data: {
        isSelf: this.type === 'SELF' || this.type === 'SELF-MINI',
        batch: item
      }
    });
    detailDialog.afterClosed().subscribe(result => {
    });
    return true;
  }

  viewJourney = (item) => {
    this.router.navigate([`../../journeys-take/${item}`]);
    return true;
  }

  viewMore = (item) => {
    this.dataSource = this.dataSource.map(i => {
      if (i._id === item._id) {
        i.viewedMore = !i.viewedMore;
        i.viewLimit = i.viewedMore ? undefined : 4;
      }
      return i;
    });
    return true;
  }

  onPaginateChange(event): void {
    this.pageNumber = event.pageIndex;
    this.fetchAssessments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filters: SimpleChange = changes.filters;
    if (!filters.firstChange) {
      const current = filters.currentValue.data;
      this.selectedComps = current.selectedComps.map(c => c._id);
      this.selectedTypes = current.selectedTypes.map(c => c.value);
      this.selectedStatuses = current.selectedStatuses.map(c => c.value);
      this.onlyActive = current.onlyActive;
      this.paginator.pageIndex = 0;
      this.onPaginateChange({ pageIndex: 0 });
    }
  }

}
