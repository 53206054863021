import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DashboardComponent } from './dashboard.component';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatTableModule,
  MatSortModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatRadioModule,
  MatDividerModule,
  MatCheckboxModule,
  MatChipsModule,
  MatListModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatCardModule
} from '@angular/material';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { PieChartModule } from 'app/shared/analytics/pie-chat-card/pie-chart.module';

import { StackedAreaComponent } from 'app/core/dashboard/component/stacked-area/stacked-area.component';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highmaps from 'highcharts/modules/map.src';
import { UserCompDistributionComponent } from './component/user-comp-distribution/user-comp-distribution.component';
import { CompetencyBarComponent } from 'app/core/dashboard/component/competency-bar/competency-bar.component';
import { ContentViewCountPieComponent } from 'app/core/dashboard/component/content-view-count-pie/content-view-count-pie.component';
import { JourneyGraphComponent } from 'app/core/dashboard/component/journey-graphs/journey-graphs.component';
import { TeamFilterComponent } from 'app/core/dashboard/dialogs/team-filter/team-filter.component';
import { ActiveCompDistComponent } from 'app/core/dashboard/component/active-competency-distribution/active-competency-distribution.component';
import { JourneyCompDistComponent } from 'app/core/dashboard/component/journey-competency-distribution/journey-competency-distribution.component';
import { LeaderboardComponent } from './component/leaderboard/leaderboard.component';
import { EngagementIndexComponent } from './component/engagement-index/engagement-index.component';
import { EngagementComparisonComponent } from './component/engagement-comparison/engagement-comparison.component';
import { TopCompetenciesComponent } from './component/top-competencies/top-competencies.component';
import { BottomCompetenciesComponent } from './component/bottom-competencies/bottom-competencies.component';

import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { TeamPerceptionGapComponent } from './component/team-perception-gap/team-perception-gap.component';
import { JourneysDataComponent } from './component/journeys-data/journeys-data.component';
import { JourneyStatsComponent } from './component/journey-stats/journey-stats.component';
import { CompetencyHistoryComponent } from './component/competency-history/competency-history.component';
import { MdePopoverModule } from '@material-extended/mde';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';

const routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'dashboard/comp-history/:compId/:teamIds',
    component: CompetencyHistoryComponent
  }
];

@NgModule({
  declarations: [
    CompetencyBarComponent,
    DashboardComponent,
    ContentViewCountPieComponent,
    StackedAreaComponent,
    UserCompDistributionComponent,
    JourneyGraphComponent,
    TeamFilterComponent,
    ActiveCompDistComponent,
    JourneyCompDistComponent,
    LeaderboardComponent,
    EngagementIndexComponent,
    EngagementComparisonComponent,
    TopCompetenciesComponent,
    BottomCompetenciesComponent,
    TeamPerceptionGapComponent,
    JourneysDataComponent,
    JourneyStatsComponent,
    CompetencyHistoryComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    FuseWidgetModule,
    BrowserAnimationsModule,
    CorePipesModule,

    SharedDirectivesModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    ChartsModule,
    NgxChartsModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ChartModule,

    FuseSharedModule,
    FuseWidgetModule,

    MatListModule,
    MatDialogModule,
    SvgIconModule,
    MdePopoverModule,
    MatCardModule,
    CommonComponentsModule
  ],
  exports: [
    DashboardComponent,

  ],
  entryComponents: [
    CompetencyBarComponent,
    ContentViewCountPieComponent,
    StackedAreaComponent,
    UserCompDistributionComponent,
    JourneyGraphComponent,
    TeamFilterComponent,
    UserCompDistributionComponent,
    ActiveCompDistComponent,
    JourneyCompDistComponent
  ],
  providers: [
    DashboardService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, highmaps, exporting] } // add as factory to your providers
  ]
})

export class DashboardModule {
}
