import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';

import { HomeV2Component } from './home-v2.component';

import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { DragScrollModule } from 'ngx-drag-scroll';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';
import { GiveFeedbackComponent } from './dialogs/give-feedback/give-feedback.component';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';

import {
  MatGridListModule,
  MatCardModule,
  MatTabsModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,

  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatSelectModule,
  MatSortModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,


  MatToolbarModule

} from '@angular/material';
import { HomeService } from './home-v2.service';
import { JourneySelectorComponent } from './dialogs/journey-selector/journey-selector.component';
import { GiveFeedbackModule } from 'app/shared/give-feedback/give-feedback.module';
import { CoursesComponent } from './courses/courses.component';
import { AssessmentListModule } from 'app/shared/assessment-list/assessment-list.module';


const routes = [
  {
    path: 'home',
    component: HomeV2Component
  }
];

@NgModule({
  declarations: [
    HomeV2Component,
    GiveFeedbackComponent,
    JourneySelectorComponent,
    CoursesComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    CorePipesModule,
    FuseSidebarModule,
    ChatPanelModule,

    LevelIndicatorModule,
    SharedDirectivesModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    FuseWidgetModule,
    MatProgressBarModule,
    DragScrollModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,

    MatProgressSpinnerModule,

    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,

    MatSelectModule,

    MatSortModule,

    MatBadgeModule,
    MatListModule,
    MatChipsModule,

    MatToolbarModule,
    SvgIconModule,
    GiveFeedbackModule,
    CommonComponentsModule,
    AssessmentListModule,
  ],
  exports: [
    HomeV2Component,
    CoursesComponent
  ],
  entryComponents: [
    GiveFeedbackComponent,
    JourneySelectorComponent
  ],
  providers: [
    HomeService
  ]
})

export class HomeV2Module {
}
