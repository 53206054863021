export enum CompetencyListTypes {
    myCompetencies = 1,
    othersCompetencies = 2
}

export enum CompetencyStatuses {
    active = 1,
    expected = 2,
    additional = 3
}
