import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'delete-journey-component',
  templateUrl: 'delete-journey.component.html',
})
export class DeleteJourneyComponent {
  journeyId: any;
  user: any;
  constructor(
    public dialogRef: MatDialogRef<DeleteJourneyComponent>,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private requestHelpers: RequestHelpers,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = this.localStorageService.get('user');
    this.journeyId = data['journeyId'];
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  deleteJourney(): void {

    const postObj = {
      id: this.journeyId,
      userId: this.user['id'],
      orgId: this.user['orgId']
    };
    this.http
    .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_PURGE_JOURNEY , postObj, this.requestHelpers.getBFFHeader())
        .subscribe(
          data => {
          this.openSnackBar('Request sent', 'Ok');
          this.dialogRef.close();
          this.router.navigate(['/journeys']);
        },
        error => {
          this.dialogRef.close();
          this.requestHelpers.catchExceptions(error);
        }
      );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
