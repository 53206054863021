import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'behaviourIndicator' })

export class BehaviourIndicatorPipe implements PipeTransform {

  transform(score: number): any {

    switch (true) {

      case score <= 1.34:
        return 'NO CHANGE';

      case score > 1.34 && score <= 2.33:
        return 'IMPROVING';

      case score > 2.33:
        return 'SIGNIFICANTLY IMPROVED';

      default:
        break;
    }
  }
}
