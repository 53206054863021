import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { HelpService } from 'app/core/help/help.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: fuseAnimations,
  providers: []
})
export class HelpComponent implements OnInit, OnDestroy {
  
  fuseConfig: any;
  form: FormGroup;

  @HostBinding('class.bar-closed')
  barClosed: boolean;
  selectedContentId: Number = 1;
  urlArray: Array<object> = [];
  userGuideUrl: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private helpservice: HelpService,
  ) {
    // Set the defaults
    this.barClosed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.helpservice.onUrlChanged.subscribe((result) => {
      this.selectedContentId = this.regexParserUrl(result);
    });
  }

  ngOnInit(): void {
    // Build the config form
    // noinspection TypeScriptValidateTypes
    this.form = this._formBuilder.group({
      layout: this._formBuilder.group({
        style: new FormControl(),
        width: new FormControl(),
        navbar: this._formBuilder.group({
          background: new FormControl(),
          folded: new FormControl(),
          hidden: new FormControl(),
          position: new FormControl(),
          variant: new FormControl()
        }),
        toolbar: this._formBuilder.group({
          background: new FormControl(),
          hidden: new FormControl(),
          position: new FormControl()
        }),
        footer: this._formBuilder.group({
          background: new FormControl(),
          hidden: new FormControl(),
          position: new FormControl()
        }),
        sidepanel: this._formBuilder.group({
          hidden: new FormControl(),
          position: new FormControl()
        })
      }),
      customScrollbars: new FormControl()
    });


    this.urlArray = [
      {
        regEx: '^\/home$',
        id: 1
      },
      {
        regEx: '^\/competencies\?[a-z]+',
        id: 2
      },
      {
        regEx: '(?=^\/journeys\-take\/).+$',
        // regEx: '^\/journeys\-take\/*[0-9+a-z]*',
        id: 3
      },
      {
        regEx: '^\/journeys\/create$',
        id: 4
      },      
      {
        regEx: '(?=^\/journeys\/edit\-tasks\/).+$',
        id: 5
      },
      {
        regEx: '^\/supported\-journeys\?[a-z]+',
        id: 6
      },
      {
        regEx: '^\/feedback\-request\?[a-z]+',
        id: 7
      },
      {
        regEx: '^\/journeys\?[a-z]+',
        id: 8
      },      
      {
        regEx: '^\/dashboard$',
        id: 9
      },
      {
        regEx: '^\/dashboard\/comp\-history\/.+$',
        id: 10
      },
      {
        regEx: '^\/my\-teams',
        id: 11
      },
      {
        regEx: '^\/user\-profile\/*[0-9+a-z]+',
        id: 12
      },
      {
        regEx: '^\/supporters\-profile\/\[0-9]+',
        id: 13
      }, 
      {
        regEx: '^\/my\-assessments',
        id: 14
      }, 
      {
        regEx: '^\/others\-profile\/*[0-9]*',
        id: 15
      },     
      {
        regEx: '\/search+[?]+q=*[0-9+a-z]+',
        id: 16
      }, 
    ];

    this.userGuideUrl = environment.USER_GUIDE;
  }

  regexParserUrl(router): Number {
    let result = 0;
    for (let i = 0; i < this.urlArray.length; i++) {
      if (new RegExp(this.urlArray[i]['regEx']).test(router)) {
        result = this.urlArray[i]['id'];
        break;
      }
    }
    return result;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Remove the custom function menu
    this._fuseNavigationService.removeNavigationItem('custom-function');
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
