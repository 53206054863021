import { Component, OnInit,ViewChild } from '@angular/core';
import { CoursesService } from '../courses/courses.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  
  @ViewChild('navc', { read: DragScrollComponent }) dsss: DragScrollComponent;
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  pageSize: number;
  pageIndex: number;
  dataSourceCourse: any[];
  dataLengthCourse: any;
  leftNavDisabledContent = false;
  rightNavDisabledContent = false;
  public user: any;
  public courseSlide: boolean = false;
  public updatedOn;

  constructor(private coursesService: CoursesService,
     private localStorageService: LocalStorageService)
      { }

  fetchCourses(): void {
    return;
  }

   searchText = 'comp';
   //userId = 2248 ;
   
  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.fetchCourses();
    this.getUserCourses();
  }

  getUserCourses(){
    this.courseSlide = false;
      this.coursesService.fetchUserCourses(this.user.id)
      .subscribe((res: any) => {
        const dataLength = res.data.length;
        if(res.data.length > 0) {
          this.courseSlide = true;
        }
        else {
          return;
        }
        this.dataSourceCourse = res.data[0].courseData ;
        this.dataLengthCourse = res.data[0].courseData.length;
        this.updatedOn = this.dataSourceCourse[0].createdOn;
      }
      )
  }

  leftBoundStatContent(reachesLeftBound: boolean): void {
    this.leftNavDisabledContent = reachesLeftBound;
  }

  rightBoundStatContent(reachesRightBound: boolean): void {
    this.rightNavDisabledContent = reachesRightBound;
  }

  moveLeft(): void {
    this.ds.moveLeft();
    this.ds.moveLeft();
  }

  moveRight(): void {
    this.ds.moveRight();
    this.ds.moveRight();
  }

  moveLeftContent(): void {
    this.dsss.moveLeft();
    this.dsss.moveLeft();
  }

  moveRightContent(): void {
    this.dsss.moveRight();
    this.dsss.moveRight();
  }

}
