import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-open-feedback',
  templateUrl: './open-feedback.component.html',
  styleUrls: ['./open-feedback.component.scss']
})
export class OpenFeedbackComponent implements OnInit {

  feedbackId: string;
  routeSubscription: any;

  constructor(
    private activatedroute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.activatedroute.params.subscribe(data => {
      this.feedbackId = data['id'];
    });
  }

  onFeedbackResult($event): void { }
}
