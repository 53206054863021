import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TakeJourneyService } from '../../take-journey.service';

@Component({
  selector: 'cc-feedback.component',
  templateUrl: 'cc-feedback.component.html',
  providers: [RequestHelpers]
})
export class CCFeedbackComponent {
  journey: JourneyModel;
  task: TaskModel;
  isProcessingRequest: boolean;
  selectedOption: boolean;
  isSendingFeedback: Boolean;

  @Output() feedbackRegistered = new EventEmitter<object>();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public dialogRef: MatDialogRef<CCFeedbackComponent>,
    public snackBar: MatSnackBar,
    public newTakeJourneyService: TakeJourneyService,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.journey = <JourneyModel>data.journey;
    this.task = <TaskModel>data.task;
  }

  requestFeedback(): void {
    this.isProcessingRequest = true;

    this.newTakeJourneyService.addCCFeedback(this.journey._id, this.task.taskId, this.task.taskContentId, true).subscribe(
      (data: any) => {
        console.log(data);
        this.openSnackBar('Request has been sent', 'Ok');
        this.isProcessingRequest = false;
        this.onNoClick({ success: true });
      },
      (error: any) => {
        console.log(error);
        this.openSnackBar('Something went wrong! Try again.', 'Ok');
        this.isProcessingRequest = false;
        this.onNoClick({ success: false });
      }
    );
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }

  selectOption(result): void {
    this.selectedOption = result;
  }

  sendCCFeedback(): void {
    const result = this.selectedOption;
    if (result === undefined) {
      return;
    }

    this.isSendingFeedback = true;

    this.newTakeJourneyService.addCCFeedback(this.journey._id, this.task.taskId, this.task.taskContentId, result).subscribe(data => {

      const journey = { ...this.journey };
      if (data) {
        journey.tasks.forEach(task => {
          if (task.taskId === this.task.taskId) {
            task.isContentLiked = this.selectedOption;
          }
        });
        this.newTakeJourneyService.updateOnJourneyChanged(journey);
      }
      
      this.isSendingFeedback = false;
      this.feedbackRegistered.emit({ status: true, result: this.selectedOption });
      this.dialogRef.close();
    }, err => {
      this.isSendingFeedback = false;
      console.error('Error while sending CC feedback', err);
      this.feedbackRegistered.emit({ status: false });
      this.dialogRef.close();
    });

  }

}
