import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { FeedbackFilterModel } from 'app/models/filter-model';
import { LocalStorageService } from 'ngx-store';
import { User } from 'app/models/user';
import { MatPaginator, MatDialog } from '@angular/material';
import { FeedbackListService } from './feedback-list.service';
import { GiveFeedbackComponent } from 'app/core/feedback-request/dialogs/give-feedback/give-feedback.component';

@Component({
  selector: 'feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit, OnChanges, OnDestroy {

  dataLength = 0;
  pageSize = 10;
  displayedColumns: string[] = [];
  dataSource: Array<any> = [];
  user: User;
  filterData: any;
  selectedStatuses: Array<any> = [];
  selectedCompetencies: Array<String> = [];
  selectedOwners: Array<Number> = [];
  isTableFetching: Boolean = false;
  routeSubscription: any;
  pageIndex = 0;
  fetchFeedbackSubscription;

  constructor(
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    private service: FeedbackListService,
    private dialog: MatDialog
  ) { }

  @Input() status: Array<Number>;
  @Input() filters: FeedbackFilterModel;
  @Input() ownerId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.displayedColumns = [
      'requestedBy',
      'details',
      'message',
      'requestedOn',
      'actions'
    ];

    this.selectedStatuses = this.status ? this.status : [];
    this.fetchFeedback();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filters: SimpleChange = changes.filters;
    if (!filters.firstChange) {
      console.log('change');
      const current = <FeedbackFilterModel>filters.currentValue;
      if (current && current.requestedBy) {
        this.selectedOwners = current.requestedBy.map(owner => owner.id);
        this.paginator.pageIndex = 0;
        this.pageIndex = 0;
        this.fetchFeedback();
      }
    }
  }

  onPaginateChange(event): void {
    this.pageIndex = event.pageIndex;
    this.fetchFeedback();
  }

  fetchFeedback = () => {

    if (this.fetchFeedbackSubscription) {
      this.fetchFeedbackSubscription.unsubscribe();
    }

    this.isTableFetching = true;
    const order = 'createdOn desc';

    const filterList = [];
    const basicFilter = `(from IN (` + this.user['id'] + `)) and (type = 1))`;
    filterList.push(basicFilter);
    if (this.selectedOwners.length) {
      const ownerFilter = `(to IN (${this.selectedOwners.join(',')}))`;
      filterList.push(ownerFilter);
    }

    if (this.selectedStatuses.length) {
      const statusFilter = `(status IN (${this.selectedStatuses.join(',')}))`;
      filterList.push(statusFilter);
    }

    let filter;
    if (filterList.length > 1) {
      filter = `&filter=(${filterList.join(' AND ')})`;
    } else if (filterList.length === 1) {
      filter = `&filter=${filterList[0]}`;
    }
    console.log(filter);
    this.fetchFeedbackSubscription = this.service.fetchJourneys({ filter, pageIndex: this.pageIndex, pageSize: this.pageSize, order })
      .subscribe(
        data => {
          console.log(data);
          this.dataSource = data['resource'];
          this.dataLength = data['meta']['count'];
          this.isTableFetching = false;
        },
        error => {
          this.isTableFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  openDialog(feedbackDetails): void {
    const dialogRef = this.dialog.open(GiveFeedbackComponent, {
      width: '90vw',
      height: 'auto',
      data: {
        feedbackDetails: feedbackDetails
      }
    });

    dialogRef.componentInstance.onResult.subscribe(result => {
      if (result) {
        this.onPaginateChange({ pageIndex: 0 });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.fetchFeedbackSubscription) {
      this.fetchFeedbackSubscription.unsubscribe();
    }
  }


}
