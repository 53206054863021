import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { CuratedContentType } from 'app/enums/curated-content-type';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';

@Component({
  selector: 'app-content',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent {
  public contentType: number;
  public contentURL: string;
  public contentBase64: any;
  public pdfBase64 = null;
  public pdfLoaded = false;
  public pdfUrl: any;
  public contentSafeUrl: SafeValue;
  public isYouTubeContent: boolean;
  public title: string;
  public fileHash: string;
  public contentId: string;
  public fromJourney: boolean;
  page = 1;
  totalPages: number;
  isLoaded = false;



  constructor(
    public dialogRef: MatDialogRef<ContentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private snackBar: MatSnackBar,
    private mix: MixpanelManager
  ) {
    // load data
    this.contentType = this.data.contentType ? this.data.contentType : 1;
    this.title = this.data.title ? this.data.title : '';
    this.contentURL = this.data.contentURL ? this.data.contentURL : null;
    this.fileHash = this.data.fileHash ? this.data.fileHash : null;
    this.contentId = this.data.contentId ? this.data.contentId : null;
    this.fromJourney = this.data.fromJourney === undefined ? true : this.data.fromJourney;
    this.contentBase64 = this.data.contentBase64 ? this.data.contentBase64 : null;

    if (this.contentType === 5 && this.contentURL.includes('youtube.com')) {
      this.isYouTubeContent = true;
      this.contentURL = this.contentURL.replace('watch?v=', 'embed/');
      this.contentSafeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.contentURL);
      this.addContentView();
    }
    if ((this.contentType === CuratedContentType.image || this.contentType === CuratedContentType.document) && !this.contentBase64) {
      this.fetchContent(this.contentId).subscribe(res => {
        this.contentBase64 = res;
        if (
          this.contentType ===
          CuratedContentType.document
        ) {

            const binary_string =  window.atob(this.contentBase64);
            const len = binary_string.length;
            const bytes = new Uint8Array( len );
            for (let i = 0; i < len; i++)        {
                bytes[i] = binary_string.charCodeAt(i);
            }
          this.pdfBase64 = bytes;
            this.pdfUrl = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(new Blob([bytes],{type: 'application/pdf'})));
            this.pdfLoaded = true;
        }
        this.addContentView();
      }, err => {
        console.error('Error while fetching content', err);
        this.snackBar.open('Error while fetching content', '', {
          duration: 5000
        });
        this.dialogRef.close();
      });
    }
  }

  onClick(): void {
    this.dialogRef.close();
  }
  public fetchContent(contentId: string): any {
    const url =
      environment.GENESIS_SERVICE_URL + environment.GENESIS_GET_CONTENT;
    return this.http.get(
      url + '/' + contentId,
      this.requestHelpers.getNonJSONBFFHeader()
    );
  }

  public addContentView(): any {
    const contentId = this.contentId;
    if (!contentId || this.fromJourney) {
      return;
    }
    this.mix.track(MixpanelEventType.RECOMMENDATION_CONTENT_VIEWED, {
      contentId,
      contentType: this.contentType,
      title: this.title
    });
    const postData = {
      contentId: contentId
    };
    this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_CC_ANALYTICS,
      postData,
      this.requestHelpers.getBFFHeader()
    ).subscribe(res => {
      console.log('res', res);
    }, err => {
      console.error('Error', err);
    });
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  public addContentFeedback(isLiked: boolean): any {
    const contentId = this.contentId;
    if (!contentId || this.fromJourney) {
      return;
    }
    this.mix.track(MixpanelEventType.RECOMMENDATION_CONTENT_FEEDBACK, {
      contentId,
      contentType: this.contentType,
      title: this.title,
      isLiked
    });
    const postData = {
      contentId: contentId,
      isLiked: isLiked
    };
    this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_ADD_CC_FEEDBACK,
      postData,
      this.requestHelpers.getBFFHeader()
    ).subscribe(res => {
      console.log('res', res);
    }, err => {
      console.error('Error', err);
    });
  }

}
