import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FeedbackRequestComponent } from './feedback-request.component';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatProgressBarModule
} from '@angular/material';

import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { MatProgressButtons } from 'mat-progress-buttons';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdePopoverModule } from '@material-extended/mde';
import { GiveFeedbackComponent } from './dialogs/give-feedback/give-feedback.component';
import { FilterByPersonComponent } from './dialogs/filter-by-person/filter-by-person.component';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { GiveFeedbackModule } from 'app/shared/give-feedback/give-feedback.module';
import { FeedbackListModule } from 'app/shared/feedback-list/feedback-list.module';
import { FeedbackListFilterComponent } from 'app/shared/feedback-list/feedback-list-filter/feedback-list-filter.component';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';


const routes = [
  {
    path: 'feedback-request',
    component: FeedbackRequestComponent
  }
];

@NgModule({
  declarations: [
    FeedbackRequestComponent,
    GiveFeedbackComponent,
    FilterByPersonComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    ChartsModule,
    NgxChartsModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatGridListModule,
    CdkTableModule,
    NgxContentLoadingModule,
    MatSortModule,
    FuseWidgetModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    MatDialogModule,
    MatListModule,
    MatChipsModule,
    MdePopoverModule,
    MatCardModule,
    MatToolbarModule,
    CorePipesModule,
    MatProgressSpinnerModule,
    SharedDirectivesModule,
    MatProgressButtons,
    SvgIconModule,
    GiveFeedbackModule,
    FeedbackListModule,
    CommonComponentsModule
  ],
  exports: [
    FeedbackRequestComponent
  ],
  entryComponents: [
    GiveFeedbackComponent,
    FilterByPersonComponent
  ]
})

export class FeedbackRequestModule {

}
