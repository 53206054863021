import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from 'app/shared/svg-icon/svg-icon.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    SvgIconComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    HttpClientModule,
    InlineSVGModule.forRoot()
  ],
  exports: [
    SvgIconComponent
  ]
})
export class SvgIconModule { }
