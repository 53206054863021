import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class CompetencyListService {
    constructor(
        private http: HttpClient,
        private requestHelpers: RequestHelpers,
    ) { }

    fetchCompetencies({ filter, pageIndex, pageSize }): Observable<any> {
        return this.http
            .get(
                environment.GENESIS_SERVICE_URL +
                environment.GET_COMPETENCY_LIST +
                '?offset=' + pageIndex +
                '&limit=' + pageSize +
                filter, this.requestHelpers.getBFFHeader());
    }
}
