import { Component, OnDestroy, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bottom-competencies-component',
  templateUrl: './bottom-competencies.component.html',
  styleUrls: ['./bottom-competencies.component.scss']
})
export class BottomCompetenciesComponent implements OnInit, OnDestroy {

  @Input() bottomCompetencies: Array<any>;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }
}
