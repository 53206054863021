import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JourneyReportComponent } from '../journey-report.component'


@Component({
  selector: 'retakeJourneyConfirmation',
  templateUrl: './RetakeJourneyConfirmation.component.html',
  styleUrls: ['./RetakeJourneyConfirmation.component.scss']

})
export class RetakeJourneyConfirmation {
  constructor(
    public dialogRef: MatDialogRef<RetakeJourneyConfirmation>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onOkayClick() {
    this.onNoClick({ status: true });
  }
  onNoClick(result): void {
    this.dialogRef.close(result);
  }
  onCancelClick() {
    this.dialogRef.close();
  }

}
