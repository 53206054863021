import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { TaskModel, JourneyModel } from 'app/models/journey-model';
import { MatDialog } from '@angular/material';
import { TaskDetailsComponent } from './dialogs/task-details/task-details.component';
import { JourneyTasksService } from './journey-tasks.service';
import { ButtonOpts } from 'mat-progress-buttons';
import { MatSnackBar } from '@angular/material';
import { DrakeStoreService } from '@swimlane/ngx-dnd';
import { JourneyStatus } from 'app/enums/journey-status';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'journey-tasks',
  templateUrl: './journey-tasks.component.html',
  styleUrls: ['./journey-tasks.component.scss'],
  animations: fuseAnimations
})
export class JourneyTasksComponent implements OnInit, OnDestroy {

  @Input() journey: JourneyModel;
  @Input() tasks: TaskModel[];
  @Input() supporters: Array<any>;

  private _unsubscribeAll: Subject<any>;
  routeSubscription: any;
  journeyId: string;

  currentTask: TaskModel;
  isDisabledAddTask: boolean;
  firstTasks: any;
  movableTasks: any;
  lastTasks: any;
  editedTasks: any;
  loadingTasks: Boolean = false;
  isUpdatingTasks: Boolean = false;

  updateTasksBtnOptions: ButtonOpts = {
    active: false,
    text: 'SAVE CHANGES',
    buttonColor: 'accent',
    barColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    value: 0,
    disabled: false
  };


  constructor(
    public dialog: MatDialog,
    private _journeyTasksService: JourneyTasksService,
    public snackBar: MatSnackBar,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (this.supporters === undefined) {
      this.supporters = [];
    }

    this._journeyTasksService.onOpenEditDialog
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((task: any) => {
        this.openTaskDetailsDialog('edit', false, task, this.supporters);
      });

    this.firstTasks = this.tasks.filter(task => (task.status !== 1 && task.taskType !== 4));
    this.movableTasks = this.tasks.filter(task => (task.status === 1 && task.taskType !== 4));
    this.lastTasks = this.tasks.filter(task => task.taskType === 4);
    this.editedTasks = this.tasks;

    this._journeyTasksService.onTaskOrderUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.isUpdatingTasks = false;
      });

    this._journeyTasksService.onNewTaskAddedResponse
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        if (result.status) {
          this.tasks = result.tasks;
          this.firstTasks = this.tasks.filter(task => (task.status !== 1 && task.taskType !== 4));
          this.movableTasks = this.tasks.filter(task => (task.status === 1 && task.taskType !== 4));
          this.lastTasks = this.tasks.filter(task => task.taskType === 4);
          this.combineTasks();
          this.isDisabledAddTask = false;
          this.dialog.closeAll();
        }
      });

    this._journeyTasksService.onDeleteTaskResponse
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.tasks = result.tasks;
        this.firstTasks = this.tasks.filter(task => (task.status !== 1 && task.taskType !== 4));
        this.movableTasks = this.tasks.filter(task => (task.status === 1 && task.taskType !== 4));
        this.lastTasks = this.tasks.filter(task => task.taskType === 4);
        this.combineTasks();
        this.isDisabledAddTask = false;
      });
  }

  openTaskDetailsDialog(type, isEditDisabled, task = new TaskModel(), supporters = this.supporters): void {

    if (!isEditDisabled && this.journey.ongoingTask && this.journey.ongoingTask === this.tasks.length) {

      console.log(`Last task is ongoing`);
      this.openSnackBar(`Cannot add Task at this stage of the Journey`, `Ok`);

    } else {

      const dialogRef = this.dialog.open(TaskDetailsComponent, {
        width: '50vw',
        height: 'auto',
        data: {
          task: task,
          type: type,
          supporters: supporters,
          isEditDisabled: isEditDisabled
        }
      });

      dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
      });

    }
  }

  selectTask(task): void {
    setTimeout(() => {
      this.currentTask = task;
      this._journeyTasksService.setCurrentTask({ currentTask: this.currentTask, journey: {} });
      this.isDisabledAddTask = false;
    }, 200);
  }

  onDrop(ev): void {
    // Validation
    // fetch states of old tasks

    this.combineTasks();

    const taskIndex = this.tasks.findIndex(item => ev.value.taskId === item.taskId);

    if (taskIndex === (this.tasks.length - 1)) {
      this.openSnackBar('You cannot add User defined tasks at the end of Journey. Moving it above Assessment task.', 'Ok');
      this.swapTasks(Object.assign([], this.tasks), taskIndex, taskIndex - 1);
    }
  }

  combineTasks(): void {
    let combinedTasks = this.firstTasks.concat(this.movableTasks).concat(this.lastTasks);
    combinedTasks = combinedTasks.map((task, idx) => {
      task.taskNo = idx + 1;
      return task;
    });
    this.editedTasks = combinedTasks;
  }

  swapTasks(arr, a, b): void {
    let temp;
    temp = arr[a];
    arr[a] = arr[b];
    arr[b] = temp;

    setTimeout(() => {
      this.tasks = Object.assign([], arr);
    }, 50);
  }

  onDrag(ev): void {
  }

  updateTaskOrder(): void {
    this.isUpdatingTasks = true;
    this._journeyTasksService.updateTaskOrder(this.editedTasks);
  }

  openNewTaskWindow(): void {
    this._journeyTasksService.activateNewTaskView({});
    this.isDisabledAddTask = true;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
