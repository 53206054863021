import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RequestHelpers } from '../../../services/request-helpers.service';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'ngx-store';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'myteam-approvals',
  templateUrl: './myteam-approvals.component.html',
  styleUrls: ['./myteam-approvals.component.scss']
})
export class MyteamApprovalsComponent implements OnInit {
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }
  levelData: Array<any>;
  useGreyBg: string;
  peerLevel: number;
  // competencyName: string;
  data: any = {
    statuses: [
      { name: 'Active', display: 'active', count: 0, value: true, class: 'active' },
      { name: 'Expected', display: 'expected', count: 0, value: true, class: 'active' },
      { name: 'Additional', display: 'additional', count: 0, value: false, class: 'active' }
    ],
    competencies: [],
    title: 'My Approvals'
  };

  dataLength = 0;
  pageSize = 10;
  displayedColumns: string[] = [
    'competency.name',
    'user.firstName',
    'currentLevel.levelName',
    'suggestedLevel.levelName',
    'creator.firstName',
    'createdBy'
  ];

  // current page of items
  users: Array<any> = [];
  pageOfItems: Array<any> = [];
  dataSource: Array<any> = [];
  popUpContent: any = {};
  user: any;
  filterData: any;
  selectedStatuses: Array<any> = [];
  currentLevel: string;
  targetLevel: string;
  flag = false;
  isTableFetching: Boolean = true;
  compFilter = '';
  pageEvent: Object;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.onPaginateChange({ pageIndex: 0 });
  }

  onPaginateChange($event: any): void {
    this.isTableFetching = true;
    
    let order = '';
    /*
    if ($event.filter) {
      filter = $event.filter;
    } else {
      filter = '&additional=1&expected=1&active=1';
    }*/
    // if ($event.sort) {
    //   if ($event.by === '') {
    //     order = null;
    //   } else {
    //     order = $event.sort + ' ' + $event.by;
    //   }
    // } else {
      order = null;
    // }

    this.dataSource = [];
    
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_MANAGER_APPROVALS +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&order=' + order, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.dataSource = data['resource'];
          
          this.dataLength = data['meta']['count'];
          this.isTableFetching = false;
        },
        error => {
          this.isTableFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }


  onStatusFilterChanged($event): void {
    this.compFilter = '';
    if ($event.length) {
      for (let i = 0; i < $event.length; i++) {
        this.compFilter = this.compFilter + '&' + $event[i]['display'] + '=1';
      }
    } else {
      this.compFilter = '&additional=1&expected=1&active=1';
    }
    this.paginator.pageIndex = 0;
    this.onPaginateChange({ pageIndex: 0, filter: this.compFilter });
  }

  sortData(sort: any): void {

    this.onPaginateChange({ pageIndex: 0, filter: this.compFilter, sort: sort.active, by: sort.direction });
  }
  openPopup(element: any): void {

    this.flag = true;
    this.popUpContent = element;
    for (let i = 0; i < this.popUpContent['levels'].length; i++) {
      if (this.popUpContent['levels'][i]['id'] === this.popUpContent['startLevel']) {
        this.currentLevel = this.popUpContent['levels'][i]['levelName'];
      }
      if (this.popUpContent['levels'][i]['id'] === this.popUpContent['endLevel']) {
        this.targetLevel = this.popUpContent['levels'][i]['levelName'];
      }
    }

  }

  statusFilterClear(): void {
    this.selectedStatuses = [];
    this.paginator.pageIndex = 0;
    this.onPaginateChange({ pageIndex: 0 });
  }

  approveRequest(req, approved): void {
    this.http
      .put(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_MANAGER_APPROVALS + '/' + req._id,
        {
          approved : approved
        }
        , this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          console.log(data);
          // this.dataSource = data['resource'];
          
          // this.dataLength = data['meta']['count'];
          // this.isTableFetching = false;
        },
        error => {
          // this.isTableFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

}

