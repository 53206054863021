import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { User } from 'app/models/user';
import { Subscription } from 'rxjs';
import { MessageService } from 'app/services/subjects.service';

@Component({
  selector: 'team-perception-gap-component',
  templateUrl: './team-perception-gap.component.html',
  styleUrls: ['./team-perception-gap.component.scss']
})
export class TeamPerceptionGapComponent implements OnInit, OnDestroy {
  @Input() selectedTeams: any[];
  user: User;
  single: any[];
  multi: any[];
  view: any[] = [];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';
  selectedTeam: any;
  colorScheme = {
    domain: ['#03A9F4']
  };
  subscription: Subscription;
  chartdata: Array<any> = [];
  overdueCount = 0;
  competencyBarData: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private _dashboardService: DashboardService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.user = <User>this.localStorageService.get('user');
    this.subscription = this._messageService.getTeamFilter().subscribe(message => {
      this.selectedTeams = message.selectedTeam;
      this.getTeamPerceptionGap();
    });

    if (this.selectedTeams && this.selectedTeams.length) {
      this.getTeamPerceptionGap();
    }
  }

  getTeamPerceptionGap(): void {
    this.competencyBarData = true;

    this._dashboardService.getTeamPerceptionGap(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id))
      .subscribe(
        (result: any) => {
          if (result.resource) {
            this.chartdata = result.resource.map(data => {
              return { name: data.competencyName, value: data.avgPerceptionGap };
            });
          }

          this.competencyBarData = false;
        },
        (error: any) => {
          console.log(error);
          this.competencyBarData = false;
        });
  }

  ngOnDestroy(): void { }
}
