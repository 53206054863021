import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'resend-feedback-request-component',
  templateUrl: 'resend-feedback-request-component.html',
  providers: [RequestHelpers],
  animations: fuseAnimations
})
export class ResendFeedbackRequestComponent {
  journeyId: string;
  taskId: string;
  feedbackObj: any;
  supporterDetails: any;
  sendReminderLoading: boolean;
  sendRequestLoading: boolean;
  orgId: number;
  userId: number;
  pendingFeedbackUsers: any;
  resendFeeedbackRequestUsers: any;
  checkboxes: {};
  displayedColumns = ['checkbox', 'publicId', 'name', 'role'];
  isProcessingRequest: Boolean = false;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public dialogRef: MatDialogRef<ResendFeedbackRequestComponent>,
    public snackBar: MatSnackBar,
    private mix: MixpanelManager,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.journeyId = data.journeyId;
    this.taskId = data.taskId;
    this.orgId = data.orgId;
    this.userId = data.userId;
    this.supporterDetails = data.supporterDetails;
    this.feedbackObj = data.feedbackObj;
    this.getPendingFeedbackUsers();
    this.getResendFeedbackUsers();
  }

  getPendingFeedbackUsers(): void {
    const pendingFeedback = this.feedbackObj.filter(fb => {
      if (fb.status === 1) {
        return fb;
      }
    });

    this.pendingFeedbackUsers = [];
    this.supporterDetails.forEach(user => {
      pendingFeedback.forEach(fb => {
        if (fb.userId === user.userId) {
          user.selected = false;
          this.pendingFeedbackUsers.push(user);
        }
      });
    });
  }

  getResendFeedbackUsers(): void {
    this.resendFeeedbackRequestUsers = [];
    this.resendFeeedbackRequestUsers = this.supporterDetails.filter(
      supporter => {
        let found = false;
        this.feedbackObj.forEach(user => {
          if (user.userId === supporter.userId) {
            found = true;
          }
        });
        return !found;
      }
    );

    this.resendFeeedbackRequestUsers = this.resendFeeedbackRequestUsers.filter(
      user => {
        if (user.active) {
          return user;
        }
      }
    );
    this.checkboxes = {};
    this.resendFeeedbackRequestUsers.map(contact => {
      this.checkboxes[contact.userId] = false;
    });
  }

  toggleSelectedUser(user, type): void {
    if (type === 1) {
      const idx = this.pendingFeedbackUsers.findIndex(
        item => item.userId === user.userId
      );
      this.pendingFeedbackUsers[idx].selected = !this
        .pendingFeedbackUsers[idx].selected;
    }
    if (type === 2) {
      const idx = this.resendFeeedbackRequestUsers.findIndex(
        item => item.userId === user.userId
      );
      this.resendFeeedbackRequestUsers[idx].selected = !this
        .resendFeeedbackRequestUsers[idx].selected;
    }
  }

  requestFeedback(): void {

    this.sendReminderLoading = true;
    this.sendRequestLoading = true;
    const sendReminder = this.pendingFeedbackUsers.filter(user => {
      if (user.selected) {
        return user;
      }
    });

    const sendReminderUsers = sendReminder.map(user => {
      return user.userId;
    });

    const sendRequest = this.resendFeeedbackRequestUsers.filter(user => {
      if (user.selected) {
        return user;
      }
    });

    const sendRequestUsers = sendRequest.map(user => {
      return user.userId;
    });

    if (sendReminder.length === 0 && sendRequest.length === 0) {
      this.openSnackBar('Please select at least one user to send reminder to', 'Ok');
      this.sendReminderLoading = false;
      this.sendRequestLoading = false;
      return;
    }
    if (sendReminder.length > 0) {
      const data = {
        journeyId: this.journeyId,
        taskId: this.taskId,
        users: sendReminderUsers
      };

      this.http
        .post(
          environment.GENESIS_SERVICE_URL +
          environment.GENESIS_RESEND_REMINDER,
          data,
          this.requestHelpers.getBFFHeader())
        .subscribe(
          (result: any) => {
            this.openSnackBar('Request Reminders have been sent', 'Ok');
            this.onNoClick({ success: true });
            this.sendReminderLoading = false;
          },
          (error: any) => {
            console.log(error);
            this.openSnackBar('Something went wrong! Try again.', 'Ok');
            this.sendReminderLoading = false;
            this.onNoClick({ success: false });
          }
        );
    } else {
      this.sendReminderLoading = false;
    }
    if (sendRequest.length > 0) {
      const dataRequest = {
        journeyId: this.journeyId,
        taskId: this.taskId,
        users: sendRequestUsers,
        type: 1,
        source: 1,
        requestMsg: 'Please provide feedback!'
      };

      this.http
        .post(
          environment.GENESIS_SERVICE_URL +
          environment.GENESIS_RESEND_REQUEST,
          dataRequest,
          this.requestHelpers.getBFFHeader())
        .subscribe(
          (data: any) => {
            this.openSnackBar('Requests have been sent', 'Ok');
            this.onNoClick({ success: true });
            this.sendRequestLoading = false;
          },
          (error: any) => {
            console.log(error);
            this.openSnackBar('Something went wrong! Try again.', 'Ok');
            this.sendRequestLoading = false;
            this.onNoClick({ success: false });
          }
        );
    } else {
      this.sendRequestLoading = false;
    }
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
