import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatIconModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatDialogModule,
  MatButtonModule,
  MatFormFieldModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatCheckboxModule,
  MatInputModule,
  MatTableModule,
  MatGridListModule,
  MatSortModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule
} from '@angular/material';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { FuseWidgetModule } from '@fuse/components';
import { MdePopoverModule } from '@material-extended/mde';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SharedDirectivesModule } from '../directives/directives';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { BehavioralAssessmentModule } from '../behavioral-assessment/behavioral-assessment.module';
import { AssessmentListService } from './assessment-list/assessment-list.service';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';
import { DetailsDialogComponent } from './assessment-list/details-dialog/details-dialog.component';
import { TakeAssessmentComponent } from './assessment-list/take-assessment/take-assessment.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    ChartsModule,
    NgxChartsModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatGridListModule,
    CdkTableModule,
    NgxContentLoadingModule,
    MatSortModule,
    FuseWidgetModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MdePopoverModule,
    MatCardModule,
    MatToolbarModule,
    CorePipesModule,
    MatProgressSpinnerModule,
    SharedDirectivesModule,
    SvgIconModule,
    MatProgressBarModule,
    BehavioralAssessmentModule,
    MatSlideToggleModule
  ],
  providers: [AssessmentListService],
  declarations: [AssessmentListComponent, DetailsDialogComponent, TakeAssessmentComponent],
  exports: [AssessmentListComponent, DetailsDialogComponent, TakeAssessmentComponent],
  entryComponents: [DetailsDialogComponent, TakeAssessmentComponent],
})
export class AssessmentListModule { }
