import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { ChartOptions } from 'chart.js';

@Injectable()
export class DashboardService {

  constructor(
    private _http: HttpClient,
    private requestHelpers: RequestHelpers
  ) { }

  getCompDistributionGraph(managerId, orgId, teamIds, offset): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_COMPETENCY_DISTRIBUTION_GRAPH}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}&offset=${offset}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getTopCompetencies(managerId, orgId, teamIds, bottomScore): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_COMPETENCY_SCORE_LIST}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}&bottomScore=${bottomScore}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getBottomCompetencies(managerId, orgId, teamIds, bottomScore): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_COMPETENCY_SCORE_LIST}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}&bottomScore=${bottomScore}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getTeamPerceptionGap(managerId, orgId, teamIds): Observable<any> {
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_TEAM_PERCEPTION_GAP}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getJourneysData(managerId, orgId, teamIds): Observable<any> {
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_JOURNEY_DATA}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getJourneyStats(managerId, orgId, teamIds): Observable<any> {
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_JOURNEY_STATS}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getJourneyStatsList(managerId, orgId, teamIds, limit, offset, journeyStatus): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_JOURNEY_STATS_LIST}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds.toString()}&limit=${limit}&offset=${offset}&journeyStatus=${journeyStatus}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getUserCompList(managerId, orgId, teamIds, competencyId, limit, offset): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_USER_COMPETENCY_LIST}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds}&competencyId=${competencyId}&limit=${limit}&offset=${offset}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getUserCompHistoryList(userId, compId, orgId, limit, offset): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_USER_COMPETENCY_HISTORY_LIST}?userId=${userId}&orgId=${orgId}&competencyId=${compId}&limit=${limit}&offset=${offset}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getUserDetails(userId, orgId): Observable<any> {
    const url = `${environment.GENESIS_SERVICE_URL}${environment.GET_USER_PROFILE_INFO}?userId=${userId}&orgId=${orgId}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getPerceptionGapTrend(managerId, orgId, teamIds, competencyId, timeFilter): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_COMPETENCY_PERCEPTION_GAP}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds}&competencyId=${competencyId}&timeFilter=${timeFilter}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getAssessmentScoresTrend(managerId, orgId, teamIds, competencyId, timeFilter): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_COMPETENCY_ASSMNT_SCORES}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds}&competencyId=${competencyId}&timeFilter=${timeFilter}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getRelatedCompetencies(managerId, orgId, teamIds, competencyId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_RELATED_COMPETENCY_DATA}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds}&competencyId=${competencyId}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getLineChartOptions(): ChartOptions {
    return {
      responsive: true,
      legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
        reverse: false
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            id: 'y-axis-0',
            position: 'left',
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          },
          {
            id: 'y-axis-1',
            position: 'right',
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }
        ]
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: 'March',
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              enabled: true,
              fontColor: 'orange',
              content: 'LineAnno'
            }
          },
        ],
      },
    };
  }

  getLineChartColors(): any {
    return [
      {
        backgroundColor: 'rgba(13,71,161,1)',
        borderColor: 'rgba(13,71,161,1)',
        pointBackgroundColor: 'rgba(13,71,161,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(13,71,161,1)'
      },
      {
        backgroundColor: 'rgba(3,169,244,1)',
        borderColor: 'rgba(3,169,244,1)',
        pointBackgroundColor: 'rgba(3,169,244,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(3,169,244,1)'
      },
      {
        backgroundColor: 'rgba(126,87,194,1)',
        borderColor: 'rgba(126,87,194,1)',
        pointBackgroundColor: 'rgba(126,87,194,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(126,87,194,1)'
      },
      {
        backgroundColor: 'rgba(206,147,215,1)',
        borderColor: 'rgba(206,147,215,1)',
        pointBackgroundColor: 'rgba(206,147,215,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(206,147,215,1)'
      }
    ];
  }

  getManagersObservations(managerId, orgId, teamIds, competencyId): any {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.CALYPSO_BASE_URL}${environment.CALYPSO_GET_BEHAVIOURAL_DATA}?managerId=${managerId}&orgId=${orgId}&teamIds=${teamIds}&competencyId=${competencyId}`;
    return this._http.get(url, this.requestHelpers.getBFFHeader());
  }

  getPieChartOptions(): ChartOptions {
    return {
      responsive: true,
      legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
        reverse: false
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      }
    };
  }

  getPieChartColors(): Array<any> {
    return [{
      backgroundColor: ['rgba(30,136,228,1)', 'rgba(67,160,71,1)', 'rgba(27,94,32,1)'],
    }];
  }

  getCompetencyDetails(orgId, competencyId): Observable<any> {
    const url = `${environment.DF_MONGO_BASE_URL_HL}${orgId}${environment.DF_COMPETENCY}?filter=_id=${competencyId}`;
    return this._http.get(url, this.requestHelpers.getDFHeader());
  }

  getCompetencyLevels(orgId): Observable<any> {
    const url = `${environment.DF_BASE_URL_HL}${orgId}${environment.DF_COMPETENCY_LEVELS}?filter=(disabled=false)`;
    return this._http.get(url, this.requestHelpers.getDFHeader());
  }
}
