import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competency-list-filter',
  templateUrl: './competency-list-filter.component.html',
  styleUrls: ['./competency-list-filter.component.scss']
})
export class CompetencyListFilterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
