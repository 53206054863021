import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-store';
import { MatPaginator } from '@angular/material';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  providers: [RequestHelpers]
})
export class AssessmentComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'competency',
    'batch_identifier',
    'managerName',
    'status',
    'assessmentType',
    'dueDate',
    'actions'
  ];
  user: any;
  dataSource: Array<any> = [];
  fCompetencies: Array<any> = [];
  selectedCompetency: any;
  usersWithId: Array<any> = [];
  userName: any;
  batchIdentifierArray: Array<any> = [];
  batchIdentifier: any;
  value: 50;
  uniqueCompetencies: Array<any> = [];
  uniqueCompetenciesArray: Array<any> = [];
  uniqueUserNameArray: Array<any> = [];
  uniqueBatchNameArray: Array<any> = [];
  dataLength: 0;
  pageSize = 10;
  isTableFetching: Boolean = false;
  pageEvent: Object;
  isDataPresent: Boolean;
  popOverData: Array<any> = [];
  isFetchingAssessStatus: Boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private http: HttpClient,
    private _localStorageService: LocalStorageService,
    private requestHelpers: RequestHelpers
  ) { }

  ngOnInit(): void {
    this.user = this._localStorageService.get('user');
    this.getFiltersData();
    this.onPaginateChange({ pageIndex: 0 });
  }

  getFiltersData(): void {
    this.isTableFetching = true;

    const postData = {
      userId: this.user.id,
      orgId: this.user.orgId,
    };

    this.http.post(
      environment.AUKAT_BASE_URL +
      environment.AUKAT_GET_BATCHES,
      postData,
      this.requestHelpers.getBFFHeader())
      .subscribe(
        res => {
          this.isTableFetching = false;
          if (res['resource'] !== undefined) {
            this.isDataPresent = true;
          }

          const resource = res['resource'];
          if (resource !== undefined) {
            resource.forEach(element => {

              // Get Competencies for dropdown
              if (element.competency[0] !== null) {
                this.fCompetencies.push({ key: element.competency[0]._id, value: element.competency[0].name });
              }


              // Get Users array with ids
              this.usersWithId.push({ key: element.userId, value: element.firstName + ' ' + element.lastName });

              // Get Batch Identifiers
              const x = element.batchIdentifier;
              this.batchIdentifierArray.push(x);
            });
          }

          // Filtered Array with unique competencies

          const myUniqueArray = Array.from(new Set(this.fCompetencies.map(i => i.key)));
          myUniqueArray.forEach(item => {
            const result = this.fCompetencies.find(i => i.key === item);
            this.uniqueCompetenciesArray.push(result);
          });

          // Filtered Array with unique Name id

          const myUniqueNameArray = Array.from(new Set(this.usersWithId.map(i => i.key)));
          myUniqueNameArray.forEach(item => {
            const result = this.usersWithId.find(i => i.key === item);
            this.uniqueUserNameArray.push(result);
          });

          //  Filtered Array with unique batch

          this.batchIdentifierArray.forEach(i => {
            if (this.uniqueBatchNameArray.indexOf(i) === -1) {
              this.uniqueBatchNameArray.push(i);
            }
          });

          const index = this.uniqueBatchNameArray.indexOf(undefined);
          if (index > -1) {
            this.uniqueBatchNameArray.splice(index, 1);
          }
        },
        error => {
          this.isTableFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  onPaginateChange($event: any): void {
    this.isTableFetching = true;
    const postData = {
      userId: this.user.id,
      orgId: this.user.orgId,
      limit: this.pageSize,
      offset: $event.pageIndex
    };

    if (this.userName) {
      postData['empId'] = [this.userName];
    }
    if (this.selectedCompetency) {
      postData['competencyId'] = this.selectedCompetency;
    }
    if (this.batchIdentifier) {
      postData['batchIdentifier'] = this.batchIdentifier;
    }

    this.dataSource = [];
    this.http.post(
      environment.AUKAT_BASE_URL +
      environment.AUKAT_GET_BATCHES,
      postData,
      this.requestHelpers.getBFFHeader())
      .subscribe(
        res => {
          this.isTableFetching = false;
          if (res['resource'] === undefined) {
            this.isDataPresent = false;
          } else {
            this.isDataPresent = true;
            this.dataLength = res['meta']['totalCount'];
            res['resource'].forEach(item => {
              let keepGoing = true;
              item.supporters.forEach(supp => {
                if (keepGoing) {
                  if (supp.userId == this.user.id) {
                    item['managerFlag'] = true;
                    keepGoing = false;
                  } else {
                    item['managerFlag'] = false;
                  }
                }

              })
            })
            this.dataSource = res['resource'];
          }
        },
        error => {
          this.isTableFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }


  fetchAssessmentStatus(batch): void {
    this.popOverData = [];
    this.isFetchingAssessStatus = true;
    this.http
      .get(
        environment.AUKAT_BASE_URL +
        environment.AUKAT_GET_ASSESSEMENT_STATUS +
        `?userId=${this.user.id}&orgId=${this.user.orgId}&batchId=${batch._id}`,
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (result: any) => {
          if (result.resource.length) {
            result.resource.forEach(element => {
              element['selected'] = element.status === 3 ? true : false;
            });
            this.popOverData = result.resource;
          }

          this.isFetchingAssessStatus = false;

        },
        (error: any) => {
          console.log(error);
          this.isFetchingAssessStatus = false;
        });
  }


  submitRequest(): void {
    this.onPaginateChange({ pageIndex: 0 });
    if (this.paginator !== undefined) {
      this.paginator._pageIndex = 0;
    }
  }

  clearRequest(): void {
    this.selectedCompetency = null;
    this.userName = null;
    this.batchIdentifier = null;
    this.onPaginateChange({ pageIndex: 0 });
  }
}
