import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
import { JourneyModel } from 'app/models/journey-model';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { TakeJourneyService } from '../../take-journey.service';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { ManageSupporterComponent } from 'app/core/my-journeys/take-journey/dialogs/manage-supporter/manage-supporter.component';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { GiveFeedbackComponent } from '../../dialogs/give-feedback/give-feedback.component';
import { JourneyStatus } from 'app/enums/journey-status';
import { FeedbackType } from 'app/enums/feedback-type';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';


@Component({
  selector: 'feedbacks-component',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FeedbacksComponent implements OnInit, OnDestroy {
  // Private
  private _unsubscribeAll: Subject<any>;

  journey: JourneyModel;
  user: User;
  activeSupporters: any;
  feedbackData: any;
  selectedUserDetails: object;
  selectedUser: number;
  role: number;
  loadingSupportersFeedback: boolean;
  supporterFeedback: any;
  isSendingFeedbackReminder: boolean;
  journeyStatus = JourneyStatus;
  loginUserId: any;
  journeyRoles = JourneyAccessRoles;
  rolePrefix: string;
  isJourneyManager: boolean;

  constructor(
    private newTakeJourneyService: TakeJourneyService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private requestHelpers: RequestHelpers,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _fuseSidebarService: FuseSidebarService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.loginUserId = this.user.id;
    this.newTakeJourneyService.onJourneyChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(journey => {
        console.log(journey);
        this.journey = journey;
        this.role = this.journey.role;
        this.rolePrefix = this.journey.role === this.journeyRoles.taker ? 'My' : `${journey.userDetails['firstName']}'s`;
        this.activeSupporters = this.journey.supporterDetails.filter(
          user => user.active
        );
        const journeyManager = this.journey.supporters.filter(supporter => {
          if (supporter.userId === this.user.id && supporter.role === 'Manager') {
            return supporter;
          }
        });
        this.isJourneyManager = journeyManager.length > 0 ? true : false;
        this.getFeedback(this.journey._id);
      });
  }

  getFeedback(journeyId): void {
    this.loadingSupportersFeedback = true;
    const filter = '((feedbackMeta.journeyId = ' + journeyId + ') AND (type=' + FeedbackType.requested_feedback + '))';
    this.newTakeJourneyService.getUserFeedbacks(filter).subscribe(
      (data: any) => {
        console.log(data);
        this.feedbackData = data;
        this.feedbackData = this.feedbackData.map(feedback => {
          feedback.showRequest = false;
          return feedback;
        });
        this.gotoSupporter(0);
        this.loadingSupportersFeedback = false;
      },
      (error: any) => {
        console.log(error);
        this.loadingSupportersFeedback = false;
      }
    );
  }

  gotoSupporter(index): void {
    console.log('index', index);
    this.selectedUser = index;
    this.selectedUserDetails = this.activeSupporters[index];
    this.supporterFeedback = this.feedbackData.filter(
      feedback => feedback.from === this.activeSupporters[index].userId
    );
    console.log(this.supporterFeedback);
  }

  openManagerSupportersDialog(): void {
    const dialogRef = this.dialog.open(ManageSupporterComponent, {
      width: '50vw',
      data: {
        details: this.journey,
        orgId: this.user.orgId,
        userId: this.user['id']
      }
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe(
      journey => {
        this.journey = journey;
        // call update journey subject
        this.newTakeJourneyService.updateJourneySubject(this.journey);
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  sendReminder(feedback, userId): void {
    const idx = this.supporterFeedback.findIndex(fb => fb._id === feedback._id);
    this.supporterFeedback[idx].isSendingFeedbackReminder = true;
    const data = {
      journeyId: feedback.feedbackMeta.journeyId,
      taskId: feedback.feedbackMeta.taskId,
      users: [userId]
    };
    console.log(data);

    this.http
      .post(
        environment.GENESIS_SERVICE_URL + environment.GENESIS_RESEND_REMINDER,
        data,
        this.requestHelpers.getBFFHeader()
      )
      .subscribe(
        (result: any) => {
          this.openSnackBar('Request Reminders have been sent', 'Ok');
          this.supporterFeedback[idx].isSendingFeedbackReminder = false;
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
          this.supporterFeedback[idx].isSendingFeedbackReminder = false;
        }
      );
  }

  openDialog(feedbackDetails): void {
    const dialogRef = this.dialog.open(GiveFeedbackComponent, {
      width: '90vw',
      height: 'auto',
      data: {
        feedbackDetails: feedbackDetails
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.getFeedback(this.journey._id);
      }
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }
}
