import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engagement-comparison',
  templateUrl: './engagement-comparison.component.html',
  styleUrls: ['./engagement-comparison.component.scss']
})
export class EngagementComparisonComponent implements OnInit {
    // view: any[] = [700, 400];
    engagementComparison = [
        {
            'name': 'My Reportees',
            'series': [
                {
                    'name': 'Jan',
                    'value': 30
                },
                {
                    'name': 'Feb',
                    'value': 34.3
                },
                {
                    'name': 'Mar',
                    'value': 36
                },
                {
                    'name': 'Apr',
                    'value': 36
                },
                {
                    'name': 'May',
                    'value': 28
                },
                {
                    'name': 'Jun',
                    'value': 25
                },
                {
                    'name': 'Jul',
                    'value': 30
                },
                {
                    'name': 'Aug',
                    'value': 32
                },
                {
                    'name': 'Sep',
                    'value': 31
                },
                {
                    'name': 'Oct',
                    'value': 20
                },
                {
                    'name': 'Nov',
                    'value': 32
                }
            ]
        },

        {
            'name': 'Marketing',
            'series': [
                {
                    'name': 'Jan',
                    'value': 30
                },
                {
                    'name': 'Feb',
                    'value': 32.5
                },
                {
                    'name': 'Mar',
                    'value': 29
                },
                {
                    'name': 'Apr',
                    'value': 15
                },
                {
                    'name': 'May',
                    'value': 19
                },
                {
                    'name': 'Jun',
                    'value': 23
                },
                {
                    'name': 'Jul',
                    'value': 25
                },
                {
                    'name': 'Aug',
                    'value': 32
                },
                {
                    'name': 'Sep',
                    'value': 31
                },
                {
                    'name': 'Oct',
                    'value': 22
                },
                {
                    'name': 'Nov',
                    'value': 36
                }
            ]
        },

        {
            'name': 'Human Resources',
            'series': [
                {
                    'name': 'Jan',
                    'value': 30
                },
                {
                    'name': 'Feb',
                    'value': 35
                },
                {
                    'name': 'Mar',
                    'value': 36
                },
                {
                    'name': 'Apr',
                    'value': 37
                },
                {
                    'name': 'May',
                    'value': 38
                },
                {
                    'name': 'Jun',
                    'value': 35
                },
                {
                    'name': 'Jul',
                    'value': 30
                },
                {
                    'name': 'Aug',
                    'value': 32
                },
                {
                    'name': 'Sep',
                    'value': 31
                },
                {
                    'name': 'Oct',
                    'value': 20
                },
                {
                    'name': 'Nov',
                    'value': 32
                }
            ]
        }
    ];
    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Month';
    showYAxisLabel = true;
    yAxisLabel = 'Engagement Index';
    autoScale: any;
    
  constructor() { }

  ngOnInit() {
  }

}
