import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as mixpanel from 'mixpanel-browser';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Injectable()
export class MixpanelManager {

  constructor() {
    mixpanel.init(environment.MIXPANEL_TOKEN);
  }

  public track(type, data = {}): void {
    mixpanel.track(type, data);
    firebase.analytics().logEvent(type, data);
    
  }

  public identify(userId): void {
    mixpanel.identify(userId.toString());
    firebase.analytics().setUserId(userId.toString());
  }

  public register(data): void {
    mixpanel.register(data);
    firebase.analytics().setUserProperties(data);
  }

  public reset(): void {
    mixpanel.reset();
  }

}
