import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { User } from 'app/models/user';
import { Subscription } from 'rxjs';
import { MessageService } from 'app/services/subjects.service';

@Component({
  selector: 'journeys-data-component',
  templateUrl: './journeys-data.component.html',
  styleUrls: ['./journeys-data.component.scss']
})
export class JourneysDataComponent implements OnInit, OnDestroy {
  @Input() selectedTeams: any[];
  user: User;
  journeysData: any;
  isLoadingData: any;
  subscription: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private _dashboardService: DashboardService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.user = <User>this.localStorageService.get('user');

    this.isLoadingData = true;
    this.subscription = this._messageService.getTeamFilter().subscribe(message => {
      this.selectedTeams = message.selectedTeam;
      this.getJourneysData();
    });

    if (this.selectedTeams && this.selectedTeams.length) {
      this.getJourneysData();
    }
  }

  getJourneysData(): void {
    this.isLoadingData = true;

    this._dashboardService.getJourneysData(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id))
      .subscribe(
        (result: any) => {
          if (result.resource && result.resource.length) {
            this.journeysData = result.resource[0];
          }
          this.isLoadingData = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingData = false;
        });
  }

  ngOnDestroy(): void { }
}
