import { JourneyStatus } from 'app/enums/journey-status';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { Competency } from './competency.model';

export class JourneyModel {
  public _id: string;
  public baselineIds: Array<any>;
  public baselineMapping: Array<any>;
  public competency: Competency;
  public competencyId: string;
  public selectedCompetencies: Array<any>;
  public createdBy: number;
  public createdOn: Date;
  public credits: number;
  public endLevelData: Object;
  public recommendedDuration: number;
  public updatedOn: Date;
  public startLevel: number;
  public endLevel: number;
  public startLevelData: Object;
  public status: JourneyStatus;
  public step: number;
  public tasks: Array<TaskModel>;
  public title: string;
  public titleHash: string;
  public isChecked: boolean;
  public rules: Array<any>;
  public imageSrc: string;

  public alllevels: Array<any>;
  public completedOn: Date;
  public completedTasks: number;
  public disabled: boolean;
  public dueDate: Date;
  public endLevelId: number;
  public isComplete: boolean;
  public isOverDue: boolean;
  public ongoingTask: number;
  public selectedTask: number;
  public startLevelId: number;
  public startedOn: Date;
  public supporterDetails: Array<any>;
  public supporters: Array<any>;
  public templateId: string;
  public updatedBy: number;
  public userDetails: Object;
  public userId: number;
  public totalSteps: number;
  public deletedSupporters: Array<any>;
  public role: JourneyAccessRoles;
  public ruleDisplayText: string;

  public channelId: string;
  public fileHash : String;
  public conetentType:number ;
  constructor() {
    this.baselineIds = [];
    this.baselineMapping = [];
    this.competency = new Competency();
    this.selectedCompetencies = [];
    this.competencyId = '';
    this.startLevelData = {};
    this.tasks = new Array<TaskModel>();
    this.rules = [];
    this.title = '';
    this.titleHash = '';
    this.isChecked = false;
    this.step = 1;
    this.recommendedDuration = 1;
    this.deletedSupporters = [];
    this.ongoingTask = 0;
    this.selectedTask = 0;
    this.role = JourneyAccessRoles.taker;
  }
}

export class TaskModel {
  public attachments: object ;
  public taskType: number;
  public title: string;
  public description: string;
  public successCriteria: string;
  public successNextStep: number;
  public failureNextStep: number;
  public taskNo: number;
  public taskContentId: string;
  public taskContentName: string;
  public contentType: number;
  public source: string;
  public duration: number;
  public fbRequestContent: string;
  public rules: Array<any>;
  public assessmentDuration: number;
  public assessmentInstructions: string;

  public enableIsDone: boolean;
  public feedback: Array<any>;
  public isFbRequested: boolean;
  public pendingResponse: number;
  public status: number;
  public supporters: Array<any>;
  public taskId: string;
  public titleHash: string;
  public questionConfig: Array<any>;
  public knowledgeCheck: Array<any>;

  public pendingKCheck: boolean;
  public pendingFeedback: boolean;
  public isKcPresent: boolean;
  public isContentLiked: boolean;

  public attempts: Array<any>;
  public attemptsAllowed: any;
  public instructions: string;
  public ruleDisplayText: string;
  public expectedOutcome: string;
  public conetentType:number ;

  constructor() {
    this.taskType = 1;
    this.title = '';
    this.description = '';
    this.successCriteria = null;
    this.successNextStep = null;
    this.failureNextStep = null;
    this.taskNo = 1;
    this.taskContentId = null;
    this.taskContentName = null;
    this.duration = null;
    this.fbRequestContent = null;
    this.rules = [];
    this.pendingKCheck = false;
    this.pendingFeedback = false;
  }
}
