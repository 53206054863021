import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel } from 'app/models/journey-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ImageService } from 'app/services/images.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { JourneyStatus } from 'app/enums/journey-status';

@Component({
  selector: 'manage-supporter-component',
  templateUrl: 'manage-supporter-component.html',
  styleUrls: ['manage-supporter.component.scss'],
  providers: [ImageService, RequestHelpers]
})
export class ManageSupporterComponent {

  journey: JourneyModel;
  onJourneyEdited = new EventEmitter<Object>();

  supportersDetails: Array<any>;
  allUsersData: Array<any>;
  roles: Array<any>;

  userId: number;
  orgId: number;

  role: string;
  defaultImage: string;
  isLoadingSupporters: boolean;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ManageSupporterComponent>,
    private imageService: ImageService,
    private mix: MixpanelManager,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.journey = <JourneyModel>JSON.parse(JSON.stringify(data.details));

    // this.journey.tasks.pop();
    // this.journey.tasks.shift();

    this.orgId = data.orgId;
    this.userId = data.userId;
    this.allUsersData = [];
    this.getAllUsers(null);
    this.getJourneyRoles();
  }

  getJourneyRoles(): void {
    this.http
      .get(
        environment.DF_BASE_URL_HL + this.orgId +
        environment.DF_JOURNEY_ROLES,
        this.requestHelpers.getDFHeader())
      .subscribe(
        (data: any) => {
          this.roles = data['resource'];
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getAllUsers(event): void {
    this.allUsersData = [];

    this.isLoadingSupporters = true;

    let filter = '';
    const supportersId = [];
    this.journey.supporters.forEach((item) => {
      supportersId.push(item.userId);
    });
    supportersId.push(this.userId);

    if (event && event['target']['value'].length > 0) {
      const searchString = event['target']['value'];
      filter += '((firstName LIKE ' + searchString + '%25) OR ( lastName LIKE ' + searchString
        + '%25) OR ( email LIKE ' + searchString + '%25)) AND (id NOT IN (' + supportersId + '))';
    } else {
      if (supportersId.length > 0) {
        filter = '(id NOT IN (' + supportersId + '))';
      }
    }

    filter += ' AND (orgId= ' + this.orgId + ') AND (isEnabled=true)';
    const releated = 'fileDetails_by_profileImage';
    this.http
      .get(
        environment.DF_BASE_URL +
        environment.USER_TABLE + '?filter=' + filter + '&limit=10&related=' + releated, this.requestHelpers.getDFHeader())
      .subscribe(
        (data: any) => {

          this.allUsersData = data['resource'];
          for (let i = 0; i < this.allUsersData.length; i++) {
            if (this.allUsersData[i].fileDetails_by_profileImage) {
              this.allUsersData[i]['publicId'] = this.allUsersData[i].fileDetails_by_profileImage['publicId'];
            } else {
              this.allUsersData[i]['publicId'] = null;
            }
          }

          this.isLoadingSupporters = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingSupporters = false;
        }
      );
  }

  addSupporters(user): void {
    user.addingSupporter = true;

    const journey = JSON.parse(JSON.stringify(this.journey));

    const addedSupporters = journey.supporterDetails.filter((item) => {
      return item.userId === user['id'];
    });

    if (addedSupporters.length && addedSupporters[0]['active']) {
      this.openSnackBar('Already a Supporter.', 'Ok');
      return;
    }

    if (journey.status === JourneyStatus.completed) {
      this.openSnackBar('Cannot update after the journey is completed.', 'Ok');
      return;
    }

    const postObj = {
      'userId': user['id'],
      'role': user.role
    };

    const supportData = {
      'active': true,
      'userId': user['id'],
      'role': user.role,
      'firstName': user['firstName'],
      'lastName': user['lastName'],
      'publicId': user['fileDetails_by_profileImage'] === null ? null : user['fileDetails_by_profileImage']['publicId']
    };

    journey.supporters.push(postObj);

    const postData = {
      journey: {
        supporters: journey.supporters
      },
      userId: this.userId,
      orgId: this.orgId,
      id: journey._id
    };

    this.http
      .put(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY + '/' +
        journey._id, postData, this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          if (data.success) {
            this.journey = journey;

            if (addedSupporters.length && !addedSupporters[0]['active']) {
              for (let i = 0; i < this.journey.supporterDetails.length; i++) {
                if (this.journey.supporterDetails[i].userId === user['id']) {
                  this.journey.supporterDetails[i]['active'] = true;
                  this.journey.supporterDetails[i]['role'] = user['role'];
                  break;
                }
              }
            } else {
              this.journey.supporterDetails.push(supportData);
            }

            this.getAllUsers(null);
            this.mix.track(MixpanelEventType.JOURNEY_EDITED, { journeyId: this.journey['_id'] });
            this.openSnackBar('Supporter added.', 'Ok');
            this.onJourneyEdited.emit(this.journey);
          } else {
            this.openSnackBar(data.message, 'Ok');
          }

          user.addingSupporter = false;
        },
        (error: any) => {
          console.log(error);
          user.addingSupporter = false;
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
        }
      );
  }

  removeSupporters(supporter, index): void {

    this.journey.supporterDetails[index]['removingSupporter'] = true;
    
    const journey = JSON.parse(JSON.stringify(this.journey));

    const suppIndex = this.journey.supporters.findIndex(sup => sup.userId === supporter.userId);

    journey.supporters.splice(suppIndex, 1);

    const postData = {
      journey: {
        supporters: journey.supporters
      },
      userId: this.userId,
      orgId: this.orgId,
      id: journey._id
    };

    this.http
      .put(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY + '/' +
        journey._id, postData, this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {

          if (data.success) {
            this.mix.track(MixpanelEventType.JOURNEY_EDITED, { journeyId: journey['_id'] });
            
            this.journey.supporterDetails[index]['removingSupporter'] = false;
            this.journey.supporterDetails[index]['active'] = false;
            this.journey.supporters = journey.supporters;

            this.getAllUsers(null);
            this.openSnackBar('Supporter removed.', 'Ok');
            this.onJourneyEdited.emit(this.journey);
          } else {
            this.openSnackBar(data.message, 'Ok');
          }
        },
        (error: any) => {
          console.log(error);
          this.journey.supporterDetails[index]['removingSupporter'] = false;          
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
        }
      );
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
