import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { PageNotFoundComponent } from './pagenotfound.component';
import { MatButtonModule, MatCardModule, MatToolbarModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

import { MdePopoverModule } from '@material-extended/mde';

const routes = [
    {
        path     : 'pagenotfound',
        component: PageNotFoundComponent
    }
];

@NgModule({
    declarations: [
        PageNotFoundComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,
        FuseSharedModule,
        // BrowserModule,
        // BrowserAnimationsModule,
        FormsModule,
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MdePopoverModule
    ],
    exports     : [
        PageNotFoundComponent
    ],
})

export class PageNotFoundModule
{
}
