import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { environment } from '../../../environments/environment';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-store';
import { RequestHelpers } from '../../services/request-helpers.service';
import { JourneyStatus } from 'app/enums/journey-status';
import { fuseAnimations } from '@fuse/animations';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { GiveFeedbackComponent } from './dialogs/give-feedback/give-feedback.component';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { HomeService } from './home-v2.service';
import { ContentViewComponent } from 'app/shared/content-view/content-view.component';
import { JourneySelectorComponent } from './dialogs/journey-selector/journey-selector.component';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';


@Component({
  selector: 'app-home-v2',
  templateUrl: './home-v2.component.html',
  styleUrls: ['./home-v2.component.scss'],
  animations: fuseAnimations
})

export class HomeV2Component implements OnInit {

  data: Array<string>;
  journey: JourneyModel;
  user: any;
  journeyData: Array<any> = [];
  newJourneyData: Array<any> = [];
  isTableFetchingJourneys: Boolean = false;
  loadingRequestedFeedback: Boolean = false;
  loadingReceivedFeedback: Boolean = false;
  successData: Boolean = false;
  journeyStatusData: Boolean = false;
  newJourneyStatusData: Boolean = false;
  supportersRequestedData: any;
  isMyTaskFetching: Boolean = false;

  requestedFeedbackRequestedData: any;
  requestedFeedbackRequestedCount: Boolean = false;

  filterJourneySupporter: string;

  // Level Indicator variables
  color = 'primary';
  mode = 'determinate';
  value = 0;
  bufferValue = 0;

  disabled;
  xDisabled;
  yDisabled;
  leftNavDisabled = false;
  rightNavDisabled = false;
  leftNavDisabledNewJourneys = false;
  rightNavDisabledNewJourneys = false;
  index = 0;
  userJourneyStats: Object = {
    undecided: 0,
    completed: 0,
    deleted: 0,
    failed: 0,
    notstarted: 0,
    ongoing: 0,
    ontime: 0,
    overdue: 0,
    supporting: 0
  };
  journeyActivities: Array<any> = [];
  completedJourneys: Array<any> = [];

  recContent = [];
  recContentStatus = false;
  leftNavDisabledContent = false;
  rightNavDisabledContent = false;

  stockImage = environment.IMAGES.DEFAULT_CONTENT;

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  @ViewChild('navd', { read: DragScrollComponent }) dss: DragScrollComponent;
  @ViewChild('navc', { read: DragScrollComponent }) dsss: DragScrollComponent;
  @ViewChild('tabGroup') tabGroup;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public homeService: HomeService,
    public mix: MixpanelManager
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit(): void {
    this.data = ['name', 'address', 'phone'];

    this.filterJourneySupporter = null;
    this.user = this.localStorageService.get('user');
    this.module();
  }

  moveLeft(): void {
    this.ds.moveLeft();
    this.ds.moveLeft();
  }

  moveLeftNewJourneys(): void {
    this.dss.moveLeft();
    this.dss.moveLeft();
    this.mix.track(MixpanelEventType.RECOMMENDATION_JOURNEY_SWIPED, { direction: 'LEFT' });
  }

  moveLeftContent(): void {
    this.dsss.moveLeft();
    this.dsss.moveLeft();
    this.mix.track(MixpanelEventType.RECOMMENDATION_CONTENT_SWIPED, { direction: 'LEFT' });
  }

  moveRight(): void {
    this.ds.moveRight();
    this.ds.moveRight();
  }

  moveRightNewJourneys(): void {
    this.dss.moveRight();
    this.dss.moveRight();
    this.mix.track(MixpanelEventType.RECOMMENDATION_JOURNEY_SWIPED, { direction: 'RIGHT' });
  }

  moveRightContent(): void {
    this.dsss.moveRight();
    this.dsss.moveRight();
    this.mix.track(MixpanelEventType.RECOMMENDATION_CONTENT_SWIPED, { direction: 'RIGHT' });
  }

  leftBoundStat(reachesLeftBound: boolean): void {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean): void {
    this.rightNavDisabled = reachesRightBound;
  }

  leftBoundStatNewJourneys(reachesLeftBound: boolean): void {
    this.leftNavDisabledNewJourneys = reachesLeftBound;
  }

  rightBoundStatNewJourneys(reachesRightBound: boolean): void {
    this.rightNavDisabledNewJourneys = reachesRightBound;
  }

  leftBoundStatContent(reachesLeftBound: boolean): void {
    this.leftNavDisabledContent = reachesLeftBound;
  }

  rightBoundStatContent(reachesRightBound: boolean): void {
    this.rightNavDisabledContent = reachesRightBound;
  }

  openDialog(feedbackDetails): void {
    const dialogRef = this.dialog.open(GiveFeedbackComponent, {
      width: '90vw',
      height: 'auto',
      data: {
        feedbackDetails: feedbackDetails
      }
    });

    dialogRef.componentInstance.onResult.subscribe(result => {
      if (result && result.success) {
        this.getRequestedCountFeedbackRequested();
      }
    });
  }

  resendRequest(index): void {
    this.supportersRequestedData[index]['resendingRequest'] = true;
    const dataRequest = {
      journeyId: this.supportersRequestedData[index]['feedbackMeta']['journeyId'],
      taskId: this.supportersRequestedData[index]['feedbackMeta']['taskId'],
      users: [this.supportersRequestedData[index]['from']],
    };

    this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_RESEND_REMINDER,
        dataRequest,
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          this.openSnackBar('Requests have been sent', 'Ok');
          this.supportersRequestedData[index]['resendingRequest'] = false;
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
          this.supportersRequestedData[index]['resendingRequest'] = false;
        }
      );

  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  module(): void {
    this.getRequestedCountFeedbackRequested();
    this.onFilterMyJourneys();
    this.getRequestedFeedback();
    this.getUserJourneyStats();
    this.getJourneyActivities();
    this.getRecommendedJourneys();
    this.getRecommendedContent();
  }

  getUserJourneyStats(): void {
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GET_USER_JOURNEY_STATS, this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          this.successData = data.success;
          if (data.success && data.resource.length) {
            this.userJourneyStats = data.resource[0];
          } else {
            console.log('this is also and error');
          }

        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getRecommendedJourneys(): void {
    this.homeService.getRecommendedJourneys(this.user.id, this.user.orgId)
      .subscribe(
        (res: any) => {
          this.newJourneyStatusData = true;
          this.newJourneyData = res.data.journeys;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getRecommendedContent(): void {
    this.homeService.getRecommendedContent(this.user.id, this.user.orgId)
      .subscribe(
        (res: any) => {
          this.recContent = res.data.contents;
          this.recContentStatus = true;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  getJourneyActivities(): void {
    this.http
      .get(
        environment.GENOS_BASE_URL +
        environment.genosEndpoints.GET_USER_ACTIVITIES,
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (res: any) => {
          const data = res.resource;
          data.forEach(element => {
            element.data = typeof element.data === 'object' ? element.data : JSON.parse(element.data);
            element['formattedReceivedOn'] = moment(element['createdOn']['_seconds'] * 1000).format('MMM Do YYYY, h:mm a');
            if (element.data.journeyId) {
              element.actionName = 'View Journey',
                element.link = `../journeys-take/${element.data.journeyId}`;
            } else {
              element.actionName = 'View Assessments',
                element.link = `../my-assessments`;
            }
          });
          this.journeyActivities = data;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getRequestedFeedback(): void {
    this.loadingRequestedFeedback = true;

    const filter = '&filter=(to IN (' + this.user['id'] + ')) AND (status IN (1)) AND (type=1)';
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_USER_FEEDBACK_DATA + '?offset=0&limit=10' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.loadingRequestedFeedback = false;
          this.supportersRequestedData = data;
          this.supportersRequestedData.forEach(item => {
            if (item['receivedOn']) {
              item['formattedReceivedOn'] = moment(item['receivedOn']['$date']).format('MMM Do YYYY, h:mm a');
            }
            item['formattedCreatedOn'] = moment(item['createdOn']['$date']).format('MMM Do YYYY, h:mm a');
          });
        },
        error => {
          this.requestHelpers.catchExceptions(error);
          this.loadingRequestedFeedback = false;
        }
      );
  }

  getRequestedCountFeedbackRequested(): void {
    this.requestedFeedbackRequestedCount = true;
    const filter = 'filter=(from IN ( ' + this.user['id'] + ')) or (supporters.userId IN (' + this.user['id'] + ')) and (status = 1) and (type=1)';
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_USER_FEEDBACK_DATA + '?' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.requestedFeedbackRequestedCount = false;
          this.requestedFeedbackRequestedData = data;

          this.requestedFeedbackRequestedData.forEach(item => {
            if (item['receivedOn']) {
              item['formattedReceivedOn'] = moment(item['receivedOn']['$date']).format('MMM Do YYYY, h:mm a');
            }
            item['formattedCreatedOn'] = moment(item['createdOn']['$date']).format('MMM Do YYYY, h:mm a');
          });
        },
        error => {
          this.requestedFeedbackRequestedCount = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  onFilterMyJourneys(): void {

    this.isTableFetchingJourneys = true;
    this.isMyTaskFetching = true;
    this.filterJourneySupporter = '?limit=20&filter=(userId IN (' + this.user['id'] + ')) and (status not IN(5,6,7))&appendCompDetails=1&appendLevelDetails=1';

    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY + this.filterJourneySupporter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.journeyStatusData = true;
          data['resource'].forEach(element => {
            const completedTasks = element.tasks.filter(task => task.status === JourneyStatus.completed);
            element.completedTasks = (completedTasks.length / element.tasks.length) * 100;
          });

          this.journeyData = [];
          this.journeyData = data['resource'];
          this.completedJourneys = data['resource'].filter(journey => journey.status === JourneyStatus.completed);
          this.journeyData = this.journeyData.filter(journey => journey.status !== JourneyStatus.completed);
          this.isTableFetchingJourneys = false;
          this.isMyTaskFetching = false;
        },
        error => {
          this.isTableFetchingJourneys = false;
          this.isMyTaskFetching = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  viewContent(content): void {
    const dialogRef = this.dialog.open(ContentViewComponent, {
      panelClass: 'content-viewer',
      width: '50vw',
      data: {
        contentId: content.id,
        contentURL: content.link,
        contentType: content.contentType,
        title: content.title,
        fromJourney: false
      }
    });
  }

  selectNewJourney(templateId: string): void {
    this.dialog.open(JourneySelectorComponent, {
      panelClass: 'journey-selector',
      width: '250px',
    });
    this.mix.track(MixpanelEventType.RECOMMENDATION_JOURNEY_VIEWED, {
      templateId
    });
    return;
  }

}
