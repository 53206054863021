import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { SearchContentLibraryComponent } from './search-content-library.component';
import { SearchModule } from 'app/core/search/search.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    FormsModule,
    SearchModule
  ],
  exports: [
    SearchContentLibraryComponent
  ],
  declarations: [
    SearchContentLibraryComponent
  ]
})
export class SearchContentLibraryModule { 

}




