import { Competency } from './competency.model';
import { User } from './user';
import { Team } from './team-model';

export class JourneyFilterModel {
    public comps: Array<Competency>;
    public owners: Array<User>;
    constructor() {
        this.comps = [];
        this.owners = [];
    }
}
export class FeedbackFilterModel {
    public requestedBy: Array<User>;
    constructor() {
        this.requestedBy = [];
    }
}

export class FeedbackUserFilterModel{
    public requestedBy: Array<Number>;
    constructor() {
        this.requestedBy = [];
    }
}

export class JourneyFilterModelMini {
    public comps: Array<String>;
    public owners: Array<Number>;
    constructor() {
        this.comps = [];
        this.owners = [];
    }
}

export class UserFilterModel {
    public journeyStatuses: Array<any>;
    public teams: Array<Team>;
    public comps: Array<Competency>;
    constructor() {
        this.journeyStatuses = [];
        this.teams = [];
        this.comps = [];
    }
}

export class UserFilterModelMini {
    public journeyStatuses: Array<any>;
    public teams: Array<Number>;
    public comps: Array<String>;
    constructor() {
        this.journeyStatuses = [];
        this.teams =[];
        this.comps = [];
    }
}