import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'instructions-component',
  templateUrl: 'instructions.component.html'
})
export class InstructionsComponent {

  constructor(
    public dialogRef: MatDialogRef<InstructionsComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data);
  }

  onFeedbackResult($event): void {

    if ('isCanceled' in $event && $event.isCanceled) {
      this.onNoClick({status: $event.success});
    }

    if ('success' in $event && $event.success) {
      this.onNoClick({status: $event.success});
    }
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
