import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'team-filter-component',
  templateUrl: 'team-filter-component.html',
  styleUrls: ['../../dashboard.component.scss'],
  providers: []
})
export class TeamFilterComponent {

  teams: Array<any> = [];
  selectedTeams: Array<any> = [];

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TeamFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.teams = data.teams;
    console.log(this.teams);
    this.selectedTeams = data.selectedTeams;
  }

  clearFilter(): void {
    this.selectedTeams = [];
  }

  apply(): void {
    if (this.selectedTeams.length >= 1) {
      this.dialogRef.close(this.selectedTeams);
    } else {
      this.openSnackBar('One team should be selected.', 'Ok');
    }

  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
