import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationError } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
@Component({
  selector: 'app-search-content-library',
  templateUrl: './search-content-library.component.html',
  styleUrls: ['./search-content-library.component.scss']
})
export class SearchContentLibraryComponent implements OnInit, OnDestroy {

  collapsed: boolean;
    fuseConfig: any;
    searchText = '';

    @Output()
    input: EventEmitter<any>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private activatedroute: ActivatedRoute,
        private router: Router
    )
    {
        // Set the defaults
        this.input = new EventEmitter();
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.collapse();
                this.clearSearchText();

            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
    }

    routeToContentLib(searchTxt): any{
        searchTxt = searchTxt.trim();
        this.router.navigate(['../search'], {
        queryParams : {
            'q': searchTxt
        }
        });
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
    }

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        this.input.emit(event.target.value);
    }

    clearSearchText(): void {
     this.searchText = '';
    }


}



