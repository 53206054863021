import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, SimpleChanges, OnChanges } from '@angular/core';
import { UserFilterModel, UserFilterModelMini } from 'app/models/filter-model';
import { MatDialog } from '@angular/material';
import { UserListFilterDialogComponent } from './user-list-filter-dialog/user-list-filter-dialog.component';

@Component({
  selector: 'user-list-filter',
  templateUrl: './user-list-filter.component.html',
  styleUrls: ['./user-list-filter.component.scss']
})
export class UserListFilterComponent implements OnInit, OnChanges {

  @Input() filterData: UserFilterModel;
  @Input() alreadyFiltered: UserFilterModelMini;
  @Input() disabled: Boolean;
  @Output() filterSelect = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog
  ) {
  }

  allFilterData: UserFilterModel;
  selectedFilterData: UserFilterModel;

  ngOnInit(): void {
    this.allFilterData = this.filterData;
    this.selectedFilterData = new UserFilterModel();

    const selectedComps = this.allFilterData.comps.filter(comp => this.alreadyFiltered.comps.includes(comp._id));
    const selectedTeams = this.allFilterData.teams.filter(team => this.alreadyFiltered.teams.includes(team.id));
    const selectedJourneyStatuses = this.allFilterData.journeyStatuses.filter(stat => this.alreadyFiltered.journeyStatuses.includes(stat.name));
    this.selectedFilterData = { ...this.selectedFilterData, comps: selectedComps, teams: selectedTeams, journeyStatuses: selectedJourneyStatuses };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filterData: SimpleChange = changes.filterData;
    if (filterData && !filterData.firstChange) {
      const current = <UserFilterModel>filterData.currentValue;
      if (current && current.comps) {
        this.allFilterData = current;
        const selectedComps = current.comps.filter(comp => this.alreadyFiltered.comps.includes(comp._id));
        this.selectedFilterData = { ...this.selectedFilterData, comps: selectedComps };
      }
      if (current && current.teams) {
        this.allFilterData = current;
        const selectedTeams = current.teams.filter(team => this.alreadyFiltered.teams.includes(team.id));
        this.selectedFilterData.teams = selectedTeams;
      }
      if (current && current.journeyStatuses) {
        this.allFilterData = current;
        const selectedJourneyStatuses = current.journeyStatuses.filter(s => this.alreadyFiltered.journeyStatuses.includes(s.name));
        this.selectedFilterData.journeyStatuses = selectedJourneyStatuses;
      }
    }
  }

  openFilterDialog(): void {
    const filterDialog = this.dialog.open(UserListFilterDialogComponent, {
      width: '30vw',
      height: 'auto',
      data: { allFilterData: { ...this.allFilterData }, selectedFilterData: { ...this.selectedFilterData } }
    });

    filterDialog.afterClosed().subscribe(result => {
      if (result) {
        this.selectedFilterData = result;
        this.filterSelect.emit(result);
      }
    });
  }

}
