import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from 'app/services/images.service';
import { MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';

@Component({
  selector: 'my-assessments',
  templateUrl: './my-assessments.component.html',
  styleUrls: ['./my-assessments.component.scss'],
  providers: [ImageService, RequestHelpers, MixpanelManager]
})
export class MyAssessmentsComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    public mixPanelManager: MixpanelManager

  ) { }

  displayedColumns: string[] = [
    'competency',
    'type',
    'status',
    'dueDate',
    'actions'
  ];
  user: any;
  isFetchingAssessStatus: Boolean;
  pageEvent: Object;
  isFetchingComps: Boolean;

  comps: Array<any> = [];
  types: Array<any> = [{ type: 'Baseline', value: 1 }, { type: 'End of Journey', value: 2 }];
  statuses: Array<any> = [{ status: 'Not Started', value: 1 }, { status: 'Ongoing', value: 2 }, { status: 'Completed', value: 3 }, { status: 'Continuance', value: 6 }];
  selectedComps: any = [];
  selectedTypes: any = [];
  selectedStatuses: any = [];
  onlyActive: Boolean = false;
  loadedFilterDataCount = 0;

  filters = {
    data: {
      selectedComps: [],
      selectedTypes: [],
      selectedStatuses: []
    }
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tabGroup') tabGroup;

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.getCompetencies();
  }

  getCompetencies(): void {
    this.isFetchingComps = true;
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GET_BASELINE_COMP_FILTER + '?userId=' + this.user['id'],
        this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          this.loadedFilterDataCount++;
          this.comps = data['resource'];
          this.isFetchingComps = false;
        },
        (error: any) => {
          this.loadedFilterDataCount++;
          this.requestHelpers.catchExceptions(error);
          this.isFetchingComps = false;
        }
      );
  }

  openFilterDialog(): void {
    const filterDialog = this.dialog.open(FilterDialogComponent, {
      width: '30vw',
      height: 'auto',
      data: {
        allComps: this.comps,
        allTypes: this.types,
        allStatuses: this.statuses,
        selectedComps: this.selectedComps,
        selectedTypes: this.selectedTypes,
        selectedStatuses: this.selectedStatuses,
        onlyActive: this.onlyActive
      }
    });

    filterDialog.afterClosed().subscribe(result => {
      this.filters = result;
      this.selectedComps = result.data.selectedComps;
      this.selectedTypes = result.data.selectedTypes;
      this.selectedStatuses = result.data.selectedStatuses;
      this.onlyActive = result.data.onlyActive;
    });
  }

}
