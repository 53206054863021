import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MyTeamsComponent } from './my-teams.component';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatDialogModule
} from '@angular/material';


import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdePopoverModule } from '@material-extended/mde';

import { TeamFilterComponent } from 'app/core/my-teams/dialogs/team-filter/team-filter.component';
import { FilterDialogComponent } from 'app/core/my-teams/dialogs/filter-dialog/filter-dialog.component';
import { CompetencyFilterComponent } from 'app/core/my-teams/dialogs/competency-filter/competency-filter.component';
import { UserListModule } from 'app/shared/user-list/user-list.module';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';

const routes = [
  {
    path: 'my-teams',
    component: MyTeamsComponent
  }
];

@NgModule({
  declarations: [
    MyTeamsComponent,
    TeamFilterComponent,
    FilterDialogComponent,
    CompetencyFilterComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    ChartsModule,
    NgxChartsModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatGridListModule,
    CdkTableModule,
    NgxContentLoadingModule,
    MatSortModule,
    FuseWidgetModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MdePopoverModule,
    MatCardModule,
    MatToolbarModule,
    CorePipesModule,
    MatProgressSpinnerModule,
    SharedDirectivesModule,
    MatDialogModule,
    SvgIconModule,
    UserListModule,
    CommonComponentsModule
  ],
  exports: [
    MyTeamsComponent
  ],
  entryComponents: [
    TeamFilterComponent,
    FilterDialogComponent,
    CompetencyFilterComponent
  ]
})

export class MyTeamsModule {
}
