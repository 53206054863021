import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { MessageService } from 'app/services/subjects.service';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GiveFeedbackComponent } from './dialogs/give-feedback/give-feedback.component';
import { InstructionsComponent } from './dialogs/instructions/instructions.component';

@Component({
  selector: 'behavioral-assessment',
  templateUrl: './behavioral-assessment.component.html',
  styleUrls: ['./behavioral-assessment.component.scss']
})
export class BehavioralAssessmentComponent implements OnInit, OnDestroy {

  @Input() assessmentId: string;
  @Input() directStart: Boolean;
  @Input() assessmentDetails: any;

  @Output() assessmentSubmited = new EventEmitter<object>();
  @Output() assessmentStarted = new EventEmitter<object>();

  assessmentQuestions: Array<any> = [];
  currentQuestionIndex: number;

  assessmentEndDateTime: Date;
  assessmentStartDateTime: Date;

  progressSpinnerColor = 'mat-orange';
  progressSpinnerMode = 'determinate';
  progressSpinnerValue = 0;

  progressBarColor = 'mat-lime-500-bg';
  progressBarMode = 'determinate';
  progressBarValue = 0;
  numOfQuestionsAnswered = 0;

  assessmentStep: number; // 0-Fetching information, 1-Instructions, 2-Ongoing, 3-Completed(Immediate), 4-Result
  assessmentResult: any;
  details: any;

  user: User;
  isLoadingQuestions: Boolean;
  isSubmitingAssessment: Boolean;
  // tslint:disable-next-line:max-line-length
  instructions: String = '<p>This questionnaire evaluates individual capabilities and attributes. Each question has multiple options to select  from as your response. <strong>There are no right or wrong answers.</strong> </p><p>As a tip, we would suggest that you go with the first response that comes to your mind. Try not to spend too much  time on one question as this will affect the speed with which you complete the assessment. Please rate yourself or the  team member being assessed based on observations of recent on-the-job behaviors and performance results. Respond by  selecting one of the below mentioned options that you feel is most applicable </p><p>&nbsp; &nbsp; 1. Never, in no instance<br />&nbsp;&nbsp;&nbsp;&nbsp;2. Rarely, in less than 20% of the time<br />&nbsp;&nbsp;&nbsp;&nbsp;3. Sometimes<br />&nbsp;&nbsp;&nbsp;&nbsp;4. Often, in about 80% of  the time<br />&nbsp;&nbsp;&nbsp;&nbsp;5. Always, in all instances</p><p>  <strong> Note: All questions are mandatory.</strong></p>';

  // Strongly disagree (1), Disagree (2), Neither agree nor disagree (3), Agree (4), Strongly agree (5)
  options = {
    [OptionFormat.DIRECT_RATING_FIVE]: [
      { text: 'Strongly disagree', score: 1, optionId: 1 },
      { text: 'Disagree', score: 2, optionId: 2 },
      { text: 'Neither agree nor disagree', score: 3, optionId: 3 },
      { text: 'Agree', score: 4, optionId: 4 },
      { text: 'Strongly agree', score: 5, optionId: 5 }
    ],
    [OptionFormat.REVERSE_RATING_FIVE]: [
      { text: 'Strongly disagree', score: 5, optionId: 1 },
      { text: 'Disagree', score: 4, optionId: 2 },
      { text: 'Neither agree nor disagree', score: 3, optionId: 3 },
      { text: 'Agree', score: 2, optionId: 4 },
      { text: 'Strongly agree', score: 1, optionId: 5 }
    ],
    [OptionFormat.BEHAVIOURAL_INDICATORS]: [
      { text: 'Not improved', score: 1, optionId: 1 },
      { text: 'Improving', score: 2, optionId: 2 },
      { text: 'Significantly improved', score: 3, optionId: 3 }
    ],
    [OptionFormat.TRUE_FALSE]: [
      { text: 'True', score: 1, optionId: 1 },
      { text: 'False', score: 0, optionId: 2 }
    ]
  };
  optionFormat = OptionFormat;
  assessmentFeedback: String;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private requestHelpers: RequestHelpers,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.assessmentStep = 0;
    this.getAssessmentQuestions();
  }

  startAssessment(): void {
    this.assessmentStarted.emit({ status: true });

    this.currentQuestionIndex = 0;
    this.assessmentStep = 2;
  }

  getAssessmentQuestions(): void {
    this.isLoadingQuestions = true;
    this.http.get(
      environment.AUKAT_BASE_URL +
      environment.AUKAT_GET_ASSESSMENT_QUESTIONS + `?id=${this.assessmentId}&orgId=${this.user.orgId}`,
      this.requestHelpers.getBFFHeader())
      .subscribe(
        (result: any) => {
          if (result.status) {
            if (result.resource.length) {
              result.resource.forEach(question => {
                question['options'] = this.options[question.optionFormat];
              });
              this.assessmentQuestions = result.resource;
              this.details = result.assessmentDetails;
              this.isLoadingQuestions = false;

              if (this.directStart) {
                this.startAssessment();
              } else {
                this.assessmentStep = 1;
              }
            } else {
              this.openSnackBar('There are no questions in this assessment.', 'Ok');
              this.router.navigate(['/my-assessments']);
            }
          }

        },
        (error: any) => {
          this.isLoadingQuestions = false;
          console.log(error);
        });
  }

  loadNextQuestion(): void {
    if (this.assessmentQuestions && this.assessmentQuestions.length && this.assessmentQuestions.length > (this.currentQuestionIndex + 1)) {
      this.currentQuestionIndex++;
    }
  }

  validateAssessment(): void {
    if (this.assessmentDetails.feedbackId) {
      this.openDialog();
    } else {
      this.submitAssessment();
    }
  }

  submitAssessment(): void {
    this.isSubmitingAssessment = true;

    const postData = {
      assessmentId: this.assessmentId,
      orgId: this.user.orgId,
      questions: []
    };

    this.assessmentQuestions.forEach(question => {
      postData.questions.push({ questionId: question._id, optionId: question.selected.optionId.toString(), optionValue: question.selected.score });
    });

    this.http.post(
      environment.AUKAT_BASE_URL +
      environment.AUKAT_SUBMIT_ASSESSMENT,
      postData,
      this.requestHelpers.getBFFHeader())
      .subscribe(
        (result: any) => {
          this.isSubmitingAssessment = false;
          this.assessmentSubmited.emit({ success: true });
        },
        (error: any) => {
          this.isSubmitingAssessment = false;
          this.assessmentSubmited.emit({ success: false });
        });
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(GiveFeedbackComponent, {
      width: '90vw',
      height: 'auto',
      data: {
        feedbackId: this.assessmentDetails.feedbackId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result && result.status) {
        this.submitAssessment();
      }
    });
  }
  viewInstructions = () => {
    const detailDialog = this.dialog.open(InstructionsComponent, {
      width: '50vw',
      data: {
      }
    });
    detailDialog.afterClosed().subscribe(result => {
    });
    return true;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    this.messageService.onAssmntCompDestroyed.next();
  }
}

export enum OptionFormat {
  DIRECT_RATING_FIVE = 1,
  REVERSE_RATING_FIVE = 2,
  BEHAVIOURAL_INDICATORS = 3,
  TRUE_FALSE = 4
}
