import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { ButtonOpts } from 'mat-progress-buttons';
import { TaskModel, JourneyModel } from '../../models/journey-model';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { MessageService } from 'app/services/subjects.service';
import * as moment from 'moment';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { JourneyStatus } from 'app/enums/journey-status';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { CCFeedbackComponent } from 'app/core/my-journeys/take-journey/dialogs/cc-feedback/cc-feedback.component';
import { TaskType } from 'app/enums/task-type';
import { JourneyCompletedComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-completed/journey-completed.component';

@Component({
  selector: 'journey-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit, OnDestroy {

  @Input() data: any;
  @Input() assessmentType: number; // 1 = nugget, 2 = KC
  @Input() journeyId: string;
  @Input() role: JourneyAccessRoles;
  @Input() journey: JourneyModel;

  @Output() assessmentCompleted = new EventEmitter<object>();
  @Output() assessmentFailed = new EventEmitter<object>();

  loadingReport = false;

  begingAssessmentButton: ButtonOpts = {
    active: false,
    text: 'Start Assessment',
    buttonColor: 'accent',
    barColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    value: 0,
    disabled: true
  };

  submitAssessmentButton: ButtonOpts = {
    active: false,
    text: 'Submit Assessment',
    buttonColor: 'accent',
    barColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    value: 0,
    disabled: false
  };

  task: TaskModel;

  filter: any;
  assessmentQuestions: Array<any> = [];
  currentQuestionIndex: number;

  assessmentEndDateTime: Date;
  assessmentStartDateTime: Date;

  progressSpinnerColor = 'mat-orange';
  progressSpinnerMode = 'determinate';
  progressSpinnerValue = 0;

  progressBarColor = 'mat-lime-500-bg';
  progressBarMode = 'determinate';
  progressBarValue = 0;
  numOfQuestionsAnswered = 0;

  assessmentStep: number; // 0-Fetching information, 1-Instructions, 2-Ongoing, 3-Completed(Immediate), 4-Result
  assessmentResult: any;

  user: User;
  report: any;

  canTakeNugget = false;
  canTakeNuggetAgain = false;
  journeyFailed = false;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public snackBar: MatSnackBar,
    private requestHelpers: RequestHelpers,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    private newTakeJourneyService: TakeJourneyService,
    private dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.task = <TaskModel>this.data.task;

    console.log('This is the data', this.data.task);

    this.assessmentStep = 0;
    this.begingAssessmentButton.disabled = this.role !== 1;

    if (this.task.taskType === TaskType.curated_content
      && !this.task.pendingKCheck
      && this.task.status !== JourneyStatus.completed) {
      // Should just go to 0 step
    } else if (this.task.status === JourneyStatus.completed || this.task.status === JourneyStatus.failed) {
      this.assessmentStep = 4;
      this.getNuggetReport();
    } else {
      this.assessmentStep = 1;
      this.getAssessmentQuestions();
    }
  }

  startAssessment(): void {
    if (this.assessmentType === 2) {
      this.submitAssessmentButton.text = 'Submit Knowledge Check';
    } else {
      this.submitAssessmentButton.text = 'Submit Assessment';
    }
    if (this.assessmentType === 1) {
      this.currentQuestionIndex = 0;
      this.assessmentStep = 2;
      this.assessmentStartDateTime = new Date();
      this.assessmentEndDateTime = moment(this.assessmentStartDateTime).add(this.task.assessmentDuration, 'm').toDate();
      this.startDateProgressSpinner(this.assessmentStartDateTime, this.assessmentEndDateTime);
      this.sendMessage(1);
    }

    if (this.assessmentType === 2) {
      this.currentQuestionIndex = 0;
      this.assessmentStep = 2;
      this.assessmentStartDateTime = new Date();
      this.assessmentEndDateTime = moment(this.assessmentStartDateTime).add(this.task.assessmentDuration, 'm').toDate();
      this.sendMessage(1);
    }
  }

  sendMessage(type: number): void {
    this.messageService.sendMessage({ type: type });
  }

  getNuggetReport(): void {
    this.loadingReport = true;
    this.newTakeJourneyService.getNuggetReport(this.journeyId, this.task.taskId)
      .subscribe((data: any) => {
        this.loadingReport = false;
        this.report = data.report;
        if (!this.report || !this.report.responses) {
          return;
        }

        this.report.responses = this.report.responses.map(res => {
          if (res.type === 1) {
            res.selected = res.options.find(opt => opt.optionHash === res.selectedOptions[0]);
          }
          const options = res.options.map(opt => {
            opt.selected = res.selectedOptions.filter(x => x === opt.optionHash).length > 0;
            return opt;
          });
          res.options = options;
          return res;
        });
      }, (err: any) => {
        this.loadingReport = false;
        console.error(err);
      });
  }

  getAssessmentQuestions(): void {

    if (this.task.taskNo === this.journey.ongoingTask) {
      this.begingAssessmentButton.active = true;
    } else {
      this.begingAssessmentButton.active = false;
    }
    this.begingAssessmentButton.text = 'Loading Assessment...';

    let attemptCounts = 0;
    if (this.task.attempts !== undefined) {
      attemptCounts = this.task.attempts.length;
    }
    const attemptsAllowed = Number(this.task.attemptsAllowed);
    if (attemptsAllowed > 0 && attemptsAllowed <= attemptCounts) {
      this.canTakeNugget = false;
    } else {
      this.canTakeNugget = true;
    }

    if (this.task) {
      if (this.assessmentType === 1) {
        const duration = this.task.assessmentDuration;
        this.task['formatedDuration'] = duration > 60 ? (Math.floor(duration / 60) + ' hrs and ' + (duration % 60) + ' minutes') : (duration + ' minutes');

        this.newTakeJourneyService.getNugget(this.journey.competencyId, this.journey.endLevel, this.task.questionConfig)
          .subscribe(
            (data: any) => {
              this.assessmentQuestions = data;

              if (this.assessmentQuestions.length) {
                this.assessmentQuestions.forEach(item => {
                  item.selected = false;
                  item.options.forEach(option => {
                    option.selected = false;
                  });
                });
                this.begingAssessmentButton.active = false;
                this.begingAssessmentButton.disabled = false;
                this.begingAssessmentButton.text = 'Start Assessment';
              } else {
                this.begingAssessmentButton.active = false;
                this.begingAssessmentButton.disabled = true;
                this.begingAssessmentButton.text = 'Start Assessment';
              }
            },
            error => {
              this.begingAssessmentButton.active = false;
              this.begingAssessmentButton.disabled = true;
              this.begingAssessmentButton.text = 'Start Assessment';
              console.log(error);
            }
          );
      }

      if (this.assessmentType === 2) {

        // fetch from the curated content

        this.newTakeJourneyService.checkKnowledgeCheck(this.task.taskContentId, this.user.orgId)
          .then(response => {
            if ('knowledgeCheck' in response && response['knowledgeCheck'].length) {
              this.assessmentQuestions = response['knowledgeCheck'];
              if (this.assessmentQuestions.length) {
                this.assessmentQuestions.forEach(item => {
                  item.selected = false;
                  item.options.forEach(option => {
                    option.selected = false;
                  });
                });
                this.begingAssessmentButton.active = false;
                this.begingAssessmentButton.disabled = false;
                this.begingAssessmentButton.text = 'Start Knowledge Check';
              } else {
                this.begingAssessmentButton.active = false;
                this.begingAssessmentButton.disabled = true;
                this.begingAssessmentButton.text = 'Start Knowledge Check';
              }

            } else {
              return;
            }
          })
          .catch(error => {
            console.log(error);
          });


      }
    } else {
      this.begingAssessmentButton.active = false;
      this.begingAssessmentButton.disabled = true;
      this.begingAssessmentButton.text = 'Start Assessment';
    }
  }

  loadPreviousQuestion(): void {
    if (this.assessmentQuestions && this.assessmentQuestions.length && this.currentQuestionIndex !== 0) {
      this.currentQuestionIndex--;
    }
  }

  loadNextQuestion(): void {
    if (this.assessmentQuestions && this.assessmentQuestions.length && this.assessmentQuestions.length > (this.currentQuestionIndex + 1)) {
      this.currentQuestionIndex++;
    }
  }

  openCCFeedback(journeyPassed = false): void {
    const dialogRef = this.dialog.open(CCFeedbackComponent, {
      width: '50vw',
      data: {
        journey: this.journey,
        task: this.task
      }
    });
    if (journeyPassed) {
      dialogRef.afterClosed().subscribe(res => {
        this.openJourneyCompletedDialog();
      });
    }
  }

  openJourneyCompletedDialog(): void {
    const dialogRef = this.dialog.open(JourneyCompletedComponent, {
      width: '30vw',
      data: {}
    });
  }

  submitAssessment(): void {
    this.submitAssessmentButton.active = true;
    this.submitAssessmentButton.text = 'Please wait...';

    const postData = {
      journeyId: this.journeyId,
      taskId: this.task.taskId,
      startTime: this.assessmentStartDateTime,
      endTime: this.assessmentEndDateTime,
      userResponses: []
    };

    console.log('assessment questions', this.assessmentQuestions);

    if (this.assessmentType === 1) {
      this.assessmentQuestions.forEach(question => {
        if (question.type === 1) {
          postData.userResponses.push({ questionId: question._id, selectedOptions: question.selected ? [question.selected.optionHash] : [] });
        }

        if (question.type === 2) {
          const correctOptions = [];
          question.options.forEach(option => {
            if (option.selected) {
              correctOptions.push(option.optionHash);
            }
          });
          if (correctOptions.length) {
            postData.userResponses.push({ questionId: question._id, selectedOptions: correctOptions });
          } else {
            postData.userResponses.push({ questionId: question._id, selectedOptions: [] });
          }
        }
      });
    }

    if (this.assessmentType === 2) {
      this.assessmentQuestions.forEach(question => {
        if (question.type === 1) {
          postData.userResponses.push({ questionId: question.questionHash, selectedOptions: question.selected ? [question.selected.optionHash] : [] });
        }

        if (question.type === 2) {
          const correctOptions = [];
          question.options.forEach(option => {
            if (option.selected) {
              correctOptions.push(option.optionHash);
            }
          });
          if (correctOptions.length) {
            postData.userResponses.push({ questionId: question.questionHash, selectedOptions: correctOptions });
          } else {
            postData.userResponses.push({ questionId: question.questionHash, selectedOptions: [] });
          }
        }
      });
    }

    console.log('Will send this object for sending assessment result', postData);

    this.newTakeJourneyService.submitNugget(postData)
      .subscribe(
        (data: any) => {
          console.log('data', data);
          this.submitAssessmentButton.active = false;

          if (this.assessmentType === 2) {
            this.submitAssessmentButton.text = 'Submit Knowledge Check';
          } else {
            this.submitAssessmentButton.text = 'Submit Assessment';
          }

          this.openSnackBar(data.message, 'Ok');
          this.assessmentStep = 3;

          const result = data;

          const { journeyCompleted } = data;

          if (data.didPass && this.assessmentType === 2) {
            this.openCCFeedback(journeyCompleted);
          } else {
            if (journeyCompleted) {
              this.openJourneyCompletedDialog();
            }
          }

          let attemptCounts = 1;
          if (this.task.attempts !== undefined) {
            attemptCounts = this.task.attempts.length + 1;
          }
          const attemptsAllowed = Number(this.task.attemptsAllowed);
          if (attemptsAllowed > 0 && attemptsAllowed <= attemptCounts) {
            this.canTakeNuggetAgain = false;
          } else {
            this.canTakeNuggetAgain = true;
          }

          if (result.attempt) {
            if (this.task.attempts) {
              this.task.attempts.push(result.attempt);
            } else {
              this.task.attempts = [result.attempt];
            }
            const journey = { ...this.journey };
            journey.tasks.forEach(task => {
              if (task.taskId === this.task.taskId) {
                task.attempts = this.task.attempts;
              }
            });
            this.newTakeJourneyService.updateOnJourneyChanged(journey);
          }

          if (this.task.taskType === TaskType.assessment && result.journey.status === JourneyStatus.failed) {
            this.journeyFailed = true;
          } else {
            this.journeyFailed = false;
          }

          if (result.report) {
            result.report = result.report.map(res => {
              if (res.questionType === 1) {
                res.selected = res.options.find(opt => opt.optionHash === res.selectedOptions[0]);
              }
              const options = res.options.map(opt => {
                opt.selected = res.selectedOptions.filter(x => x === opt.optionHash).length > 0;
                return opt;
              });
              res.options = options;
              return res;
            });
          }
          this.assessmentResult = result;
          // this.sendMessage(2);
        },
        (error: any) => {
          console.log(error);
          this.submitAssessmentButton.active = false;
          if (this.assessmentType === 2) {
            this.submitAssessmentButton.text = 'Submit Knowledge Check';
          } else {
            this.submitAssessmentButton.text = 'Submit Assessment';
          }
          // this.submitAssessmentButton.text = 'Submit Assessment';
          this.openSnackBar('Something went wrong!', 'Ok');
          this.assessmentStep = 3;
          // this.backToJourneyDetails(2);
          // this.sendMessage(2);
        }
      );
  }

  startDateProgressSpinner(startDateTime, endDateTime): void {
    startDateTime = moment(startDateTime);
    endDateTime = moment(endDateTime);
    const InitialDateDiff = moment.duration(endDateTime.diff(startDateTime)).asSeconds();

    this.progressSpinnerValue = (InitialDateDiff / InitialDateDiff) * 100;

    const intervalId = setInterval(() => {

      const currentDate = moment(new Date());
      const newDuration = moment.duration(endDateTime.diff(currentDate)).asSeconds();
      this.progressSpinnerValue = (newDuration / InitialDateDiff) * 100;
      if (this.progressSpinnerValue <= 0) {
        clearInterval(intervalId);
        this.submitAssessment();
      }

    }, 1000);
  }

  nextTask(): void {
    this.assessmentCompleted.emit();
  }

  goBackToCCTask(): void {
    this.assessmentFailed.emit();
  }

  goBackToInstructions(): void {
    this.assessmentStep = 1;
    this.getAssessmentQuestions();
  }

  percentage(first: number, second: number): number {
    const percent = (first / second * 100);
    return percent;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  goToFinalTask(): void {
    console.log('Will go to the final task now');
    const journey = { ...this.journey };
    journey.status = JourneyStatus.failed;
    journey.tasks.forEach(task => {
      if (task.taskId === this.task.taskId) {
        task.status = JourneyStatus.failed;
      }
    });
    journey.ongoingTask++;
    this.newTakeJourneyService.updateOnJourneyChanged(journey);
  }

  ngOnDestroy(): void {
    this.messageService.onAssmntCompDestroyed.next();
  }
}
