import { Pipe, PipeTransform } from '@angular/core';
import { CuratedContentType } from 'app/enums/curated-content-type';


@Pipe({ name: 'CCTypeIconPipe' })

export class CCTypeIconPipe implements PipeTransform {

  transform(status: CuratedContentType): any {

    switch (status) {
      case 1:
        return 'description';
      case 2:
        return 'image';
      case 3:
        return 'videocam';
      case 4:
        return 'volume_up';
      case 5:
        return 'link';
      default:
        return '';
    }
  }
}
