import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreToSpace'
})

export class UnderscoreToSpace implements PipeTransform {

  transform(value: any): string {

    if (value) {
      return value.replace(/_/g, ' ');
    }

    return value;
  }
}
