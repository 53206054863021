import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { MatDialog, MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';
import { fuseAnimations } from '@fuse/animations';
import { CuratedContentType } from '../../enums/curated-content-type';
import { TeamFilterComponent } from 'app/core/dashboard/dialogs/team-filter/team-filter.component';
import { MessageService } from 'app/services/subjects.service';
import { Subscription } from 'rxjs';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [RequestHelpers],
  animations: fuseAnimations
})
export class DashboardComponent implements OnInit {
  widget6: any = {};

  selectedProject: any;
  @Input() teamFilter: any;
  mostLikedContentData: boolean;
  getMostLikedContent: any[];
  mostViewedContentData: boolean;
  widget5: any = {};
  unansweredFeedbackData: boolean;
  widget7: any = {};
  widget8: any = {};
  widget9: any = {};

  allTeams: Array<any> = [];
  selectedTeams: Array<any> = [];

  subscription: Subscription;

  mostViewdContent: any[];
  unansweredFeedbackRequestData: any[];
  dateNow = Date.now();
  user: any;
  heatMap: any;
  widget1SelectedYear = '2016';
  widget5SelectedDay = 'today';
  barChart: any;

  pageSize = 4;
  viewDataLength: number;
  likeDataLength: number;
  ufrDataLength = 0;
  favoriteSeason: string;
  seasons: string[] = ['Day', 'Month', 'Week'];
  journeyInsights: Array<any> = [];
  onGoingStatus: boolean;
  pageEvent: Object;
  topCompetencies: Array<any> = [];
  bottomCompetencies: Array<any> = [];

  displayedColumns: string[] = [
    'name',
    'title',
    'dueDate',
    'overdueby'
  ];

  isFetchingTeams: Boolean;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _dashboardService: DashboardService,
    private messageService: MessageService,
  ) {
    this.likeDataLength = 0;
    this.pageEvent = { pageIndex: 0 };
    this.mostLikedContentData = true;
    this.mostViewedContentData = true;
    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      this.selectedTeams = message.selectedTeam;
      this.mostLikedContent({ pageIndex: 0 });
      this.mostViewedContent({ pageIndex: 0 });
      this.unansweredFeedbackRequest({ pageIndex: 0 });
      this.getTopCompetencies();
      this.getBottomCompetencies();
    });

    this.onGoingStatus = true;

    setInterval(() => {
      this.dateNow = Date.now();
    }, 1000);
  }

  getTeamsForFilter(): void {
    this.isFetchingTeams = true;
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_TEAM +
        `?managerId=${this.user.id}&limit=100`,
        this.requestHelpers.getBFFHeader()
      )
      .subscribe(
        data => {
          this.allTeams = data['resource'];
          this.selectedTeams = this.allTeams.filter(item => item.isSudoTeam);
          if (!this.selectedTeams.length) {
            this.selectedTeams = [this.allTeams[0]];
          }
          this.isFetchingTeams = false;

          this.messageService.sendTeamFilter({ selectedTeam: this.selectedTeams });
        },
        error => {
          console.log(error);
          this.isFetchingTeams = false;
        }
      );
  }

  mostLikedContent($event: any): void {

    const teamIds = [];
    let teamFilter = '';
    this.mostLikedContentData = true;

    if (this.selectedTeams.length > 0) {
      this.selectedTeams.forEach(item => {
        teamIds.push(item.id);
      });
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=10' + teamFilter;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.mostLikedContentData = false;
          this.getMostLikedContent = data['resource'][0]['content_likes']['list'];
          this.likeDataLength = data['resource'][0]['content_likes']['totalCount'];
        },
        error => {
          console.log(error);
          this.mostLikedContentData = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  mostViewedContent($event: any): void {
    this.mostViewedContentData = true;
    const teamIds = [];
    let teamFilter = '';
    if (this.selectedTeams.length > 0) {
      this.selectedTeams.forEach(item => {
        teamIds.push(item.id);
      });
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=12' + teamFilter;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.mostViewedContentData = false;
          this.mostViewdContent = data['resource'][0]['content_views']['list'];
          this.viewDataLength = data['resource'][0]['content_views']['totalCount'];

        },
        error => {
          console.log(error);
          this.mostViewedContentData = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  getContentType(type): string {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    let enumkeys = [];
    let enumvalues = [];
    let isIE = false;

    if (match !== -1) {
      isIE = true;
    }

    if (!isIE) {
      const values: string[] = Object.values(CuratedContentType);
      enumkeys = values.slice(values.length / 2);
      enumvalues = values.slice(0, values.length / 2);
    } else {
      enumvalues = ['document', 'image', 'video', 'audio', 'link'];
      enumkeys = [1, 2, 3, 4, 5];
    }


    const enumobj: any = {};
    for (let i = 0; i < enumkeys.length; i++) {
      const element = enumkeys[i];
      enumobj[enumkeys[i]] = enumvalues[i];
    }

    return enumobj[type];
  }

  unansweredFeedbackRequest($event: any): void {
    this.unansweredFeedbackData = true;
    const teamIds = [];
    let teamFilter = '';
    if (this.selectedTeams.length > 0) {
      this.selectedTeams.forEach(item => {
        teamIds.push(item.id);
      });
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=9' + teamFilter;
    this.unansweredFeedbackRequestData = [];
    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.unansweredFeedbackData = false;
          this.unansweredFeedbackRequestData = data['resource'][0]['feedabck_req_count_list']['list'];
          this.ufrDataLength = data['resource'][0]['feedabck_req_count_list']['totalCount'];
        },
        error => {
          console.log(error);
          this.unansweredFeedbackData = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  openTeamFilterDialog(): void {

    if (!this.allTeams.length) {
      
      if (this.isFetchingTeams){
        this.openSnackBar('We are fetching the Teams, Please wait..', 'Ok');
      } else {
        this.openSnackBar('There are no team(s) to select', 'Ok');
      }

      return;
    }
    const dialogRef = this.dialog.open(TeamFilterComponent, {
      width: '30vw',
      data: {
        teams: this.allTeams,
        selectedTeams: this.selectedTeams
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedTeams = result;
        this.messageService.sendTeamFilter({ selectedTeam: this.selectedTeams });
      }
    });
  }

  removeTeamFilter(teamId): void {
    if (this.selectedTeams.length > 1) {
      this.selectedTeams = this.selectedTeams.filter(item =>
        item.id !== teamId
      );
      this.messageService.sendTeamFilter({ selectedTeam: this.selectedTeams });
    } else {
      this.openSnackBar('One team should be selected.', 'Ok');
    }

  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  getTopCompetencies(): void {
    this._dashboardService.getTopCompetencies(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id), 0)
      .subscribe(
        (result: any) => {
          if (result.resource) {
            this.topCompetencies = result.resource;
          }
        },
        (error: any) => {
          console.log(error);
        });
  }

  getBottomCompetencies(): void {
    this._dashboardService.getBottomCompetencies(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id), 1)
      .subscribe(
        (result: any) => {
          if (result.resource) {
            this.bottomCompetencies = result.resource;
          }
        },
        (error: any) => {
          console.log(error);
        });
  }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.getTeamsForFilter();
    this.mostLikedContent({ pageIndex: 0 });
    this.mostViewedContent({ pageIndex: 0 });
    this.unansweredFeedbackRequest({ pageIndex: 0 });
  }
}
