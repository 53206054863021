import { Inject, AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ChatService } from 'app/shared/chat/chat.service';
import { LocalStorageService } from 'ngx-store';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { ImageService } from '../../../services/images.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';

@Component({
  selector: 'chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
  providers: [ImageService]
})
export class ChatViewComponent implements OnInit, OnDestroy, AfterViewInit {
  user: any;
  chat: any;
  dialog: any;
  contact: any;
  replyInput: any;
  selectedChat: any;
  details: any;
  removedOn: boolean;
  disableReplyBtn: boolean;

  @ViewChild(FusePerfectScrollbarDirective)
  directiveScroll: FusePerfectScrollbarDirective;

  @ViewChildren('replyInput')
  replyInputField;

  @ViewChild('replyForm')
  replyForm: NgForm;


  localUser: any;
  groupInfo: any;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {ChatService} _chatService
   */
  constructor(
    public dialogPopUp: MatDialog,
    private _chatService: ChatService,
    private db: AngularFirestore,
    private localStorageService: LocalStorageService,
    private imageService: ImageService,
  ) {
    // Set the private defaults
    this.removedOn = true;
    this.localUser = this.localStorageService.get('user');
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.getChatData();

    const selectedJourneyRole = this.localStorageService.get('journeyRole');
    if (selectedJourneyRole && selectedJourneyRole === JourneyAccessRoles.manager) {
      this.disableReplyBtn = true;
    }
    
  }

  getChatData(): void {
    // this.user = this._chatService.user;

    this._chatService.onChatSelected
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(chatData => {
        if (chatData) {
          this.removedOn = chatData.removedOn;
          this.selectedChat = chatData;
          this.contact = chatData.contact;
          this.dialog = chatData.dialog;

          // this.readyToReply();
          this.user = {
            // id: this.localUser['id'],
            avatar: this.contact['avatar'],
            name: this.contact['name'],
            chatList: []
          };

          const fetchingURL = 'channels/' + this.contact.id;

          const snap = this.db.doc('channels/' + this.contact.id).snapshotChanges();
          snap.subscribe(items => {
            this.groupInfo = items.payload.data();
            this.groupInfo['channelId'] = items.payload.id;
            // console.log('items', items);
            this.groupInfo['image'] = this.imageService.getUserProfileImage(this.groupInfo['image'] ? this.groupInfo['image'] : null);

            this.groupInfo['owners'].forEach(ownerDetais => {
              this.groupInfo['membersMeta'].forEach(item => {
                const profileImageURL = item['publicProfileId'] ? item['publicProfileId'] : null;

                item['imageURL'] = this.imageService.getUserProfileImage(profileImageURL);

                if (this.localUser['id'] === ownerDetais) {

                  this.groupInfo['isOwner'] = true;
                }
                if (ownerDetais === item['userId']) {
                  item['owner'] = 'Owner';
                } else {
                  item['owner'] = null;
                }
              });
            });

          });

          // console.log(this.groupInfo);

        }
      });


  }

  openAddRemoveUserDialog(groupInfo): void {
    const dialogRef = this.dialogPopUp.open(DialogAddRemovePeopleComponent, {
      width: '50vw',
      height: '50vw',
      data: {
        groupInfo: groupInfo,
        userData: this.localUser,
        // channelId: this.contact.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      // this.toggleChat(this.selectedContact);
      // console.log(result);
      console.log('The dialog was closed');
    });
  }
  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.replyInput = this.replyInputField.first.nativeElement;
    this.readyToReply();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Select contact
   */
  selectContact(): void {
    this._chatService.selectContact(this.contact);
  }

  /**
   * Ready to reply
   */
  readyToReply(): void {
    setTimeout(() => {
      this.replyForm.reset();
      this.focusReplyInput();
      this.scrollToBottom();
    });

  }

  /**
   * Focus to the reply input
   */
  focusReplyInput(): void {
    setTimeout(() => {
      this.replyInput.focus();
    });
  }

  /**
   * Scroll to the bottom
   *
   * @param {number} speed
   */
  scrollToBottom(speed?: number): void {
    speed = speed || 400;
    if (this.directiveScroll) {
      this.directiveScroll.update();

      setTimeout(() => {
        this.directiveScroll.scrollToBottom(0, speed);
      });
    }
  }

  /**
   * Reply
   */
  reply(): void {
    // Message


    if (!this.replyForm.form.value.message || this.replyForm.form.value.message.length <= 1) {
      return;
    }

    const message = {
      who: this.localUser['id'],
      message: this.replyForm.form.value.message,
      time: new Date().toISOString(),
      avatar: this.localUser['avatar'],
      name: this.localUser['firstName'] + ' ' + this.localUser['lastName'],
    };
    this.dialog.push(message);

    const messageToSend = {
      channelId: this.contact['id'],
      content: {
        mentions: [],
        mentionsMeta: [],
        text: this.replyForm.form.value.message
      },
      from: {
        userId: Number(this.localUser['id']),
        firstName: this.localUser['firstName'],
        lastName: this.localUser['lastName'],
        publicProfileId: this.localUser['fileDetails_by_profileImage'] ? this.localUser['fileDetails_by_profileImage']['publicId'] : null,
      },
      createdOn: new Date(),
      type: 'TEXT'
    };

    this.db.collection('messages').add(messageToSend);

    // this.replyForm.form.value.message = ' ';
    this.readyToReply();
  }
}



@Component({
  selector: 'add-remove-people-component',
  templateUrl: 'add-remove-people-component.html',
  providers: [ImageService, RequestHelpers]
})
export class DialogAddRemovePeopleComponent {
  supportersDetails: any;

  allUsersData: any;
  flag: boolean;
  defaultImage: string;
  allUserId: Array<number>;
  userId: number;
  isAddSupporterBtnDisabled: Boolean = false;

  groupDetails: any;
  userData: any;
  constructor(private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogAddRemovePeopleComponent>,
    private imageService: ImageService,
    private _httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.groupDetails = data['groupInfo'];
    this.userData = data['userData'];
    this.flag = false;
    this.allUserId = [];
    // console.log(this.groupDetails);
    // console.log(this.userData);
    // this.groupDetails['membersMeta'] = this.groupDetails['membersMeta'].filter(item => item.userId !== this.userData['id']);
    for (let i = 0; i < this.groupDetails['membersMeta'].length; i++) {
      this.allUserId.push(this.groupDetails['membersMeta'][i]['userId']);
    }
    this.allUserId.push(this.userData['id']);
    this.getAllUsers(null);

  }

  removeSupporters(supporterDetails, index): void {

    this.groupDetails['membersMeta'] = this.groupDetails['membersMeta'].filter(item => item.userId !== supporterDetails['userId']);

    const tempAllId = [];
    // console.log(this.groupDetails);
    this.groupDetails['membersMeta'].forEach(item => {
      tempAllId.push(item['userId']);
    });
    tempAllId.push(this.userData['id']);
    const editChannel = {
      'channelId': this.groupDetails['channelId'],
      'userId': this.userData['id'],
      'newMembers': tempAllId
    };
    // console.log(tempAllId);
    this.allUserId.splice(index, 1);
    this.getAllUsers(null);
    this._httpClient
      .post(
        environment.FIREBASE_API_URL +
        environment.EDIT_CHANNEL, editChannel)
      .subscribe(
        (data: any) => {
          this.openSnackBar('Request has been sent', 'Ok');
        },
        error => {
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
        }
      );
  }
  addSupporters(user): void {

    const isSupporterAdded = this.groupDetails['membersMeta'].filter((item) => {
      return item.userId === user['id'];
    });
    const newUserId = [];
    this.groupDetails['membersMeta'].forEach(item => {
      newUserId.push(item['userId']);
    });
    newUserId.push(user['id']);
    newUserId.push(this.userData['id']);
    // console.log(newUserId);
    if (isSupporterAdded.length) {
      this.openSnackBar('Already a group member.', 'Ok');
      return;
    }
    this.flag = false;

    const editChannel = {
      'channelId': this.groupDetails['channelId'],
      'userId': this.userData['id'],
      'newMembers': newUserId
    };
    const newUser = {
      'firstName': user['firstName'],
      'lastName': user['lastName'],
      'userId': user['id'],
      'imageURL': user['fileDetails_by_profileImage'] ? this.imageService.getUserProfileImage(['fileDetails_by_profileImage']['publicId']) : null,
    };
    this.groupDetails['membersMeta'].push(newUser);
    this._httpClient
      .post(
        environment.FIREBASE_API_URL +
        environment.EDIT_CHANNEL, editChannel)
      .subscribe(
        (data: any) => {
          this.allUserId.push(user['id']);
          this.getAllUsers(null);
          this.openSnackBar('Request has been sent', 'Ok');
        },
        error => {

          this.openSnackBar('Something went wrong! Try again.', 'Ok');

        }
      );
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  getAllUsers(event): void {
    this.isAddSupporterBtnDisabled = true;
    let filter;

    if (event && event['target']['value'].length > 0) {
      const searchString = event['target']['value'];
      filter = '((firstName LIKE ' + searchString + '%25) OR ( lastName LIKE ' + searchString
        + '%25) OR ( email LIKE ' + searchString + '%25)) AND (id NOT IN (' + this.allUserId + ')) AND (orgId= ' + this.userData['orgId'] +
        ')';
    } else {
      if (this.allUserId.length > 0) {
        filter = '(id NOT IN (' + this.allUserId + ')) AND (orgId= ' + this.userData['orgId'] + ')';
      } else {
        filter = '(orgId= ' + this.userData['orgId'] + ')';
      }
    }
    const releated = 'fileDetails_by_profileImage';
    this.http
      .get(
        environment.DF_BASE_URL +
        environment.USER_TABLE + '?filter=' + filter + '&limit=10&related=' + releated, this.requestHelpers.getDFHeader())
      .subscribe(
        data => {

          this.allUsersData = data['resource'];
          for (let i = 0; i < this.allUsersData.length; i++) {
            if (this.allUsersData[i].fileDetails_by_profileImage) {
              this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(this.allUsersData[i].fileDetails_by_profileImage['publicId']);
            } else {
              this.allUsersData[i]['imageURL'] = this.imageService.getUserProfileImage(null);
            }
          }
          // console.log(data);
          this.isAddSupporterBtnDisabled = false;
        },
        error => {
          console.log(error);
          this.isAddSupporterBtnDisabled = false;
        }
      );
  }
  onNoClick(result): void {
    this.dialogRef.close();
  }

}

