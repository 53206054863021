import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { environment } from 'environments/environment';
import { MatDialog, MatSnackBar, MatStepper } from '@angular/material';
import { JourneyTasksService } from 'app/shared/journey-tasks/journey-tasks.service';
import { ImageService } from 'app/services/images.service';
import { ButtonOpts } from 'mat-progress-buttons';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { JourneyStatus } from '../../../enums/journey-status';

import { ConfirmDiscardDialogComponent } from 'app/core/my-journeys/create-journey/dialogs/confirm-discard/confirm-discard.component';
import { SelectCompDialogComponent } from 'app/core/my-journeys/create-journey/dialogs/select-competency/select-competency.component';

import * as _ from 'lodash';

@Component({
  selector: 'create-journey',
  templateUrl: './create-journey.component.html',
  styleUrls: ['./create-journey.component.scss'],
  animations: fuseAnimations
})
export class CreateJourneyComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  user: any;
  routeSubscription: any;
  selectedCompetency: any;
  selectedTemplate: any;
  userManagerMap: any;

  journeyId: string;
  defaultImage: string;

  roles: Array<any>;
  allSupporters: Array<any>;

  orgComps: Array<any> = [];
  activeComps1: Array<any> = [];
  activeComps2: Array<any> = [];
  activeComps3: Array<any> = [];
  expectedComps: Array<any> = [];
  additionalComps: Array<any> = [];
  journeyTemplates: Array<any> = [];
  selectedSupporters: Array<any> = [];

  loadingCompetencies: boolean;
  loadingTemplates: boolean;
  isLoadingSupporters: boolean;
  isLinear = true;

  step1Editable: boolean;
  step2Editable: boolean;
  step3Editable: boolean;
  step4Editable: boolean;

  userId: number;
  requiredJourneySupporters = 1;
  isAddTaskLoaded: boolean;

  createJourneyBtnOptions: ButtonOpts = {
    active: false,
    text: 'Create Journey',
    buttonColor: 'accent',
    barColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    value: 0,
    disabled: false
  };

  constructor(
    private _http: HttpClient,
    private _requestHelpers: RequestHelpers,
    private _localStorageService: LocalStorageService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _journeyTasksService: JourneyTasksService,
    private imageService: ImageService,
    private router: Router,
    private mix: MixpanelManager
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.user = this._localStorageService.get('user');
    this.allSupporters = [];

    this.getInitData();
    this.getSupporters();
    this.getJourneyRoles();
    this.getReportingManager(this.user.id);

    this._journeyTasksService.onNewTaskAdded
      .subscribe((data) => {
        this.saveNewTask(data.task, data.ignoreTask);
      });

    this._journeyTasksService.onDeleteTask
      .subscribe((task) => {
        this.deleteTask(task);
      });

    this._journeyTasksService.onUpdateTaskOrder
      .subscribe((tasks) => {
        this.updateTaskOrder(tasks);
      });

    this._journeyTasksService.onUpdateTask
      .subscribe((task) => {
        this.updateTask(task);
      });
  }

  getInitData(): void {
    this.loadingCompetencies = true;

    this._http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_GET_USER_COMP_MAP + '?createJourney=true&userId=' + this.user.id + '&orgId=' + this.user.orgId, this._requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
          if (data.userCompetencies && data.orgCompetencies) {
            data.userCompetencies.forEach(element => {
              element.compDetails.levels.forEach(level => {
                level['levelName'] = level.title;

                if (level.level < element['endLevel'] && level.level > element['startLevel']) {
                  level['levelStatus'] = 'gap';
                }
                if (level.level <= element['startLevel']) {
                  level['levelStatus'] = 'active';
                }
                if (level.level === element['endLevel']) {
                  level['levelStatus'] = 'current';
                }

              });
            });

            data.orgCompetencies.forEach(element => {
              element['selected'] = false;
            });

            data.userCompetencies.forEach(element => {
              element['selected'] = false;
            });

            this.orgComps = data.orgCompetencies;
            this.expectedComps = data.userCompetencies.filter(item => item.tag === 'expected');
            this.additionalComps = data.userCompetencies.filter(item => item.tag === 'additional');

            const activeComps = data.userCompetencies.filter(item => item.tag === 'active');

            if (activeComps.length) {
              const chunksOfActiveComps = _.chunk(activeComps, (activeComps.length / 3));
              if (chunksOfActiveComps.length === 1) {
                this.activeComps1 = chunksOfActiveComps[0];
              } else if (chunksOfActiveComps.length === 2) {
                this.activeComps1 = chunksOfActiveComps[0];
                this.activeComps2 = chunksOfActiveComps[1];
              } else if (chunksOfActiveComps.length > 2) {
                this.activeComps1 = chunksOfActiveComps[0];
                this.activeComps2 = chunksOfActiveComps[1];
                this.activeComps3 = chunksOfActiveComps[2];
              }
            }

            this.loadingCompetencies = false;
          }

        },
        (error: any) => {
          this.loadingCompetencies = false;
        }
      );
  }

  saveNewTask(task, ignoreTask): void {
    task.taskId = this.generateUid();
    task.status = JourneyStatus.not_started;

    if (!ignoreTask) {
      const tempTask = Object.assign({}, task);
      task.supporters = [];
      if (task.isFbRequested) {
        tempTask.supporters.forEach(supp => {
          task.supporters.push({ userId: supp.id, role: supp.role });
        });
      }
      this.selectedTemplate.tasks.unshift(task);
      this._journeyTasksService.onNewTaskResponse(true, this.selectedTemplate.tasks);
    }
  }

  updateTaskOrder(tasks): void {
    this.openSnackBar('Changes Saved Successfully.', 'Ok');
    this.selectedTemplate.tasks = tasks;
    this._journeyTasksService.setResultOnTaskOrderUpdated({ success: true });
  }

  updateTask(task): void {
    const taskIndex = this.selectedTemplate.tasks.findIndex(item => item.taskId === task.taskId);

    if (taskIndex !== -1) {
      this.selectedTemplate.tasks[taskIndex] = task;
    }

    this._journeyTasksService.onTaskUpdated.next({ success: true });
    this.openSnackBar('Changes Saved Successfully.', 'Ok');
  }

  deleteTask(task): void {
    const taskIndex = this.selectedTemplate.tasks.findIndex(item => item.taskId === task.taskId);

    if (taskIndex !== -1) {
      this.selectedTemplate.tasks.splice(taskIndex, 1);

      this.selectedTemplate.tasks.forEach((item, index) => {
        item.taskNo = (index + 1);
      });
    }
  }

  openSelectComDialog(comp, compIndex, type): void {
    const dialogRef = this.dialog.open(SelectCompDialogComponent, {
      width: '450px',
      data: {
        competency: comp.compDetails,
        type: type
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orgComps.forEach(item => {
          item['selected'] = false;
        });

        this.additionalComps.forEach(item => {
          item['selected'] = false;
        });

        this.expectedComps.forEach(item => {
          item['selected'] = false;
        });

        switch (type) {
          case 'organisation':
            this.orgComps[compIndex]['selected'] = !this.orgComps[compIndex]['selected'];
            this.selectedCompetency = this.orgComps[compIndex];
            break;

          case 'additional':
            this.additionalComps[compIndex]['selected'] = !this.additionalComps[compIndex]['selected'];
            this.selectedCompetency = this.additionalComps[compIndex];
            break;

          case 'expected':
            this.expectedComps[compIndex]['selected'] = !this.expectedComps[compIndex]['selected'];
            this.selectedCompetency = this.expectedComps[compIndex];
            break;

          default:
            break;
        }
      }
    });
  }

  onStepSelectionChanged($event): void {
    if ($event.selectedIndex > $event.previouslySelectedIndex) {
      this.step1Editable = false;
      this.step2Editable = false;
      this.step3Editable = false;
      this.step4Editable = false;
    }

    if ($event.selectedIndex === 1) {
      this.getTemplates();
    }

    if ($event.selectedIndex === 2 && this.userManagerMap) {
      const managerIndex = this.allSupporters.findIndex(item => item.id === this.userManagerMap['managerId']);
      if (managerIndex !== -1) {
        this.allSupporters[managerIndex].role = 'Manager';
        this.selectedSupporters.push(this.allSupporters[managerIndex]);
        this.allSupporters.splice(managerIndex, 1);
      }
    }

    if ($event.selectedIndex === 3) {
      this.isAddTaskLoaded = true;
    } else {
      this.isAddTaskLoaded = false;
    }
  }

  getTemplates(): void {
    this.loadingTemplates = true;

    this._http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_JOURNEY_TEMPLATES + '?filter=(competencyId=' +
        this.selectedCompetency.compDetails.id + ') AND (status=3)',
        this._requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {

          data.resource.forEach(element => {
            element['selected'] = false;
          });

          this.journeyTemplates = data.resource;
          this.loadingTemplates = false;

        },
        (error: any) => {
          this.loadingTemplates = false;
        }
      );
  }

  selectTemplate(templateIndex): void {
    this.journeyTemplates.forEach(element => {
      element['selected'] = false;
    });

    this.journeyTemplates[templateIndex].selected = true;
    this.selectedTemplate = this.journeyTemplates[templateIndex];
    this.selectedTemplate.tasks.forEach(element => {
      element.taskId = this.generateUid();
    });
    this.requiredJourneySupporters = this.getNumberOfRequiredJourneySupporters(this.selectedTemplate);
  }

  getJourneyRoles(): void {
    this._http
      .get(
        environment.DF_BASE_URL_HL + this.user.orgId +
        environment.DF_JOURNEY_ROLES,
        this._requestHelpers.getDFHeader())
      .subscribe(
        data => {
          this.roles = data['resource'];
        },
        error => {
          console.log(error);
        }
      );
  }

  removeSupporters(supporter, index): void {
    this.selectedSupporters.splice(index, 1);
    this.allSupporters.unshift(supporter);
  }

  addSupporters(user, idx): void {

    const isSupporterAdded = this.selectedSupporters.filter((item) => {
      return item.userId === user['id'];
    });

    if (isSupporterAdded.length) {
      this.openSnackBar('Already a Supporter.', 'Ok');
      return;
    }

    this.selectedSupporters.push(user);
    this.allSupporters.splice(idx, 1);
  }

  getSupporters(event = null): void {
    let filter;

    if (event && event['target']['value'].length > 0) {
      const searchString = event['target']['value'];
      filter = '((firstName LIKE ' + searchString + '%25) OR ( lastName LIKE ' + searchString
        + '%25) OR ( email LIKE ' + searchString + '%25)) AND (id NOT IN (' + this.allSupporters + ')) AND (orgId= ' + this.user.orgId +
        ') AND (isEnabled=true)';
    } else {
      if (this.allSupporters.length > 0) {
        filter = '(id NOT IN (' + this.allSupporters + ')) AND (orgId= ' + this.user.orgId + ') AND (isEnabled=true)';
      } else {
        filter = '(id NOT IN (' + this.user.id + ')) AND (orgId= ' + this.user.orgId + ') AND (isEnabled=true)';
      }
    }
    const releated = 'fileDetails_by_profileImage';
    this._http
      .get(
        environment.DF_BASE_URL +
        environment.USER_TABLE + '?filter=' + filter + '&limit=10&related=' + releated, this._requestHelpers.getDFHeader())
      .subscribe(
        data => {
          this.allSupporters = data['resource'];
          for (let i = 0; i < this.allSupporters.length; i++) {
            this.allSupporters[i]['userId'] = this.allSupporters[i]['id'];
            if (!this.allSupporters[i]['imageURL'] && this.allSupporters[i].fileDetails_by_profileImage) {
              this.allSupporters[i]['imageURL'] = this.imageService.getUserProfileImage(this.allSupporters[i].fileDetails_by_profileImage['publicId']);
              this.allSupporters[i]['publicId'] = this.allSupporters[i].fileDetails_by_profileImage['publicId'];
            } else {
              this.allSupporters[i]['imageURL'] = this.imageService.getUserProfileImage(null);
              this.allSupporters[i]['publicId'] = null;
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getReportingManager(userId): void {
    const filter = `?filter=((userId = ${userId}) AND (type = 1))`;
    this._http
      .get(
        environment.DF_BASE_URL_HL +
        this.user.orgId +
        environment.DF_USER_MANAGER_MAP + filter, this._requestHelpers.getDFHeader())
      .subscribe(
        (data: any) => {
          if (data.resource.length) {
            this.userManagerMap = data.resource[0];
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  createJourney(): void {

    this.createJourneyBtnOptions.active = true;
    this.createJourneyBtnOptions.text = 'Please wait..';

    this.selectedTemplate.tasks.forEach((item, index) => {
      item.taskNo = (index + 1);
    });

    this.selectedTemplate.supporters = [];
    this.selectedSupporters.forEach(supp => {
      this.selectedTemplate.supporters.push({ userId: supp.id, role: supp.role });
    });

    const postData = {
      userId: this.user.id,
      orgId: this.user.orgId,
      JourneyTemplate: this.selectedTemplate
    };


    this._http.post(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_JOURNEY,
      postData,
      this._requestHelpers.getBFFHeader())
      .subscribe(
        (result: any) => {
          let journeyId = null;
          if (result.resource && result.resource.length > 0) {
            journeyId = result.resource[0]._id;
          }
          this.mix.track(MixpanelEventType.JOURNEY_CREATED, { journeyId });
          this.openSnackBar('Journey Created Successfully.', 'Ok');
          this.createJourneyBtnOptions.active = false;
          this.createJourneyBtnOptions.text = 'Start Journey';
          this.router.navigate(['/journeys']);
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Unable to create Journey. Please try again.', 'Ok');
          this.createJourneyBtnOptions.active = false;
          this.createJourneyBtnOptions.text = 'Start Journey';
        });
  }

  openDiscardChangesDialog(stepper: MatStepper, goToStep: number): void {
    const dialogRef = this.dialog.open(ConfirmDiscardDialogComponent, {
      width: '450px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.previousTab(stepper, goToStep);
      }
    });
  }

  previousTab(stepper: MatStepper, goToStep: number): void {
    switch (goToStep) {
      case 0:
        this.step1Editable = true;
        this.selectedTemplate = null;
        this.selectedCompetency = null;

        this.selectedSupporters.forEach(item => {
          this.allSupporters.push(item);
        });

        this.selectedSupporters = [];


        this.orgComps.forEach(element => {
          element['selected'] = false;
        });

        this.expectedComps.forEach(element => {
          element['selected'] = false;
        });

        this.additionalComps.forEach(element => {
          element['selected'] = false;
        });
        break;

      case 1:
        this.step2Editable = true;
        this.selectedTemplate = null;

        this.selectedSupporters.forEach(item => {
          this.allSupporters.push(item);
        });

        this.selectedSupporters = [];
        break;

      case 2:
        this.step3Editable = true;

        this.selectedSupporters.forEach(item => {
          this.allSupporters.push(item);
        });

        this.selectedSupporters = [];
        break;

      case 3:
        this.step4Editable = true;
        break;

      default:
        break;
    }

    setTimeout(() => {
      stepper.previous();
    }, 50);
  }

  nextTab(stepper: MatStepper, goToStep: number): void {
    this.step1Editable = false;
    this.step2Editable = false;
    this.step3Editable = false;
    this.step4Editable = false;

    setTimeout(() => {
      stepper.next();
    }, 50);
  }

  generateUid(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  getNumberOfRequiredJourneySupporters(journey): number {
    const feedbackArray = [];
    journey.tasks
      .filter(task => task.taskType === 2 || task.taskType === 3)
      .forEach(task => {
        if (task.rules !== undefined) {
          task.rules.forEach(rule => {
            if (rule.selectedValue && !isNaN(rule.selectedValue)) {
              feedbackArray.push(rule.selectedValue);
            }
          });
        }
      });

    if (journey.rules !== undefined) {
      journey.rules.forEach(rule => {
        if (rule.selectedValue && !isNaN(rule.selectedValue)) {
          feedbackArray.push(rule.selectedValue);
        }
      });
    }

    let minFB = 0;
    if (feedbackArray.length === 0) {
      minFB = 0;
    } else {
      minFB = Math.max.apply(Math, feedbackArray);
    }

    return minFB;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
