import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { Competency } from '../../../models/competency.model';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(private requestHelpers: RequestHelpers, public http: HttpClient) { }


fetchUserCourses(userID): any 
{
  const url = `${environment.CALYPSO_BASE_URL}${environment.FETCH_USER_COURSES}`; 
    return this.http.get(url +
    '?userId=' + userID ,
    this.requestHelpers.getBFFHeader()
     );
}
}
