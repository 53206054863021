import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { JourneyStatus } from 'app/enums/journey-status';
import { MatSnackBar, MatDialog } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { TaskType } from 'app/enums/task-type';
import { CuratedContentPreviewComponent } from 'app/core/my-journeys/take-journey/dialogs/curated-content-preview/curated-content-preview.component';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { CuratedContentType } from 'app/enums/curated-content-type';
import { CCFeedbackComponent } from 'app/core/my-journeys/take-journey/dialogs/cc-feedback/cc-feedback.component';
import { ContentViewComponent } from 'app/shared/content-view/content-view.component';
import { JourneyCompletedComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-completed/journey-completed.component';

@Component({
  selector: 'curated-content-task-component',
  templateUrl: './curated-content-task.component.html',
  styleUrls: ['./curated-content-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CuratedContentTaskComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() journey: JourneyModel;
  @Input() task: TaskModel;
  @Input() showKCTab: Boolean;
  @Input() selectedTaskId: String;

  animationDirection: 'left' | 'right' | 'none';
  animationDirectionFeedback: 'left' | 'right' | 'none';
  isMarkingIamDone: Boolean = false;
  isKnowledgeCheck: Boolean = false;
  isLoadingCC: Boolean = false;

  taskType = TaskType;
  journeyStatus = JourneyStatus;
  imagePDFViewer: any;
  user: any;
  journeyAccessRoles = JourneyAccessRoles;
  curatedContentType = CuratedContentType;
  curatedContent: any;
  contentBase64: any;
  pdfBase64: any;
  page = 1;
  totalPages: number;
  isLoaded = false;

  constructor(
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private mix: MixpanelManager,
    private newTakeJourneyService: TakeJourneyService
  ) {
    this.animationDirection = 'none';
    this.animationDirectionFeedback = 'none';
  }

  ngOnInit(): void {
    if (this.showKCTab && this.selectedTaskId === this.task.taskId) {
      this.showKCTab = true;
    } else {
      this.showKCTab = false;
    }

    this.user = this.localStorageService.get('user');
    console.log(this.journey.role);
    this.getCCDetails();
  }

  ngAfterViewInit(): void {}
  getCCDetails(): void {
    this.isLoadingCC = true;
    this.newTakeJourneyService
      .getCCDetails(this.task.taskContentId, this.user.orgId)
      .subscribe(
        (data: any) => {
          if (data.resource.length) {
            this.curatedContent = data.resource[0];
            console.log(this.curatedContent);
            this.isLoadingCC = false;
          } else {
            this.isLoadingCC = false;
            this.openSnackBar('Something went wrong! Try again.', 'Ok');
          }
        },
        (error: any) => {
          this.isLoadingCC = false;
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
        }
      );
  }

  viewCC(): void {
    // If task is not started, then call the startTask service
    if (this.task.status === this.journeyStatus.not_started) {
      this.startTask(this.journey._id, this.task.taskId);
    }
    this.trackCCViews(this.task.taskContentId, this.task.taskId);
    this.mix.track(MixpanelEventType.CONTENT_VIEWED, {
      taskContentId: this.task.taskContentId,
      type: this.curatedContent['contentType']
    });
  }

  loadCCContent(curatedContent: any): void {
    this.dialog.open(ContentViewComponent, {
      panelClass: 'content-viewer',
      width: '50vw',
      data: {
        contentType: this.task.contentType,
        title: curatedContent.title,
        contentURL: curatedContent.link,
        contentId: curatedContent._id,
        fromJourney: true
      }
    });
    this.isLoadingCC = false;
    return;
  }

  openPreviewCCDialog(): void {
    const dialogRef = this.dialog.open(CuratedContentPreviewComponent, {
      width: '50vw',
      height: '50vw',
      data: {
        imageData: this.imagePDFViewer
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  trackCCViews(contentId, taskId): void {
    this.newTakeJourneyService
      .trackCCViews(contentId, taskId, this.journey._id)
      .subscribe(
        (result: any) => {},
        (error: any) => {}
      );
  }

  iAmDone(): void {
    this.isMarkingIamDone = true;
    if (this.task.isKcPresent && this.task['isKcRequired']) {
      this.newTakeJourneyService
        .updateTaskStatus(this.journey._id, this.task.taskId, true)
        .subscribe(
          (data: any) => {
            this.showKCTab = true;
            this.isMarkingIamDone = false;
            this.task.pendingKCheck = true;

            // update journey everywhere
            const journey = { ...this.journey };
            journey.tasks.forEach(task => {
              if (task.taskId === this.task.taskId) {
                task.pendingKCheck = true;
              }
            });
            this.newTakeJourneyService.updateOnJourneyChanged(journey);
          },
          (error: any) => {
            console.log(error);
            this.isMarkingIamDone = false;
          }
        );
    } else {
      this.completeTask(this.task.taskId, this.journey._id);
    }
  }

  startTask(journeyId, taskId): void {
    this.newTakeJourneyService.startTask(journeyId, taskId).subscribe(
      (data: any) => {
        const taskIndex = this.journey.tasks.findIndex(
          task => task.taskId === this.task.taskId
        );
        if (taskIndex !== -1) {
          this.journey.tasks[taskIndex].status = this.journeyStatus.ongoing;
          this.newTakeJourneyService.updateOnJourneyChanged(this.journey);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  openJourneyCompletedDialog(): void {
    const dialogRef = this.dialog.open(JourneyCompletedComponent, {
      width: '30vw',
      data: {}
    });
  }

  completeTask(taskId: string, journeyId: string): void {
    this.newTakeJourneyService.completeTask(taskId, journeyId).subscribe(
      (data: any) => {
        this.mix.track(MixpanelEventType.TASK_COMPLETED, { taskId });
        this.openSnackBar(data['message'], 'Ok');
        if (data['success']) {
          const index = this.journey.tasks.findIndex(
            item => item.taskId === taskId
          );

          if (index !== -1) {
            this.journey.tasks[index].status = JourneyStatus.completed;
          }

          const { journeyCompleted } = data;
          this.openCCFeedbackDialog(journeyCompleted);

          this.journey.ongoingTask = this.journey.tasks[index].taskNo + 1;
          // this.journey.selectedTask++;
          this.newTakeJourneyService.onJourneyChanged.next(this.journey);
          this.showKCTab = false;
        }
        this.isMarkingIamDone = false;
      },
      (error: any) => {
        console.log(error);
        this.isMarkingIamDone = false;
        this.openSnackBar('Something went wrong! Try again.', 'Ok');
      }
    );
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  ngOnDestroy(): void {
    this.showKCTab = false;
  }

  goToNext(): void {
    const journey = { ...this.journey };
    const taskId = this.task.taskId;
    journey.tasks.forEach(task => {
      if (task.taskId === taskId) {
        task.status = JourneyStatus.completed;
      }
    });
    journey.ongoingTask++;
    this.isKnowledgeCheck = false;
    this.showKCTab = false;
    this.newTakeJourneyService.updateOnJourneyChanged(journey);
  }

  openCCFeedbackDialog(journeyCompleted = false): void {
    const dialogRef = this.dialog.open(CCFeedbackComponent, {
      width: '50vw',
      data: {
        journey: this.journey,
        task: this.task
      }
    });

    const sub = dialogRef.componentInstance.feedbackRegistered.subscribe(
      data => {
        if (data.status) {
          const journey = { ...this.journey };
          journey.tasks.forEach(task => {
            if (task.taskId === this.task.taskId) {
              task.isContentLiked = data.result;
            }
          });
          this.newTakeJourneyService.updateJourneySubject(this.journey);
        }
      },
      err => {
        console.log('Something went wrong', err);
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
      if (journeyCompleted) {
        this.openJourneyCompletedDialog();
      }
    });
  }

  goBackToTask(): void {
    this.showKCTab = false;
  }
}
