import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input,
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { JourneyModel } from 'app/models/journey-model';
import { JourneyStatus } from 'app/enums/journey-status';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ManageSupporterComponent } from 'app/core/my-journeys/take-journey/dialogs/manage-supporter/manage-supporter.component';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'start-task-component',
  templateUrl: './start-task.component.html',
  styleUrls: ['./start-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class StartTaskComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() journey: JourneyModel;
  private _unsubscribeAll: Subject<any>;

  journeyStatus = JourneyStatus;
  isStartingJourney: Boolean = false;
  journeyDueDate: Date = new Date();
  journeyAccessRoles = JourneyAccessRoles;
  filteredSupporters: Array<any> = [];
  user: any;

  constructor(
    private snackBar: MatSnackBar,
    private newTakeJourneyService: TakeJourneyService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    console.log(this.journey);
    this.user = this.localStorageService.get('user');
    this.filteredSupporters = this.journey.supporterDetails.filter(supp => supp.active);
    this._unsubscribeAll = new Subject();
  }

  ngAfterViewInit(): void {
  }

  startJourney(): void {
    console.log(this.journey);

    if (!this.journey.supporterDetails.length) {
      this.openSnackBar('Please add supporters before starting the Journey', 'Ok');
      return;
    }

    this.isStartingJourney = true;

    this.newTakeJourneyService.startJourney(this.journey._id, this.journeyDueDate)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (data: any) => {

          if (data.success) {
            this.journey.dueDate = this.journeyDueDate;
            this.journey.status = JourneyStatus.ongoing;
            this.journey.ongoingTask = 1;
            this.journey.selectedTask = 1;
            this.newTakeJourneyService.onJourneyChanged.next(this.journey);
          }

          this.isStartingJourney = false;
          this.openSnackBar(data.message, 'Ok');
        },
        error => {
          console.log(error);
          this.isStartingJourney = false;
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
        }
      );
  }

  openManageSupportersDialog(): void {
    const dialogRef = this.dialog.open(ManageSupporterComponent, {
      width: '50vw',
      data: {
        details: this.journey,
        orgId: this.user.orgId,
        userId: this.user.id
      }
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe((journey) => {
      this.journey = journey;
      // call update journey subject
      this.newTakeJourneyService.updateJourneySubject(this.journey);
    });

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
