import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JourneyListModule } from 'app/shared/journey-list/journey-list.module';
import { CorePipesModule } from 'app/pipes/pipes.module';
import {
  MatButtonModule,
  MatDividerModule,
  MatIconModule,
  MatTabsModule,
  MatCardModule,
  MatExpansionModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatChipsModule
} from '@angular/material';
import { UserProfileService } from 'app/core/user-profile/user-profile.service';
import { FuseSharedModule } from '@fuse/shared.module';
import { CompetencyListModule } from 'app/shared/competency-list/competency-list.module';


const routes = [
  {
    path: 'user-profile/:id',
    component: UserProfileComponent
  }
];

@NgModule({
  declarations: [
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    BrowserAnimationsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDividerModule,
    CorePipesModule,
    FuseSharedModule,
    JourneyListModule,
    CompetencyListModule
  ],
  providers: [
    UserProfileService
  ],
  exports: [
    UserProfileComponent
  ]
})


export class UserProfileModule {
}
