import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { TakeJourneyComponent } from './take-journey.component';
import { CuratedContentPreviewComponent } from './dialogs/curated-content-preview/curated-content-preview.component';
import { ManageSupporterComponent } from './dialogs/manage-supporter/manage-supporter.component';
import { JourneyDetailsDialogComponent } from './dialogs/journey-details-dialog/journey-details-dialog.component';
import { FeedbackRequestComponent } from './dialogs/feedback-request/feedback-request.component';
import { CCFeedbackComponent } from './dialogs/cc-feedback/cc-feedback.component';
import { EditJourneyDetailsComponent } from './dialogs/edit-journey-details/edit-journey-details.component';
import { DeleteJourneyComponent } from './dialogs/delete-journey/delete-journey.component';
import { ResendFeedbackRequestComponent } from './dialogs/resend-feedback-request/resend-feedback-request.component';
import { GiveFeedbackComponent } from './dialogs/give-feedback/give-feedback.component';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { NgxPrintModule } from 'ngx-print';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';
import { FileUploadModule } from 'ng2-file-upload';
import {JourneyTaskMenuComponent} from '../../../shared/journey-task-menu/journey-task-menu.component';
import { ConversationsComponent } from 'app/core/my-journeys/take-journey/tabs/conversations/conversations.component';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatRadioModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatRippleModule
} from '@angular/material';

import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdePopoverModule } from '@material-extended/mde';
import { FuseSidebarModule, FuseCountdownModule } from '@fuse/components';
import { ImageViewerModule } from 'ng2-image-viewer';
import { MatProgressButtons } from 'mat-progress-buttons';
import { AssessmentModule } from 'app/shared/assessment/assessment.module';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';
import { ChatModule } from 'app/shared/chat/chat.module';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { FeedbackDetailModule } from 'app/shared/feedback-detail/feedback-detail.module';
import { JourneyReportModule } from 'app/shared/journey-report/journey-report.module';

import { JourneyDetailsComponent } from 'app/core/my-journeys/take-journey/journey-details/journey-details.component';
import { TasksComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/tasks.component';
import { FeedbacksComponent } from 'app/core/my-journeys/take-journey/tabs/feedbacks/feedbacks.component';
import {AttachmentViewComponent} from 'app/shared/common-components/attachment-view/attachment-view.component';

// Task types component
import { StartTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/start-task/start-task.component';
import { EndTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/end-task/end-task.component';
import { CuratedContentTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/curated-content-task/curated-content-task.component';
import { AssessmentTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/assessment-task/assessment-task.component';
import { FeedbackRequestTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/feedback-request-task/feedback-request-task.component';
import { OfflineTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/offline-task/offline-task.component';
import { UDTTaskComponent } from 'app/core/my-journeys/take-journey/tabs/tasks/task-types/udt-task/udt-task.component';
import { OpenFeedbackComponent } from './open-feedback/open-feedback.component';
import { ContentViewComponent } from 'app/shared/content-view/content-view.component';
import { ConfirmStartAssessmentComponent } from 'app/core/my-journeys/take-journey/dialogs/confirm-start-assessment/confirm-start-assessment.component';
import { JourneyReportDialogComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-report/journey-report.component';
import { GiveFeedbackModule } from 'app/shared/give-feedback/give-feedback.module';
import { InstructionsComponent } from 'app/core/my-journeys/take-journey/dialogs/instructions/instructions.component';
import { InformationTaskComponent } from './tabs/tasks/task-types/information-task/information-task.component';
import { JourneyCompletedComponent } from './dialogs/journey-completed/journey-completed.component';
import { OfflineUploadContentComponent } from './dialogs/offline-upload-content/offline-upload-content.component';
import { UploadContentsComponent } from './tabs/tasks/task-types/upload-contents/upload-contents.component';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';

const routes = [
  {
    path: 'journeys-take/:id',
    component: TakeJourneyComponent,
    name: 'Journey'
  },
  {
    path: 'journeys-take-task/:id/:taskId',
    component: TakeJourneyComponent,
    name: 'TaskJourney'
  },
  {
    path: 'give-feedback/:id',
    component: OpenFeedbackComponent
  }
];

@NgModule({
  declarations: [
    TakeJourneyComponent,
    CuratedContentPreviewComponent,
    FeedbackRequestComponent,
    CCFeedbackComponent,
    ManageSupporterComponent,
    JourneyDetailsDialogComponent,
    EditJourneyDetailsComponent,
    JourneyDetailsComponent,
    TasksComponent,
    StartTaskComponent,
    EndTaskComponent,
    CuratedContentTaskComponent,
    AssessmentTaskComponent,
    FeedbackRequestTaskComponent,
    OfflineTaskComponent,
    UDTTaskComponent,
    FeedbacksComponent,
    ConversationsComponent,
    DeleteJourneyComponent,
    ResendFeedbackRequestComponent,
    GiveFeedbackComponent,
    OpenFeedbackComponent,
    ContentViewComponent,
    ConfirmStartAssessmentComponent,
    JourneyReportDialogComponent,
    InstructionsComponent,
    InformationTaskComponent,
    JourneyCompletedComponent,
    OfflineUploadContentComponent,
    UploadContentsComponent,
      AttachmentViewComponent,
      JourneyTaskMenuComponent
  ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        ChartsModule,
        NgxChartsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatInputModule,
        MatTableModule,
        MatGridListModule,
        CdkTableModule,
        NgxContentLoadingModule,
        MatSortModule,
        FuseWidgetModule,
        BrowserAnimationsModule,
        MatBadgeModule,
        MatListModule,
        MatChipsModule,
        MdePopoverModule,
        MatCardModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSnackBarModule,
        MatDatepickerModule,
        FuseSidebarModule,
        FuseCountdownModule,
        ImageViewerModule,
        MatProgressSpinnerModule,
        MatProgressButtons,
        MatRadioModule,
        AssessmentModule,
        SharedDirectivesModule,
        LevelIndicatorModule,
        MatProgressBarModule,
        CorePipesModule,
        MatExpansionModule,
        MatRippleModule,
        ChatModule,
        FeedbackDetailModule,
        SvgIconModule,
        JourneyReportModule,
        // Videogular modules
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        GiveFeedbackModule,
        NgxPrintModule,
        CommonComponentsModule,
        FileUploadModule,
        PdfJsViewerModule
    ],
  exports: [
    TakeJourneyComponent,
  ],
  entryComponents: [
    CuratedContentPreviewComponent,
    FeedbackRequestComponent,
    CCFeedbackComponent,
    ManageSupporterComponent,
    JourneyDetailsDialogComponent,
    DeleteJourneyComponent,
    EditJourneyDetailsComponent,
    JourneyDetailsComponent,
    TasksComponent,
    StartTaskComponent,
    EndTaskComponent,
    CuratedContentTaskComponent,
    AssessmentTaskComponent,
    FeedbackRequestTaskComponent,
    OfflineTaskComponent,
    UDTTaskComponent,
    FeedbacksComponent,
    ConversationsComponent,
    ResendFeedbackRequestComponent,
    GiveFeedbackComponent,
    ContentViewComponent,
    ConfirmStartAssessmentComponent,
    JourneyReportDialogComponent,
    InstructionsComponent,
    JourneyCompletedComponent,
    OfflineUploadContentComponent,
      AttachmentViewComponent
  ],
  providers: [
    TakeJourneyService
  ]
})

export class TakeJourneyModule {
}
