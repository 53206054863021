import { Component } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

const regNum = new RegExp('[0-9]');
const regUpperCase = new RegExp('[A-Z]');
const regLowerCase = new RegExp('[a-z]');
const regSpecialChar = new RegExp('[!@#$%\^&*)(+=._-]');
const snackConfig = {
  duration: 5000
};

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  passwordForm: FormGroup;
  loading = false;
  hasNum = false;
  hasUpper = false;
  hasLower = false;
  hasSpec = false;

  constructor(
    private auth: AuthService,
    private _fuseConfigService: FuseConfigService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.passwordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        this.ValidatePasswordPattern.bind(this)
      ]],
      confirmPassword: ['', Validators.required]
    }, {
        validator: this.MustMatch('password', 'confirmPassword')
      });
  }

  validatePassword(password): { validation: boolean, message: string } {
    this.hasNum = regNum.test(password);
    this.hasUpper = regUpperCase.test(password);
    this.hasLower = regLowerCase.test(password);
    this.hasSpec = regSpecialChar.test(password);

    const count = <number><any>this.hasNum +
      <number><any>this.hasUpper +
      <number><any>this.hasLower +
      <number><any>this.hasSpec;

    const validation = count >= 3;
    let message = '';
    if (!validation) {
      message = 'Password is too simple';
    }
    return { validation, message };
  }

  ValidatePasswordPattern(control: AbstractControl): any {
    const v = this.validatePassword(control.value);
    return v.validation ? null : { nomatch: true };
  }

  private MustMatch(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onSubmit(): void {
    const validation = this.validatePassword(this.passwordForm.value.password);
    if (!validation.validation) {
      this.snackBar.open(validation.message, '', snackConfig);
      return;
    }
    const password = this.passwordForm.value.password;
    this.loading = true;
    this.auth.changePassword(password).subscribe(
      res => {
        this.loading = false;
        if (res.status) {
          const user = <User>this.localStorageService.get('user');
          if (user.onboardingStatus !== 2) {
            this.router.navigate(['/onboarding']);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          console.error('Error: ', res.message);
          this.snackBar.open(res.message, '', snackConfig);
        }
      },
      err => {
        console.error('Error while changing the password: ', err);
        this.loading = false;
        this.snackBar.open('Something went wrong', '', snackConfig);
      });
  }

}
