
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class UserProfileService implements Resolve<any> {

  onMyTeamClicked: Subject<any>;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
  ) {
    this.onMyTeamClicked = new Subject();

  }

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getCompetencies(userId, filter): Observable<any> {
    return this.http.get(
      environment.GENESIS_SERVICE_URL +
      environment.GET_COMPETENCY_LIST + `?userId=${userId}&${filter}`,
      this.requestHelpers.getBFFHeader()
    );

  }

  competencyJourniesData(competencyId, userId, orgId): Observable<any> {
    return this.http.get(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_JOURNEY +
      '?filter=((competencyId=' + competencyId +
      ')AND(userId=' + userId + '))AND((status=1)OR(status=3)OR(status=2)OR(status=7)OR((status=6) AND (newJourneyCreated IS NULL)))&orgId=' + orgId +
      '&appendUserDetails=1&appendCompDetails=1&appendLevelDetails=1',
      this.requestHelpers.getBFFHeader()
    );

  }

  getUserCompetencies(userId, orgId): Observable<any> {
    return this.http.get(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_GET_USER_COMP_MAP +
      '?createJourney=true&userId=' + userId +
      '&orgId=' + orgId,
      this.requestHelpers.getBFFHeader()
    );
  }


  getDfJourney(orgId, userId): Observable<any> {
    return this.http.get(
      environment.DF_MONGO_BASE_URL_HL +
      orgId +
      environment.DF_JOURNEY + `?filter=((userId=${userId})AND((status IN (1,2,3,4,7))OR((status = 6) AND (newJourneyCreated IS NULL))))&count_only=true`,
      this.requestHelpers.getDFHeader()
    );
  }

  getDfFeedback(orgId, userId): Observable<any> {
    return this.http.get(
      environment.DF_MONGO_BASE_URL_HL +
      orgId +
      environment.DF_FEEDBACK + `?filter=((from=${userId})OR(to IN (${userId})))&count_only=true`,
      this.requestHelpers.getDFHeader()
    );
  }

  getUserInfo(userId, orgId): Observable<any> {
    return this.http.get(
      environment.GENESIS_SERVICE_URL +
      environment.GET_USER_PROFILE_INFO + `?userId=${userId}&orgId=${orgId}`,
      this.requestHelpers.getBFFHeader()
    );
  }

  fetchLeaderboardScore(postData): Observable<any> {
    return this.http.post(
      environment.CALYPSO_BASE_URL +
      environment.CALYPSO_GET_USER_ACTIVITY_SCORE,
      postData,
      this.requestHelpers.getBFFHeader()
    )
  }

  getJourneyListSupported(offset, limit, orgId, filter): Observable<any> {
    return this.http.get(
      environment.GENESIS_SERVICE_URL +
      environment.GET_JOURNEY_LIST_SUPPORTED +
      '?offset=' + offset +
      '&limit=' + limit +
      '&orgId=' + orgId +
      '&filter=' + filter +
      '&order=null',
      this.requestHelpers.getBFFHeader()
    )
  }

}
