import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../../../../services/subjects.service';
import { RequestHelpers } from '../../../../services/request-helpers.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-store';
import { User } from '../../../../models/user';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
    selector: 'leaderboard',
    templateUrl: './leaderboard.component.html',
    styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
    @Input() teamFilter: any;
    user: User;
    leaderBoardData: any[] = [];

    subscription: Subscription;
    selectedTeam: any;
    loadingdata = false;
    usercount = 0;
    pageSize = 50;

    constructor(
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private requestHelpers: RequestHelpers,
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.user = <User>this.localStorageService.get('user');
        // this.competencyBarData = true;

        this.subscription = this.messageService.getTeamFilter().subscribe(message => {
            console.log(message.selectedTeam);
            this.selectedTeam = message.selectedTeam;
            this.getLeaderboard({ pageIndex: 0 });
        });

        if (this.teamFilter.length > 0) {
            this.selectedTeam = this.teamFilter;
            this.getLeaderboard({ pageIndex: 0 });
        }
    }

    leaderboardTrackBy = (idx, item) => {
        return item.userId
    }

    getLeaderboard = ($event: any): void => {
        let teamFilter = '';
        const teamIds = [];
        if (this.selectedTeam.length > 0) {
            this.selectedTeam.forEach(item => {
                teamIds.push(item.id);
            });
            console.log(teamIds.toString());
            teamFilter = '&teamIds=' + teamIds.toString();
        }
        const urlfilter = `?managerId=${this.user.id}&orgId=${this.user.orgId}&limit=${this.pageSize}&offset=${$event.pageIndex}${teamFilter}`;
        this.loadingdata = true;

        this.http
            .get(
                environment.CALYPSO_BASE_URL +
                environment.LEADERBOARD +
                urlfilter, this.requestHelpers.getBFFHeader())
            .subscribe(
                (data: any) => {
                    this.usercount = data.meta.count;
                    this.leaderBoardData = data.resource;
                    console.log(data);
                    this.loadingdata = false;
                },
                (error: any) => {
                    console.log(error);
                    // this.competencyBarData = false;
                    this.loadingdata = false;
                    this.requestHelpers.catchExceptions(error);
                }
            );
    }

}
