import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { User } from 'app/models/user';
import { Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MessageService } from 'app/services/subjects.service';
import { JourneyStatus } from 'app/enums/journey-status';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'journey-stats-component',
  templateUrl: './journey-stats.component.html',
  styleUrls: ['./journey-stats.component.scss'],
  animations: fuseAnimations
})
export class JourneyStatsComponent implements OnInit, OnDestroy {
  @Input() selectedTeams: any[];
  user: User;
  userJourneyStats: Object = {
    completedJourneys: 0,
    inconclusiveJourneys: 0,
    notStartedJourneys: 0,
    ongoingJourneys: 0,
    pastDueJourneys: 0
  };

  isLoadingData: any;
  isLoadingFilteredComps: any;
  subscription: Subscription;
  selectedOverviewTab: JourneyStatus = JourneyStatus.past_due;
  filteredJourneys: Array<any> = [];
  dataLength = 0;
  pageSize = 5;
  pageEvent: Object;
  journeyStatus = JourneyStatus;
  @ViewChild(MatPaginator) paginator: MatPaginator;
 public isNotStarted: boolean ;

  constructor(
    private localStorageService: LocalStorageService,
    private _dashboardService: DashboardService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.isLoadingFilteredComps = true;

    this.user = <User>this.localStorageService.get('user');
    this.subscription = this._messageService.getTeamFilter().subscribe(message => {
      this.selectedTeams = message.selectedTeam;
      this.getJourneyStats();
      this.getJourneyStatsList({ pageIndex: 0 });
    });

    if (this.selectedTeams && this.selectedTeams.length) {
      this.getJourneyStats();
      this.getJourneyStatsList({ pageIndex: 0 });
    }
  }

  getJourneyStats(): void {
    this.isLoadingData = true;

    this._dashboardService.getJourneyStats(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id))
      .subscribe(
        (result: any) => {
          if (result.resource && result.resource.length) {
            this.userJourneyStats = result.resource[0];
          }
          this.isLoadingData = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingData = false;
        });
  }

  getJourneyStatsList($event): void {
    this.isLoadingFilteredComps = true;

    this._dashboardService.getJourneyStatsList(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id), this.pageSize, $event.pageIndex, this.selectedOverviewTab)
      .subscribe(
        (result: any) => {

          this.filteredJourneys = result.resource;
          this.dataLength = result.meta.count;
          this.isLoadingFilteredComps = false;

        },
        (error: any) => {
          console.log(error);
          this.isLoadingFilteredComps = false;
        });
  }

  onOverviewSelected(status: JourneyStatus): void {
    
    this.isNotStarted = status == JourneyStatus.not_started;
    this.selectedOverviewTab = status;
    this.paginator.pageIndex = 0;
    this.getJourneyStatsList({ pageIndex: 0 });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
