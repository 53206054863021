import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRippleModule,
  MatSelectModule,
  MatDialogModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatTableModule,
  MatStepperModule,
  MatChipsModule
} from '@angular/material';

import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { CreateJourneyComponent } from 'app/core/my-journeys/create-journey/create-journey.component';
import { MatProgressButtons } from 'mat-progress-buttons';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';
import { ConfirmDiscardDialogComponent } from 'app/core/my-journeys/create-journey/dialogs/confirm-discard/confirm-discard.component';
import { SelectCompDialogComponent } from 'app/core/my-journeys/create-journey/dialogs/select-competency/select-competency.component';
import { JourneyTasksModule } from 'app/shared/journey-tasks/journey-tasks.module';

const routes: Routes = [
  // {
  //   path: 'journeys/create',
  //   component: CreateJourneyComponent
  // }
];

@NgModule({
  declarations: [
    CreateJourneyComponent,
    SelectCompDialogComponent,
    ConfirmDiscardDialogComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    MatDialogModule,
    NgxDnDModule,
    FuseSharedModule,
    FuseSidebarModule,
    MatProgressBarModule,
    MatProgressButtons,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTableModule,
    CorePipesModule,
    MatStepperModule,
    MatChipsModule,

    LevelIndicatorModule,
    JourneyTasksModule
  ],
  exports: [
    MatStepperModule
  ],
  entryComponents: [
    SelectCompDialogComponent,
    ConfirmDiscardDialogComponent
  ]
})
export class CreateJourneyModule {
}
