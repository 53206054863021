import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { NotificationsComponent } from './notifications.component';
import { MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NotificationsComponent
  ],
  imports: [
    SharedDirectivesModule,
    CommonModule,
    MatIconModule,
    RouterModule
  ],
  exports: [
    NotificationsComponent
  ]
})
export class NotificationsModule {
}
