import {Component, ViewEncapsulation, OnInit, ViewChild, ElementRef, Input, SimpleChanges, SimpleChange, OnDestroy} from '@angular/core';
import { ImageService } from '../../../services/images.service';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { trigger, transition, style, animate } from '@angular/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AngularFireDatabase } from 'angularfire2/database';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ImageService, RequestHelpers],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateY(10px)' }),
        animate('.5s ease-out', style({ opacity: '1', transform: 'translateY(0px)' })),
      ]),
    ]),
    // trigger('popUp', [
    //   transition(':enter', [
    //     style({ transform: 'translateY(50px)' }),
    //     animate('.5s ease-out', style({ transform: 'translateY(0px)' })),
    //   ]),
    //   transition(':leave', [
    //     style({ transform: 'translateY(0px)' }),
    //     animate('.5s ease-out', style({ transform: 'translateY(50px)' })),
    //   ]),
    // ])
  ]
})
export class ChatPanelComponent implements OnInit, OnDestroy {

  user: User;
  messages: any[];
  typedMessage = '';
  private channelId = '';
  private msgCount = 40;
  thinking: boolean;
  cavisImage = environment.IMAGES.CAVIS;
  moreMessages = false;
  lastMessageOption = false;
  sidebarFolded: boolean;
  private _unsubscribeAll: Subject<any>;

  @ViewChild('inputBox') inputBox: ElementRef;

  constructor(
    private db: AngularFirestore,
    private rtdb: AngularFireDatabase,
    private localStorageService: LocalStorageService,
    private imageService: ImageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private _fuseSidebarService: FuseSidebarService
  ) {
    this.sidebarFolded = false;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._fuseSidebarService.getSidebar('chatPanel').foldedChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((folded) => {
          console.log(folded);
          this.sidebarFolded = folded;
        });
    this.user = <User>this.localStorageService.get('user');
    this.fetchCavisChannel();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private fetchCavisChannel(): void {
    console.log('Called this function');
    const channelRef = this.db.collection('channels', ref => ref
      .where('members', 'array-contains', this.user.id)
      .where('type', '==', 'DM_BOT')
      .orderBy('lastMessageOn', 'desc'))
      .get();
    channelRef.subscribe(snap => {
      const channels = [];
      snap.forEach(item => {
        const key = item.id;
        const data = item.data();
        const chanObj = {
          channelId: key,
          ...data
        };
        channels.push(chanObj);
      });
      const channelId = channels[0].channelId;
      this.channelId = channelId;
      this.fetchChats();
    });
  }

  private fetchChats(): void {
    const messagesRef = this.db.collection('messages', ref => ref
      .where('channelId', '==', this.channelId)
      .orderBy('createdOn', 'desc')
      .limit(this.msgCount))
      .snapshotChanges();
    messagesRef.subscribe(snap => {
      const messages = [];
      snap.forEach(item => {
        const msg = item.payload.doc.data();
        const messageObj = {
          'who': msg['from'] && msg['from']['userId'] ? msg['from']['userId'] : null,
          'message': msg['content']['text'],
          'time': msg['createdOn'].toDate(),
          'name': msg['from'] ? msg['from']['firstName'] + ' ' + msg['from']['lastName'] : null,
          'avatar': this.imageService.getUserProfileImage(msg['from']['publicProfileId']),
          'type': msg['type'],
          'options': (msg['type'] === 'OPTIONS' || msg['type'] === 'REVIEW') && msg['content'] ? msg['content'] : null,
          'intentName': (msg['type'] === 'REVIEW') && msg['content'] && msg['content']['intentName'] ? msg['content']['intentName'] : null,
          'selectedValue': (msg['type'] === 'REVIEW' && msg['selectedValue']) ? msg['selectedValue'] : null,
          'messageId': item.payload.doc.id,
          'selected': msg['selected'] ? msg['selected'] : null,
          'imageUrl': msg['content'] ? msg['content']['url'] : null
        };
        messages.unshift(messageObj);
        this.messages = messages;
        if (messages.length) {
          const lastMessage = messages[messages.length - 1];
          this.lastMessageOption = lastMessage && lastMessage.type && lastMessage.type === 'OPTIONS';
        } else {
          this.lastMessageOption = false;
        }
        // TODO: Figure out the count after new data return. Maybe do a get count call
        setTimeout(() => {
          this.moreMessages = messages.length === this.msgCount;
        }, 2000);
      });
    });
  }

  askCavis(message: string): void {
    this.thinking = true;
    this.http.post(
      environment.GENOS_BASE_URL + environment.genosEndpoints.MESSAGE_CAVIS + `?channelId=${this.channelId}`,
      { text: message, userName: this.user.firstName },
      this.requestHelpers.getBFFHeader()
    ).subscribe(res => {
      this.thinking = false;
    }, err => {
      this.thinking = false;
    });
  }

  async sendMessage(typedMessage: string): Promise<void> {
    if (typedMessage === '') {
      return;
    }
    this.typedMessage = '';
    const messageToSend = {
      channelId: this.channelId,
      content: {
        mentions: [10000],
        mentionsMeta: [],
        text: typedMessage,
      },
      from: {
        userId: Number(this.user.id),
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        profileImageId: this.user['fileDetails_by_profileImage'] ? this.user['fileDetails_by_profileImage']['publicId'] : null,
      },
      createdOn: new Date(),
      type: 'TEXT'
    };
    this.inputBox.nativeElement.focus();
    await this.db.collection('messages').add(messageToSend);
    this.askCavis(typedMessage);
  }

  async selectOption(selectedOption, messageId): Promise<void> {
    this.db.collection('messages').doc(messageId)
      .set({ selected: selectedOption }, { merge: true })
      .then(data => console.log('update successful: ', data))
      .catch(err => console.error(err));
    const messageToSend = {
      channelId: this.channelId,
      content: {
        mentions: [10000],
        mentionsMeta: [],
        text: selectedOption
      },
      from: {
        userId: Number(this.user.id),
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        profileImageId: this.user['fileDetails_by_profileImage'] ? this.user['fileDetails_by_profileImage']['publicId'] : null,
      },
      createdOn: new Date(),
      type: 'TEXT'
    };
    await this.db.collection('messages').add(messageToSend);
    this.askCavis(selectedOption);
    this.inputBox.nativeElement.focus();
  }

  markAsRead(): void {
    if (this.channelId) {
      const deleteResponse = this.rtdb.object('notifications/' + this.user.id + '/channels/' + this.channelId + '/count').remove();
      deleteResponse.then(res => {
      });
    }
  }

  showMore(): void {
    this.msgCount += 20;
    this.fetchChats();
  }

  messageTrackBy(idx, item): string {
    return item.messageId;
  }


  unfoldSidebarTemporarily(): void {
    this.markAsRead();
    this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
  }

  foldSidebarTemporarily(): void {
    this.markAsRead();
    this.sidebarFolded = false;
    this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
  }

  toggleSidebarOpen(): void {
    this.markAsRead();
    this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
  }

}
