import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { LocalStorageService } from 'ngx-store';
import { environment } from 'environments/environment';
import { FileUploaderOptions } from 'ng2-file-upload';

@Injectable()
export class RequestHelpers {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
  }

  getHyperloopMongoUrl(url: string, orgId: number): string {
    return '/cavanticsmongodb/_table/hyperloop.orgid_' + orgId + url;
  }

  getHyperloopMysqlUrl(url: string, orgId: number): string {
    return '/booststaging/_table/hyperloop_' + orgId + url;
  }

  public getInfoFromAuthToken(token): any {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post(environment.BOOST_SERVICE_URL + environment.GET_INFO_FROM_AUTH_TOKEN, options);
  }

  public getBFFHeader(): any {
    const dfSessionToken = <string>this.localStorageService.get('dfSessionToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + <string>this.localStorageService.get('accessToken'),
        'idToken': <string>this.localStorageService.get('idToken'),
        'dfSessionToken': dfSessionToken ? dfSessionToken : 'null'
      })
    };
    return options;
  }

  public getNonJSONBFFHeader(): any {
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + <string>this.localStorageService.get('accessToken')
      }),
      'responseType': 'text'
    };
    return options;
  }

  public getBlobResponseBFFHeader(): any {
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + <string>this.localStorageService.get('accessToken')
      }),
      'responseType': 'blob'
    };
    return options;
  }

  public getJSONBFFHeader(): any {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return options;
  }

  public getDFHeader(): any {
    const dfheader = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-DreamFactory-Api-Key': environment.DREAMFACTORY_API_KEY,
      'X-DreamFactory-Session-Token': this.localStorageService.get('dfSessionToken')
    });
    const dfoptions = { headers: dfheader };
    return dfoptions;
  }

  public catchExceptions(e: any): void {
    console.log('Error msg the ApiRequest service: ', e);
  }

  public getFileUploaderOptions(): FileUploaderOptions {
    return {
      disableMultipart: true,
      autoUpload: true,
      method: 'post',
      itemAlias: 'attachment',
      authTokenHeader: 'Authorization',
      allowedMimeType: [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
        'image/gif'
      ],
      maxFileSize: 10 * 1024 * 1024,
      queueLimit: 1,
      authToken: 'Bearer ' + <string>this.localStorageService.get('accessToken'),
      headers: [
        {
          name: 'idToken',
          value: <string>this.localStorageService.get('idToken')
        },
        {
          name: 'dfSessionToken',
          value: <string>this.localStorageService.get('dfSessionToken')
        }
      ]
    };
  }
}
