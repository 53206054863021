import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ButtonOpts } from 'mat-progress-buttons';

@Component({
  selector: 'filter-by-person.component',
  templateUrl: 'filter-by-person.component.html',
  styleUrls: ['../../supported-journeys.component.scss'],
})
export class FilterByPersonComponent {

  constructor(
    public dialogRefFilterPerson: MatDialogRef<FilterByPersonComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedUsers = data.selected;
  }

  requestFeedbackBtnOptions: ButtonOpts = {
    active: false,
    text: 'Done',
    buttonColor: 'accent',
    barColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    value: 0,
    disabled: false
  };
  fbContent: string;
  selectedUsers: any;

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  personFilterClear(): void {
    this.selectedUsers = [];
  }

  apply(): void {
    this.dialogRefFilterPerson.close(this.selectedUsers);
  }

  cancel(): void {
    this.dialogRefFilterPerson.close();
  }
}
