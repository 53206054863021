import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'app/services/auth.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthorizedResponsesInterceptor implements HttpInterceptor {


  constructor(
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if (event.status === 401) {
              this.authService.logout();
            }
          }
        }, error => {
          if (error.status === 401) {
            this.authService.logout();
          }
        })
      );
  }
}
