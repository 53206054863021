import { Pipe, PipeTransform } from '@angular/core';
import { CuratedContentType } from 'app/enums/curated-content-type';


@Pipe({ name: 'CCTypeButtonLabelPipe' })

export class CCTypeButtonLabelPipe implements PipeTransform {

  transform(status: CuratedContentType): any {

    switch (status) {
      case 1:
        return 'View Document';
      case 2:
        return 'View Image';
      case 3:
        return 'Watch Video';
      case 4:
        return 'Listen to Audio';
      case 5:
        return 'Open Link';
      default:
        return '';
    }
  }
}
