import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MyAssessmentsComponent } from './my-assessments.component';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTabsModule,
  MatSortModule,
  MatGridListModule,
  MatBadgeModule,
  MatListModule,
  MatChipsModule,
  MatCardModule,
  MatDialogModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSlideToggleModule,

} from '@angular/material';

import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MdePopoverModule } from '@material-extended/mde';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { BehavioralAssessmentModule } from 'app/shared/behavioral-assessment/behavioral-assessment.module';
import { MyAssessmentsService } from './my-assessments.service';
import { CanDeactivateGuard } from 'app/guards/can-deactivate.guard';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';
import { AssessmentListModule } from 'app/shared/assessment-list/assessment-list.module';
import { TakeAssessmentComponent } from 'app/shared/assessment-list/assessment-list/take-assessment/take-assessment.component';

const routes = [
  {
    path: 'my-assessments',
    component: MyAssessmentsComponent
  },
  {
    path: 'my-assessments/take-assessment/:id',
    component: TakeAssessmentComponent,
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  declarations: [
    MyAssessmentsComponent,
    // TakeAssessmentComponent,
    FilterDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    ChartsModule,
    NgxChartsModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatGridListModule,
    CdkTableModule,
    NgxContentLoadingModule,
    MatSortModule,
    FuseWidgetModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MdePopoverModule,
    MatCardModule,
    MatToolbarModule,
    CorePipesModule,
    MatProgressSpinnerModule,
    SharedDirectivesModule,
    SvgIconModule,
    MatProgressBarModule,
    BehavioralAssessmentModule,
    CommonComponentsModule,
    MatSlideToggleModule,
    AssessmentListModule
  ],
  exports: [
    MyAssessmentsComponent,
    // TakeAssessmentComponent
  ],
  providers: [
    MyAssessmentsService,
    CanDeactivateGuard,
  ],
  entryComponents: [
    FilterDialogComponent,
  ]
})

export class MyAssessmentsModule {
}
