import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { FeedbackStatus } from 'app/enums/feedback-status';
import { FeedbackModel } from 'app/models/feedback-model';
import { ImageService } from 'app/services/images.service';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';
import * as _ from 'lodash';

import { MatSnackBar } from '@angular/material';
import { constructivityMockData } from './give-feedback.mock-data';

const defaultFeedbackMessage = 'Type a minimum of 100 characters.';

@Component({
  selector: 'give-feedback-shared',
  templateUrl: './give-feedback.component.html',
  styleUrls: ['./give-feedback.component.scss']
})

export class GiveFeedbackComponent implements OnInit {

  @Input() feedbackId: string;
  @Input() showCancelButton: boolean;
  @Output() onResult = new EventEmitter<any>();


  user: User;
  routeSubscription: any;
  isOwnFeedback: Boolean;
  isFeedbackGiven: Boolean;
  loading = true;
  feedback: FeedbackModel;
  feedbackButtonDisabled = false;
  firstTimeOpen = true;
  userName = '';
  constructivityData: Object;
  mainAnalysis: Object;
  otherAnalysis: Array<any>;
  feedbackMessage = defaultFeedbackMessage;
  userTypedFeedback = '';
  feedbackSuccess: Boolean = false;
  textChanged: Function;

  thresholdOptions = {
    '0': { color: '#f44336' },
    '0.6857': { color: '#ffb300' },
    '0.857': { color: '#4caf50' }
  };

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private imageService: ImageService,
    private mix: MixpanelManager,
    public snackBar: MatSnackBar,

  ) {
    this.textChanged = _.debounce(this.analyzeFeedback, 500);
  }

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.fetchFeedbackInfo(this.feedbackId);
    this.firstTimeOpen = true;
    this.userName = this.user.firstName + ' ' + this.user.lastName;
  }

  fetchFeedbackInfo(id: string): void {
    this.loading = true;
    const filter = `filter=((_id = ${id}) AND (from = ${this.user.id}))`;
    this.http.get(environment.GENESIS_SERVICE_URL
      + environment.GENESIS_USER_FEEDBACK_DATA
      + '?'
      + filter, this.requestHelpers.getBFFHeader()).subscribe(res => {
        const data = res;
        console.log('feedback data', data);
        if (data[0]) {
          this.isOwnFeedback = true;
          this.feedback = data[0];
          this.feedback.fbRequestCreator[0][
            'imageURL'
          ] = this.imageService.getUserProfileImage(
            this.feedback.fbRequestCreator[0]['publicId']
          );
          this.feedback.userDetails[
            'imageURL'
          ] = this.imageService.getUserProfileImage(
            this.feedback.userDetails['publicId']
          );
        } else {
          this.isOwnFeedback = false;
          this.loading = false;
          return;
        }
        if (data[0].status === FeedbackStatus.feedback_received) {
          this.isFeedbackGiven = true;
          this.analyzeFeedback(this.feedback.feedback);
        } else {
          this.isFeedbackGiven = false;
        }
        this.loading = false;
      }, err => {
        this.loading = false;
        console.error('err', err);
      });
  }

  giveFeedback(fbContent): void {
    if (fbContent === undefined) {
      return;
    }
    this.feedbackButtonDisabled = true;
    const postData = {
      feedbackId: this.feedbackId,
      feedback: fbContent,
      constructiveness: this.constructivityData && this.constructivityData['analysis'] ? this.constructivityData['analysis'] : {}
    };
    this.http
      .post(
        environment.GENESIS_SERVICE_URL + environment.GENESIS_GIVE_FEEDBACK,
        postData,
        this.requestHelpers.getNonJSONBFFHeader()
      )
      .subscribe(
        (data: any) => {
          this.fetchFeedbackInfo(this.feedbackId);
          this.firstTimeOpen = false;
          this.openSnackBar('Feedback sent.', 'Ok');
          this.feedbackButtonDisabled = true;
          this.onResult.emit({ success: true });
          this.mix.track(MixpanelEventType.FEEDBACK_GIVEN, {
            feedbackId: this.feedback['_id']
          });
        },
        (error: any) => {
          this.feedbackButtonDisabled = false;
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
          this.onResult.emit({ success: false });
        }
      );
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  analyzeFeedback = (statement?) => {
    if (!statement) {
      statement = this.userTypedFeedback;
      if (statement.length < 100) {
        this.feedbackMessage = defaultFeedbackMessage;
        this.constructivityData = null;
        this.mainAnalysis = null;
        this.otherAnalysis = [];
        return;
      }
    }
    this.feedbackMessage = 'Analyzing feedback...';
    const body = {
      statements: [statement],
      feedback_status: this.feedback.status
    };

    this.http.post(environment.SKYNET_BASE_URL + environment.SKYNET_ANALYZE_FEEDBACK, body)
      .subscribe(res => {
        if (res && res['length']) {
          const data = res[0];
          if (data['status']) {
            this.constructivityData = data;
            this.mainAnalysis = data['display'].find(i => i.main);
            this.otherAnalysis = data['display'].filter(i => !i.main);
            this.feedbackMessage = '';
          } else {
            if (data['message']) {
              this.feedbackMessage = data['message'];
              this.constructivityData = null;
              this.mainAnalysis = null;
              this.otherAnalysis = [];
            }
          }
        } else {
          this.constructivityData = null;
          this.mainAnalysis = null;
          this.otherAnalysis = [];
        }
      }, err => {
        this.feedbackMessage = 'Failed to analyze the feedback';
        this.constructivityData = null;
        this.mainAnalysis = null;
        this.otherAnalysis = [];
      });
  }

  cancel(): void {
    this.onResult.emit({ success: false, isCanceled: true });
  }

  isLow(value): Boolean {
    return value === 'Low';
  }

  isMed(value): Boolean {
    return value === 'Medium';
  }

  isHigh(value): Boolean {
    return value === 'High';
  }

  onNoClick(): void {
    this.onResult.emit({ success: true, isCanceled: true });
  }
}
