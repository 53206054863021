import { Component, OnInit, Input } from '@angular/core';

const baseIconUrl = './assets/icons/sl/';
const baseImageUrl = './assets/images/sl/';
@Component({
  selector: 'sl-icon',
  styleUrls: ['./svg-icon.component.scss'],
  template: `
    <div class="custom-icon" [style.height.px]="iconHeight" [style.width.px]="iconWidth" [inlineSVG]="iconUrl" *ngIf="format!='image'"></div>
    <img class="custom-icon" [style.height.px]="iconHeight" [style.width.px]="iconWidth" src={{iconUrl}} *ngIf="format=='image'"/>
  `
})
export class SvgIconComponent implements OnInit {

  @Input() icon: string;
  @Input() iconHeight: string;
  @Input() iconWidth: string;
  @Input() format: string;

  iconUrl: string;

  constructor() { }

  ngOnInit(): void {
    if (this.format && this.format === 'image') {
      this.iconUrl = baseImageUrl + this.icon + '.jpg';
      console.log(this.iconUrl);
    } else {
      this.iconUrl = baseIconUrl + this.icon + '.svg';
    }

    if (!this.iconWidth) {
      this.iconWidth = '100';
    }
    if (!this.iconHeight) {
      this.iconHeight = '100';
    }
  }

}
