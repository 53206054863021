
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'competencyIcon' })

export class CompetencyIconPipe implements PipeTransform {

  transform(compType: number, compName: string): any {

    switch (compType) {
      case 1:
        {
          switch (compName) {
            case 'Building Relationships':
              return 'buildingrelationships';

            case 'Commitment':
              return 'commitment';

            case 'Communication':
              return 'communication';

            case 'Conflict Management':
              return 'conflictmanagement';

            case 'Innovation':
              // return 'innovation';
              return 'defaultbehaviour';


            case 'Task Management':
              return 'taskmanagement';

            default:
              return 'defaultbehaviour';
          }
        }
      case 2:
        return 'defaultfunctional';

      case 3:
        return 'defaulttechnical';

      default:
        return 'buildingrelationships';
    }
  }
}
