import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel } from 'app/models/journey-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JourneyStatus } from 'app/enums/journey-status';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';

@Component({
  selector: 'edit-journey-details-component',
  templateUrl: 'edit-journey-details-component.html',
  providers: [RequestHelpers]
})
export class EditJourneyDetailsComponent {

  journey: JourneyModel;
  user: any;
  journeyDueDate: Date;
  journeyMinDate: Date = new Date();
  journeyMaxDate: Date = new Date();
  journeyTitle: string;
  jouneryEdit = new EventEmitter<Object>();
  isUpdatingJourney: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditJourneyDetailsComponent>,
    private requestHelpers: RequestHelpers,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private mix: MixpanelManager,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.journey = <JourneyModel>data.journey;
    this.user = data.user;
    this.journeyTitle = this.journey.title;

    if (!this.journey.startedOn) {
      const currentDate = new Date();
      currentDate.setDate(new Date().getDate() + this.journey.recommendedDuration);
      this.journeyDueDate = new Date(currentDate);
      this.journeyMaxDate.setDate(new Date().getDate() + 365);
    } else {
      this.journeyDueDate = new Date(this.journey.dueDate);
      this.journeyMaxDate.setDate(new Date().getDate() + 365);
    }
  }

  updateJourney(): void {

    this.isUpdatingJourney = true;

    const journey = JSON.parse(JSON.stringify(this.journey));

    journey.dueDate = this.journeyDueDate;
    journey.title = this.journeyTitle;
    journey.tasks.pop();
    journey.tasks.shift();

    const postData = {
      journey: journey,
      userId: this.user.id,
      orgId: this.user.orgId,
      id: journey._id
    };

    this.checkValidation(this.journey)
      .then(result => {
        this.http
          .put(
            environment.GENESIS_SERVICE_URL +
            environment.GENESIS_JOURNEY + '/' +
            this.journey._id, postData, this.requestHelpers.getBFFHeader())
          .subscribe(
            (data: any) => {

              this.mix.track(MixpanelEventType.JOURNEY_EDITED, { journeyId: this.journey._id });
              this.journey.title = this.journeyTitle;
              this.journey.dueDate = this.journeyDueDate;

              this.isUpdatingJourney = false;
              this.openSnackBar('Updated successfully!', 'Ok');

              this.jouneryEdit.emit(postData.journey);
              this.dialogRef.close();

            },
            (error: any) => {
              console.log(error);
              this.isUpdatingJourney = false;
              this.openSnackBar('Unable to update!', 'Ok');
            }
          );
      })
      .catch(error => {
        console.log(error);
        this.isUpdatingJourney = false;
        this.openSnackBar(error.message, 'Ok');
      });
  }

  checkValidation(journey: JourneyModel): Promise<any> {
    return new Promise((res, rej) => {
      if (journey.status === JourneyStatus.completed) {
        rej({ message: 'Cannot update after journey is complete' });
      } else if (this.journeyTitle.length > 30) {
        rej({ message: 'Title lenght should not be more than 30 characters.' });
      } else {
        res({ message: 'Good to go' });
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
