import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HelpService {

  public onUrlChanged = new Subject<string>();

  constructor() {
  }

  public onUrlDataChanged(data: string): any {
    this.onUrlChanged.next(data);
  }
   
}
