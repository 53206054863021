import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class JourneyTasksService implements Resolve<any> {

  onCurrentTaskChanged: Subject<any>;
  onNewTaskClicked: Subject<any>;
  onNewTaskAdded: Subject<any>;
  onNewTaskAddedResponse: Subject<any>;
  onDeleteTask: Subject<any>;
  onDeleteTaskResponse: Subject<any>;
  onUpdateTaskOrder: Subject<any>;
  onTaskOrderUpdated: Subject<any>;
  onUpdateTask: Subject<any>;
  onTaskUpdated: Subject<any>;
  onOpenEditDialog: Subject<any>;
  
  constructor() {
    this.onCurrentTaskChanged = new Subject();
    this.onNewTaskClicked = new Subject();
    this.onNewTaskAdded = new Subject();
    this.onNewTaskAddedResponse = new Subject();
    this.onDeleteTask = new Subject();
    this.onDeleteTaskResponse = new Subject();
    this.onUpdateTaskOrder = new Subject();
    this.onTaskOrderUpdated = new Subject();
    this.onUpdateTask = new Subject();
    this.onTaskUpdated = new Subject();
    this.onOpenEditDialog = new Subject();
  }

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  setCurrentTask(data): void {
    this.onCurrentTaskChanged.next(data);
  }

  activateNewTaskView(status): void {
    this.onNewTaskClicked.next(status);
  }

  addTaskInList(task, ignoreTask): void {
    this.onNewTaskAdded.next({task: task, ignoreTask: ignoreTask});
  }

  updateTaskOrder(tasks): void {
    this.onUpdateTaskOrder.next(tasks);
  }

  updateTask(task): void {
    this.onUpdateTask.next(task);
  }

  setResultOnTaskOrderUpdated(result): void {
    this.onTaskOrderUpdated.next(result);
  }

  onNewTaskResponse(status, tasks = []): void {
    this.onNewTaskAddedResponse.next({status, tasks});
  }

  deleteTask(task): void {
    this.onDeleteTask.next(task);
  }

  onTaskResponse(result): void {
    this.onDeleteTaskResponse.next(result);
  }

  openEditTaskDialog(task): void {
    this.onOpenEditDialog.next(task);
  }
}
