import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListHeaderSectionComponent } from './list-header-section/list-header-section.component';
import { MatIconModule, MatProgressBarModule } from '@angular/material';
import { ContentCardsComponent } from './content-card/content-card.component';
import { CoursesCardsComponent } from './course-card/course-card.component';
import { TaskAttachmentComponent } from './task-attachment/task-attachment.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressBarModule
  ],
  declarations: [ListHeaderSectionComponent, ContentCardsComponent, CoursesCardsComponent, TaskAttachmentComponent],
  exports: [ListHeaderSectionComponent, ContentCardsComponent, CoursesCardsComponent, TaskAttachmentComponent]
})
export class CommonComponentsModule { }
