import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from 'app/services/images.service';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'take-assessment',
  templateUrl: './take-assessment.component.html',
  styleUrls: ['./take-assessment.component.scss'],
  providers: [ImageService, RequestHelpers]
})
export class TakeAssessmentComponent implements OnInit, AfterViewInit {

  constructor(
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private activatedroute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private location:Location,
  ) { }

  user: any;
  assessmentId: string;
  isCheckingAssessmentStatus: Boolean;
  directStart: Boolean;
  assessment: any;
  ongoingAssessment: Boolean;

  ngOnInit(): void {
    this.user = this.localStorageService.get('user');
    this.activatedroute.params.subscribe(data => {
      this.assessmentId = data['id'];
      this.checkAssessmentStatus(data['id']);
    });

  }

  checkAssessmentStatus(assessmentId): void {
    this.isCheckingAssessmentStatus = true;

    this.http.get(
      `${environment.DF_MONGO_BASE_URL_HL}` +
      `${this.user.orgId}` +
      `${environment.DF_BASELINE_ASSESSMENT}` +
      `?filter=(_id=${assessmentId})`,
      this.requestHelpers.getDFHeader())
      .subscribe(
        (result: any) => {
          if (result.resource.length) {

            if (result.resource[0].status === 3) {
              this.openSnackBar('This assessment is already submitted.', 'Ok');
              this.router.navigate(['/my-assessments']);
            }

            if (result.resource[0].userId !== this.user.id) {
              this.router.navigate(['/pagenotfound']);
            }
            this.directStart = true;
            // if (!result.resource[0].isBaselineAssessment && this.user.id === result.resource[0].ownerId) {
            //   this.directStart = false;
            // }

            this.assessment = result.resource[0];
          }

          this.isCheckingAssessmentStatus = false;
        },
        (error: any) => {
          this.isCheckingAssessmentStatus = false;
          this.openSnackBar('Something went wrong while loading the assessment.', 'Ok');
          this.router.navigate(['/my-assessments']);
        });
  }

  onAssessmentStarted($event): void {
    this.ongoingAssessment = $event.status;
  }

  onAssessmentSubmited($event): void {
    if ($event.success) {
      this.openSnackBar('Your assessment submitted successfully!', 'Ok');
    } else {
      this.openSnackBar('Something went wrong while submitting the assessment.', 'Ok');
    }

    this.ongoingAssessment = false;
    this.location.back();
  }

  isOngoingAssessment(): Boolean {
    return this.ongoingAssessment;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngAfterViewInit(): void {
    this.user = this.localStorageService.get('user');
  }
}
