import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { fuseAnimations } from '@fuse/animations';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { User } from 'app/models/user';
import { MessageService } from 'app/services/subjects.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';


@Component({
  selector: 'competency-bar',
  templateUrl: './competency-bar.component.html',
  styleUrls: ['./competency-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CompetencyBarComponent implements OnInit, OnDestroy {
  @Input() teamFilter: any;

  private _unsubscribeAll: Subject<any>;
  single: any[];
  multi: any[];
  user: User;
  view: any[] = [];
  
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';
  selectedTeam: any;
  colorScheme = {
    domain: ['#03A9F4']
  };
  subscription: Subscription;
  chartdata: any [];
  overdueCount = 0;
  competencyBarData: boolean;
  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private messageService: MessageService,
  ) {
  }
  ngOnInit(): void {
    this.user = <User>this.localStorageService.get('user');
    this.competencyBarData = true;
    this.chartdata = [];
    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      console.log(message.selectedTeam);
      this.selectedTeam = message.selectedTeam;
      this.competencyBarGraph();
    });

    if (this.teamFilter.length > 0) {
      this.selectedTeam = this.teamFilter;
      this.competencyBarGraph();
    }


  }

  competencyBarGraph(): void {
    this.competencyBarData = true;
    const teamIds = [];
    let teamFilter = '';
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';
      // const teamIds = this.selectedTeam.filter(item => item.id);

    }
    const tempFilter = '?managerId=' + this.user['id'] + '&activityType=14&limit=10&offset=0' + teamFilter;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        tempFilter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          console.log(data);
          this.competencyBarData  = false;
          this.chartdata = data['resource'][0]['def_jour_comp_count']['graph'];
          this.overdueCount = data['resource'][0]['def_jour_comp_count']['totalOverdue'];
        },
        error => {
          console.log(error);
          this.competencyBarData  = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }


  ngOnDestroy(): void {
  }
}
