import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { MixpanelManager } from './mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';

(window as any).global = window;

@Injectable()
export class AuthService {

  isAuthenticated = new BehaviorSubject(false);
  fetchedUserData = new BehaviorSubject(false);

  auth0 = new auth0.WebAuth({
    clientID: environment.auth.clientID,
    domain: environment.auth.domain,
    responseType: 'id_token token',
    redirectUri: environment.auth.redirect,
    audience: environment.auth.audience,
    scope: environment.auth.scope
  });

  expiresIn: number;
  accessToken: string;
  expiresAt: any;
  authenticated: boolean;
  tokenCreatedOn: number;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private afAuth: AngularFireAuth,
    private mix: MixpanelManager
  ) { }

  private async signInFirebase(token): Promise<void> {
    try {
      const firebaseUser = await this.afAuth.auth.signInWithCustomToken(token);
    } catch (err) {
      console.error('Error with signing into firebase: ', err);
    }
  }

  private setSession(authResult): void {
    this.localStorageService.set('accessToken', authResult.accessToken);
    this.localStorageService.set('expiresIn', authResult.expiresIn);
    this.localStorageService.set('idToken', authResult.idToken);
    this.localStorageService.set('tokenCreatedOn', (new Date()).getTime());
    this.expiresAt = authResult.expiresIn * 1000 + Date.now();
    this.accessToken = authResult.accessToken;
    this.authenticated = true;
  }

  private createCavisChannel(userId): void {
    const data = { from: userId, to: 10000, type: 'DM_BOT' };
    this.http.post(environment.FIREBASE_API_URL + environment.firebaseEndpoints.CREATE_DM, data)
      .subscribe(res => { }, err => { });
  }

  private async getUserInfo(): Promise<any> {
    const api = this.http.get(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_GET_USER_DETAILS,
      this.requestHelpers.getBFFHeader()
    ).toPromise();
    try {
      const data = await api;

      if (!data['resource'] || !data['resource'].length) {
        this.logout();
        return;
      }

      const userObj = data['resource'][0];

      // Check if the user is enabled on DeveLoop
      if (userObj['isEnabled'] === false || userObj['is_enabled'] === false) {
        this.logout();
        return;
      }

      const { firebaseToken, dfSessionToken } = userObj;
      this.localStorageService.set('dfSessionToken', dfSessionToken);
      this.localStorageService.set('user', userObj);
      this.createCavisChannel(userObj['id']);
      await this.signInFirebase(firebaseToken);
      this.fetchedUserData.next(true);
    } catch (err) {
      this.logout();
    }
  }

  initAuthService(): void {
    this.accessToken = this.localStorageService.get('accessToken');
    this.tokenCreatedOn = this.localStorageService.get('tokenCreatedOn');
    this.expiresAt = this.localStorageService.get('expiresIn') * 1000 + this.tokenCreatedOn;
    if (this.accessToken) {
      this.authenticated = true;
      this.getUserInfo();
    }
    this.checkAuthentication();
  }

  async handleLoginCallback(): Promise<void> {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        window.location.hash = '';
        this.isAuthenticated.next(true);
        this.setSession(authResult);
        this.getUserInfo();
      } else if (err) {
        console.error(`Error: ${err.error}`);
        this.login();
      }
      const redirectPath = this.localStorageService.get('redirectPath');
      if (redirectPath) {
        this.router.navigateByUrl(redirectPath);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  login(): void {
    this.auth0.authorize();
  }

  private clearOneSignal(): void {
    const oneSignalPlayerId = this.localStorageService.get('oneSignalPlayerId');
    this.http
      .delete(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_USER_NOTIFICATION_SETTING + '/' + oneSignalPlayerId, this.requestHelpers.getBFFHeader())
      .subscribe(
        (data: any) => {
        },
        error => { }
      );
  }

  logout(): void {
    this.mix.track(MixpanelEventType.LOG_OUT);
    this.mix.reset();
    this.clearOneSignal();
    this.afAuth.auth.signOut();
    this.localStorageService.clear();
    // TODO: Figure out URL for the Auth0 login page
    this.auth0.logout({
      returnTo: environment.APPLICATION_URL,
      clientID: environment.auth.clientID
    });
  }

  checkAuthentication(): void {
    this.isAuthenticated.next(this.isLoggedIn);
  }

  changePassword(pass): Observable<any> {
    const body = {
      newPassword: pass
    };
    return this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_CHANGE_PASSWORD,
      body,
      this.requestHelpers.getBFFHeader());
  }

  get isLoggedIn(): boolean {
    const value = Date.now() < this.expiresAt && this.authenticated;
    return value;
  }

}
