import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MyCompetenciesComponent } from './my-competencies.component';

import { MdePopoverModule } from '@material-extended/mde';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { CdkTableModule } from '@angular/cdk/table';
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LevelIndicatorModule } from 'app/shared/level-indicator/level-indicator.module';
import { SharedDirectivesModule } from 'app/shared/directives/directives';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';

import {
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatSortModule,
    MatGridListModule,
    MatBadgeModule,
    MatListModule,
    MatChipsModule,
    MatCardModule,
    MatToolbarModule,
    MatProgressSpinnerModule

} from '@angular/material';
import { CompetencyListModule } from 'app/shared/competency-list/competency-list.module';
import { CommonComponentsModule } from 'app/shared/common-components/common-components.module';
const routes = [
    {
        path: 'competencies',
        component: MyCompetenciesComponent
    }
];

@NgModule({
    declarations: [
        MyCompetenciesComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        ChartsModule,
        NgxChartsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatInputModule,
        MatTableModule,
        MatGridListModule,
        CdkTableModule,
        NgxContentLoadingModule,
        MatSortModule,
        FuseWidgetModule,
        BrowserAnimationsModule,
        MatBadgeModule,
        MatListModule,
        MatChipsModule,
        MdePopoverModule,
        MatCardModule,
        MatToolbarModule,
        LevelIndicatorModule,
        MatProgressSpinnerModule,
        SharedDirectivesModule,
        SvgIconModule,
        CorePipesModule,
        CompetencyListModule,
        CommonComponentsModule
    ],
    exports: [
        MyCompetenciesComponent
    ],
})

export class MyCompetenciesModule {
}
