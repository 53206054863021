import { Component, Input, Output, OnDestroy, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import * as moment from 'moment';

@Component({
  selector: 'countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, AfterViewInit, OnDestroy {
  // Event date
  @Input('eventDate')
  eventDate;

  @Output()
  timerElapsed = new EventEmitter();

  countdown: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    // Set the defaults
    this.countdown = {
      days: '',
      hours: '',
      minutes: '',
      seconds: ''
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const currDate = moment();
    const eventDate = moment(this.eventDate);

    // Get the difference in between the current date and event date
    let diff = eventDate.diff(currDate, 'seconds');

    // Create a subscribable interval
    const countDown = interval(1000)
      .pipe(
        map(value => {
          return diff = diff - 1;
        }),
        map(value => {
          const timeLeft = moment.duration(value, 'seconds');
          return {
            days: timeLeft.asDays().toFixed(0),
            hours: timeLeft.hours(),
            minutes: timeLeft.minutes(),
            seconds: timeLeft.seconds()
          };
        })
      );

    // Subscribe to the countdown interval
    countDown
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.countdown = value;
        if (this.isCountDownElapsed()) {
          this.timerElapsed.emit();
          this._unsubscribeAll.next();
          this._unsubscribeAll.complete();
        }
      });
  }

  isCountDownElapsed(): boolean {
    return this.countdown.days <= 0 && this.countdown.hours <= 0 && this.countdown.minutes <= 0 && this.countdown.seconds <= 0;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
