import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel } from 'app/models/journey-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ImageService } from 'app/services/images.service';

@Component({
  selector: 'confirm-start-assessment-component',
  templateUrl: 'confirm-start-assessment.component.html',
  providers: [ImageService, RequestHelpers]
})
export class ConfirmStartAssessmentComponent {

  journey: JourneyModel;
  onJourneyEdited = new EventEmitter<Object>();

  supportersDetails: Array<any>;
  allUsersData: Array<any>;
  roles: Array<any>;

  userId: number;
  orgId: number;

  role: string;
  defaultImage: string;
  isLoadingSupporters: boolean;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmStartAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }

  startAssessment(): void {
    this.onNoClick({status: true});
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  
}
