import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'behaviourIndicatorColor' })

export class BehaviourIndicatorColorPipe implements PipeTransform {

  transform(score: number): any {

    switch (true) {
      
      case score <= 1.34:
        return 'blue-500';

      case score > 1.34 && score <= 2.33:
        return 'green-400';

      case score > 2.33:
        return 'green-600';

      default:
        return ' mat-icon-no-color';
    }
  }
}
