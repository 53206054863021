import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CompetencyListTypes, CompetencyStatuses } from 'app/enums/competency-list-types';
import { CompetencyListService } from './competency-list.service';

@Component({
  selector: 'competency-list',
  templateUrl: './competency-list.component.html',
  styleUrls: ['./competency-list.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CompetencyListComponent implements OnInit, OnDestroy {

  constructor(
    private service: CompetencyListService
  ) { }

  @Input() type: CompetencyListTypes;
  @Input() statuses: Array<CompetencyStatuses>;
  @Input() userId: Number;

  pageIndex = 0;
  pageSize = 10;
  dataLength = 0;
  dataSource = [];
  isTableFetching = false;
  displayedColumns: String[] = [];
  fetchCompetenciesSubscription;

  ngOnInit(): void {
    this.fetchCompetencies();
    switch (this.type) {
      case CompetencyListTypes.myCompetencies:
        this.displayedColumns = [
          'name',
          'type',
          'level',
          'action'
        ];
        break;
      case CompetencyListTypes.othersCompetencies:
        this.displayedColumns = [
          'name',
          'type',
          'level',
        ];
    }
  }

  onPaginateChange(event): void {
    this.pageIndex = event.pageIndex;
    this.fetchCompetencies();
  }

  fetchCompetencies(): void {

    if (this.fetchCompetenciesSubscription) {
      this.fetchCompetenciesSubscription.unsubscribe();
    }

    this.isTableFetching = true;

    const pageIndex = this.pageIndex;
    const pageSize = this.pageSize;

    let filter = '';

    if (this.statuses.length) {
      this.statuses.forEach(stat => {
        switch (stat) {
          case CompetencyStatuses.active:
            filter = filter + '&active=1';
            break;
          case CompetencyStatuses.expected:
            filter = filter + '&expected=1';
            break;
          case CompetencyStatuses.additional:
            filter = filter + '&additional=1';
            break;
        }
      });
    } else {
      filter = filter + '&active=1&expected=1&additional=1';
    }

    // When fetching other people's competency
    if (this.userId) {
      filter = filter + `&userId=${this.userId}`;
    }

    this.fetchCompetenciesSubscription = this.service.fetchCompetencies({ pageIndex, pageSize, filter })
      .subscribe(
        data => {
          this.dataSource = data['resource'];
          const orig = this.dataSource;
          const newArray = orig.map(comp => {
            const newComp = comp;
            newComp['level'] = comp['levels'].map(level => {
              const obj = {
                levelName: ''
              };
              let levelStatus = '';

              if (level.id < comp['endLevel'] && level.id > comp['startLevel']) {
                levelStatus = 'gap';
              }
              if (level.id <= comp['startLevel']) {
                levelStatus = 'active';
              }
              if (level.id === comp['endLevel']) {
                levelStatus = 'current';
              }
              obj['levelStatus'] = levelStatus;
              level['levelStatus'] = levelStatus;
              level['levelName'] = level.levelName;
              obj['levelName'] = level.levelName;
              return obj;
            });
            return newComp;
          });

          this.dataLength = data['meta']['count'];
          this.isTableFetching = false;
        },
        error => {
          this.isTableFetching = false;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fetchCompetenciesSubscription) {
      this.fetchCompetenciesSubscription.unsubscribe();
    }
  }

}
