// These are the common constants in all the environments

const genesisEndpoints = {
    GET_MY_TEAM_USERS: '/getMyTeamUsers',
    GENESIS_TEAM: '/team',
    GET_COMPETENCIES_MY_TEAM_FILTER: '/getCompetenciesForMyTeamFilter',
    GENESIS_JOURNEY: '/journey',
    GENESIS_COMPLETE_JOURNEY: '/completeJourney',
    GENESIS_GET_CONTENT: '/getContent',
    GENESIS_START_JOURNEY: '/startJourney',
    GENESIS_COMPLETE_TASK: '/completeTask',
    GENESIS_CREATE_FEEDBACK_REQUEST: '/createFeedbackRequest',
    GENESIS_USER_FEEDBACK_DATA: '/getUserFeedbackData',
    GENESIS_GET_NUGGET: '/getNugget',
    GENESIS_SUBMIT_NUGGET: '/submitNugget',
    GENESIS_GIVE_FEEDBACK: '/giveFeedback',
    GENESIS_GET_USER_DETAILS: '/getWebUserDetails',
    GENESIS_USER_NOTIFICATION_SETTING: '/userNotificationSetting',
    GENESIS_GET_USER_COMP_MAP: '/getUserCompetencyMapping',
    GENESIS_JOURNEY_TEMPLATES: '/journeyTemplate',
    GENESIS_RESTART_JOURNEY: '/restartJourney',
    GENESIS_PURGE_JOURNEY: '/purgeJourney',
    GENESIS_RESEND_REMINDER: '/resendFeedbackreminder',
    GENESIS_RESEND_REQUEST: '/resendFeedbackRequest',
    GENESIS_MANAGER_APPROVALS: '/approval',
    GET_USER_PROFILE_INFO: '/getUserProfileInfo',
    GENESIS_FETCH_TASK_RULES: '/fetchTaskRules',
    GENESIS_CC_ANALYTICS: '/viewCuratedContent',
    GENESIS_START_TASK: '/startTask',
    GENESIS_UPDATE_TASK_STATUS: '/updateTaskStatus',
    GENESIS_ADD_CC_FEEDBACK: '/addCuratedContentFeedback',
    GET_NUGGET_REPORT: '/getNuggetReport',
    GENESIS_GET_JOURNEY_ASSIGNEES: '/getJourneyAssignees',
    GENESIS_FEEDABCK: '/feedback',
    GENESIS_FEEDBACK_USERS: '/fetchFeedbackCreators',
    GENESIS_ROLE_FEATURES: '/getFeaturesByRole',
    GET_INFO_FROM_AUTH_TOKEN: '/getInfoFromAuthToken',
    GET_JOURNEY_LIST_FILTER: '/journeyListFilterWeb',
    GET_BASELINE_COMP_FILTER: '/baselineCompFilterData',
    GET_JOURNEY_LIST: '/listJourniesWeb',
    GET_JOURNEY_LIST_SUPPORTED: '/getListJourniesWeb',
    GET_JOURNEY_LIST_WITH_FILTERS: '/getJourniesListWithFilters',
    GET_COMPETENCY_LIST: '/getUserCompetencyList',
    GET_JOURNEY_SUPPORTER: '/getJourneySupporter',
    GET_USER_JOURNEY_STATS: '/getUserJourneyStats',
    GET_NEW_JOURNEYS_FOR_DEVELOOP: '/getNewJourneysForDeveloop',
    GENESIS_CHANGE_PASSWORD: '/changePassword',
    GENESIS_GET_JOURNEY_OWNERS: '/getJourneyOwners',
    GET_USER_CONSENT: '/getUserConsent',
    USER_CONSENT: '/userConsent',
    UPLOAD_TASK_CONTENT: '/uploadTaskContent',
    GET_UPLOADED_CONTENT: '/getTaskContent',
    DOWNLOAD_UPLOADED_CONTENT: '/downloadTaskContent',
    GET_UPLOADED_CONTENT_URL: '/getTaskContentUrl'
};

const aukatEndpoints = {
    AUKAT_GET_ASSESSMENT_QUESTIONS: '/getAssessmentQuestions',
    AUKAT_SUBMIT_ASSESSMENT: '/submitAssessment',
    AUKAT_GET_ASSESSEMENTS: '/getAllAssessments',
    AUKAT_GET_BATCHES: '/getBatches',
    AUKAT_GET_ASSESSEMENT_STATUS: '/checkBatchAssessmentStatus',
    AUKAT_CREATE_JOURNEY_BATCH: '/createJourneyEndBatch',
    AUKAT_GET_JOURNEY_REPORT_DATA: '/fetchJourneyData',
    AUKAT_GET_USER_ASSESSMENTS: '/getUserAssessments'
};

const dfEndpoints = {
    DF_USER_MANAGER_MAP: '.UomUserManagerMap',
    DF_TEAM_USER_MAP: '.UomTeamUserMap',
    DF_JOURNEY: '.journey',
    DF_CURATED_CONTENT: '.contentversion',
    DF_JOURNEY_ROLES: '.JourneyRoles',
    DF_FEEDBACK: '.feedback',
    DF_JOURNEY_RESULT: '.journeyReportData',
    DF_BASELINE_ASSESSMENT: '.baselineAssessment',
    DF_BASELINE_BATCH: '.baselineBatch',
    DF_COMPETENCY: '.competency',
    DF_COMPETENCY_LEVELS: '.CompLevel'
};

const firebaseEndpoints = {
    SEND_MESSAGE: '/sendMessage',
    CREATE_DM: '/createDM',
    CREATE_GROUP: '/createGroup',
    EDIT_CHANNEL: '/editChannel',
    GET_JOURNEY_ACTIVITIES: '/getJourneyActivities',

};

const genosEndpoints = {
    ADD_REVIEW: '/addReview',
    MESSAGE_CAVIS: '/messageCavis',
    GET_USER_ACTIVITIES: '/getUserActivities'
};

const calypsoEndpoints = {
    ENGAGEMENT_INDEX: '/engagementindex',
    LEADERBOARD: '/leaderboard',
    CALYPSO_GET_USER_ACTIVITY_SCORE: '/getuseractivityscore',
    CALYPSO_GET_JOURNEY_DATA: '/getJourneyData',
    CALYPSO_GET_TEAM_PERCEPTION_GAP: '/getTeamPerceptionGraph',
    CALYPSO_GET_JOURNEY_STATS_LIST: '/getJourneyStatsList',
    CALYPSO_GET_JOURNEY_STATS: '/getJourneyStats',
    CALYPSO_GET_COMPETENCY_SCORE_LIST: '/getCompetencyScoreList',
    CALYPSO_GET_COMPETENCY_DISTRIBUTION_GRAPH: '/getCompetencyDistributionGraph',
    CALYPSO_GET_USER_COMPETENCY_LIST: '/getUserCompetencyList',
    CALYPSO_GET_USER_COMPETENCY_HISTORY_LIST: '/getUserCompetencyHistoryList',
    CALYPSO_GET_RELATED_COMPETENCY_DATA: '/getRelatedCompetencyData',
    CALYPSO_GET_BEHAVIOURAL_DATA: '/getBehaviouralData',
    CALYPSO_GET_COMPETENCY_PERCEPTION_GAP: '/getCompetencyPerceptionGap',
    CALYPSO_GET_COMPETENCY_ASSMNT_SCORES: '/getCompetencyAssessmentScores',
    GET_RECOMMENDED_JOURNEYS: '/getRecommendedJourneys',
    GET_RECOMMENDED_COMPETENCIES: '/getRecommendedComps',
    GET_RECOMMENDED_CONTENT: '/getRecommendedContent',
    CHECK_IF_MANAGER: '/checkIfTeamManager',
    GET_SEARCH_COMPETENCY: '/searchCompetency',
    GET_COMPETENCY_CONTENTS: '/competencyContents',
    GET_SEARCH_JOURNEY: '/searchJourney',
    GET_ALL_CONTENTS: '/searchContents',
    GET_SEARCH_COURSES: '/searchCourses',
    FETCH_USER_COURSES: '/fetchUserCourses'
};

const skynetEndpoints = {
    SKYNET_ANALYZE_FEEDBACK: '/analyze_feedback'
};

const extras = {
    USER_GUIDE: 'https://storage.googleapis.com/loop_user_guide/DeveLoopUserGuide.pdf',
    DEFAULT_IMAGE: 'generic_profile',
}

export const APP_CONSTANTS = {
    // Desctructuring for backward compatibility
    ...genesisEndpoints,
    ...aukatEndpoints,
    ...dfEndpoints,
    ...firebaseEndpoints,
    ...genosEndpoints,
    ...calypsoEndpoints,
    ...skynetEndpoints,
    ...extras,
    genesisEndpoints,
    aukatEndpoints,
    dfEndpoints,
    firebaseEndpoints,
    genosEndpoints,
    calypsoEndpoints,
    skynetEndpoints,
    extras
}
