import { Injectable, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { JourneyModel } from 'app/models/journey-model';
import { LocalStorageService } from 'ngx-store';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class TakeJourneyService {
  public onJourneyChanged = new ReplaySubject<JourneyModel>(1);
  public onShowKC = new ReplaySubject<Boolean>(1);
  public onShowFeedback = new ReplaySubject<Boolean>(1);
  public onShowTaskDetails = new ReplaySubject<Boolean>(1);

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService
  ) { }

  getJourneyDetails(journeyId, orgId): Observable<any> {

    return this.http.get(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_JOURNEY + '?filter=_id=' +
      journeyId + '&orgId=' +
      orgId + '&appendUserDetails=1&appendCompDetails=1&appendLevelDetails=1&appendJourneyRules=1&appendContentKCFlag=1',
      this.requestHelpers.getBFFHeader()
    );

  }

  getUserFeedbacks(
    filter: string,
    limit: number = null,
    offset: number = null
  ): Observable<any> {
    let url =
      environment.GENESIS_SERVICE_URL + environment.GENESIS_USER_FEEDBACK_DATA;
    url += `?filter=${encodeURI(filter)}`;
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (offset) {
      url += `&offset=${offset}`;
    }

    return this.http.get(url, this.requestHelpers.getBFFHeader());
  }

  public updateOnJourneyChanged(journey: JourneyModel): void {
    this.onJourneyChanged.next(journey);
  }

  public startTask(journeyId, taskId): Observable<any> {
    const postData = {
      journeyId: journeyId,
      taskId: taskId
    };

    return this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_START_TASK,
      postData,
      this.requestHelpers.getNonJSONBFFHeader()
    );
  }

  public getCCDetails(taskContentId, orgId): Observable<any> {
    const filter = '?filter=_id=' + taskContentId;
    return this.http.get(
      environment.DF_MONGO_BASE_URL_HL +
      orgId +
      environment.DF_CURATED_CONTENT +
      filter,
      this.requestHelpers.getDFHeader()
    );
  }

  public loadCCContent(contentId): Observable<any> {
    const url =
      environment.GENESIS_SERVICE_URL + environment.GENESIS_GET_CONTENT;
    return this.http.get(
      url + '/' + contentId,
      this.requestHelpers.getNonJSONBFFHeader()
    );
  }

  public trackCCViews(contentId, taskId, journeyId): Observable<any> {
    const postData = {
      contentId: contentId,
      taskId: taskId,
      journeyId: journeyId
    };

    return this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_CC_ANALYTICS,
      postData,
      this.requestHelpers.getBFFHeader()
    );
  }

  public checkKnowledgeCheck(id: string, orgId: number): Promise<any> {
    return new Promise((res, rej) => {
      const filter = '?filter=_id=' + id;
      this.http
        .get(
          environment.DF_MONGO_BASE_URL_HL +
          orgId +
          environment.DF_CURATED_CONTENT +
          filter,
          this.requestHelpers.getDFHeader()
        )
        .subscribe(
          (data: any) => {
            if (data['resource'].length) {
              res(data['resource'][0]);
            } else {
              rej({ error: 'Something is not right' });
            }
          },
          (error: any) => {
            console.log(error);
            rej(error);
          }
        );
    });
  }

  public getNuggetReport(journeyId: string, taskId: string): Observable<any> {
    const postData = {
      journeyId,
      taskId
    };
    return this.http.post(environment.GENESIS_SERVICE_URL + environment.GET_NUGGET_REPORT,
      postData,
      this.requestHelpers.getBFFHeader());
  }

  public getNugget(competencyId: string, levelId: any, questionConfig: any): Observable<any> {
    const postData = {
      competencyId,
      levelId,
      questionConfig
    };
    return this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_GET_NUGGET, postData, this.requestHelpers.getBFFHeader());
  }

  public submitNugget(postData: any): Observable<any> {
    return this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_SUBMIT_NUGGET, postData, this.requestHelpers.getBFFHeader());
  }

  public completeTask(taskId: string, journeyId: string): Observable<any> {
    const postData1 = {
      journeyId: journeyId,
      taskId: taskId
    };

    return this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_COMPLETE_TASK,
      postData1,
      this.requestHelpers.getBFFHeader()
    );
  }

  public updateTaskStatus(
    jouneyId: string,
    taskId: string,
    kcstatus = false,
    feedbackstatus = false
  ): Observable<any> {
    const postData = {
      journeyId: jouneyId,
      taskId: taskId,
      pendingKCheck: kcstatus,
      pendingFeedback: feedbackstatus
    };

    return this.http.put(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_UPDATE_TASK_STATUS,
      postData,
      this.requestHelpers.getBFFHeader()
    );
  }

  public addCCFeedback(journeyId, taskId, contentId, isLiked): Observable<any> {
    const postData = {
      journeyId: journeyId,
      taskId: taskId,
      contentId: contentId,
      isLiked: isLiked
    };


    return this.http.post(
      environment.GENESIS_SERVICE_URL + environment.GENESIS_ADD_CC_FEEDBACK,
      postData,
      this.requestHelpers.getBFFHeader()
    );
  }

  updateJourneySubject(journey: JourneyModel): void {
    this.onJourneyChanged.next(journey);
  }

  updateJourney(jouneryId: string, postData: Object): Observable<Object> {
    return this.http.put(
      `${environment.GENESIS_SERVICE_URL}${
      environment.GENESIS_JOURNEY
      }/${jouneryId}`,
      postData,
      this.requestHelpers.getBFFHeader()
    );
  }

  public resendReminder(journeyId: string, taskId: string, users: Array<number>): Observable<any> {
    const data = {
      journeyId: journeyId,
      taskId: taskId,
      users: users
    };
    return this.http.post(environment.GENESIS_SERVICE_URL + environment.GENESIS_RESEND_REMINDER, data, this.requestHelpers.getBFFHeader());
  }

  public startJourney(journeyId: string, dueDate: any): Observable<any> {

    const postData = {
      journeyId: journeyId,
      dueDate: dueDate
    };

    return this.http.post(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_START_JOURNEY, postData, this.requestHelpers.getBFFHeader()
    );

  }

  public getTaskRules(postData): Observable<any> {

    return this.http.post(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_FETCH_TASK_RULES,
      postData, this.requestHelpers.getBFFHeader()
    );
  }

  public resendRequest(journeyId: string, taskId: string, users: Array<number>, requestMsg: string): Observable<any> {
    const data = { journeyId: journeyId, taskId: taskId, users: users, type: 1, source: 1, requestMsg: requestMsg };
    return this.http.post(environment.GENESIS_SERVICE_URL + environment.GENESIS_RESEND_REQUEST, data, this.requestHelpers.getBFFHeader());
  }

  public checkIfUserIsManager(postData): Observable<any> {
    return this.http
      .post(
        environment.CALYPSO_BASE_URL +
        environment.CHECK_IF_MANAGER,
        postData, this.requestHelpers.getBFFHeader()
      );
  }

  public downloadUploadedContent(orgId, fileHash): Observable<any> {
    return this.http
        .get(
            environment.GENESIS_SERVICE_URL +
            environment.DOWNLOAD_UPLOADED_CONTENT +
            '?orgId=' + orgId +
            '&fileHash=' + fileHash,
            this.requestHelpers.getBlobResponseBFFHeader()
        );
  }

  public getUploadedContent(orgId, journeyId, fileName): Observable<any> {
    return this.http
    .get(
      environment.GENESIS_SERVICE_URL +
      environment.GET_UPLOADED_CONTENT +
      '?orgId=' + orgId +
        '&journeyId=' + journeyId +
      '&fileName=' + fileName,
      this.requestHelpers.getNonJSONBFFHeader()
    );
  }
  public getUploadedContentUrl(orgId, journeyId, fileName): Observable<any> {
    return this.http
        .get(
            environment.GENESIS_SERVICE_URL +
            environment.GET_UPLOADED_CONTENT_URL +
            '?orgId=' + orgId +
            '&journeyId=' + journeyId +
            '&fileName=' + fileName,
            this.requestHelpers.getBFFHeader()
        );
  }
}
