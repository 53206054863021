import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { User } from 'app/models/user';
import { JourneyStatus } from 'app/enums/journey-status';
import { CapitalizePipe } from 'app/pipes/capitalize.pipe';
import { UnderscoreToSpace } from 'app/pipes/underscoreToSpace.pipe';
import { ProfileAccessRoles } from 'app/enums/profile-access-roles';
import { LocalStorageService } from 'ngx-store';
import { UserFilterModel } from 'app/models/filter-model';
import { UserListService } from './user-list.service';
import { MatPaginator } from '@angular/material';


@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private localStorageService: LocalStorageService,
    private service: UserListService
  ) { }

  @Input() filters: UserFilterModel;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  user: User;

  dataLength = 0;
  pageSize = 10;
  pageEvent: Object;
  displayedColumns: string[] = ['name', 'email', 'manager', 'teams'];
  users: Array<User> = [];

  allStatuses: Array<any> = [];
  selectedStatuses: Array<any> = [];

  allCompetencies: Array<any> = [];
  selectedCompetencies: Array<any> = [];

  allTeams: Array<any> = [];
  selectedTeams: Array<any> = [];

  statusFilterString: Array<String> = [];
  teamFilterString: Array<String> = [];
  compFilterString: Array<String> = [];

  isFetchingUsers: Boolean = false;
  isFetchingManager: Boolean = false;
  isClearAllFilters: Boolean = false;

  popupData: Array<any> = [];

  isViewProfileEnabled: boolean;
  selectedUserProfile: User;

  journeyStatus = JourneyStatus;
  capitalizePipe = new CapitalizePipe();
  underscoreToSpace = new UnderscoreToSpace();
  role = ProfileAccessRoles.manager;
  fetchUserSubscription;

  ngOnInit(): void {
    this.user = <User>this.localStorageService.get('user');
    this.allStatuses = this.enumToArray(this.journeyStatus);
    this.onPaginateChange({ pageIndex: 0 });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filters: SimpleChange = changes.filters;
    if (!filters.firstChange) {
      const current = <UserFilterModel>filters.currentValue;
      if (current) {
        if (current.comps) {
          this.selectedCompetencies = current.comps.map(comp => comp._id);
        }
        if (current.teams) {
          this.selectedTeams = current.teams.map(team => team.id);
        }
        if (current.journeyStatuses) {
          this.selectedStatuses = current.journeyStatuses.map(stat => JourneyStatus[stat.name]);
        }
        this.paginator.pageIndex = 0;
        this.onPaginateChange({ pageIndex: 0 });
      }
    }
  }

  enumToArray(data: Object): any {
    const keys = Object.keys(data);
    const statuses = keys.slice(keys.length / 2);
    const newStatuses = [];

    statuses.forEach(element => {
      const display = this.capitalizePipe.transform(this.underscoreToSpace.transform(element), true);
      newStatuses.push({ 'name': element, 'display': display, class: 'active' });
    });

    const finalStatuses = newStatuses
      .filter(s => s.name !== 'purged' && s.name !== 'past_due')
      .map(s => {
        if (s.name === 'failed') {
          s.display = 'Continuance';
        }
        if (s.name === 'undecided') {
          s.display = 'Inconclusive';
        }
        return s;
      });
    return finalStatuses;
  }

  getReportingManagers(users): void {
    this.isFetchingManager = true;
    const userIds = users.map(u => u.id);
    if (userIds.length === 0) {
      this.isFetchingManager = false;
      return;
    }

    const mngrFilter = `?filter=((userId IN (${userIds})) AND (type = 1))`;
    const related = '&related=uom_user_by_managerId';

    this.service.fetchManagers(mngrFilter, related)
      .subscribe(
        (data: any) => {
          this.isFetchingManager = false;
          if (data.resource.length) {
            data.resource.forEach(item => {
              const userIndex = this.users.findIndex(usr => usr.id === item['userId']);
              if (userIndex !== -1) {
                this.users[userIndex]['managerDetails'] = item['uom_user_by_managerId'];
              }
            });
          }
        },
        (error: any) => {
          console.log(error);
          this.isFetchingManager = false;
        }
      );
  }

  onPaginateChange(event): void {

    this.isFetchingUsers = true;

    if (this.fetchUserSubscription) {
      this.fetchUserSubscription.unsubscribe();
    }

    const postData = {
      'status': this.selectedStatuses,
      'teamIds': this.selectedTeams,
      'competencies': this.selectedCompetencies,
      'limit': this.pageSize,
      'offset': event.pageIndex
    };

    this.fetchUserSubscription = this.service.fetchUserList(postData)
      .subscribe(
        (data: any) => {
          if (data.success) {
            data.resource.forEach(item => {
              item['managerDetails'] = null;
              if (item.fileDetails_by_profileImage) {
                item['publicId'] = item.fileDetails_by_profileImage.publicId;
              } else {
                item['publicId'] = null;
              }
              if (item[`hyperloop_${this.user.orgId}.UomTeam_by_hyperloop_${this.user.orgId}.UomTeamUserMap`]) {
                const filteredTeams = item[`hyperloop_${this.user.orgId}.UomTeam_by_hyperloop_${this.user.orgId}.UomTeamUserMap`].filter(team => !team.isSudoTeam);
                item[`hyperloop_${this.user.orgId}.UomTeam_by_hyperloop_${this.user.orgId}.UomTeamUserMap`] = filteredTeams;
              }
              item['viewLimit'] = 1;
              item['teams'] = item['hyperloop_' + this.user.orgId +
                '.UomTeam_by_hyperloop_' + this.user.orgId + '.UomTeamUserMap'];
              console.log(item['teams']);
            });

            if (data.resource.length) {
              this.users = data.resource;
              if (data.meta !== undefined) {
                this.dataLength = data.meta.count;
              }
              else {
                this.dataLength = 0;
              }
              this.getReportingManagers(this.users);

            } else {
              this.users = [];
            }
          }
          this.isFetchingUsers = false;
        },
        (error: any) => {
          console.log(error);
          this.isFetchingUsers = false;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fetchUserSubscription) {
      this.fetchUserSubscription.unsubscribe();
    }
  }

  openPopup(data: Array<any>): void {
    this.popupData = data;
  }
  viewMore = (item) => {
    this.users = this.users.map(i => {
      if (i.id === item.id) {
        i['viewedMore'] = !i['viewedMore'];
        i['viewLimit'] = i['viewedMore'] ? undefined : 1;
      }
      return i;
    });
    return true;
  }

}
