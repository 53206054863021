import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { Competency } from '../../models/competency.model';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private requestHelpers: RequestHelpers, public http: HttpClient) {
  }

  getSearchContents(orgId, offset, limit, type, searchText, compId): any
  {
    if (compId === undefined){
    const url = `${environment.CALYPSO_BASE_URL}${environment.GET_ALL_CONTENTS}`; 
    return this.http.get(url + '?orgId=' + orgId +
    '&limit=' + limit +
    '&offset=' + offset +
    '&type=' + type +
    '&searchText=' + searchText, this.requestHelpers.getBFFHeader() );
    }
    else if (compId !== undefined){
      const url = `${environment.CALYPSO_BASE_URL}${environment.GET_ALL_CONTENTS}`; 
      return this.http.get(url + '?orgId=' + orgId +
      '&limit=' + limit +
      '&offset=' + offset +
      '&type=' + type +
      '&searchText=' + searchText +
      '&compId=' + compId, this.requestHelpers.getBFFHeader()  );
    }
  }
   
getSearchCompetency(orgId, limit, offset, searchText): any
{
  const url = `${environment.CALYPSO_BASE_URL}${environment.GET_SEARCH_COMPETENCY}`; 
  return this.http.get(url + '?orgId=' + orgId +
  '&limit=' + limit +
  '&offset=' + offset +
  '&searchText=' + searchText , this.requestHelpers.getBFFHeader() );

}

getSearchJourney(orgId, limit, offset, searchText? , priCompId?): any
{
  if (priCompId === undefined)
  {
  const url = `${environment.CALYPSO_BASE_URL}${environment.GET_SEARCH_JOURNEY}`; 
  return this.http.get(url + '?orgId=' + orgId +
  '&limit=' + limit +
  '&offset=' + offset +
  '&searchText=' + searchText, this.requestHelpers.getBFFHeader()
   );
  }
  else if (priCompId !== undefined)
  {
    const url = `${environment.CALYPSO_BASE_URL}${environment.GET_SEARCH_JOURNEY}`; 
    return this.http.get(url + '?orgId=' + orgId +
    '&limit=' + limit +
    '&offset=' + offset +
    '&searchText=' + searchText +
    '&compId=' + priCompId, this.requestHelpers.getBFFHeader()
    );
  }
}

getSearchCourses(limit, offset,searchText): any
{
    const url = `${environment.CALYPSO_BASE_URL}${environment.GET_SEARCH_COURSES}`; 
    return this.http.get(url +
    '?limit=' + limit +
    '&offset=' + offset +
    '&searchText=' + searchText, this.requestHelpers.getBFFHeader()
     );
}

}
