import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Input,
} from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { TaskModel, JourneyModel } from 'app/models/journey-model';
import { LocalStorageService } from 'ngx-store';
import { Subject } from 'rxjs/Subject';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FeedbackRequestComponent } from 'app/core/my-journeys/take-journey/dialogs/feedback-request/feedback-request.component';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';
import { User } from '../../../../../../../models/user';
import { TaskType } from '../../../../../../../enums/task-type';
import { JourneyStatus } from '../../../../../../../enums/journey-status';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { JourneyReportDialogComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-report/journey-report.component';

@Component({
  selector: 'end-task-component',
  templateUrl: './end-task.component.html',
  styleUrls: ['./end-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EndTaskComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() task: TaskModel;
  @Input() journey: JourneyModel;

  animationDirection: 'left' | 'right' | 'none';
  animationDirectionFeedback: 'left' | 'right' | 'none';
  isCompletingJourney: boolean;
  user: User;
  assessment: any;
  journeyStatus = JourneyStatus;
  journeyAccessRoles = JourneyAccessRoles;
  journeyId: string;
  customizableChecked: boolean;
  isBehavioral: boolean;
  isManager: Boolean;
  isRetakingJourney: Boolean;
  showControls: Boolean;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private activatedroute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
    private dialog: MatDialog,
    private newTakeJourneyService: TakeJourneyService,
    public snackBar: MatSnackBar) {
    this.animationDirection = 'none';
    this.animationDirectionFeedback = 'none';

    this.customizableChecked = true;
  }

  completeJourney(journeyId: string): void {
    this.isCompletingJourney = true;

    const postData1 = {
      journeyId: journeyId
    };

    this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_COMPLETE_JOURNEY, postData1, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.isCompletingJourney = false;
          if (data['data'] !== 'fail') {
            this.router.navigate(['/journeys']);
            this.openSnackBar(data['message'], 'Ok');
          } else {
            this.openSnackBar(this.journey['ruleDisplayText'], 'Ok');
          }
        },
        error => {
          console.log(error);
          this.isCompletingJourney = false;
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
        }
      );
  }

  requestJourneyFeedback(): void {
    const dialogRef = this.dialog.open(FeedbackRequestComponent, {
      width: '50vw',
      data: {
        journey: this.journey,
        user: this.user,
        type: 2
      }
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe(
      journey => {
        this.journey = journey;
        // call update journey subject
        this.journey['feedback'] = [];
        this.newTakeJourneyService.updateJourneySubject(this.journey);
      }
    );

    dialogRef.afterClosed().subscribe(result => {

      if (result['success']) {

        this.newTakeJourneyService.getJourneyDetails(this.journey['_id'], this.user['orgId']);
      }
      sub.unsubscribe();
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnInit(): void {
    this.showControls = true;
    this.user = this.localStorageService.get('user');
    if (this.journey.supporters) {
      const result = this.journey.supporters.find(item => item.userId === this.user.id && item.role === 'Manager');
      if (result) {
        this.isManager = true;
      }
    }


    if (this.journey.competency['tag'][0].id === 1) {
      this.isBehavioral = true;
    }

    this.journeyId = this.journey['_id'];
    this.assessment = this.journey.tasks[this.journey.tasks.length - 2];

  }

  retakeJourney(): void {
    this.isRetakingJourney = true;
    const postObj = {
      journeyId: this.journeyId,
      keepCustomization: this.customizableChecked
    };

    this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_RESTART_JOURNEY, postObj, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.openSnackBar('Request sent', 'Ok');

          this.router.navigate(['/journeys-take/' + data['resource'][0]['_id']]);
          this.isRetakingJourney = false;
        },
        error => {
          this.requestHelpers.catchExceptions(error);
          this.isRetakingJourney = false;
        }
      );
  }

  openJourneyReportDialog(): void {
    const dialogRef = this.dialog.open(JourneyReportDialogComponent, {
      width: '90vw',
      height: '50vw',
      data: {
        journeyId: this.journey._id,
        isManager: this.isManager
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }
}
