import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { User } from 'app/models/user';
import { LocalStorageService } from 'ngx-store';
import { MessageService } from 'app/services/subjects.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'user-comp-distribution',
  templateUrl: './user-comp-distribution.component.html',
  styleUrls: ['./user-comp-distribution.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class UserCompDistributionComponent implements OnInit, OnDestroy {
  @Input() selectedTeams: any[];
  user: User;
  subscription: Subscription;
  chartdata: any;
  competencyData: boolean;
  colorScheme: any = {
    domain: [
      '#F1F8E9',
      '#DCEDC8',
      '#C5E1A5',
      '#AED581',
      '#9CCC65',
      '#8BC34A',
      '#7CB342',
      '#689F38'
    ]
  };

  constructor(
    private localStorageService: LocalStorageService,
    private _dashboardService: DashboardService,
    private messageService: MessageService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.competencyData = true;

    this.user = <User>this.localStorageService.get('user');
    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      this.selectedTeams = message.selectedTeam;
      this.getUserCompDistributionData();
    });

    if (this.selectedTeams && this.selectedTeams.length) {
      this.getUserCompDistributionData();
    }
  }

  getUserCompDistributionData(): void {
    this.competencyData = true;

    this._dashboardService.getCompDistributionGraph(this.user.id, this.user.orgId, this.selectedTeams.map(team => team.id), 0)
      .subscribe(
        (result: any) => {
          if (result.resource) {
            this.chartdata = result.resource;
          }
          this.competencyData = false;
        },
        (error: any) => {
          console.log(error);
          this.competencyData = false;
        });
  }

  onCompSelected($event): void {
    const result = this.chartdata.findIndex(comp => comp.name === $event.series);
    if (result !== -1) {
      this._router.navigate([`/dashboard/comp-history/${this.chartdata[result].compId}/${this.selectedTeams.map(team => team.id).toString()}`]);
    }
  }

  ngOnDestroy(): void {
  }
}
