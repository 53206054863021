import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { JourneyModel } from 'app/models/journey-model';
import { LocalStorageService } from 'ngx-store';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class MyAssessmentsService {
  
  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers
  ) { }

  // public startTask(journeyId, taskId): Observable<any> {
  //   const postData = {
  //     journeyId: journeyId,
  //     taskId: taskId
  //   };

  //   return this.http.post(
  //     environment.GENESIS_SERVICE_URL + environment.GENESIS_START_TASK,
  //     postData,
  //     this.requestHelpers.getNonJSONBFFHeader()
  //   );
  // }

  // public getAssessments($event): Observable<any> {
    
  // }
}
