import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from 'app/core/logout/logout.component';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  declarations: [
    LogoutComponent
  ],
  exports: [
    LogoutComponent
  ],
})
export class LogoutModule { }
