import { NgModule } from '@angular/core';

import { TruncateStringWithEllipsesPipe } from './TruncateStringWithEllipses';
import { PublicIdToImageUrlPipe } from './publicIdToImageUrl';
import { JourneyStatusMessagesPipe } from './journey-status-messages.pipe';
import { JourneyStatusColorPipe } from './journey-status-color.pipe';
import { BatchStatusMessagesPipe } from './batch-status-messages.pipe';
import { CCTypeButtonLabelPipe } from './cc-type-button-label.pipe';
import { CCTypeLabelPipe } from './cc-type-label.pipe';
import { CCTypeIconPipe } from './cc-type-icon.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { UnderscoreToSpace } from './underscoreToSpace.pipe';
import { AppDatePipe } from './appDate.pipe';
import { CompetencyIconPipe } from './competency-icons.pipe';
import { BehaviourIndicatorPipe } from './behaviour-indicator.pipe';
import { BehaviourIndicatorIconPipe } from './behaviour-indicator-icon.pipe';
import { BehaviourIndicatorColorPipe } from './behaviour-indicator-color.pipe';
import { AssessmentStatusColorPipe } from './assessment-status-color.pipe';

@NgModule({
  declarations: [
    TruncateStringWithEllipsesPipe,
    PublicIdToImageUrlPipe,
    CapitalizePipe,
    UnderscoreToSpace,
    JourneyStatusMessagesPipe,
    JourneyStatusColorPipe,
    BatchStatusMessagesPipe,
    CCTypeButtonLabelPipe,
    CCTypeLabelPipe,
    CCTypeIconPipe,
    AppDatePipe,
    CompetencyIconPipe,
    BehaviourIndicatorPipe,
    BehaviourIndicatorIconPipe,
    BehaviourIndicatorColorPipe,
    AssessmentStatusColorPipe
  ],
  imports: [],
  exports: [
    TruncateStringWithEllipsesPipe,
    PublicIdToImageUrlPipe,
    CapitalizePipe,
    UnderscoreToSpace,
    JourneyStatusMessagesPipe,
    JourneyStatusColorPipe,
    BatchStatusMessagesPipe,
    CCTypeButtonLabelPipe,
    CCTypeLabelPipe,
    CCTypeIconPipe,
    AppDatePipe,
    CompetencyIconPipe,
    BehaviourIndicatorPipe,
    BehaviourIndicatorIconPipe,
    BehaviourIndicatorColorPipe,
    AssessmentStatusColorPipe
  ]
})
export class CorePipesModule {
}
