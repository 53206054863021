import { Component, OnDestroy, OnInit, Input } from '@angular/core';

@Component({
  selector: 'top-competencies-component',
  templateUrl: './top-competencies.component.html',
  styleUrls: ['./top-competencies.component.scss']
})
export class TopCompetenciesComponent implements OnInit, OnDestroy {

  @Input() topCompetencies: Array<any>;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }
}
