import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'help-required-component',
  templateUrl: 'help-required.component.html',
  styleUrls: ['./help-required.component.scss']
})
export class HelpRequiredComponent {
  inputData: any;
  user: any;

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<HelpRequiredComponent>,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
    this.user = this.localStorageService.get('user');
    this.inputData = data;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  needMoreHelp(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
