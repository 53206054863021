import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';

import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Component({
  selector: 'confirmation-popup.component',
  templateUrl: 'confirmation-popup.component.html',
})


export class ConfirmationPopUpComponent {
  journeyId: string;
  customizableChecked: boolean;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopUpComponent>,
    private http: HttpClient,
    private router: Router,
    private requestHelpers: RequestHelpers,

    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.journeyId = data['journeyId'];
    this.customizableChecked = true;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  retakeJourney(): void {

    const postObj = {
      journeyId: this.journeyId,
      keepCustomization: this.customizableChecked
    };
    
    this.http
    .post(
      environment.GENESIS_SERVICE_URL +
      environment.GENESIS_RESTART_JOURNEY, postObj, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.openSnackBar('Request sent', 'Ok');
          this.dialogRef.close();
          this.router.navigate(['/journeys-take/' + data['resource'][0]['_id']]);
          
        },
        error => {
          this.dialogRef.close();
          this.requestHelpers.catchExceptions(error);
        }
      );
  }
  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
