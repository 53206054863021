import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyStatus } from '../../../../enums/journey-status';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { userInfo } from 'os';
@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent {
  batch: any;
  isSelf: boolean;
  displayedColumns: string[];
  JourneyStatus;
  user: any;
  constructor(
    private localStorageService: LocalStorageService,
    private requestHelpers: RequestHelpers,
    public dialogRef: MatDialogRef<DetailsDialogComponent>,
    public snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = this.localStorageService.get('user');
    this.JourneyStatus = JourneyStatus;
    this.batch = data.batch;
    this.isSelf = data.isSelf;
    this.displayedColumns = [
      'name',
      'status',
      'completionDate'
    ];
    console.log(this.batch);
    this.batch['selfData'] = this.batch.supportersData.filter(supporter => {
      if (supporter.userId === this.user.id) {
        return supporter;
      }
    })[0];
  }
  startAssessment(assessmentId: string): void {
    console.log(assessmentId);
    this.dialogRef.close();
    this.router.navigate([`../../my-assessments/take-assessment/${assessmentId}`]);

  }

}
