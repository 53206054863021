import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RequestHelpers } from '../../services/request-helpers.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from '../../services/images.service';
import { MatPaginator } from '@angular/material';
import { User } from 'app/models/user';
import { JourneyStatus } from 'app/enums/journey-status';
import { CapitalizePipe } from 'app/pipes/capitalize.pipe';
import { UnderscoreToSpace } from 'app/pipes/underscoreToSpace.pipe';

import 'rxjs/add/operator/map';
import { UserFilterModel, UserFilterModelMini } from 'app/models/filter-model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss'],
  providers: [ImageService, RequestHelpers]
})
export class MyTeamsComponent implements OnInit, AfterViewInit {

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    private activatedroute: ActivatedRoute,
    private router: Router
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  user: User;
  users: Array<User> = [];
  allStatuses: Array<any> = [];
  selectedTeams: Array<any> = [];
  isFetchingManager: Boolean = false;

  journeyStatus = JourneyStatus;
  capitalizePipe = new CapitalizePipe();
  underscoreToSpace = new UnderscoreToSpace();

  routeSubscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  alreadyFiltered: UserFilterModelMini = new UserFilterModelMini();
  filters: UserFilterModel = new UserFilterModel();
  allFilterData: UserFilterModel = new UserFilterModel();
  loadedFilterDataCount = 0;

  ngOnInit(): void {
    this.user = <User>this.localStorageService.get('user');
    this.allStatuses = this.enumToArray(this.journeyStatus);
    this.loadedFilterDataCount++;
    this.getTeamsForFilter();
    this.getCompetenciesForFilter();

    this.allFilterData.journeyStatuses = this.enumToArray(this.journeyStatus);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.activatedroute.queryParams.subscribe(params => {
      if (params.competency) {
        this.alreadyFiltered.comps = params.competency;
      }
      if (params.team) {
        let teamArr;
        if (typeof params.team === 'string') {
          teamArr = [Number(params.team)];
        }
        else { teamArr = params.team.map(Number); }
        this.alreadyFiltered = { ... this.alreadyFiltered, teams: teamArr };
        // this.alreadyFiltered.teams = params.team.map(t => Number(t));
      }
      if (params.journeyStatus) {
        this.alreadyFiltered.journeyStatuses = params.journeyStatus;
      }
    });
  }

  getTeamsForFilter(): void {
    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_TEAM +
        `?managerId=${this.user.id}&limit=100`,
        this.requestHelpers.getBFFHeader()
      )
      .subscribe(
        data => {
          this.loadedFilterDataCount++;
          const allTeams = data['resource'];
          this.allFilterData = { ...this.allFilterData, teams: allTeams };

          if (this.alreadyFiltered) {
            const filterTeams = this.allFilterData.teams.filter(team => this.alreadyFiltered.teams.includes(team.id));
            const filterStatuses = this.allFilterData.journeyStatuses.filter(stat => this.alreadyFiltered.journeyStatuses.includes(stat.name));
            this.filters = { ...this.filters, teams: filterTeams, journeyStatuses: filterStatuses };
          }
        },
        error => {
          this.loadedFilterDataCount++;
          console.log(error);
        }
      );
  }

  getCompetenciesForFilter(): void {
    let filter = '';
    if (this.selectedTeams.length) {
      filter = '?teamIds=';
      this.selectedTeams.forEach((item, index) => {
        if (index === (this.selectedTeams.length - 1)) {
          filter = filter + item['id'];
        } else {
          filter = filter + item['id'] + ',';
        }
      });
    }

    this.http
      .get(
        environment.GENESIS_SERVICE_URL +
        environment.GET_COMPETENCIES_MY_TEAM_FILTER + filter,
        this.requestHelpers.getBFFHeader()
      )
      .subscribe(
        (data: any) => {
          this.loadedFilterDataCount++;
          if (data.success) {
            const allCompetencies = data.resource;
            this.allFilterData = { ...this.allFilterData, comps: allCompetencies };

            if (this.alreadyFiltered && this.allFilterData.comps && this.allFilterData.comps.length) {
              const filterComps = this.allFilterData.comps.filter(comp => this.alreadyFiltered.comps.includes(comp._id));
              this.filters = { ...this.filters, comps: filterComps };
            }

          }
        },
        (error: any) => {
          this.loadedFilterDataCount++;
          console.log(error);
        }
      );
  }

  getReportingManagers(users): void {
    this.isFetchingManager = true;
    const userIds = users.map(u => u.id);

    if (userIds.length === 0) {
      return;
    }

    const mngrFilter = `?filter=((userId IN (${userIds})) AND (type = 1))`;
    const related = '&related=uom_user_by_managerId';

    this.http
      .get(
        environment.DF_BASE_URL_HL +
        this.user.orgId +
        environment.DF_USER_MANAGER_MAP + mngrFilter + related, this.requestHelpers.getDFHeader())
      .subscribe(
        (data: any) => {
          this.isFetchingManager = false;
          if (data.resource.length) {
            data.resource.forEach(item => {
              const userIndex = this.users.findIndex(usr => usr.id === item['userId']);
              if (userIndex !== -1) {
                this.users[userIndex]['managerDetails'] = item['uom_user_by_managerId'];
              }
            });
          }
        },
        (error: any) => {
          console.log(error);
          this.isFetchingManager = false;
        }
      );
  }

  enumToArray(data: Object): any {
    const keys = Object.keys(data);
    const statuses = keys.slice(keys.length / 2);
    let newStatuses = [];

    statuses.forEach(element => {
      const display = this.capitalizePipe.transform(this.underscoreToSpace.transform(element), true);
      newStatuses.push({ 'name': element, 'display': display, class: 'active' });
    });

    newStatuses = newStatuses
      .filter(s => s.name !== 'purged' && s.name !== 'past_due')
      .map(s => {
        if (s.name === 'failed') {
          s.display = 'Continuance';
        }
        if (s.name === 'undecided') {
          s.display = 'Inconclusive';
        }
        return s;
      });
    return newStatuses;
  }

  filterSelect(selectedFilter: UserFilterModel): void {
    const queryParams = {};
    if (selectedFilter.comps.length || selectedFilter.teams.length || selectedFilter.journeyStatuses.length) {
      if (selectedFilter.comps.length) {
        queryParams['competency'] = selectedFilter.comps.map(comp => comp._id);
      }
      if (selectedFilter.teams.length) {
        queryParams['team'] = selectedFilter.teams.map(team => team.id);
      }
      if (selectedFilter.journeyStatuses.length) {
        queryParams['journeyStatus'] = selectedFilter.journeyStatuses.map(s => s.name);
      }
      this.router.navigate(['/my-teams'], { queryParams, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/my-teams']);
    }
    this.filters = { ...selectedFilter };
  }
}
