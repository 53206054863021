import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import { LocalStorageService } from 'ngx-store';
import { ChatService } from 'app/shared/chat/chat.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { ImageService } from '../../../../../services/images.service';
import { RequestHelpers } from '../../../../../services/request-helpers.service';

@Component({
  selector: 'chat-chats-sidenav',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  animations: fuseAnimations
})
export class ChatChatsSidenavComponent implements OnInit, OnDestroy {
  chats: any[];
  chatSearch: any;
  contacts: any[];
  searchText: string;
  user: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  @Input() journey: any;

  /**
   * Constructor
   *
   * @param {ChatService} _chatService
   * @param {FuseMatSidenavHelperService} _fuseMatSidenavHelperService
   * @param {ObservableMedia} _observableMedia
   */
  constructor(
    private _chatService: ChatService,
    private _fuseMatSidenavHelperService: FuseMatSidenavHelperService,
    public _observableMedia: ObservableMedia,
    private localStorageService: LocalStorageService,
    
  ) {
    // Set the defaults
    this.chatSearch = {
      name: ''
    };
    this.searchText = '';

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // const details = this.localStorageService.get('journeyDetails');
    // console.log(details);
    this.getChat(this.journey['channelId']);

    this.user = this._chatService.user;
    this.chats = this._chatService.chats;
    this.contacts = this._chatService.contacts;

    this._chatService.onChatsUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(updatedChats => {
        this.chats = updatedChats;
      });

    this._chatService.onUserUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(updatedUser => {
        this.user = updatedUser;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get chat
   *
   * @param contact
   */
  getChat(contact): void {
    console.log('getchat toggle', contact);
    this._chatService.getChat(contact);

    /* if (!this._observableMedia.isActive('gt-md')) {
      this._fuseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
    } */
  }

  /**
   * Set user status
   *
   * @param status
   */
  setUserStatus(status): void {
    this._chatService.setUserStatus(status);
  }

  /**
   * Change left sidenav view
   *
   * @param view
   */
  changeLeftSidenavView(view): void {
    this._chatService.onLeftSidenavViewChanged.next(view);
  }

  /**
   * Logout
   */
  logout(): void {
    console.log('logout triggered');
  }
}
