import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'truncateStringWithEllipses'})
export class TruncateStringWithEllipsesPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, limit: number = 5, args: any[] = []): string
    {
        return value ? (value.length > limit) ? String(value).substr(0, limit - 1) + '...' : value : '';
    }
}
