import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FeedbackDetailComponent } from './feedback-detail.component';
import { MatIconModule, MatButtonModule, MatProgressBarModule, MatProgressSpinnerModule} from '@angular/material';
import { CorePipesModule } from 'app/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, MatIconModule, CorePipesModule, MatButtonModule, MatProgressBarModule, MatProgressSpinnerModule,RouterModule],
  declarations: [FeedbackDetailComponent],
  exports: [FeedbackDetailComponent]
})
export class FeedbackDetailModule {}
