import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JourneyFilterModel } from 'app/models/filter-model';
import { Competency } from 'app/models/competency.model';
import { User } from 'app/models/user';

@Component({
  selector: 'app-journey-list-filter-dialog',
  templateUrl: './journey-list-filter-dialog.component.html',
  styleUrls: ['./journey-list-filter-dialog.component.scss']
})
export class JourneyListFilterDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JourneyListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { allFilterData: JourneyFilterModel, selectedFilterData: JourneyFilterModel },
  ) { }

  selectedFilter: JourneyFilterModel = new JourneyFilterModel();
  selectedComp: Array<String> = [];
  selectedOwner: Array<Number> = [];

  ngOnInit(): void {
    this.selectedFilter = this.data.selectedFilterData;
    this.selectedComp = this.selectedFilter.comps.map(comp => comp._id);
    this.selectedOwner = this.selectedFilter.owners.map(owner => owner.id);
  }

  selectComp(ev): void {
    const { value } = ev;
    const selectedComps = this.data.allFilterData.comps.filter(comp => value.includes(comp._id));
    this.selectedFilter.comps = selectedComps;
  }

  removeComp(compToRemove: Competency): void {
    this.selectedFilter.comps = this.selectedFilter.comps.filter(comp => comp._id !== compToRemove._id);
    this.selectedComp = this.selectedFilter.comps.map(comp => comp._id);
  }

  selectOwner(ev): void {
    const { value } = ev;
    const selectedOwners = this.data.allFilterData.owners.filter(owner => value.includes(owner.id));
    this.selectedFilter.owners = selectedOwners;
  }

  removeOwner(ownerToRemove: User): void {
    this.selectedFilter.owners = this.selectedFilter.owners.filter(owner => owner.id !== ownerToRemove.id);
    this.selectedOwner = this.selectedFilter.owners.map(owner => owner.id);
  }

  clear(): void {
    this.selectedFilter = new JourneyFilterModel();
    this.selectedComp = [];
    this.dialogRef.close(this.selectedFilter);
  }

  applyFilter(): void {
    this.dialogRef.close(this.selectedFilter);
  }

}
