import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { Color, BaseChartDirective } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { LocalStorageService } from 'ngx-store';
import { User } from 'app/models/user';
import { ImageService } from 'app/services/images.service';

@Component({
  selector: 'competency-history-component',
  templateUrl: './competency-history.component.html',
  styleUrls: ['./competency-history.component.scss']
})
export class CompetencyHistoryComponent implements OnInit, OnDestroy {

  competencyId: String;
  competencyDetails: Object;
  selectedUsersDetails: Object;
  pageEvent: Object;
  pageEventHistory: Object;
  popUpContent: Object;
  
  selectedUsersHistory: Array<any> = [];
  relatedCompetencies: Array<any> = [];
  selectedTeams: Array<any> = [];
  compLevels: Array<any> = [];
  users: Array<any> = [];
  
  user: User;

  pageSize = 5;
  dataLength = 0;
  dataLengthHistory = 0;
  selectedUserIndex = 0;
  
  isLoadingUserHistory: Boolean = false;
  isLoadingCompDetails: Boolean = false;
  isLoadingCompLevels: Boolean = false;
  isLoadingUserDetails: Boolean = false;
  isLoadingPerceptionGapTrend: Boolean = false;
  isLoadingAssmntScoreTrend: Boolean = false;
  isLoadingRelatedComps: Boolean = false;
  isLoadingManagersObservation: Boolean = false;

  selectedFilterPerceptionGapTrend: String = 'WEEK';
  selectedFilterAssmntScoresTrend: String = 'WEEK';

  // Bar chart related config and data
  public lineChartDataPG: ChartDataSets[] = [];
  public lineChartLabelsPG = [];
  public lineChartLegendPG = false;
  public lineChartOptionsPG: (ChartOptions & { annotation: any }) = {};

  public lineChartDataAST: ChartDataSets[] = [];
  public lineChartLabelsAST = [];
  public lineChartLegendAST = true;
  public lineChartOptionsAST: (ChartOptions & { annotation: any }) = {};

  public lineChartColors: Color[] = [];
  public lineChartType = 'line';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  // Bar chart related config and data ends here

  // Pie chart related config and data
  public pieChartOptions: ChartOptions = {};
  public pieChartLabels = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [];
  // Pie chart related config and data ends here

  constructor(
    private _activatedroute: ActivatedRoute,
    private _dashboardService: DashboardService,
    private _localStorageService: LocalStorageService,
    private _imageService: ImageService
  ) { }

  ngOnInit(): void {
    this.user = <User>this._localStorageService.get('user');

    this.lineChartOptionsPG = this._dashboardService.getLineChartOptions();
    this.lineChartOptionsAST = this._dashboardService.getLineChartOptions();
    this.lineChartColors = this._dashboardService.getLineChartColors();

    this.pieChartOptions = this._dashboardService.getPieChartOptions();
    this.pieChartColors = this._dashboardService.getPieChartColors();

    this._activatedroute.params.subscribe(data => {
      this.competencyId = data['compId'];
      this.selectedTeams = data['teamIds'].split(',');

      this.getCompetencyDetails(this.user.orgId, this.competencyId);
      this.getCompetencyLevels(this.user.orgId);
      this.getUserCompList({ pageIndex: 0 });
      this.getPerceptionGapTrend(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId, this.selectedFilterPerceptionGapTrend);
      this.getAssessmentScoresTrend(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId, 'WEEK');
      this.getRelatedCompetencies(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId);
      this.getManagersObservations(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId);
    });
  }

  getCompetencyDetails(orgId, compId): void {
    this.isLoadingCompDetails = true;
    this._dashboardService.getCompetencyDetails(orgId, compId)
      .subscribe(
        (result: any) => {
          if (result.resource.length) {
            this.competencyDetails = result.resource[0];
          }
          this.isLoadingCompDetails = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingCompDetails = false;
        }
      );
  }

  getCompetencyLevels(orgId): void {
    this.isLoadingCompLevels = true;
    this._dashboardService.getCompetencyLevels(orgId)
      .subscribe(
        (result: any) => {
          if (result.resource.length) {
            this.compLevels = result.resource;
          }
          this.isLoadingCompLevels = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingCompLevels = false;
        }
      );
  }

  getUserCompList($event): void {
    this._dashboardService.getUserCompList(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId, this.pageSize, $event.pageIndex)
      .subscribe(
        (result: any) => {
          if (result.success && result.resource && result.resource.length) {
            this.dataLength = result.meta.count;
            this.users = result.resource;
            this.selectUser(0);
          }
        },
        (error: any) => {
          console.log(error);
        });
  }

  getUserCompHistoryList($event): void {
    this.isLoadingUserHistory = true;
    this._dashboardService.getUserCompHistoryList(this.users[this.selectedUserIndex].userId, this.competencyId, this.user.orgId, this.pageSize, $event.pageIndex)
      .subscribe(
        (result: any) => {
          if (result.success && result.resource && result.resource.length) {
            this.dataLengthHistory = result.meta.count;
            this.selectedUsersHistory = result.resource;
          }
          this.isLoadingUserHistory = false;
        },
        (error: any) => {
          this.isLoadingUserHistory = false;
        });
  }

  getUserDetails(): void {
    this.isLoadingUserDetails = true;
    this._dashboardService.getUserDetails(this.users[this.selectedUserIndex].userId, this.user.orgId)
      .subscribe(
        (data: any) => {
          data['imageUrl'] = this._imageService.getUserProfileImage(data.publicId);
          data['teams'] = data['teams'].length > 0 ? data['teams'].join(', ') : 'No teams yet';
          this.selectedUsersDetails = <User>data;

          this.isLoadingUserDetails = false;
        }, (err: any) => {
          this.isLoadingUserDetails = false;
        });
  }

  getPerceptionGapTrend(managerId, orgId, teamIds, competencyId, timeFilter): void {
    this.isLoadingPerceptionGapTrend = true;
    this._dashboardService.getPerceptionGapTrend(managerId, orgId, teamIds, competencyId, timeFilter)
      .subscribe(
        (result: any) => {

          if (result.success && result.resource.length) {
            this.lineChartDataPG = result.resource[0].chartData.map(data => {
              return { data: data.data, label: data.label, fill: false };
            });
            this.lineChartLabelsPG = result.resource[0].chartLabels;
          }

          this.isLoadingPerceptionGapTrend = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingPerceptionGapTrend = false;
        });
  }

  getAssessmentScoresTrend(managerId, orgId, teamIds, competencyId, timeFilter): void {
    this.isLoadingAssmntScoreTrend = true;

    this._dashboardService.getAssessmentScoresTrend(managerId, orgId, teamIds, competencyId, timeFilter)
      .subscribe(
        (result: any) => {
          if (result.success && result.resource.length) {
            this.lineChartDataAST = result.resource[0].chartData.map(data => {
              return { data: data.data, label: data.label, fill: false };
            });
            this.lineChartLabelsAST = result.resource[0].chartLabels;
          }
          this.isLoadingAssmntScoreTrend = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingAssmntScoreTrend = false;
        });
  }

  getManagersObservations(managerId, orgId, teamIds, competencyId): void {
    this.isLoadingManagersObservation = true;
    this._dashboardService.getManagersObservations(managerId, orgId, teamIds, competencyId)
      .subscribe(
        (result: any) => {
          if (result.success && result.resource.length) {
            this.pieChartData = result.resource[0].chartData;
            this.pieChartLabels = result.resource[0].chartLabels;
          }
          this.isLoadingManagersObservation = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingManagersObservation = false;
        });
  }

  getRelatedCompetencies(managerId, orgId, teamIds, competencyId): void {
    this.isLoadingRelatedComps = true;
    this._dashboardService.getRelatedCompetencies(managerId, orgId, teamIds, competencyId)
      .subscribe(
        (result: any) => {
          if (result.success && result.resource && result.resource.length) {
            this.relatedCompetencies = result.resource;
          }
          this.isLoadingRelatedComps = false;
        },
        (error: any) => {
          console.log(error);
          this.isLoadingRelatedComps = false;
        }
      );
  }

  selectUser(indx): void {
    this.selectedUserIndex = indx;
    this.getUserDetails();
    this.getUserCompHistoryList({ pageIndex: 0 });
  }

  onPGTFilterChange($event): void {
    this.getPerceptionGapTrend(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId, this.selectedFilterPerceptionGapTrend);
  }

  onASFilterChange($event): void {
    this.getAssessmentScoresTrend(this.user.id, this.user.orgId, this.selectedTeams.toString(), this.competencyId, this.selectedFilterAssmntScoresTrend);
  }

  openPopup(element: any): void {
    this.popUpContent = element;
    console.log(this.popUpContent);
  }

  ngOnDestroy(): void { }
}
