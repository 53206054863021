import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { LocalStorageService } from 'ngx-store';
import { environment } from '../../environments/environment';

@Injectable()
export class ImageService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {

  }
  public getUserProfileImage(publicId: string): string {
    const defaultImage = 'generic_profile';
    if (publicId) {
      return environment.CLOUDINARY_PUBLIC_URL +
        'b_rgb:fff,w_120,h_120,c_thumb,fl_progressive,d_' + defaultImage + '.png/' + publicId;
    }else{
      return environment.CLOUDINARY_PUBLIC_URL + 
      'b_rgb:fff,w_120,h_120,c_thumb,fl_progressive/' + defaultImage;
    }
  }

}
