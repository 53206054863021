import { Component, Inject } from '@angular/core';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'confirm-discard.component',
  templateUrl: 'confirm-discard.component.html',
  providers: [RequestHelpers]
})
export class ConfirmDiscardDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDiscardDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  confirmDiscard(): void {
    this.dialogRef.close({ confirm: true });
  }

  cancelDiscard(): void {
    this.dialogRef.close({ confirm: false });
  }
}
