import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'ngx-store';
export const navigation: FuseNavigation[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'item',
    icon: 'home',
    url: '/home'
  },
  // {
  //   id: 'insights',
  //   title: 'Insights',
  //   type: 'item',
  //   icon: 'dashboard',
  //   url: '/dashboard'
  // },
  {
    id: 'my-assessments',
    title: 'All Assessments',
    type: 'item',
    icon: 'assignment',
    url: '/my-assessments'
  },
  {
    id: 'journeys',
    title: 'My Journeys',
    type: 'item',
    icon: 'flag',
    url: '/journeys'
  },
  {
    //   this.authService.logoutUser();
    id: 'supported-journeys',
    title: 'Supported Journeys',
    type: 'item',
    icon: 'supervisor_account',
    url: '/supported-journeys'
  },
  {
    id: 'feedback-requests',
    title: 'Feedback Requests',
    type: 'item',
    icon: 'comment',
    url: '/feedback-request'
  },
  {
    id: 'competencies',
    title: 'My Competencies',
    type: 'item',
    icon: 'tune',
    url: '/competencies'
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'group',
    children: [

      {
        id: 'change-password',
        title: 'Change Password',
        type: 'item',
        icon: 'vpn_key',
        url: '/change-password'
      },
      {
        id: 'user-guide',
        title: 'User Guide',
        type: 'item',
        icon: 'help',
        function: function (): void {
          window.open(environment.USER_GUIDE, '_blank');
        }
      },
      {
        id: 'user-profile',
        title: 'My Profile',
        type: 'item',
        icon: 'account_circle',
        url: '/user-profile/self',
      },
      // {
      //   id: 'assessment',
      //   title: 'Assessment',
      //   type: 'item',
      //   icon: 'assessment',
      //   url: '/assessment'
      // },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        icon: 'exit_to_app',
        url: '/logout'
      }
    ]
  }
];
