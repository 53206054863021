import { Pipe, PipeTransform } from '@angular/core';
import { JourneyStatus } from 'app/enums/journey-status';
import * as moment from 'moment';

@Pipe({ name: 'batchStatusMessages' })

export class BatchStatusMessagesPipe implements PipeTransform {

  transform(status: JourneyStatus, dateTime: Date): any {

    switch (status) {
      case 1: {
        // if (!data.customizationComplete && data.isAssigned)
        // return "EDIT TO BEGIN";
        const diff = moment(dateTime).diff(moment(), 'days');
        return diff < 0 ? 'Past due' : 'Not started';
      }
      case 2:
        const diff = moment(dateTime).diff(moment(), 'days');
        return diff < 0 ? 'Past due' : 'Ongoing';

      case 3:
        return 'Completed';
      case 4:
        return 'Past due';
      case 5:
        return 'Deleted';
      case 6:
        return 'Continuance';
      case 7:
        return 'Inconclusive';
      default:
        return 'New';
    }
  }
}
