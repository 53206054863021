import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { ButtonOpts } from 'mat-progress-buttons';
import { MatDialog, MatSnackBar } from '@angular/material';

import { DataSource } from '@angular/cdk/collections';

import * as shape from 'd3-shape';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { MessageService } from 'app/services/subjects.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { CuratedContentType } from 'app/enums/curated-content-type';

@Component({
  selector: 'content-view-count-pie',
  templateUrl: './content-view-count-pie.component.html',
  styleUrls: ['./content-view-count-pie.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ContentViewCountPieComponent implements OnInit, OnDestroy {
  @Input() teamFilter: any;
  user: any;
  mainChart: any[];
  private _unsubscribeAll: Subject<any>;
  widget11: any = {};
  subscription: Subscription;
  selectedTeam: any;
  donutData: boolean;
  dateNow = Date.now();
  public widgets = {
    'widget11': {
      'title': 'Views by Content Type',
      'ranges': {
        'TW': 'This Week',
        'LW': 'Last Week',
        '2W': '2 Weeks Ago'
      },
      'mainChart': {
        'actualData': [],
      },
      'footerRight': {
        'title': 'Total Views',
        'count': 0
      }
    }
  };
  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.donutData = true;
    this.user = this.localStorageService.get('user');
    this.widget11 = {
      currentRange: 'actualData',
      legend: false,
      explodeSlices: false,
      labels: true,
      doughnut: true,
      gradient: false,
      scheme: {
        domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
      },
      onSelect: (ev) => {
        console.log(ev);
      }

    };
    // console.log('donut');
    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      console.log(message.selectedTeam);
      this.selectedTeam = message.selectedTeam;
      
      this.getViewsContent();
    });
    if (this.teamFilter.length > 0){
      this.selectedTeam = this.teamFilter;
      this.getViewsContent();
    }
  }

  getViewsContent(): void {
    this.donutData = true; 
    const teamIds = [];
    let teamFilter = '';
    console.log(this.selectedTeam);
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = 'managerId=' + this.user['id'] + '&activityType=13' + teamFilter;
    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL + '?' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.donutData = false;
          if (data['resource'][0]) {

            let count = 0;

            const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
            let enumkeys = [];
            let enumvalues = [];
            let isIE = false;
        
            if (match !== -1) {
              isIE = true;
            }
        
            if (!isIE) {
              const values: string[] = Object.values(CuratedContentType);
              enumkeys = values.slice(values.length / 2);
              enumvalues = values.slice(0, values.length / 2);
            } else {
              enumvalues = ['document', 'image', 'video', 'audio', 'link'];
              enumkeys = [1, 2, 3, 4, 5];
            }
        
            const enumobj: any = {};
            for (let i = 0; i < enumkeys.length; i++) {
              const element = enumkeys[i];
              enumobj[enumkeys[i]] = enumvalues[i];
            }

            this.mainChart = [];

            for (const i in data['resource'][0]['content_type_views']) {
              if (data['resource'][0]['content_type_views'][i]) {
                const item = data['resource'][0]['content_type_views'][i];
                const cobj = {
                  name : enumobj[item['_id']].charAt(0).toUpperCase() + enumobj[item['_id']].slice(1),
                  value: item['viewsCount']
                };
                count = count + item['viewsCount'];
                this.mainChart.push(cobj);
              }
            }

            this.widgets['widget11']['mainChart']['actualData'] = this.mainChart;
            this.widgets['widget11']['footerRight']['count'] = count;
          }
          console.log(this.mainChart);
        },
        error => {
          console.log(error);
          this.donutData = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
