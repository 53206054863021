import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { JourneyModel } from 'app/models/journey-model';
import { TakeJourneyService } from '../take-journey.service';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';
import { ManageSupporterComponent } from 'app/core/my-journeys/take-journey/dialogs/manage-supporter/manage-supporter.component';
import { JourneyDetailsDialogComponent } from 'app/core/my-journeys/take-journey/dialogs/journey-details-dialog/journey-details-dialog.component';

import { MatDialog, MatSnackBar } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import { JourneyStatus } from 'app/enums/journey-status';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';

import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM DD, YYYY',
    dateA11yLabel: 'MMM DD, YYYY',
    monthYearA11yLabel: 'MMM DD, YYYY',
  },
};

@Component({
  selector: 'journey-details',
  templateUrl: './journey-details.component.html',
  styleUrls: ['./journey-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class JourneyDetailsComponent implements OnInit, OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;
  journey: JourneyModel;
  isPanelOpen = true;
  journeyRoles = JourneyAccessRoles;
  rolePrefix = '';
  journeySupporters: Array<Object> = [];
  user: any;
  mode = 'determinate';
  bufferValue = 0;
  isTitleInEdit = false;
  isDateInEdit = false;
  isUpdatingJourney = false;
  journeyMinDate: Date;
  journeyMaxDate: Date;
  isJourneyEditable = false;
  journeyTitle: string;
  date = new FormControl(_moment());
  animationDirection: 'left' | 'right' | 'none';
  users_userId: any;

  constructor(
    public dialog: MatDialog,
    private newTakeJourneyService: TakeJourneyService,
    private localStorageService: LocalStorageService,
    public snackBar: MatSnackBar,
    private mix: MixpanelManager
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.animationDirection = 'left';
    this.user = this.localStorageService.get('user');
    this.users_userId = this.user.id;
    this.newTakeJourneyService.onJourneyChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(journey => {
        this.journey = journey;
        this.journey.completedTasks = this.journey.tasks.filter(task => task.status === JourneyStatus.completed).length;
        this.journeyTitle = this.journey.title;
        this.rolePrefix = this.journey.role === this.journeyRoles.taker ? 'My' : `${journey.userDetails['firstName']}'s`;
        this.journeySupporters = [];
        this.journeyMinDate = new Date();
        this.journeyMaxDate = new Date();
        for (const s of this.journey.supporterDetails) {
          if (s.active) {
            this.journeySupporters.push({
              firstName: s.firstName,
              lastName: s.lastName,
              publicId: s.publicId,
              role: s.role,
              userId: s.userId
            });
          }
        }
        if (!journey.dueDate) {
          const currentDate = new Date();
          currentDate.setDate(new Date().getDate() + journey.recommendedDuration);
          this.journey.dueDate = new Date(currentDate);
          this.date = new FormControl(_moment(currentDate));
          this.journeyMaxDate.setDate(new Date().getDate() + 365);
        } else {
          this.date = new FormControl(_moment(journey.dueDate));
          this.journeyMaxDate.setDate(new Date().getDate() + 365);
        }
        if (journey.status !== JourneyStatus.completed && journey.role === JourneyAccessRoles.taker) {
          this.isJourneyEditable = true;
        }
      });
  }

  toggleDetailsView(): void {
    this.isPanelOpen = !this.isPanelOpen;
  }

  toggleTitleEditMode(): void {
    this.isTitleInEdit = !this.isTitleInEdit;
  }

  toggleDateEditMode(): void {
    this.isDateInEdit = !this.isDateInEdit;
  }

  resetModes(isError, updateType = 3): void {
    switch (updateType) {
      case 0:
        this.isTitleInEdit = false;
        if (isError) {
          this.resetTitle();
        }
        break;
      case 1:
        this.isDateInEdit = false;
        if (isError) {
          this.resetDueDate();
        }
        break;
      default:
        this.isTitleInEdit = false;
        this.isDateInEdit = false;
        if (isError) {
          this.resetDueDate();
          this.resetTitle();
        }
    }
  }

  resetTitle(): void {
    this.journeyTitle = this.journey.title;
    this.toggleTitleEditMode();
  }

  resetDueDate(): void {
    this.date = new FormControl(_moment(this.journey.dueDate));
    this.toggleDateEditMode();
  }

  openManageSupportersDialog(): void {
    const dialogRef = this.dialog.open(ManageSupporterComponent, {
      width: '50vw',
      data: {
        details: this.journey,
        orgId: this.user.orgId,
        userId: this.user['id']
      },
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe((journey) => {
      this.journey = journey;
      // call update journey subject
      this.newTakeJourneyService.updateJourneySubject(this.journey);
    });

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  // Journey Details Popup
  openJourneyDetailsDialog(): void {
    const dialogRef = this.dialog.open(JourneyDetailsDialogComponent, {
      width: '50vw',
      data: {
        details: this.journey,
        journeySupporters: this.journey.supporterDetails,
        orgId: this.user.orgId,
        userId: this.user['id']
      },
    });

    const sub = dialogRef.componentInstance.onJourneyEdited.subscribe((journey) => {
      this.journey = journey;
      // call update journey subject
      this.newTakeJourneyService.updateJourneySubject(this.journey);
    });

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
    });
  }

  updateJourney(updateType = 3): void {

    if (this.isUpdatingJourney) {
      return;
    }

    if (updateType !== 0 && this.date.value.isSame(this.journey.dueDate, 'day')) {
      return;
    }

    this.isUpdatingJourney = true;

    const journey = {
      dueDate: updateType === 1 ? this.date.value.format() : this.journey.dueDate,
      title: updateType === 0 ? this.journeyTitle : this.journey.title
    };

    const postData = {
      journey: journey,
      userId: this.user.id,
      orgId: this.user.orgId,
      id: this.journey._id
    };

    this.checkValidation(this.journey)
      .then(result => {
        this.newTakeJourneyService.updateJourney(this.journey._id, postData)
          .subscribe(
            (data: any) => {
              this.mix.track(MixpanelEventType.JOURNEY_EDITED, { journeyId: this.journey._id });
              this.journey.title = this.journeyTitle;
              this.journey.dueDate = this.date.value.format();

              this.isUpdatingJourney = false;
              this.resetModes(false, updateType);
              this.openSnackBar('Updated successfully!', 'Ok');

              // call update journey subject
              this.newTakeJourneyService.updateJourneySubject(this.journey);

            },
            (error: any) => {
              console.log(error);
              this.isUpdatingJourney = false;
              this.resetModes(true, updateType);
              this.openSnackBar('Unable to update!', 'Ok');
            }
          );
      })
      .catch(error => {
        console.log(error);
        this.isUpdatingJourney = false;
        this.resetModes(updateType);
        this.openSnackBar(error.message, 'Ok');
      });
  }

  checkValidation(journey: JourneyModel): Promise<any> {
    return new Promise((res, rej) => {
      if (journey.status === JourneyStatus.completed) {
        rej({ message: 'Cannot update after journey is complete' });
      } else if (this.journeyTitle.length > 30) {
        rej({ message: 'Title length should not be more than 30 characters.' });
      } else if (this.journeyTitle.length === 0) {
        rej({ message: 'Title cannot be blank.' });
      } else {
        res({ message: 'Good to go' });
      }
    });
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
