

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserFilterModel } from 'app/models/filter-model';
import { Competency } from 'app/models/competency.model';
import { Team } from 'app/models/team-model';

@Component({
  selector: 'app-user-list-filter-dialog',
  templateUrl: './user-list-filter-dialog.component.html',
  styleUrls: ['./user-list-filter-dialog.component.scss']
})
export class UserListFilterDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserListFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { allFilterData: UserFilterModel, selectedFilterData: UserFilterModel },
  ) { }

  selectedFilter: UserFilterModel = new UserFilterModel();
  selectedComp: Array<String> = [];
  selectedTeam: Array<Number> = [];
  selectedStatus: Array<String> = [];
  selectedOwner: Array<Number> = [];

  ngOnInit(): void {
    this.selectedFilter = this.data.selectedFilterData;
    this.selectedComp = this.selectedFilter.comps.map(comp => comp._id);
    this.selectedTeam = this.selectedFilter.teams.map(team => team.id);
    this.selectedStatus = this.selectedFilter.journeyStatuses.map(stat => stat.name);
    // this.selectedOwner = this.selectedFilter.owners.map(owner => owner.id);
  }

  selectComp(ev): void {
    const { value } = ev;
    const selectedComps = this.data.allFilterData.comps.filter(comp => value.includes(comp._id));
    this.selectedFilter.comps = selectedComps;
  }

  removeComp(compToRemove: Competency): void {
    this.selectedFilter.comps = this.selectedFilter.comps.filter(comp => comp._id !== compToRemove._id);
    this.selectedComp = this.selectedFilter.comps.map(comp => comp._id);
  }

  selectTeam(ev): void {
    const { value } = ev;
    const selectedTeams = this.data.allFilterData.teams.filter(team => value.includes(team.id));
    this.selectedFilter.teams = selectedTeams;
  }

  removeTeam(teamToRemove: Team): void {
    this.selectedFilter.teams = this.selectedFilter.teams.filter(team => team.id !== teamToRemove.id);
    this.selectedTeam = this.selectedFilter.teams.map(team => team.id);
  }

  selectStatus(ev): void {
    const { value } = ev;
    const selectedStatuses = this.data.allFilterData.journeyStatuses.filter(comp => value.includes(comp.name));
    this.selectedFilter.journeyStatuses = selectedStatuses;
  }

  removeStatus(statusToRemove: any): void {
    this.selectedFilter.journeyStatuses = this.selectedFilter.journeyStatuses.filter(stat => stat.name !== statusToRemove.name);
    this.selectedComp = this.selectedFilter.journeyStatuses.map(comp => comp._id);
  }

  clear(): void {
    this.selectedFilter = new UserFilterModel();
    this.selectedComp = [];
    this.dialogRef.close(this.selectedFilter);
  }

  applyFilter(): void {
    this.dialogRef.close(this.selectedFilter);
  }

}
