import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { ButtonOpts } from 'mat-progress-buttons';
import { MatDialog, MatSnackBar } from '@angular/material';

import { DataSource } from '@angular/cdk/collections';

import * as shape from 'd3-shape';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { MessageService } from 'app/services/subjects.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { CuratedContentType } from 'app/enums/curated-content-type';
import { User } from 'app/models/user';

@Component({
  selector: 'journey-competency-distribution',
  templateUrl: './journey-competency-distribution.component.html',
  styleUrls: ['./journey-competency-distribution.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class JourneyCompDistComponent implements OnInit, OnDestroy {
  @Input() teamFilter: any;
  user: User;
  mainChart: any[];
  private _unsubscribeAll: Subject<any>;
  widget6: any = {};
  subscription: Subscription;
  selectedTeam: any;
  dateNow = Date.now();
  journeyBreakdownData: boolean;
  totalJourneyCount = 0;

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.journeyBreakdownData = true;
    this.user = <User>this.localStorageService.get('user');
    this.widget6 = {
      currentRange: 'TW',
      legend: false,
      explodeSlices: false,
      labels: true,
      doughnut: true,
      gradient: false,
      scheme: {
        domain: ['#673AB7', '#009688', '#FF9800']
      },
      onSelect: (ev) => {
        console.log(ev);
      }
    };
    console.log('donut');

    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      console.log(message.selectedTeam);
      this.selectedTeam = message.selectedTeam;

      this.getViewsContent();
    });
    if (this.teamFilter.length > 0) {
      this.selectedTeam = this.teamFilter;
      this.getViewsContent();
    }
    // this.getViewsContent();
  }

  getViewsContent(): void {
    this.journeyBreakdownData = true;
    const teamIds = [];
    let teamFilter = '';
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = 'managerId=' + this.user['id'] + '&activityType=18' + teamFilter;
    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL + '?' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          this.journeyBreakdownData = false;
          this.mainChart = [];
          this.totalJourneyCount = 0;
          for (let i = 0; i < data['resource'][0]['journey_comp_dist'].length; i++) {
            const tag = data['resource'][0]['journey_comp_dist'][i];
            this.mainChart.push({
              'name': tag.name,
              'value': tag.count
            });
            this.totalJourneyCount += tag.count;
          }
        },
        error => {
          console.log(error);
          this.journeyBreakdownData = false;
          this.requestHelpers.catchExceptions(error);
        }
      );
  }
  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
