import { Component, OnDestroy, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { MessageService } from 'app/services/subjects.service';

import * as shape from 'd3-shape';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { User } from 'app/models/user';
import { Subscription } from 'rxjs';


@Component({
  selector: 'journey-graphs',
  templateUrl: './journey-graphs.component.html',
  styleUrls: ['./journey-graphs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class JourneyGraphComponent implements OnInit, OnDestroy {
  user: User;
  @Input() teamFilter: any;
  selectedWidget = 'OVERDUE';
  pageSize = 4;
  dataLength = 0;

  subscription: Subscription;
  selectedTeam: any;

  dataAvailable = false;

  widgets = {
    'widget5': {
      'title': '',
      'ranges': {
        'TW': 'DAY',
        'LW': 'WEEK',
        'MW': 'MONTH'
      },
      'supporting': {
        'OVERDUE': {
          'label': 'OVERDUE',
          'displayText': 'Past due',
          'count': {
            'MW': 0,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            'MW': [
              {
                'name': 'OVERDUE',
                'series': []
              }
            ],
            'LW': [
              {
                'name': 'OVERDUE',
                'series': []
              }
            ],
            'TW': [
              {
                'name': 'OVERDUE',
                'series': []
              }
            ]
          },
          'list': {
            'totalLength' : 0,
            'title' : 'Journey Past due by Employee',
            'MW' : [],
            'LW' : [],
            'TW' : []
          },
          'dataAvailable': {
            'MW': false,
            'LW': false,
            'TW': false,
          },
          'loading': {
            'MW': false,
            'LW': false,
            'TW': false,
          }
        },
        'COMPLETE': {
          'label': 'COMPLETE',
          'displayText': 'Completed',
          'count': {
            'MW': 0,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            'MW': [
              {
                'name': 'COMPLETE',
                'series': []
              }
            ],
            'LW': [
              {
                'name': 'COMPLETE',
                'series': []
              }
            ],
            'TW': [
              {
                'name': 'COMPLETE',
                'series': []
              }
            ]
          },
          'list': {
            'totalLength' : 0,
            'title' : 'Journey Completed by Employee',
            'MW' : [],
            'LW' : [],
            'TW' : []
          },
          'dataAvailable': {
            'MW': false,
            'LW': false,
            'TW': false,
          },
          'loading': {
            'MW': false,
            'LW': false,
            'TW': false,
          }
        },
        'ONGOING': {
          'label': 'ONGOING',
          'displayText': 'Ongoing',
          'count': {
            'MW': 0,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            'MW': [
              {
                'name': 'ONGOING',
                'series': []
              }
            ],
            'LW': [
              {
                'name': 'ONGOING',
                'series': []
              }
            ],
            'TW': [
              {
                'name': 'ONGOING',
                'series': []
              }
            ]
          },
          'list': {
            'totalLength' : 0,
            'title' : 'Active Journeys by Employee',
            'MW' : [],
            'LW' : [],
            'TW' : []
          },
          'dataAvailable': {
            'MW': false,
            'LW': false,
            'TW': false,
          },
          'loading': {
            'MW': false,
            'LW': false,
            'TW': false,
          }
        },
        'YET_TO_BEGIN': {
          'label': 'YET_TO_BEGIN',
          'displayText': 'Not Started',
          'count': {
            'MW': 0,
            'LW': 0,
            'TW': 0
          },
          'chart': {
            'MW': [
              {
                'name': 'YET_TO_BEGIN',
                'series': []
              }
            ],
            'LW': [
              {
                'name': 'YET_TO_BEGIN',
                'series': []
              }
            ],
            'TW': [
              {
                'name': 'YET_TO_BEGIN',
                'series': []
              }
            ]
          },
          'list': {
            'totalLength' : 0,
            'title' : 'Journeys Not Yet Started for Employee',
            'MW' : [],
            'LW' : [],
            'TW' : []
          },
          'dataAvailable': {
            'MW': false,
            'LW': false,
            'TW': false,
          },
          'loading': {
            'MW': false,
            'LW': false,
            'TW': false,
          }
        },

      }
    },
  };

  widget5 = {
    currentRange: 'LW',
    xAxis: true,
    yAxis: true,
    gradient: false,
    legend: false,
    showXAxisLabel: false,
    showYAxisLabel: false,
    scheme: {
      domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
    },
    'customColors': [
      {
        'name': 'OVERDUE',
        'value': '#FFC107'
      },
      {
        'name': 'COMPLETE',
        'value': '#558b2f'
      },
      {
        'name': 'ONGOING',
        'value': '#8BC34A'
      },
      {
        'name': 'YET_TO_BEGIN',
        'value': '#21579b'
      }
    ],
    onSelect: (ev) => {
      console.log(ev);
    },
    supporting: {
      currentRange: '',
      xAxis: false,
      yAxis: false,
      gradient: false,
      legend: false,
      showXAxisLabel: false,
      xAxisLabel: 'Days',
      showYAxisLabel: false,
      yAxisLabel: 'Isues',
      scheme: {
        domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
      },
      curve: shape.curveBasis
    }
  };

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    private localStorageService: LocalStorageService,
    private messageService: MessageService,
  ) {
    this.user = <User>localStorageService.get('user');

  }

  switchTab(widgetLabel: string): void {
    this.selectedWidget = widgetLabel;
  }

  timeFilterChange(filter: string): void {
    this.widget5.currentRange = filter;
    if (this.widget5.currentRange === 'TW'){
      this.getDayData({ pageIndex: 0 });
    }
    if (this.widget5.currentRange === 'LW'){
      this.getWeekData({ pageIndex: 0 });
    }
    if (this.widget5.currentRange === 'MW'){
      this.getMonthData({ pageIndex: 0 });
    }
  }

  ngOnInit(): void {

    this.subscription = this.messageService.getTeamFilter().subscribe(message => {
      console.log(message.selectedTeam);
      this.selectedTeam = message.selectedTeam;

    this.onPaginateChange({ pageIndex: 0 });
    });
    if (this.teamFilter.length > 0){
      this.selectedTeam = this.teamFilter;
      this.onPaginateChange({ pageIndex: 0 });
    }
  }

  private getDayData($event: any): void {
    const teamIds = [];
    let teamFilter = '';
    console.log(this.selectedTeam);
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=52' + teamFilter;

    this.widgets.widget5.supporting.COMPLETE.dataAvailable.TW = false;
    this.widgets.widget5.supporting.COMPLETE.loading.TW = true;
    this.widgets.widget5.supporting.ONGOING.dataAvailable.TW = false;
    this.widgets.widget5.supporting.ONGOING.loading.TW = true;
    this.widgets.widget5.supporting.OVERDUE.dataAvailable.TW = false;
    this.widgets.widget5.supporting.OVERDUE.loading.TW = true;
    this.widgets.widget5.supporting.YET_TO_BEGIN.dataAvailable.TW = false;
    this.widgets.widget5.supporting.YET_TO_BEGIN.loading.TW = true;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&interval=DAY&datapoints=7' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          for (const dtype in data['resource'][0]) {
            if (data['resource'][0][dtype]) {
              let activityModel = null;

              switch (dtype) {
                case 'journey_complete':
                  activityModel = 'COMPLETE';
                  break;
                case 'journey_start':
                  activityModel = 'ONGOING';
                  break;

                case 'journey_defaulted':
                  activityModel = 'OVERDUE';
                  break;

                case 'journey_not_started':
                  activityModel = 'YET_TO_BEGIN';
                  break;
              }
              console.log(activityModel);
              if (activityModel) {
                this.widgets.widget5.supporting[activityModel].chart.TW[0].series = [];
                for (const i in data['resource'][0][dtype]['data']) { // chart
                  if (data['resource'][0][dtype]['data'][i]) {
                    const cobj = {
                      name : new Date(data['resource'][0][dtype]['data'][i]['endDate']),
                      value: data['resource'][0][dtype]['data'][i]['count']
                    };
                    this.widgets.widget5.supporting[activityModel].chart.TW[0].series.push(cobj);
                  }
                }
                if (data['resource'][0][dtype]['list'].length > 0){
                  this.widgets.widget5.supporting[activityModel].dataAvailable.TW = true;
                }
                this.widgets.widget5.supporting[activityModel].loading.TW = false;
                this.widgets.widget5.supporting[activityModel].count.TW = data['resource'][0][dtype]['currentCount'];
                this.widgets.widget5.supporting[activityModel].list.TW = data['resource'][0][dtype]['list'];
                this.widgets.widget5.supporting[activityModel].list.totalLength = data['resource'][0][dtype]['list']['totalCount'];
              }
            }
          }
          this.dataAvailable = true;
        },
        error => {
          console.log(error);
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  private getWeekData($event: any): void {
    const teamIds = [];
    let teamFilter = '';
    console.log(this.selectedTeam);
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=52' + teamFilter;

    this.widgets.widget5.supporting.COMPLETE.dataAvailable.LW = false;
    this.widgets.widget5.supporting.COMPLETE.loading.LW = true;
    this.widgets.widget5.supporting.ONGOING.dataAvailable.LW = false;
    this.widgets.widget5.supporting.ONGOING.loading.LW = true;
    this.widgets.widget5.supporting.OVERDUE.dataAvailable.LW = false;
    this.widgets.widget5.supporting.OVERDUE.loading.LW = true;
    this.widgets.widget5.supporting.YET_TO_BEGIN.dataAvailable.LW = false;
    this.widgets.widget5.supporting.YET_TO_BEGIN.loading.LW = true;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&interval=WEEK&datapoints=4' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          for (const dtype in data['resource'][0]) {
            if (data['resource'][0][dtype]) {
              let activityModel = null;

              switch (dtype) {
                case 'journey_complete':
                  activityModel = 'COMPLETE';
                  break;
                case 'journey_start':
                  activityModel = 'ONGOING';
                  break;

                case 'journey_defaulted':
                  activityModel = 'OVERDUE';
                  break;

                case 'journey_not_started':
                  activityModel = 'YET_TO_BEGIN';
                  break;
              }
              console.log(activityModel);
              if (activityModel) {
                this.widgets.widget5.supporting[activityModel].chart.LW[0].series = [];
                for (const i in data['resource'][0][dtype]['data']) { // chart
                  if (data['resource'][0][dtype]['data'][i]) {
                    const cobj = {
                      name : new Date(data['resource'][0][dtype]['data'][i]['endDate']),
                      value: data['resource'][0][dtype]['data'][i]['count']
                    };
                    this.widgets.widget5.supporting[activityModel].chart.LW[0].series.push(cobj);
                  }
                }
                if (data['resource'][0][dtype]['list'].length > 0){
                  this.widgets.widget5.supporting[activityModel].dataAvailable.LW = true;
                }
                this.widgets.widget5.supporting[activityModel].loading.LW = false;
                this.widgets.widget5.supporting[activityModel].count.LW = data['resource'][0][dtype]['currentCount'];
                this.widgets.widget5.supporting[activityModel].list.LW = data['resource'][0][dtype]['list'];
                this.widgets.widget5.supporting[activityModel].list.totalLength = data['resource'][0][dtype]['list']['totalCount'];
              }
            }
          }
          this.dataAvailable = true;
        },
        error => {
          console.log(error);
          this.requestHelpers.catchExceptions(error);
        }
      );
  }

  private getMonthData($event: any): void {
    const teamIds = [];
    let teamFilter = '';
    console.log(this.selectedTeam);
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=52' + teamFilter;

    this.widgets.widget5.supporting.COMPLETE.dataAvailable.MW = false;
    this.widgets.widget5.supporting.COMPLETE.loading.MW = true;
    this.widgets.widget5.supporting.ONGOING.dataAvailable.MW = false;
    this.widgets.widget5.supporting.ONGOING.loading.MW = true;
    this.widgets.widget5.supporting.OVERDUE.dataAvailable.MW = false;
    this.widgets.widget5.supporting.OVERDUE.loading.MW = true;
    this.widgets.widget5.supporting.YET_TO_BEGIN.dataAvailable.MW = false;
    this.widgets.widget5.supporting.YET_TO_BEGIN.loading.MW = true;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&interval=MONTH&datapoints=4' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {
          for (const dtype in data['resource'][0]) {
            if (data['resource'][0][dtype]) {
              let activityModel = null;

              switch (dtype) {
                case 'journey_complete':
                  activityModel = 'COMPLETE';
                  break;
                case 'journey_start':
                  activityModel = 'ONGOING';
                  break;

                case 'journey_defaulted':
                  activityModel = 'OVERDUE';
                  break;

                case 'journey_not_started':
                  activityModel = 'YET_TO_BEGIN';
                  break;
              }
              console.log(activityModel);
              if (activityModel) {
                this.widgets.widget5.supporting[activityModel].chart.MW[0].series = [];
                for (const i in data['resource'][0][dtype]['data']) { // chart
                  if (data['resource'][0][dtype]['data'][i]) {
                    const cobj = {
                      name : new Date(data['resource'][0][dtype]['data'][i]['endDate']),
                      value: data['resource'][0][dtype]['data'][i]['count']
                    };
                    this.widgets.widget5.supporting[activityModel].chart.MW[0].series.push(cobj);
                  }
                }
                if (data['resource'][0][dtype]['list'].length > 0){
                  this.widgets.widget5.supporting[activityModel].dataAvailable.MW = true;
                }
                this.widgets.widget5.supporting[activityModel].loading.MW = false;
                this.widgets.widget5.supporting[activityModel].count.MW = data['resource'][0][dtype]['currentCount'];
                this.widgets.widget5.supporting[activityModel].list.MW = data['resource'][0][dtype]['list'];
                this.widgets.widget5.supporting[activityModel].list.totalLength = data['resource'][0][dtype]['list']['totalCount'];
              }
            }
          }
          this.dataAvailable = true;
        },
        error => {
          console.log(error);
          this.requestHelpers.catchExceptions(error);
        }
      );
  }


  onPaginateChange($event: any): void {

    this.getWeekData($event);
    /*
    const teamIds = [];
    let teamFilter = '';
    console.log(this.selectedTeam);
    if (this.selectedTeam.length > 0) {
      this.selectedTeam.forEach(item => {
        teamIds.push(item.id);
      });
      console.log(teamIds.toString());
      teamFilter = '&teamIds=' + teamIds.toString() + '&datamode=2';

    }
    const filter = '&managerId=' + this.user['id'] + '&activityType=52' + teamFilter;

    this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&interval=DAY&datapoints=7' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {

          // complete
          this.widgets.widget5.supporting.COMPLETE.chart.TW[0].series = [];

          for (const i in data['resource'][0]['journey_complete']['data']) { // chart
            if (data['resource'][0]['journey_complete']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_complete']['data'][i]['endDate']),
                value: data['resource'][0]['journey_complete']['data'][i]['count']
              };
              this.widgets.widget5.supporting.COMPLETE.chart.TW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.COMPLETE.count.TW = data['resource'][0]['journey_complete']['currentCount'];
          this.widgets.widget5.supporting.COMPLETE.list.TW = data['resource'][0]['journey_complete']['list'];
          this.widgets.widget5.supporting.COMPLETE.list.totalLength = data['resource'][0]['journey_complete']['list']['totalCount'];

          // ongoing
          this.widgets.widget5.supporting.ONGOING.chart.TW[0].series = [];

          for (const i in data['resource'][0]['journey_start']['data']) { // chart
            if (data['resource'][0]['journey_start']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_start']['data'][i]['endDate']),
                value: data['resource'][0]['journey_start']['data'][i]['count']
              };
              this.widgets.widget5.supporting.ONGOING.chart.TW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.ONGOING.count.TW = data['resource'][0]['journey_start']['currentCount'];
          this.widgets.widget5.supporting.ONGOING.list.TW = data['resource'][0]['journey_start']['list'];
          this.widgets.widget5.supporting.ONGOING.list.totalLength = data['resource'][0]['journey_start']['list']['totalCount'];

          // defaulted
          this.widgets.widget5.supporting.OVERDUE.chart.TW[0].series = [];

          for (const i in data['resource'][0]['journey_defaulted']['data']) { // chart
            if (data['resource'][0]['journey_defaulted']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_defaulted']['data'][i]['endDate']),
                value: data['resource'][0]['journey_defaulted']['data'][i]['count']
              };
              this.widgets.widget5.supporting.OVERDUE.chart.TW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.OVERDUE.count.TW = data['resource'][0]['journey_defaulted']['currentCount'];
          this.widgets.widget5.supporting.OVERDUE.list.TW = data['resource'][0]['journey_defaulted']['list'];
          this.widgets.widget5.supporting.OVERDUE.list.totalLength = data['resource'][0]['journey_defaulted']['list']['totalCount'];

          // not started
          this.widgets.widget5.supporting.YET_TO_BEGIN.chart.TW[0].series = [];

          for (const i in data['resource'][0]['journey_not_started']['data']) { // chart
            if (data['resource'][0]['journey_not_started']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_not_started']['data'][i]['endDate']),
                value: data['resource'][0]['journey_not_started']['data'][i]['count']
              };
              this.widgets.widget5.supporting.YET_TO_BEGIN.chart.TW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.YET_TO_BEGIN.count.TW = data['resource'][0]['journey_not_started']['currentCount'];
          this.widgets.widget5.supporting.YET_TO_BEGIN.list.TW = data['resource'][0]['journey_not_started']['list'];
          this.widgets.widget5.supporting.YET_TO_BEGIN.list.totalLength = data['resource'][0]['journey_not_started']['list']['totalCount'];
          this.dataAvailable = true;
        },
        error => {
          console.log(error);

          this.requestHelpers.catchExceptions(error);
        }
      );

      this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&interval=WEEK&datapoints=4' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {

          // complete
          this.widgets.widget5.supporting.COMPLETE.chart.LW[0].series = [];

          for (const i in data['resource'][0]['journey_complete']['data']) { // chart
            if (data['resource'][0]['journey_complete']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_complete']['data'][i]['endDate']),
                value: data['resource'][0]['journey_complete']['data'][i]['count']
              };
              this.widgets.widget5.supporting.COMPLETE.chart.LW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.COMPLETE.count.LW = data['resource'][0]['journey_complete']['currentCount'];
          this.widgets.widget5.supporting.COMPLETE.list.LW = data['resource'][0]['journey_complete']['list'];
          this.widgets.widget5.supporting.COMPLETE.list.totalLength = data['resource'][0]['journey_complete']['list']['totalCount'];

          // ongoing
          this.widgets.widget5.supporting.ONGOING.chart.LW[0].series = [];

          for (const i in data['resource'][0]['journey_start']['data']) { // chart
            if (data['resource'][0]['journey_start']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_start']['data'][i]['endDate']),
                value: data['resource'][0]['journey_start']['data'][i]['count']
              };
              this.widgets.widget5.supporting.ONGOING.chart.LW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.ONGOING.count.LW = data['resource'][0]['journey_start']['currentCount'];
          this.widgets.widget5.supporting.ONGOING.list.LW = data['resource'][0]['journey_start']['list'];
          this.widgets.widget5.supporting.ONGOING.list.totalLength = data['resource'][0]['journey_start']['list']['totalCount'];

          // defaulted
          this.widgets.widget5.supporting.OVERDUE.chart.LW[0].series = [];

          for (const i in data['resource'][0]['journey_defaulted']['data']) { // chart
            if (data['resource'][0]['journey_defaulted']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_defaulted']['data'][i]['endDate']),
                value: data['resource'][0]['journey_defaulted']['data'][i]['count']
              };
              this.widgets.widget5.supporting.OVERDUE.chart.LW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.OVERDUE.count.LW = data['resource'][0]['journey_defaulted']['currentCount'];
          this.widgets.widget5.supporting.OVERDUE.list.LW = data['resource'][0]['journey_defaulted']['list'];
          this.widgets.widget5.supporting.OVERDUE.list.totalLength = data['resource'][0]['journey_defaulted']['list']['totalCount'];

          // not started
          this.widgets.widget5.supporting.YET_TO_BEGIN.chart.LW[0].series = [];

          for (const i in data['resource'][0]['journey_not_started']['data']) { // chart
            if (data['resource'][0]['journey_not_started']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_not_started']['data'][i]['endDate']),
                value: data['resource'][0]['journey_not_started']['data'][i]['count']
              };
              this.widgets.widget5.supporting.YET_TO_BEGIN.chart.LW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.YET_TO_BEGIN.count.LW = data['resource'][0]['journey_not_started']['currentCount'];
          this.widgets.widget5.supporting.YET_TO_BEGIN.list.LW = data['resource'][0]['journey_not_started']['list'];
          this.widgets.widget5.supporting.YET_TO_BEGIN.list.totalLength = data['resource'][0]['journey_not_started']['list']['totalCount'];
        },
        error => {
          console.log(error);

          this.requestHelpers.catchExceptions(error);
        }
      );

      this.http
      .get(
        environment.BRAINIAC_BASE_URL +
        environment.ANALYTICS_URL +
        '?offset=' + $event.pageIndex +
        '&limit=' + this.pageSize + '&interval=MONTH&datapoints=6' +
        filter, this.requestHelpers.getBFFHeader())
      .subscribe(
        data => {

          // complete
          this.widgets.widget5.supporting.COMPLETE.chart.MW[0].series = [];

          for (const i in data['resource'][0]['journey_complete']['data']) { // chart
            if (data['resource'][0]['journey_complete']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_complete']['data'][i]['endDate']),
                value: data['resource'][0]['journey_complete']['data'][i]['count']
              };
              this.widgets.widget5.supporting.COMPLETE.chart.MW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.COMPLETE.count.MW = data['resource'][0]['journey_complete']['currentCount'];
          this.widgets.widget5.supporting.COMPLETE.list.MW = data['resource'][0]['journey_complete']['list'];
          this.widgets.widget5.supporting.COMPLETE.list.totalLength = data['resource'][0]['journey_complete']['list']['totalCount'];

          // ongoing
          this.widgets.widget5.supporting.ONGOING.chart.MW[0].series = [];

          for (const i in data['resource'][0]['journey_start']['data']) { // chart
            if (data['resource'][0]['journey_start']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_start']['data'][i]['endDate']),
                value: data['resource'][0]['journey_start']['data'][i]['count']
              };
              this.widgets.widget5.supporting.ONGOING.chart.MW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.ONGOING.count.MW = data['resource'][0]['journey_start']['currentCount'];
          this.widgets.widget5.supporting.ONGOING.list.MW = data['resource'][0]['journey_start']['list'];
          this.widgets.widget5.supporting.ONGOING.list.totalLength = data['resource'][0]['journey_start']['list']['totalCount'];

          // defaulted
          this.widgets.widget5.supporting.OVERDUE.chart.MW[0].series = [];

          for (const i in data['resource'][0]['journey_defaulted']['data']) { // chart
            if (data['resource'][0]['journey_defaulted']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_defaulted']['data'][i]['endDate']),
                value: data['resource'][0]['journey_defaulted']['data'][i]['count']
              };
              this.widgets.widget5.supporting.OVERDUE.chart.MW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.OVERDUE.count.MW = data['resource'][0]['journey_defaulted']['currentCount'];
          this.widgets.widget5.supporting.OVERDUE.list.MW = data['resource'][0]['journey_defaulted']['list'];
          this.widgets.widget5.supporting.OVERDUE.list.totalLength = data['resource'][0]['journey_defaulted']['list']['totalCount'];

          // not started
          this.widgets.widget5.supporting.YET_TO_BEGIN.chart.MW[0].series = [];

          for (const i in data['resource'][0]['journey_not_started']['data']) { // chart
            if (data['resource'][0]['journey_not_started']['data'][i]) {
              const cobj = {
                name : new Date(data['resource'][0]['journey_not_started']['data'][i]['endDate']),
                value: data['resource'][0]['journey_not_started']['data'][i]['count']
              };
              this.widgets.widget5.supporting.YET_TO_BEGIN.chart.MW[0].series.push(cobj);
            }
          }
          this.widgets.widget5.supporting.YET_TO_BEGIN.count.MW = data['resource'][0]['journey_not_started']['currentCount'];
          this.widgets.widget5.supporting.YET_TO_BEGIN.list.MW = data['resource'][0]['journey_not_started']['list'];
          this.widgets.widget5.supporting.YET_TO_BEGIN.list.totalLength = data['resource'][0]['journey_not_started']['list']['totalCount'];
        },
        error => {
          console.log(error);

          this.requestHelpers.catchExceptions(error);
        }
      );*/
  }

  ngOnDestroy(): void {
  }
}
