import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { LocalStorageService } from 'ngx-store';
import { ImageService } from '../../services/images.service';
import { RequestHelpers } from '../../services/request-helpers.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { MessageService } from 'app/services/subjects.service';
import * as firebase from 'firebase';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable()
export class ChatService implements Resolve<any>
{
  contacts: any[];
  chats: any[];
  user: any;
  details: any;
  onChatSelected: BehaviorSubject<any>;
  onContactSelected: BehaviorSubject<any>;
  onChatsUpdated: Subject<any>;
  onUserUpdated: Subject<any>;
  onLeftSidenavViewChanged: Subject<any>;
  onRightSidenavViewChanged: Subject<any>;
  localUser: any;
  dialog: any;
  subscription: Subscription;
  removedOn: boolean;
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    // private db: AngularFireDatabase,
    private db: AngularFirestore,
    private requestHelpers: RequestHelpers,
    private imageService: ImageService,
    private messageService: MessageService,
  ) {

    this.subscription = this.messageService.getChatMessage().subscribe(message => {
      // console.log(message);
      this.details = message.journey;
      // if  (this.details['channelId']){
      //   console.log('this.details', this.details);
      // }else{
      //   return;
      // }
    });

    // this.details = this.localStorageService.get('journeyDetails');
    this.localUser = this.localStorageService.get('user');
    // Set the defaults
    this.onChatSelected = new BehaviorSubject(null);
    this.onContactSelected = new BehaviorSubject(null);
    this.onChatsUpdated = new Subject();
    this.onUserUpdated = new Subject();
    this.onLeftSidenavViewChanged = new Subject();
    this.onRightSidenavViewChanged = new Subject();
    this.removedOn = false;
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getContacts(),
        this.getChats(),
        this.getUser()
      ]).then(
        ([contacts, chats, user]) => {
          this.contacts = contacts;
          this.chats = chats;
          this.user = user;
          resolve();
        },
        reject
      );
    });
  }

  setData(inputData): Promise<any> {
    // console.log(inputData);
    return new Promise((resolve, reject) => {
      this.details = inputData;
      resolve({ status: true });
    });
  }

  /**
   * Get chat
   *
   * @param contactId
   * @returns {Promise<any>}
   */
  getChat(contactId): Promise<any> {

    return new Promise((resolve, reject) => {
      // debugger;
      const avatar = this.localUser['fileDetails_by_profileImage'] ? this.localUser['fileDetails_by_profileImage']['url'] : null;
      this.user = {
        id: this.localUser['id'],
        avatar: avatar,
        name: this.localUser['firstName'] + ' ' + this.localUser['lastName'],
        chatList: []
      };
      const chatObj = {
        chatId: this.details['channelId'],
        contact: {
          avatar: this.imageService.getUserProfileImage(null),
          id: this.details['channelId'],
          name: this.details['channelName']
        },
        dialog: []
      };
      let groupInfo;
      const snap = this.db.doc('channels/' + this.details['channelId']).snapshotChanges();
      this.removedOn = false;
      let removedOnTime;
      snap.subscribe(items => {
        groupInfo = items.payload.data();
        // console.log('items', groupInfo);
        groupInfo['image'] = this.imageService.getUserProfileImage(groupInfo ? groupInfo['image'] : null);

        groupInfo['membersMeta'].forEach(item => {

          
          if (this.localUser['id'] === item['userId']) {
            if (item['removedOn']) {
              this.removedOn = true;
              removedOnTime = item['removedOn'].toDate();
            }
          }
        });

      });

      let firebaseMessages = null;
      if (this.removedOn) {
        firebaseMessages = this.db.collection
          ('messages', ref => ref
            .where('channelId', '==', this.details.channelId)
            .where('createdOn', '<=', removedOnTime)
            .orderBy('createdOn')).snapshotChanges();
      } else {
        firebaseMessages = this.db.collection
          ('messages', ref => ref.where('channelId', '==', this.details['channelId']).orderBy('createdOn')).snapshotChanges();
      }
      // console.log('channelId', this.details['channelId']);



      // const firebaseMessages = this.db.list('messages', ref => ref.orderByChild(`channelId`).equalTo(this.details['channelId']));

      // Load the chat
      // console.log(firebaseMessages);

      firebaseMessages.subscribe(firebaseitems => {
        this.dialog = [];

        const channelMessages = [];
        firebaseitems.forEach(item => {
          const key = item.payload.doc.id;
          const data = item.payload.doc.data();
          const chanObj = {
            messageId: key,
            ...data
          };
          channelMessages.push(chanObj);

        });
        // console.log('channel Message', channelMessages);
        channelMessages.forEach(items => {
          const imageURL = items['from'] && items['from']['publicProfileId'] ? items['from']['publicProfileId'] : null;
          let messageOnType = '';

          if (items['type'] === 'ACTIVITY') {
            messageOnType = items['content']['text'];
          }
          if (items['type'] === 'TEXT') {
            messageOnType = items['content']['text'];
          }
          const tempdialog = {
            'who': items['from'] && items['from']['userId'] ? Number(items['from']['userId']) : null,
            'message': messageOnType,
            'time': items['createdOn'].toDate(),
            'name': items['from'] ? items['from']['firstName'] + ' ' + items['from']['lastName'] : null,
            'avatar': this.imageService.getUserProfileImage(imageURL),
            'type': items['type']
          };
          this.dialog.push(tempdialog);
        });

        const chatData = {
          chatId: '1725a680b3249760ea21de52',
          removedOn : this.removedOn,
          dialog: this.dialog,
          contact: {
            'id': this.details['channelId'],
            'name': this.details['channelName'],
            'avatar': this.imageService.getUserProfileImage(null),
            // 'status': 'online',
            // 'mood': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...'
          }
        };

        // console.log('chatData', chatData);
        this.onChatSelected.next({ ...chatData });

      });

    });

  }

  /**
   * Create new chat
   *
   * @param contactId
   * @returns {Promise<any>}
   */
  createNewChat(contactId): Promise<any> {
    return new Promise((resolve, reject) => {

      const contact = this.contacts.find((item) => {
        return item.id === contactId;
      });

      const chatId = FuseUtils.generateGUID();

      const chat = {
        id: chatId,
        dialog: []
      };

      const chatListItem = {
        contactId: contactId,
        id: chatId,
        lastMessageTime: '2017-02-18T10:30:18.931Z',
        name: contact.name,
        unread: null
      };

      // Add new chat list item to the user's chat list
      this.user.chatList.push(chatListItem);

      // Post the created chat
      this._httpClient.post('api/chat-chats', { ...chat })
        .subscribe((response: any) => {

          // Post the new the user data
          this._httpClient.post('api/chat-user/' + this.user.id, this.user)
            .subscribe(newUserData => {

              // Update the user data from server
              this.getUser().then(updatedUser => {
                this.onUserUpdated.next(updatedUser);
                resolve(updatedUser);
              });
            });
        }, reject);
    });
  }

  /**
   * Select contact
   *
   * @param contact
   */
  selectContact(contact): void {
    this.onContactSelected.next(contact);
  }

  /**
   * Set user status
   *
   * @param status
   */
  setUserStatus(status): void {
    this.user.status = status;
  }

  /**
   * Update user data
   *
   * @param userData
   */
  updateUserData(userData): void {
    this._httpClient.post('api/chat-user/' + this.user.id, userData)
      .subscribe((response: any) => {
        this.user = userData;
      }
      );
  }

  /**
   * Update the chat dialog
   *
   * @param chatId
   * @param dialog
   * @returns {Promise<any>}
   */
  updateDialog(chatId, dialog): Promise<any> {
    return new Promise((resolve, reject) => {

      const newData = {
        id: chatId,
        dialog: dialog
      };

      this._httpClient.post('api/chat-chats/' + chatId, newData)
        .subscribe(updatedChat => {
          resolve(updatedChat);
        }, reject);
    });
  }

  /**
   * Get contacts
   *
   * @returns {Promise<any>}
   */
  getContacts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/chat-contacts')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get chats
   *
   * @returns {Promise<any>}
   */
  getChats(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/chat-chats')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Get user
   *
   * @returns {Promise<any>}
   */
  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/chat-user')
        .subscribe((response: any) => {
          resolve(response[0]);
        }, reject);
    });
  }
}
