import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRippleModule,
  MatSelectModule,
  MatDialogModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatTableModule
} from '@angular/material';

import { NgxDnDModule } from '@swimlane/ngx-dnd';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { JourneyTasksService } from 'app/shared/journey-tasks/journey-tasks.service';
import { JourneyTasksComponent } from 'app/shared/journey-tasks/journey-tasks.component';
import { TaskListItemComponent } from 'app/shared/journey-tasks/task-list-item/task-list-item.component';
import { MatProgressButtons } from 'mat-progress-buttons';
import { CorePipesModule } from 'app/pipes/pipes.module';
import { TaskDetailsComponent } from './dialogs/task-details/task-details.component';
import { DeleteTaskConfirmationComponent } from './dialogs/delete-task-confirmation/delete-task-confirmation.component';

@NgModule({
  declarations: [
    JourneyTasksComponent,
    TaskListItemComponent,
    TaskDetailsComponent,
    DeleteTaskConfirmationComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    MatDialogModule,
    NgxDnDModule,
    FuseSharedModule,
    FuseSidebarModule,
    MatProgressBarModule,
    MatProgressButtons,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTableModule,
    CorePipesModule,
  ],
  providers: [
    JourneyTasksService
  ],
  exports: [
    JourneyTasksComponent
  ],
  entryComponents: [
    TaskDetailsComponent,
    DeleteTaskConfirmationComponent
  ]
})
export class JourneyTasksModule {
}
