import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class FeedbackListService {
    constructor(
        private http: HttpClient,
        private requestHelpers: RequestHelpers,
    ) { }

    fetchJourneys({ filter, pageIndex, pageSize, order }): Observable<any> {
        return this.http
            .get(
                environment.GENESIS_SERVICE_URL +
                environment.GENESIS_FEEDABCK +
                '?offset=' + pageIndex +
                '&limit=' + pageSize +
                '&order=' + order +
                filter, this.requestHelpers.getBFFHeader());
    }
}
