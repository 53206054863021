import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { JourneyFilterModel, JourneyFilterModelMini } from 'app/models/filter-model';
import { MatDialog } from '@angular/material';
import { JourneyListFilterDialogComponent } from './journey-list-filter-dialog/journey-list-filter-dialog.component';

@Component({
  selector: 'journey-list-filter',
  templateUrl: './journey-list-filter.component.html',
  styleUrls: ['./journey-list-filter.component.scss']
})
export class JourneyListFilterComponent implements OnInit, OnChanges {

  @Input() filterData: JourneyFilterModel;
  @Input() alreadyFiltered: JourneyFilterModelMini;
  @Input() disabled: Boolean;
  @Output() filterSelect = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog
  ) {
  }

  allFilterData: JourneyFilterModel;
  selectedFilterData: JourneyFilterModel;

  ngOnInit(): void {
    this.allFilterData = this.filterData;
    this.selectedFilterData = new JourneyFilterModel();
    const selectedComps = this.allFilterData.comps.filter(comp => this.alreadyFiltered.comps.includes(comp._id));
    const selectedOwners = this.allFilterData.owners.filter(owner => this.alreadyFiltered.owners.includes(owner.id));
    this.selectedFilterData = { ...this.selectedFilterData, comps: selectedComps, owners: selectedOwners };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filterData: SimpleChange = changes.filterData;
    if (filterData && !filterData.firstChange) {
      const current = <JourneyFilterModel>filterData.currentValue;
      if (current && current.comps) {
        this.allFilterData = current;
        const selectedComps = current.comps.filter(comp => this.alreadyFiltered.comps.includes(comp._id));
        this.selectedFilterData = { ...this.selectedFilterData, comps: selectedComps };
      }
      if (current && current.owners) {
        this.allFilterData = current;
        const selectedOwners = current.owners.filter(owner => this.alreadyFiltered.owners.includes(owner.id));
        this.selectedFilterData.owners = selectedOwners;
      }
    }
  }

  openFilterDialog(): void {
    const filterDialog = this.dialog.open(JourneyListFilterDialogComponent, {
      width: '30vw',
      height: 'auto',
      data: { allFilterData: { ...this.allFilterData }, selectedFilterData: { ...this.selectedFilterData } }
    });

    filterDialog.afterClosed().subscribe(result => {
      if (result) {
        this.selectedFilterData = result;
        this.filterSelect.emit(result);
      }
    });
  }

}
