import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { LocalStorageService } from 'ngx-store';
import { User } from 'app/models/user';

@Injectable()
export class UserListService {
    constructor(
        private http: HttpClient,
        private requestHelpers: RequestHelpers,
        private localStorageService: LocalStorageService,
    ) {
    }

    fetchManagers(mngrFilter, related): Observable<any> {
        const user = <User>this.localStorageService.get('user');
        return this.http
            .get(
                environment.DF_BASE_URL_HL +
                user.orgId +
                environment.DF_USER_MANAGER_MAP + mngrFilter + related, this.requestHelpers.getDFHeader())
    }

    fetchUserList(postData): Observable<any> {
        return this.http
            .post(
                environment.GENESIS_SERVICE_URL +
                environment.GET_MY_TEAM_USERS,
                postData,
                this.requestHelpers.getBFFHeader()
            );
    }
}
