import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ViewChildren,
    QueryList
} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {JourneyModel} from 'app/models/journey-model';
import {TakeJourneyService} from '../../take-journey.service';
import {firebaseService} from '../../../../../services/firebase.service';
import {LocalStorageService} from 'ngx-store';
import {User} from 'app/models/user';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {ManageSupporterComponent} from 'app/core/my-journeys/take-journey/dialogs/manage-supporter/manage-supporter.component';
import {MatDialog} from '@angular/material';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators/takeUntil';


@Component({
    selector: 'conversations-component',
    templateUrl: './conversations.component.html',
    styleUrls: ['./conversations.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ConversationsComponent implements OnInit, OnDestroy, AfterViewInit {
    journey: JourneyModel;
    activeSupporters: any;
    db: any;
    unsubscribeMessages;
    unsubscribeChannel;
    messages: any[];
    channelMembers = [];
    lastMessageTime: Date;
    isActive: Boolean;
    typedMessage: String;
    channelId: String;
    user: User;
    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private newTakeJourneyService: TakeJourneyService,
        private localStorageService: LocalStorageService,
        private _chatViewScrollbar: FusePerfectScrollbarDirective,
        public dialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.db = firebaseService.firestore();
        const settings = {timestampsInSnapshots: true};
        this.db.settings(settings);
        this.user = this.localStorageService.get('user');
        this.newTakeJourneyService.onJourneyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(journey => {
                this.journey = journey;
                this.activeSupporters = this.journey.supporterDetails.filter(user => user.active);
                this.getChannelInfo(this.journey.channelId);
            });
    }

    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
        if (this._chatViewScrollbar) {
            this._chatViewScrollbar.update();
            setTimeout(() => {
                this._chatViewScrollbar.scrollToBottom(0);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.unsubscribeMessages) {
            this.unsubscribeMessages();
        }
        if (this.unsubscribeChannel) {
            this.unsubscribeChannel();
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getChannelInfo = channelId => {
        if (!channelId) {
            return;
        }
        this.unsubscribeChannel = this.db
            .collection('channels')
            .doc(channelId)
            .onSnapshot(doc => {
                const data = doc.data();
                const members = data.membersMeta;
                this.channelMembers = [...members];
                let currentUser = members.filter(
                    x => x.userId === Number(this.user.id)
                );
                if (currentUser.length === 0) {
                    return true;
                } else {
                    currentUser = currentUser[0];

                    if (!currentUser.active) {
                        this.isActive = false;
                        this.lastMessageTime = currentUser.removedOn.toDate();
                    } else {
                        this.isActive = true;
                    }
                }

                if (this.unsubscribeMessages) {
                    this.unsubscribeMessages();
                }

                this.getFirestoreMessages();
            });
    }

    getFirestoreMessages = () => {
        let messagesRef;

        const isActive = this.isActive;

        if (isActive) {
            messagesRef = this.db
                .collection('messages')
                .where('channelId', '==', this.journey.channelId)
                .orderBy('createdOn', 'desc');
        } else {
            messagesRef = this.db
                .collection('messages')
                .where('channelId', '==', this.journey.channelId)
                .where('createdOn', '<=', this.lastMessageTime)
                .orderBy('createdOn', 'desc');
        }

        this.unsubscribeMessages = messagesRef.onSnapshot(snap => {
            const members = this.channelMembers;
            const messages = [];
            snap.forEach(doc => {
                const data = doc.data();
                let message = {};
                if (data.type === 'TEXT' || data.type === ' IMAGE') {
                    data.from = members.find(m => Number(m.userId) === Number(data.from.userId));
                }
                if (data.type === 'TEXT') {
                    message = {
                        ...data,
                        _id: doc.id,
                        text: data.content.text,
                        createdAt: data.createdOn.toDate(),
                        user: {
                            _id: data.from.userId,
                            name: data.from.firstName
                        }
                    };
                } else if (data.type === 'IMAGE' && data.content.url) {
                    message = {
                        ...data,
                        _id: doc.id,
                        text: data.content.text ? data.content.text : '',
                        image: data.content.url,
                        createdAt: data.createdOn.toDate(),
                        user: {
                            _id: data.from.userId,
                            name: data.from.firstName
                        }
                    };
                } else if (data.type === 'ACTIVITY') {
                    message = {
                        ...data,
                        _id: doc.id,
                        text: data.content.text,
                        createdAt: data.createdOn.toDate(),
                        system: true
                    };
                } else if (data.type === 'OPTIONS' && !data.selected) {
                    message = {
                        ...data,
                        _id: doc.id,
                        text: 'Options heree...',
                        createdAt: data.createdOn.toDate(),
                        user: {_id: data.from.userId, name: data.from.firstName}
                    };
                } else {
                    return true;
                }

                messages.unshift(message);
                if (this._chatViewScrollbar) {
                    this._chatViewScrollbar.update();
                    setTimeout(() => {
                        this._chatViewScrollbar.scrollToBottom(0);
                    });
                }
            });
            this.messages = messages;
        }, err => {
        });
    }

    sendMessage(): void {
        if (!this.typedMessage || !this.journey.channelId || !this.isActive) {
            return;
        }

        const message = this.typedMessage;
        const messageToSend = {
            channelId: this.journey.channelId,
            content: {
                mentions: [],
                mentionsMeta: [],
                text: message
            },
            from: {
                userId: Number(this.user.id),
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                publicProfileId: this.user.publicId ? this.user.publicId : null
            },
            createdOn: new Date(),
            type: 'TEXT'
        };

        this.db.collection('messages').add(messageToSend);
        this.typedMessage = '';

    }

    openManageSupportersDialog(): void {
        const dialogRef = this.dialog.open(ManageSupporterComponent, {
            width: '50vw',
            data: {
                details: this.journey,
                orgId: this.user.orgId,
                userId: this.user['id']
            },
        });

        const sub = dialogRef.componentInstance.onJourneyEdited.subscribe((journey) => {
            this.journey = journey;
            // call update journey subject
            this.newTakeJourneyService.updateJourneySubject(this.journey);
        });

        dialogRef.afterClosed().subscribe(result => {
            sub.unsubscribe();
        });
    }
}
