import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { LocalStorageService } from 'ngx-store';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ImageService } from 'app/services/images.service';

import { JourneyStatus } from 'app/enums/journey-status';
import { TaskType } from 'app/enums/task-type';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';

import { User } from 'app/models/user';
import { TakeJourneyService } from 'app/core/my-journeys/take-journey/take-journey.service';

@Component({
  selector: 'take-journey',
  templateUrl: './take-journey.component.html',
  styleUrls: ['./take-journey.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class TakeJourneyComponent implements OnInit, OnDestroy, AfterViewInit {

  routeSubscription: any;
  journey: JourneyModel;
  user: User;
  selectedJourneyRole = JourneyAccessRoles.taker;
  color = 'primary';
  mode = 'determinate';
  value = 0;
  bufferValue = 0;
  redirectTaskId: String;
  isManager: boolean;

  loadingJourney: boolean;

  constructor(
    private activatedroute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private imageService: ImageService,
    private newTakeJourneyService: TakeJourneyService
  ) {

  }

  ngOnInit(): void {

    // Get Users session info from localStorage
    this.user = this.localStorageService.get('user');

    // Get Journey id from URL and call getJourneyDetails function.
    this.routeSubscription = this.activatedroute.params.subscribe(data => {
      this.getJourneyDetails(data['id']);
      this.redirectTaskId = data['taskId'];
    });
  }


  ngAfterViewInit(): void {

  }

  checkIfUserIsManager(userId): Promise<boolean> {
    return new Promise((res, rej) => {
      const postData = {
        userId,
        orgId: this.user.orgId,
        managerId: this.user.id
      };
      this.newTakeJourneyService.checkIfUserIsManager(postData)
        .subscribe(
          (data: any) => {
            console.log(data);
            this.isManager = data['status'];
            res(this.isManager);
          }
        );
    });
  }
  getJourneyDetails(journeyId): void {

    this.loadingJourney = true;
    this.newTakeJourneyService.getJourneyDetails(journeyId, this.user.orgId)
      .subscribe(
        (data: any) => {
          if (data.resource && data.resource.length) {
            this.initJourneyDetails(data.resource[0])
              .then(response => {
                this.loadingJourney = false;
                this.journey = response;
                this.getTaskRules(this.journey);
                this.newTakeJourneyService.onJourneyChanged.next(this.journey);
              })
              .catch(error => {
                this.loadingJourney = false;
                this.openSnackBar(error.message, 'Ok');
                this.router.navigate(['/pagenotfound']);
              });
          } else {
            this.loadingJourney = false;
            this.openSnackBar('Something is not right. Try again', 'Ok');
            this.router.navigate(['/pagenotfound']);
          }
        },
        (error: any) => {
          this.loadingJourney = false;
          this.openSnackBar('Something is not right. Try again', 'Ok');
        }
      );
  }

  initJourneyDetails(journey: JourneyModel): Promise<JourneyModel> {
    return new Promise((res, rej) => {
      if (journey.dueDate && journey.dueDate['$date']) {
        journey.dueDate = journey.dueDate['$date'];
      }
      this.checkIfUserIsManager(journey.userId).then(data => {
        this.isManager = data;

        journey['isManager'] = this.isManager;
        if (this.isManager) {
          journey.role = JourneyAccessRoles.manager;
        }
        const selectedJourneyRole = this.localStorageService.get('journeyRole');
        if (selectedJourneyRole) {
          journey.role = selectedJourneyRole;
        } else if (journey.userId === this.user.id) {
          journey.role = JourneyAccessRoles.taker;
        } else {

          const supporterResult = journey.supporterDetails.findIndex(supporter => {
            return supporter.userId === this.user.id;
          });
          if (supporterResult === -1 && !this.isManager) {
            rej({ message: `You don't have access to this Journey!` });
          } else if (!this.isManager) {
            journey.role = JourneyAccessRoles.supporter;
          }
        }

        this.localStorageService.remove('journeyRole');

        journey.tasks = journey.tasks.sort((n1, n2) => {
          if (n1.taskNo > n2.taskNo) {
            return 1;
          } else {
            return -1;
          }
        });

        // Start task
        const startTask = new TaskModel();
        startTask.title = 'Start';
        startTask.status = JourneyStatus.not_started;
        startTask.taskType = TaskType.start;
        startTask.contentType = -1;
        journey.tasks.unshift(startTask);

        // End task
        const endTask = new TaskModel();
        endTask.title = 'Finish';
        endTask.status = JourneyStatus.not_started;
        endTask.taskType = TaskType.finish;
        endTask.contentType = -2;
        journey.tasks.push(endTask);

        journey.completedTasks = 0;

        journey.tasks.forEach(element => {
          if (element.status === JourneyStatus.completed) {
            journey['completedTasks']++;
          }

          if (element.feedback) {
            element.feedback.forEach(fb => {
              const supporter = journey.supporterDetails.find(sup => sup.userId === fb.userId);
              if (supporter) {
                fb['imageUrl'] = this.imageService.getUserProfileImage(supporter.publicId);
              } else {
                fb['imageUrl'] = this.imageService.getUserProfileImage(null);
              }
            });
          }
          if (element.taskType === 1) {
            if (element.rules &&
              element.rules.length > 0 &&
              element.rules[0]['selectedValue'] === 'true') {
              console.log('Not required');
              element['isKcRequired'] = false;
            } else { element['isKcRequired'] = true; }
          }
        });

        journey.supporterDetails.forEach(fb => {
          fb['imageUrl'] = this.imageService.getUserProfileImage(fb.publicId);
        });

        journey.isOverDue = new Date(journey.dueDate) < new Date() ? true : false;

        // Competency-level indicator data
        const endJourneyIndex = journey.alllevels.findIndex(level => level.id === journey.endLevelData['id']);

        for (let i = 0; i < journey.alllevels.length; i++) {
          if (endJourneyIndex > -1) {
            if (i < endJourneyIndex) {
              journey.alllevels[i]['levelStatus'] = 'active';
            }
            if (i === endJourneyIndex) {
              journey.alllevels[i]['levelStatus'] = 'current';
            }
          } else {
            journey.alllevels[0]['levelStatus'] = 'current';
          }
        }

        res(journey);
      });
    });
  }

  getTaskRules(journey: JourneyModel): void {

    const tasks = [];
    journey.tasks.forEach(task => {
      if (task.rules && task.rules.length) {
        tasks.push({
          rules: task.rules,
          taskType: task.taskType,
          taskId: task.taskId
        });
      }
    });

    if (!tasks.length) {
      return;
    } else {
      const postData = { tasks: tasks };

      this.newTakeJourneyService.getTaskRules(postData)
        .subscribe(
          (result: any) => {
            console.log(result);
            result.forEach(element => {
              const taskIndex = this.journey.tasks.findIndex(task => task.taskId === element.taskId);
              if (taskIndex !== -1) {
                this.journey.tasks[taskIndex]['ruleDisplayText'] = element.ruleDisplayText;
              }
            });
          },
          (error: any) => {
            console.log(error);
          });
    }
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ngOnDestroy(): void {

  }
}
