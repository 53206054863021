import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RequestHelpers } from 'app/services/request-helpers.service';
import { JourneyModel, TaskModel } from 'app/models/journey-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TaskType } from 'app/enums/task-type';
import { MixpanelManager } from 'app/services/mixpanel.service';
import { MixpanelEventType } from 'app/enums/mixpanel-events';

@Component({
  selector: 'feedback-request-component',
  templateUrl: 'feedback-request-component.html',
  providers: [RequestHelpers]
})
export class FeedbackRequestComponent {
  journey: JourneyModel;
  task: TaskModel;
  type: 1 | 2; // 1 - Task feedback 2 - Journey feedback
  fbRequestContent: string;
  isProcessingRequest: boolean;
  onJourneyEdited = new EventEmitter<Object>();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpers,
    public dialogRef: MatDialogRef<FeedbackRequestComponent>,
    public snackBar: MatSnackBar,
    private mix: MixpanelManager,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.journey = <JourneyModel>data.journey;
    this.task = <TaskModel>data.task;
    this.type = data.type;

    if (this.type === 1) {
      this.task.fbRequestContent = this.task.fbRequestContent
        ? this.task.fbRequestContent
        : '';
    } else {
      this.fbRequestContent = '';
    }
  }

  requestFeedback(message: string): void {
    if (!message || !message.length) {
      this.openSnackBar('Please type request text.', 'Ok');
      return;
    }

    this.isProcessingRequest = true;

    const postData = {
      from: undefined,
      to: [],
      type: 1,
      source: 1,
      orgId: undefined,
      requestMsg: undefined,
      journeyId: undefined
    };

    if (this.type === 1) {
      postData.from = this.data.user.id;
      postData.to = [];
      postData.orgId = this.data.user.orgId;
      postData.requestMsg = message;
      postData['taskId'] = this.task.taskId;
      postData.journeyId = this.journey._id;
      postData.type = 1;
      postData.source = 1;
    }

    if (this.type === 2) {
      postData.from = this.data.user.id;
      postData.to = [];
      postData.orgId = this.data.user.orgId;
      postData.requestMsg = message;
      postData.journeyId = this.journey._id;
      postData.type = 1;
      postData.source = 1;
    }

    if (this.type === 1) {
      if (this.task.taskType === TaskType.user_defined) {
        this.task.supporters.forEach(supporter => {
          postData.to.push(supporter.userId);
        });
      } else {
        this.journey.supporters.forEach(supporter => {
          postData.to.push(supporter.userId);
        });
      }
    } else {
      this.journey.supporters.forEach(supporter => {
        postData.to.push(supporter.userId);
      });
    }

    this.http
      .post(
        environment.GENESIS_SERVICE_URL +
        environment.GENESIS_CREATE_FEEDBACK_REQUEST,
        postData,
        this.requestHelpers.getBFFHeader()
      )
      .subscribe(
        (data: any) => {
          let feedbackId = null;
          if (data.fbId) {
            feedbackId = data.fbId;
          }
          // if ruleFollowed, make changes to journey object
          if (this.type === 1 && data.ruleFollowed) {
            const tempJourney = this.journey;
            const taskId = tempJourney.tasks.findIndex(task => task.taskId === this.task.taskId);
            if (taskId > -1) {
              const task = tempJourney.tasks[taskId];
              task.enableIsDone = true;
            }
          }
          this.mix.track(MixpanelEventType.FEEDBACK_REQUEST_CREATED, {
            feedbackId
          });
          this.openSnackBar('Request has been sent', 'Ok');
          this.isProcessingRequest = false;
          this.onNoClick({ success: true });
          this.onJourneyEdited.emit(this.journey);
        },
        (error: any) => {
          console.log(error);
          this.openSnackBar('Something went wrong! Try again.', 'Ok');
          this.isProcessingRequest = false;
          this.onNoClick({ success: false });
        }
      );
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }
}
