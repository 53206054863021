import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestHelpers } from 'app/services/request-helpers.service';

@Injectable()
export class JourneyListService {
    constructor(
        private http: HttpClient,
        private requestHelpers: RequestHelpers,
    ) { }

    fetchJourneys({ filter, pageIndex, pageSize, order, userId }): Observable<any> {
        return this.http
            .get(
                environment.GENESIS_SERVICE_URL +
                environment.GENESIS_JOURNEY +
                '?offset=' + pageIndex +
                '&limit=' + pageSize +
                '&order=' + order +
                '&appendCompDetails=1' +
                (userId ? '' : '&appendUserDetails=1') +
                (userId ? `&userFilter=(id=${userId})` : '') +
                filter, this.requestHelpers.getBFFHeader());
    }
}
