import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  Input
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { JourneyModel } from 'app/models/journey-model';
import { TaskType } from 'app/enums/task-type';
import { JourneyStatus } from 'app/enums/journey-status';
import { TakeJourneyService } from '../../take-journey.service';
import { JourneyAccessRoles } from 'app/enums/journey-access-roles';

@Component({
  selector: 'tasks-component',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})


export class TasksComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() redirectSelectedTask: String;
  animationDirection: 'left' | 'right' | 'none';

  journey: JourneyModel;
  showFBTab: Boolean = false;
  showKCTab: Boolean = false;
  taskTypes = TaskType;
  height: number;
  journeyStatuses = JourneyStatus;
  journeyAccessRoles = JourneyAccessRoles;
  selectedTaskId: String;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private newTakeJourneyService: TakeJourneyService,
  ) {
    this.animationDirection = 'none';
  }

  ngOnInit(): void {
    this.height = window.innerHeight;

    this.newTakeJourneyService.onJourneyChanged.subscribe(journey => {
      this.journey = journey;

      if (this.journey.status === JourneyStatus.not_started) {
        this.journey.ongoingTask = 0;
        this.journey.selectedTask = 0;
      } else {
        if (this.journey.tasks[this.journey.ongoingTask].taskType === this.taskTypes.assessment
          && this.journey.tasks[this.journey.ongoingTask].status === this.journeyStatuses.completed) {
          this.journey.ongoingTask = this.journey.tasks.length - 1;
          this.journey.selectedTask = this.journey.tasks.length - 1;
        } else {
          this.journey.ongoingTask = this.journey.ongoingTask;
          this.journey.selectedTask = this.journey.ongoingTask;
        }

        if (this.journey.tasks[this.journey.ongoingTask].isKcPresent &&
          this.journey.tasks[this.journey.ongoingTask].pendingKCheck &&
          this.journey.tasks[this.journey.ongoingTask].status !== JourneyStatus.completed) {
          this.showKCTab = true;
          this.selectedTaskId = this.journey.tasks[this.journey.ongoingTask].taskId;
        }

        if (this.journey.tasks[this.journey.ongoingTask].pendingFeedback && this.journey.role === this.journeyAccessRoles.taker) {
          this.showFBTab = true;
        } else {
          this.showFBTab = false;
        }
      }

      if (this.redirectSelectedTask) {
        const filterdTaskId = this.journey.tasks.findIndex(task => task.taskId === this.redirectSelectedTask)
        if (filterdTaskId > -1) {
          this.journey.selectedTask = filterdTaskId;
        }
      }


    });
  }

  ngAfterViewInit(): void {
  }

  gotoStep(step): void {
    this.animationDirection = this.journey.selectedTask < step ? 'left' : 'right';
    this._changeDetectorRef.detectChanges();

    this.journey.selectedTask = step;
    this.showFBTab = false;
    this.showKCTab = false;
  }

  selectFeedbackTab(task, index): void {
    this.journey.selectedTask = index;
    this.showFBTab = true;
    this.showKCTab = false;
  }

  selectKCTab(task, index): void {
    this.journey.selectedTask = index;
    this.selectedTaskId = task.taskId;
    this.showKCTab = true;
    this.showFBTab = false;
  }

  onTabChange(): void {
    this.showFBTab = true;
  }

  ngOnDestroy(): void {
  }

}

